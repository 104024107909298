//External Components
import React from "react";
import Grid from "@mui/material/Grid";
import { Checkmark } from "react-checkmark";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
//Internal Components
import axios from "../../shared/axios/axiosProxy";
import { withRouter } from "../../withRouter";
import i18n from "../../utils/i18n";
//REDUX
import { connect } from "react-redux";
const queryString = require("query-string");

const mapStateToProps = (state) => ({
  provider_package: state.provider_package,
  provider_subscription: state.provider_subscription,
  providerID: state.providerID,
});

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      JWT: localStorage.getItem("JWT"),
      queryString: queryString.parse(this.props.location.search),
    };
  }
  componentDidMount() {}
  numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
  handleClose = () => {
    this.setState({ bankTransfer: false });
  };
  processPaymentStatus = (paymentStatus) => {
    const JWT = this.state.JWT;
    axios
      .patch(
        "/api/provider/subscription",
        {
          transactionData: {
            paymentID: paymentStatus.t,
            paymentStatus,
            providerID: this.props.providerID,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("processPaymentStatus RESPONSE: ", response);
        this.openInNewTab(response.data.result);
      })
      .catch((error) => {
        console.log("processPaymentStatus ERROR: ", error.response.data);
      })
      .catch((error) => console.log("Start Payment: ", error));
    this.props.navigate(`/provider/paymentstatus`);
  };
  setCardPayment = (cardPayment) => {
    let data = {
      provider_subscription: {
        activeSubscription: this.props.provider_subscription.activeSubscription,
        cardPayment: cardPayment,
        paidUntil: this.props.provider_subscription.paidUntil,
      },
    };
    const JWT = this.state.JWT;
    axios
      .patch("/api/provider", JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${JWT}`,
          "Content-Type": "application/json",
        },
      })

      .then((response) => {
        console.log("AXIOS RESPONSE: ", response);
      })
      .catch((error) => {
        console.log("AXIOS ERROR: ", error.response.data);
      })
      .catch((error) => console.log("submitToDB error", error));
  };
  parseQuery() {
    var queryData = this.state.queryString;
    let buffR = new Buffer(queryData.r, "base64").toString("ascii");
    /* eslint-disable no-unused-vars */
    let buffS = new Buffer(queryData.s, "base64").toString("ascii");
    /* eslint-disable no-unused-vars */
    let paymentStatus = JSON.parse(buffR);
    this.processPaymentStatus(paymentStatus);
    console.log("PAYMENT RESPONSE: ", paymentStatus);
    return paymentStatus;
  }
  statusMessage = () => {
    var responseObject = this.parseQuery();
    switch (responseObject.e) {
      case "SUCCESS":
        this.setCardPayment(true);
        /* this.activateSubscription(); */
        return <>
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 style={{ marginRight: "0.5em" }}>
              {i18n.t("general.success")}
            </h1>
          </Grid>

          <Grid
            container
            style={{ paddingTop: "2em" }}
            direction="row"
            sm={12}
          >
            <Grid
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
              className="ClientID-container"
              style={{ padding: "3em" }}
            >
              <p style={{ margin: "0.2em" }} className="large-text">
                {i18n.t("general.simplepayid")}{" "}
                <b>{responseObject.t}</b>
              </p>
            </Grid>
            <Checkmark color="#093c77" />
          </Grid>
        </>;
      case "FAIL":
        return <>
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 style={{ marginRight: "0.5em" }}>
              {i18n.t("general.unsuccessful")}
            </h1>
          </Grid>

          <Grid
            container
            style={{ paddingTop: "2em" }}
            direction="row"
            sm={12}
          >
            <Grid
              item
              xs={12}
              alignItems="center"
              justifyContent="center"
              className="ClientID-container"
              style={{ padding: "3em" }}
            >
              <p style={{ margin: "0.2em" }} className="large-text">
                {i18n.t("general.simplepayid")}{" "}
                <b>{responseObject.t}</b>
                <br />
                <br />
                {i18n.t("general.checkdatatext")}
                <br />
                {i18n.t("general.checkdatatext2")}
              </p>
            </Grid>
          </Grid>
        </>;
      case "CANCEL":
        return <>
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 style={{ marginRight: "0.5em" }}>
              {i18n.t("general.paymentstopped")}
            </h1>
          </Grid>
        </>;
      case "TIMEOUT":
        return <>
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 style={{ marginRight: "0.5em" }}>
              {i18n.t("general.authfailed")}
            </h1>
          </Grid>
        </>;
      default:
        return <>
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <h1 style={{ marginRight: "0.5em" }}>
              {i18n.t("general.transactionfailed")}
            </h1>
          </Grid>
        </>;
    }
  };

  render() {
    return (
      <Paper className={"paper-container"} elevation={2}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="row"
        >
          {this.statusMessage()}
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "4em" }}
          >
            <Link to="/provider/providersummary?save">
              <Button className="button-provider-request">
                {i18n.t("general.backtoprofile")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(withTranslation()(connect(mapStateToProps)(Payment)));
