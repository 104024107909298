import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import {
  foreignTradeExtra,
  invoicesExtra,
  providerCompanyTypeList,
  revenueExtra,
  vatExtra,
  etradeExtra,
  tourismExtra,
  severalLocationsExtra,
} from '../../../../../data/providerText';
import { NumberFormatCustom } from '../../../../../../shared/components/formattedInput/formattedInputs';
import i18n from '../../../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import ExtraFeeInputs from './extraFeeInputs';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const AccountingInputs = ({ ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [accountingPrice, companyTypeFilter, specialRestr, liabilityIns] =
    useSelector((state) => [
      state.provider_acc_monthly_price,
      state.provider_business_filter,
      state.provider_special_restrictions,
      state.provider_liability_insurance,
      state.provider_restrictions,
      state.priceCategory,
    ]);

  const handleChange = (event, storeObj) => {
    props.setModified('accounting', true);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };

  return (
    <div className='PriceInputContainer'>
      <p className='FullWidth'>{i18n.t('general.accounting')}</p>
      <div className='FullWidth'>
        <FormGroup>
          <FormControlLabel
            style={{
              // margin: '6px 0px 6px -11px'
              width: 'fit-content',
            }}
            control={
              <Checkbox
                id='accounting'
                checked={liabilityIns.accounting}
                onChange={(event) => {
                  props.setModified('accounting', true);
                  dispatch(
                    ACTIONS.addProviderInfo({
                      id: event.target.id,
                      data: event.target.checked,
                      stateObject: 'provider_liability_insurance',
                    })
                  );
                }}
              />
            }
            label={
              <span className='SFCheckboxLabel'>
                {i18n.t('provider.accountingins')}
              </span>
            }
          />
        </FormGroup>
      </div>
      {providerCompanyTypeList().map((item, i) => {
        if (companyTypeFilter[item.id]) {
          return (
            <div className='AdornmentInputContainer Price' key={i}>
              <TextField
                className={classes.root}
                id={`${item.id}price`}
                variant='outlined'
                type='text'
                /*   placeholder={item.label} */
                value={accountingPrice[`${item.id}price`]}
                label={item.label}
                onChange={(event) => {
                  handleChange(event, 'provider_acc_monthly_price');
                  if (event.target.value && inputError[`${item.id}price`]) {
                    setInputError({
                      ...inputError,
                      [`${item.id}price`]: false,
                    });
                  }
                }}
                error={inputError[`${item.id}price`]}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setInputError({ ...inputError, [`${item.id}price`]: true });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span
                        style={{
                          color: inputError[`${item.id}price`]
                            ? 'red'
                            : '#2D46B9',
                        }}
                      >
                        {i18n.t('general.currency/month')}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        } else return null;
      })}
      <h3 className='FullWidth'>Felárak</h3>
      <ExtraFeeInputs
        dataText={invoicesExtra()}
        title='Bejövő-kimenő számlák szerinti felárak'
        endAdornment='Ft / db'
        setModified={props.setModified}
      />
      {specialRestr.insideEU || specialRestr.outsideEU ? (
        <ExtraFeeInputs
          dataText={foreignTradeExtra()}
          title='Külkereskedelem szerinti felárak'
          endAdornment='%'
          setModified={props.setModified}
        />
      ) : null}
      <ExtraFeeInputs
        revenue
        dataText={revenueExtra()}
        title='Árbevétel szerinti felárak'
        endAdornment='%'
        setModified={props.setModified}
      />
      <ExtraFeeInputs
        dataText={vatExtra()}
        title='Áfa bevallás gyakorisága szerinti felárak'
        endAdornment='%'
        setModified={props.setModified}
      />
      <ExtraFeeInputs
        dataText={severalLocationsExtra()}
        title='Egyéb felárak'
        endAdornment='Ft / telephely'
        setModified={props.setModified}
      />
      <ExtraFeeInputs
        dataText={etradeExtra()}
        title='Webshop felárak'
        endAdornment='Ft'
        setModified={props.setModified}
      />
      <ExtraFeeInputs
        dataText={tourismExtra()}
        title='Szállás, vendéglátás felárak'
        endAdornment='Ft'
        setModified={props.setModified}
      />
    </div>
  );
};

export default AccountingInputs;
