import React from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import { loginDataToSave, validateInput } from '../../data/providerText';
import axios from '../../../shared/axios/axiosProxy';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { withRouter } from '../../../withRouter';
//REDUX
import ACTIONS, { fetchProviderData } from '../../../redux/action';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  store: state,
  provider_info: state.provider_info,
});

const mapDispatchToProps = (dispatch) => ({
  addProviderInfo: (item) => dispatch(ACTIONS.addProviderInfo(item)),
  fetchProviderData: (JWT) => dispatch(fetchProviderData(JWT)),
});

class LoginInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      activationError: '',
      passwordError: '',
      emailError: '',
      showPassword: false,
    };
  }
  loginProvider = async (e) => {
    e.preventDefault();
    /*   var finishedRegistration = false; */
    const { email, password } = this.state;
    if (email === '' || password === '') {
      this.setState({
        showError: false,
        showNullError: true,
        loggedIn: false,
      });
    } else {
      try {
        await axios
          .post('/api/auth/login', {
            email,
            password,
          })
          .then(async (response) => {
            /*   finishedRegistration = response.data.finishedRegistration; */
            localStorage.setItem('JWT', response.data.JWT);
            await this.props.fetchProviderData(response.data.JWT);
            loginDataToSave().forEach((item) => {
              let itemId = item.id;
              this.props.addProviderInfo({
                id: 'email',
                data: response.data.email,
                stateObject: 'provider_info',
              });
              this.props.addProviderInfo({
                id: itemId,
                data: response.data[itemId],
                stateObject: 'userData',
              });
            });
            this.trackLogin({
              providerID: response.data.id,
              companyName: this.props.provider_info.companyname,
            });
            response.data.finishedRegistration
              ? this.props.navigate('/provider/dashboard')
              : this.props.navigate('/provider/data');
          });
      } catch (error) {
        if (error.response.data === 'bad username') {
          console.log('USER NOT REGISTERED');
          this.setState({
            emailError: i18n.t('provider.notregistered'),
          });
        }
        if (error.response.data === 'passwords do not match') {
          console.log("PASSWORD DOESN'T MATCH");
          this.setState({
            passwordError: i18n.t('provider.wrongpw'),
          });
        }
        if (
          error.response.data.message &&
          error.response.data.message === 'user not confirmed'
        ) {
          console.log('NOT CONFIRMED');
          this.setState({
            activationError: i18n.t('provider.notactivated'),
          });
        }
      }
    }
  };
  trackLogin = (data) => {
    data.screenWidth = `${window.innerWidth}`;
    axios
      .post('/api/activity', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('CREATE ACTIVITY RESPONSE', response.statusText);
      })
      .catch((error) => {
        console.log('CREATE ACTIVITY ERROR: ', error);
      })
      .catch((error) => console.log('CREATE ACTIVITY CLIENT ERROR', error));
  };
  handleChange = (event) => {
    if (this.state[`${event.target.id}Error`].length > 0) {
      this.setState({
        [`${event.target.id}Error`]: validateInput(
          event.target.id,
          event.target.value
        )[event.target.id],
      });
    }
    this.setState({ [event.target.id]: event.target.value });
  };

  handleBlur = (event) => {
    if (event.target.id === 'password') {
      this.setState({
        passwordError: validateInput('password', event.target.value).password,
      });
    }
    if (event.target.id === 'email') {
      this.setState({
        emailError: validateInput('email', event.target.value).email,
      });
    }
  };
  render() {
    return (
      <div>
        <TextField
          id='email'
          className='input'
          type='email'
          name='email'
          value={this.state.email}
          label={i18n.t('general.emaillabel')}
          onChange={(event) => {
            this.handleChange(event);
          }}
          onBlur={(event) => {
            this.handleBlur(event);
          }}
          error={this.state.emailError ? true : false}
        />
        <span className='ErrorText'>{this.state.emailError}</span>
        <TextField
          style={{ marginTop: '24px' }}
          id='password'
          autoComplete='new-password'
          type={this.state.showPassword ? 'text' : 'password'}
          name='password'
          label={i18n.t('general.pw')}
          value={this.state.password}
          onChange={(event) => {
            this.handleChange(event);
          }}
          onBlur={(event) => {
            this.handleBlur(event);
          }}
          error={this.state.passwordError ? true : false}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' tabIndex='-1'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() =>
                    this.setState({
                      showPassword: !this.state.showPassword,
                    })
                  }
                  size='large'
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <span className='ErrorText'>{this.state.passwordError}</span>
        <span className='ErrorText'>{this.state.activationError}</span>
        <div className='MarginH24 FullWidth '>
          <StyledButton
            color='Primary'
            onClick={(e) => {
              this.loginProvider(e);
            }}
          >
            {i18n.t('provider.login')}
          </StyledButton>
        </div>
        <div className='MarginH24 FullWidth '>
          <a
            className='StandardText SecondaryBlue'
            href='/provider/forgotpassword'
          >
            {i18n.t('provider.forgotpw')}
          </a>
        </div>
        <div className='MarginH12 FullWidth '>
          <span className='StandardText'>
            {i18n.t('provider.noaccount')}{' '}
            <span>
              <a className='SecondaryBlue' href='/provider/register'>
                {i18n.t('provider.registerlink')}
              </a>
            </span>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginInputs))
);
