import React from 'react';
import {
  providerCompanyIndustryList,
  providerCompanyTypeList,
  TaxformInput,
} from '../../../../data/providerText';
import PreferencesInputs from '../inputs/preferencesInputs';
import RestrictionInputs from '../inputs/restrictionInputs';
import SpecialRestrictionInputs from '../inputs/specialRestrictionInputs';
import RestrictionRangeInputs from '../inputs/restrictionRangeInputs';
import i18n from '../../../../../utils/i18n';

const Restrictions = ({ submitToDB }) => {
  return (
    <div className='PaddingBot100'>
      <div className='ProviderInputContainerCentered'>
        <p className='ProviderInputTitle'>
          {i18n.t('providerdashboard.restrictionstext1')}
        </p>
        <p className='ProviderInputSubTitle'>
          {i18n.t('providerdashboard.restrictionstext2')}
        </p>
        <div className='RestrictionsNotification'>
          <h2>{i18n.t('provider.warning')}</h2>
          <p>{i18n.t('providerdashboard.restrictionstext3')}</p>
        </div>
        <p className='RestrictionTitle'>{i18n.t('provider.industryrest')}</p>
          <PreferencesInputs
            submitToDB={submitToDB}
            dataText={providerCompanyIndustryList()}
            storeObj='provider_industry_restrictions'
          />
        <p className='RestrictionTitle'>{i18n.t('provider.companyrest')}</p>
        <RestrictionInputs
          submitToDB={submitToDB}
          dataText={providerCompanyTypeList()}
          storeObj='provider_business_filter'
        />
        <p className='RestrictionTitle'>
          {i18n.t('provider.taxformfilterlabel')}
        </p>
        <RestrictionInputs
          submitToDB={submitToDB}
          dataText={TaxformInput()}
          storeObj='provider_vatform_restrictions'
        />
        <p className='RestrictionTitle'>{i18n.t('provider.specialrest')}</p>
        <SpecialRestrictionInputs submitToDB={submitToDB} />
        <p className='RestrictionTitle'>{i18n.t('provider.companysizerest')}</p>
        <RestrictionRangeInputs submitToDB={submitToDB} />
      </div>
    </div>
  );
};

export default Restrictions;
