import React, { useEffect, useCallback, useState } from 'react';
import StyledButton from '../../../shared/components/styledButton/styledButton.jsx';

import pp from '../../../images/pp.png';
import i18n from '../../../utils/i18n.js';
import { withTranslation } from 'react-i18next';
import ContactInfo from '../components/contactInfo/contactInfo.jsx';
import ACTIONS from '../../../redux/action.js';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { gdprLabelSF } from '../data/regFlowText.js';
import axios from '../../utils/axiosCompanyProxy.js';
import Alert from '@mui/material/Alert';
import { Switch } from '@mui/material';
import { CleaveInput } from '../../../shared/components/formattedInput/formattedInputs.jsx';
import { TextField } from '@mui/material';

const RegContactForm = ({ ...props }) => {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyLegalName, setCompanyLegalName] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [companyTaxnumber, setCompanyTaxnumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [gdprError, setGdprError] = useState(false);
  const [isReqSent, setIsReqSent] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);

  useEffect(() => {
    // console.log("UPDATED COMPANYSTORE: ", companyStore);
    // console.log(companyStore.legaladdress.length);
    // console.log(!!companyStore.legaladdress);
  }, [companyStore]);

  const dataLayerUpdate = () => {
    window.dataLayer.push({
      event: 'sf_step_reg_request_sent',
      event_category: 'regflow',
    });
  };

  const requestCallBack = (companyData) => {
    let data = Object.assign({}, companyData);
    //console.log(data, 'ASPO REQ DATA');

    axios
      .post(`/api/aspreq/requestcallback/new`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //console.log('AXIOS RESPONSE: ', response);
        this.setState({ reqID: response.data.result._id });
        this.props.addCompanyInfo({
          id: 'reqID',
          data: response.data.result._id,
          stateObject: 'company_store',
        });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  //console.log(requestCallBack, 'requestCallBack');

  useEffect(() => {
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'reqFromBupa',
        data: false,
        stateObject: 'company_store',
      })
    );
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'callbacksource',
        data: 'regrequest',
        stateObject: 'company_store',
      })
    );
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'leadtype',
        data: 'REG',
        stateObject: 'company_store',
      })
    );
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'isCallBackReq',
        data: false,
        stateObject: 'company_store',
      })
    );
  }, []);

  const validateInput = useCallback(() => {
    const validEmailRegex = RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    return validEmailRegex.test(companyStore.email);
  }, [companyStore.email]);
  const validateName = useCallback(() => {
    if (companyStore.companyctcname.trim().length > 0) {
      return true;
    }
    return false;
  }, [companyStore.companyctcname]);

  const validatePhone = useCallback(() => {
    if (companyStore.companyPhone.length >= 8) {
      return true;
    }
    return false;
  }, [companyStore.companyPhone]);

  useEffect(() => {
    if (emailError && validateInput()) {
      setEmailError(false);
    }
    if (gdprError && companyStore.gdpr) {
      setGdprError(false);
    }
    if (phoneError && validatePhone()) {
      setPhoneError(false);
    }
    if (nameError && validateName()) {
      setNameError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyStore.gdpr,
    validateInput,
    companyStore,
    emailError,
    gdprError,
    phoneError,
    nameError,
    validateName,
    validatePhone,
  ]);

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  return (
    // <div className='SFContentArea'>
    <div>
      <div className='stepContainer'>
        <div>
          {/* {alertOpen && (
            <>
              <div className='regContactOverlay' />
              <Alert
                severity='success'
                style={{
                  position: 'relative',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
                onClick={() => setAlertOpen(false)}
              >
                {i18n.t('searchflow.callBackReqSent')}
              </Alert>
            </>
          )} */}
          {/* <div style={{ padding: '12px' }}> */}
          <div>
            {/* <p className='noprovtitle'>{i18n.t('searchflow.sfresulttext2')}</p>
          <p className='noprovtext'>{i18n.t('searchflow.whatnowtext')}</p> */}
            <ContactInfo />
            <div>
              <p className='noprovtitle'>
                {i18n.t('searchflow.invoicingdetails')}
              </p>
              {i18n.t('general.noncompany')}
              <Switch
                checked={isCompany}
                onChange={() => {
                  setIsCompany(!isCompany);
                }}
              />
              {i18n.t('general.company')}
              {/* <StyledButton
              style={{ width: 'fit-content !important' }}
              color={isCompany ? 'NewNavBack' : 'NewReqBtn'}
              onClick={() => setIsCompany(false)}
            ></StyledButton>
            <StyledButton
              style={{ width: 'fit-content !important' }}
              color={!isCompany ? 'NewNavBack' : 'NewReqBtn'}
              onClick={() => setIsCompany(true)}
            ></StyledButton> */}
              {isCompany ? (
                <>
                  <TextField
                    style={{ marginTop: '12px' }}
                    label={i18n.t('general.obligcompanyname')}
                    value={companyLegalName}
                    id='companylegalname'
                    name='companylegalname'
                    onChange={(event) => {
                      setCompanyLegalName(event.target.value);
                      dispatch(
                        ACTIONS.addCompanyInfo({
                          id: event.target.id,
                          data: event.target.value,
                          stateObject: 'company_store',
                        })
                      );
                    }}
                  />
                  <TextField
                    style={{ marginTop: '12px' }}
                    label={i18n.t('general.obligaddress')}
                    value={legalAddress}
                    placeholder='pl.: 1133 Budapest Váci 110'
                    id='legaladdress'
                    name='legaladdress'
                    onChange={(event) => {
                      setLegalAddress(event.target.value);
                      dispatch(
                        ACTIONS.addCompanyInfo({
                          id: event.target.id,
                          data: event.target.value,
                          stateObject: 'company_store',
                        })
                      );
                    }}
                  />
                  <TextField
                    style={{ marginTop: '12px' }}
                    label={i18n.t('general.obligtaxnumber')}
                    value={companyTaxnumber}
                    id='companytaxnumber'
                    name='companytaxnumber'
                    placeholder='12345678-9-10'
                    onChange={(event) => {
                      setCompanyTaxnumber(event.target.value);
                      dispatch(
                        ACTIONS.addCompanyInfo({
                          id: event.target.id,
                          data: event.target.value,
                          stateObject: 'company_store',
                        })
                      );
                    }}
                    InputProps={{ inputComponent: CleaveInput }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />{' '}
                </>
              ) : (
                <TextField
                  style={{ marginTop: '12px' }}
                  label={i18n.t('general.obligaddress')}
                  placeholder='pl.: 1133 Budapest Váci 110'
                  value={legalAddress}
                  id='legaladdress'
                  name='legaladdress'
                  onChange={(event) => {
                    setLegalAddress(event.target.value);
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: event.target.id,
                        data: event.target.value,
                        stateObject: 'company_store',
                      })
                    );
                  }}
                />
              )}
            </div>
            <div style={{ margin: '12px 0' }}>
              <FormControlLabel
                key={'marketing'}
                control={
                  <Checkbox
                    id='marketing'
                    name='checked'
                    checked={companyStore.marketing}
                    onChange={(event) => {
                      dispatch(
                        ACTIONS.addCompanyInfo({
                          id: event.target.id,
                          data: event.target.checked,
                          stateObject: 'company_store',
                        })
                      );
                    }}
                    color='primary'
                  /*    disabled={this.state.submitted} */
                  />
                }
                label={
                  <span className='SFSmallText'>
                    {i18n.t('searchflow.newslettersubtext')}
                  </span>
                }
              />
              <FormControlLabel
                key={'gdpr'}
                control={
                  <Checkbox
                    id='gdpr'
                    name='gdpr'
                    checked={companyStore.gdpr}
                    onChange={(event) => {
                      dispatch(
                        ACTIONS.addCompanyInfo({
                          id: event.target.id,
                          data: event.target.checked,
                          stateObject: 'company_store',
                        })
                      );
                    }}
                    color='primary'
                    required
                  /*  disabled={this.state.submitted} */
                  />
                }
                label={gdprLabelSF()}
              />
            </div>
            {gdprError ? (
              <span
                style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
              >
                {i18n.t('searchflow.plsacceptgdpr')}
              </span>
            ) : null}
          </div>
        </div>
        <div className='SFButtonContainer'>
          <StyledButton
            color='NewNavBack'
            onClick={(e) => {
              handleBack();
            }}
          >
            {i18n.t('general.backbutton')}
          </StyledButton>
          {!isReqSent && (
            <StyledButton
              isDisabled={
                (isCompany && (
                  !companyStore.companylegalname ||
                  companyStore.companylegalname.trim().length === 0 ||
                  !companyStore.companytaxnumber ||
                  companyStore.companytaxnumber.trim().length === 0
                )) ||
                !companyStore.legaladdress ||
                companyStore.legaladdress.trim().length === 0 ||
                !companyStore.companyctcname ||
                companyStore.companyctcname.length === 0 ||
                !companyStore.companyPhone ||
                companyStore.companyPhone.length < 10 ||
                !companyStore.email ||
                companyStore.email.length === 0 ||
                !validateInput() ||
                !companyStore.gdpr
              }
              color='NewReqBtn'
              onClick={() => {
                dataLayerUpdate();
                requestCallBack(companyStore);
                dispatch(
                  ACTIONS.addCompanyInfo({
                    id: 'submitted',
                    data: true,
                    stateObject: 'company_store',
                  })
                );
                setIsReqSent(true);
                setAlertOpen(true);
                setTimeout(() => {
                  window.location.replace(
                    'http://www.bookkeepie.com/hu/thank-you-ev-reg'
                  );
                }, 3000);
              }}
            >
              {i18n.t('searchflow.order')}
            </StyledButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(RegContactForm);
