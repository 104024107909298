import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function PreferencesInputs({ storeObj, dataText }) {
  const [allChecked, setAllChecked] = React.useState(true);
  const dispatch = useDispatch();
  const providerRestrictions = useSelector((state) => state[storeObj]);

  React.useEffect(() => {
    dataText.forEach((item) => {
      if (!providerRestrictions[item.id]) {
        setAllChecked(false);
        return;
      } else {
        setAllChecked(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (event, storeObj) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: storeObj,
      })
    );
  };
  const checkAll = (event) => {
    dataText.forEach((item) => {
      dispatch(
        ACTIONS.addProviderInfo({
          id: item.id,
          data: event.target.checked,
          stateObject: storeObj,
        })
      );
    });
  };
  return (
    <div className='ProviderInputContainerCentered'>
      <div className='RestrictionInputAll'>
        <p style={{ color: '#2D46B9' }}>{i18n.t('provider.tickall')}</p>
        <GreenSwitch
          {...label}
          id='all'
          checked={allChecked}
          /*   checked={providerRestrictions[item.id]} */
          onChange={(event) => {
            setAllChecked(!allChecked);
            checkAll(event);
          }}
        />
        <span style={{ color: !allChecked ? 'grey' : '#28b446' }}>
          {!allChecked
            ? `${i18n.t('provider.idontwantit')}`
            : `${i18n.t('provider.iwantit')}`}
        </span>
      </div>
      <div className='ProviderInputContainer' style={{ padding: '12px 24px' }}>
        {dataText.map((item, i) => {
          return (
            <div className='CenteredInput FullWidth' key={i}>
              <p>{item.label}</p>
              <GreenSwitch
                {...label}
                id={item.id}
                checked={providerRestrictions[item.id]}
                onChange={(event) => {
                  handleChange(event, storeObj);
                }}
              />
              {providerRestrictions[item.id] ? (
                <span style={{ color: '#28b446' }}>
                  {i18n.t('provider.iwantit')}
                </span>
              ) : (
                <span style={{ color: 'grey' }}>
                  {i18n.t('provider.idontwantit')}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withTranslation()(PreferencesInputs);
