import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

//MUI
import Container from '@mui/material/Container';
import axios from '../../shared/axios/axiosProxy';
import StyledButton from '../../shared/components/styledButton/styledButton';
import { withRouter } from '../../withRouter';

class Confirmation extends React.Component {
  componentWillMount() {
    const { JWT } = this.props.params;
    axios.get(`/api/auth/register/${JWT}`, {}).catch((error) => {
      console.log('AXIOS ERROR: ', error.response.data);
    });
  }
  render() {
    return (
      <Container>
        <div className='FlexFullCenter Column FullWidth'>
          <h2
            style={{
              fontSize: '36px',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            {i18n.t('provider.successfulreg')}
          </h2>
          <Link to='/'>
            <StyledButton color='Primary'>
              {i18n.t('provider.login')}
            </StyledButton>
          </Link>
        </div>
      </Container>
    );
  }
}

export default withTranslation()(withRouter(Confirmation));
