//External Component
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//CSS
//REDUX
import { useDispatch } from 'react-redux';
import ACTIONS from '../../redux/action';
import { persistor } from '../../redux/store';
const Signout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('JWT');
    localStorage.removeItem('token');
    /*localStorage.removeItem("sync_storageMap");
    localStorage.removeItem("persist:root"); */
    persistor.purge();
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'status',
        data: false,
        stateObject: 'loggedIn',
      })
    );
    window.location.href = process.env.URL_REDIRECT_MAIN_HU;
    navigate('/');
  }, [dispatch, navigate]);

  return <></>;
};

export default Signout;
