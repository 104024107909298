import React from 'react';
import TextField from '@mui/material/TextField';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { Alert, Snackbar } from '@mui/material';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../../../utils/i18n';

const Description = ({ submitToDB }) => {
  const [edit, setEdit] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const [providerInfo] = useSelector((state) => [state.provider_info]);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setModified(true);
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.value,
        stateObject: 'provider_info',
      })
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <div className='PublicProfile'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <div className='PublicProfileContent FullWidth'>
        <h2>
          {i18n.t('general.description')}
          {providerInfo.description ? null : (
            <p>{i18n.t('general.pleasefill')}</p>
          )}
        </h2>
        {edit ? (
          <TextField
            id='description'
            variant='outlined'
            type='text'
            multiline
            rows={5}
            value={providerInfo.description}
            onChange={(event) => {
              handleChange(event, 'provider_info');
            }}
            onBlur={(event) => {
              dispatch(
                ACTIONS.addProviderInfo({
                  id: event.target.id,
                  data: event.target.value,
                  stateObject: 'provider_info',
                })
              );
            }}
          />
        ) : (
          <p className='StandardText18'>
            {providerInfo.description
              ? providerInfo.description
              : i18n.t('general.empty')}
          </p>
        )}
        <StyledButton
          onClick={() => {
            setEdit(!edit);
            if (edit && modified) {
              submitToDB({
                'provider_info.description': providerInfo.description,
              });
              setModified(false);
              setOpen(true);
            }
          }}
          color={'Primary'}
        >
          {edit
            ? modified
              ? i18n.t('general.save')
              : i18n.t('general.backbutton')
            : i18n.t('general.modify')}
        </StyledButton>
      </div>
    </div>
  );
};

export default Description;
