import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import { NumberFormatCustom } from '../../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const PricingInputs = ({ dataText, storeObj, ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const pricing = useSelector((state) => state[storeObj]);
  const [liabilityIns] = useSelector((state) => [
    state.provider_liability_insurance,
  ]);

  const handleChange = (event, storeObj) => {
    props.setModified(props.liabilityID, true);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };

  return (
    <div className='PriceInputContainer'>
      <div className='FullWidth'>
        <FormGroup>
          <FormControlLabel
            style={{
              // margin: "6px 0px 6px -11px",
              width: 'fit-content',
            }}
            control={
              <Checkbox
                id={props.liabilityID}
                checked={liabilityIns[props.liabilityID]}
                onChange={(event) => {
                  props.setModified(props.liabilityID, true);
                  dispatch(
                    ACTIONS.addProviderInfo({
                      id: event.target.id,
                      data: event.target.checked,
                      stateObject: 'provider_liability_insurance',
                    })
                  );
                }}
              />
            }
            label={
              <span className='SFCheckboxLabel'>{props.liabilityTitle}</span>
            }
          />
        </FormGroup>
      </div>
      {dataText.map((item, i) => {
        return (
          <div className='AdornmentInputContainer Price' key={i}>
            <TextField
              className={classes.root}
              id={item.id}
              variant='outlined'
              type='text'
              /*   placeholder={item.label} */
              value={pricing[item.id]}
              label={item.label}
              onChange={(event) => {
                handleChange(event, storeObj);
                if (event.target.value && inputError[item.id]) {
                  setInputError({
                    ...inputError,
                    [item.id]: false,
                  });
                }
              }}
              error={inputError[item.id]}
              onBlur={(event) => {
                if (!event.target.value) {
                  setInputError({ ...inputError, [item.id]: true });
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <InputAdornment position='end'>
                    <span
                      style={{
                        color: inputError[item.id] ? 'red' : '#2D46B9',
                      }}
                    >
                      {props.endAdornment}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PricingInputs;
