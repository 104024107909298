import React from 'react';
import { providerFilterSliders } from '../../../../data/providerText';
import TextField from '@mui/material/TextField';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { Alert, Snackbar } from '@mui/material';

//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../../../utils/i18n';

const RestrictionRangeInputs = ({ ...props }) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const providerRestrictions = useSelector(
    (state) => state.provider_restrictions
  );
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleChange = (event, index, id) => {
    setModified(true);
    let restrArray = providerRestrictions[id];
    let value = event.target.value;
    if (id === 'incominginvoices' || id === 'outgoinginvoices') {
      if (parseInt(event.target.value) <= 20000 || !event.target.value) {
        value = event.target.value;
      } else value = 20000;
    }
    if (id === 'noofemployees') {
      if (parseInt(event.target.value) <= 250 || !event.target.value) {
        value = event.target.value;
      } else value = 250;
    }
    if (id === 'yearlyrevenue') {
      if (parseInt(event.target.value) <= 2000 || !event.target.value) {
        value = event.target.value;
      } else value = 2000;
    }
    restrArray.splice(index, 1, parseInt(value));
    dispatch(
      ACTIONS.addProviderInfo({
        id: id,
        data: restrArray,
        stateObject: 'provider_restrictions',
      })
    );
  };
  const checkCompanySizeRestrictions = () => {
    let filled = true;
    providerFilterSliders().forEach((item) => {
      if (!filled) {
        return;
      }
      providerRestrictions[item.id].forEach((index) => {
        if (!index && index !== 0) {
          filled = false;
          setError(true);
          return filled;
        }
      });
    });
    setError(!filled);
    return filled;
  };
  return (
    <div className='RestrictionRangeContainer'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      {providerFilterSliders().map((item, i) => {
        return (
          <React.Fragment key={i}>
            <div className='RangeInputContainer'>
              <p>{item.label}</p>
              <div className='RangeInput'>
                <TextField
                  id={`${item.id}0`}
                  label='min'
                  variant='outlined'
                  type='number'
                  value={providerRestrictions[item.id][0]}
                  onChange={(event) => {
                    handleChange(event, 0, item.id);
                  }}
                  error={
                    !providerRestrictions[item.id][0] &&
                    providerRestrictions[item.id][0] !== 0
                      ? true
                      : false
                  }
                />
              </div>
              <div className='RangeInput'>
                <TextField
                  id={`${item.id}1`}
                  label='max'
                  variant='outlined'
                  type='number'
                  value={providerRestrictions[item.id][1]}
                  onChange={(event) => {
                    handleChange(event, 1, item.id);
                  }}
                  error={
                    !providerRestrictions[item.id][1] &&
                    providerRestrictions[item.id][1] !== 0
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
      {error ? (
        <p
          className='FullWidth'
          style={{
            textAlign: 'center',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          {i18n.t('provider.pleasefilloutrest')}
        </p>
      ) : null}
      {modified ? (
        <div className='FullWidth FlexFullCenter MarginHorizontal'>
          <StyledButton
            onClick={() => {
              if (modified && checkCompanySizeRestrictions()) {
                props.submitToDB({
                  provider_restrictions: providerRestrictions,
                });
                setModified(false);
                setOpen(true);
              }
            }}
            color={'Primary'}
          >
            {i18n.t('general.save')}
          </StyledButton>
        </div>
      ) : null}
    </div>
  );
};

export default RestrictionRangeInputs;
