import React from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoute } from '../auth/protected.route';
//COMPONENTS
import ProviderRegister from './providerEntry/providerRegister.jsx';
import Confirmation from './providerEntry/confirmation.jsx';
import ForgotPassword from './providerEntry/forgotPassword.jsx';
import ResetPassword from './providerEntry/resetPassword.jsx';
import ProviderDashboard from './providerDashboard/providerDashboard';
import ProviderData from './providerData/providerData.jsx';
import ProviderPreview from './providerPreview/providerPreview.jsx';
import PaymentStatus from './providerPayment/paymentStatus.jsx';
import ProviderVafForm from './providerVafForm/providerVafForm.jsx';
import ProviderRegForm from './providerRegForm/providerRegForm.jsx';

const providerRoutes = (
  <Route path='/provider'>
    <Route path='/provider/register' exact element={<ProviderRegister />} />
    <Route path='/provider/confirmation/:JWT' element={<Confirmation />} />
    <Route path='/provider/forgotpassword' exact element={<ForgotPassword />} />
    <Route path='/provider/reset/:JWT' exact element={<ResetPassword />} />
    <Route path='/provider/preview/:id' exact element={<ProviderPreview />} />
    <Route path='/provider/vafform' exact element={<ProviderVafForm />}></Route>
    <Route
      path='/provider/vafform/:id'
      exact
      element={<ProviderVafForm />}
    ></Route>
    <Route path='/provider/regform' exact element={<ProviderRegForm />}></Route>
    <Route
      path='/provider/regform/:id'
      exact
      element={<ProviderRegForm />}
    ></Route>
    {/* PROTECTED PROVIDER PATHS */}
    <Route
      path='/provider/dashboard'
      exact
      element={
        <ProtectedRoute>
          <ProviderDashboard />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path='/provider/data'
      element={
        <ProtectedRoute>
          <ProviderData />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path='/provider/paymentstatus'
      element={
        <ProtectedRoute>
          <PaymentStatus />
        </ProtectedRoute>
      }
    />
  </Route>
);

export default providerRoutes;
