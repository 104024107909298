import React, { useEffect, useCallback } from 'react';
import { Button, Paper, TextField } from '@mui/material';
import './sendReq.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import i18n from '../../../../utils/i18n';
import { gdprLabelSF } from '../../data/searchFlowText.js';
import sendreqpic from '../../../../images/sendreqpic.png';
import pp from '../../../../images/pp.png';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import ContactInfo from '../contactInfo/contactInfo.jsx';

const SendReq = ({ data, ...props }) => {
	const [nameError, setNameError] = React.useState(false);
	const [emailError, setEmailError] = React.useState(false);
	const [phoneError, setPhoneError] = React.useState(false);
	const [gdprError, setGdprError] = React.useState(false);
	const [reqDisabled, setReqDisabled] = React.useState(false);
	//const [showReqSent, setShowReqSent] = React.useState(false);
	const [reqMsg, setReqMsg] = React.useState('');
	//const [refClicked, setRefClicked] = React.useState(false);

	const dispatch = useDispatch();
	const companyStore = useSelector((state) => state.company_store);

	const dataLayerUpdate = (stepData) => {
		window.dataLayer.push({
			event: 'sf_step_asp_request_sent',
			event_category: 'aspflow',
		});
	};

	const validateInput = useCallback(() => {
		const validEmailRegex = RegExp(
			// eslint-disable-next-line no-useless-escape
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		);
		return validEmailRegex.test(companyStore.email);
	}, [companyStore.email]);

	// const validateName = useCallback(() => {
	//   const validName = RegExp(
	//     // eslint-disable-next-line no-useless-escape
	//     /[a-zA-Z]+\s+[a-zA-Z]+/g
	//   );
	//   return validName.test(companyStore.companyctcname.trim());
	// }, [companyStore.companyctcname]);

	const validateName = useCallback(() => {
		if (companyStore.companyctcname.trim().length > 0) {
			return true;
		}
		return false;
	}, [companyStore.companyctcname]);

	const validatePhone = useCallback(() => {
		if (companyStore.companyPhone.length >= 8) {
			return true;
		}
		return false;
	}, [companyStore.companyPhone]);

	useEffect(() => {
		setReqMsg(companyStore.reqMessage);
		// window.scrollTo(0, 0);
		if (emailError && validateInput()) {
			setEmailError(false);
		}
		if (gdprError && companyStore.gdpr) {
			setGdprError(false);
		}
		if (phoneError && validatePhone()) {
			setPhoneError(false);
		}
		if (nameError && validateName()) {
			setNameError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		companyStore.gdpr,
		validateInput,
		companyStore.companyPhone.length,
		emailError,
		gdprError,
		phoneError,
		nameError,
		validateName,
		validatePhone,
	]);
	const handleChange = (event) => {
		setReqMsg(event.target.value);
	};
	return (
		<React.Fragment key="reqmessage">
			{/* {showReqSent ? (
        refClicked ? (
          <RefPopUp handleClose={props.handleClose} />
        ) : (
          <ReqSent
            handleClose={props.handleClose}
            setRefClicked={setRefClicked}
          />
        )
      ) : ( */}
			<Paper className="ReqMessagePaper">
				<div className="ReqMessageHeader">
					<img alt="send rfp" src={sendreqpic} className="sendrfp" />
					<p>{i18n.t('searchflow.reqmsgtitle')}</p>
				</div>
				<div style={{ padding: '0 16px' }}>
					<div className="ReqMessageText">
						<h2>{i18n.t('searchflow.reqmessagelabel')}</h2>
					</div>
					<div>
						<ContactInfo
							emailError={emailError}
							phoneError={phoneError}
							gdprError={gdprError}
							nameError={nameError}
						/>
					</div>
					<p className="ReqMessageLength">
						<span>{i18n.t('searchflow.reqmsg')}:</span>{' '}
						<span>({reqMsg.length}/1500)</span>
					</p>
					<TextField
						id="outlined-multiline-static"
						multiline
						rows={4}
						value={reqMsg}
						onChange={(e) => {
							e.stopPropagation();
							handleChange(e);
						}}
						onBlur={(event) =>
							dispatch(
								ACTIONS.addCompanyInfo({
									id: 'reqMessage',
									data: event.target.value,
									stateObject: 'company_store',
								})
							)
						}
						inputProps={{ maxLength: 1500 }}
					/>
					<div style={{ marginTop: '12px' }}>
						<FormControlLabel
							key={'marketing'}
							control={
								<Checkbox
									id="marketing"
									name="checked"
									checked={companyStore.marketing}
									onChange={(event) => {
										dispatch(
											ACTIONS.addCompanyInfo({
												id: event.target.id,
												data: event.target.checked,
												stateObject: 'company_store',
											})
										);
									}}
									color="primary"
									/*    disabled={this.state.submitted} */
								/>
							}
							label={
								<span className="SFSmallText">
									{i18n.t('searchflow.newslettersubtext')}
								</span>
							}
						/>
						<FormControlLabel
							key={'gdpr'}
							control={
								<Checkbox
									id="gdpr"
									name="gdpr"
									checked={companyStore.gdpr}
									onChange={(event) => {
										dispatch(
											ACTIONS.addCompanyInfo({
												id: event.target.id,
												data: event.target.checked,
												stateObject: 'company_store',
											})
										);
									}}
									color="primary"
									required
									/*  disabled={this.state.submitted} */
								/>
							}
							label={gdprLabelSF()}
						/>
					</div>
					{props.gdprError ? (
						<span
							style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}>
							{i18n.t('searchflow.plsacceptgdpr')}
						</span>
					) : null}

					<div className="SpaceBetween MarginHorizontal">
						<Button
							onClick={() => {
								window.dataLayer.push({
									event: 'sf_step_offer_cancelled',
									event_category: 'aspflow',
									sf_choice: 'asp',
								});
								props.handleClose();
							}}>
							<p
								style={{
									fontSize: '14px',
									color: '#505581',
									margin: 0,
									marginLeft: '16px',
									textTransform: 'none',
								}}>
								{i18n.t('providerdashboard.undo')}
							</p>
						</Button>
						<Button
							className="ReqMsgSend"
							disabled={reqDisabled}
							onClick={() => {
								localStorage.removeItem('selectedSP');
								if (companyStore.email.length === 0 || !validateInput()) {
									setEmailError(true);
								}
								if (!companyStore.gdpr) {
									setGdprError(true);
								}

								if (
									companyStore.companyctcname.length === 0 ||
									!validateName()
								) {
									setNameError(true);
								}
								if (!validatePhone()) {
									setPhoneError(true);
								}

								if (
									// (companyStore.gdpr &&
									//   companyStore.email.length !== 0 &&
									//companyStore.companyPhone.length === 0 &&
									//   validateInput()) ||
									companyStore.companyctcname.length !== 0 &&
									companyStore.gdpr &&
									companyStore.email.length !== 0 &&
									companyStore.companyPhone.length >= 8 &&
									validateInput() &&
									validateName() &&
									validatePhone()
								) {
									dataLayerUpdate('rfp sent');
									setReqDisabled(true);
									props.createAspRequest({
										...companyStore,
										proposedSinglePrice: props.priceSingleCalc,
										proposedContPrice: props.priceContCalc,
										isCommission: props.isCommission,
										individual: props.individual,
										isDiscounted: props.isDiscounted,
										originalSinglePrice: props.originalSinglePrice,
										originalContPrice: props.originalContPrice,
									});
									props.setIsDisabled(true);
									setTimeout(() => {
										window.location.replace(
											'http://www.bookkeepie.com/hu/thank-you-asp'
										);
									}, 3000);
								}
							}}>
							<p
								style={{
									margin: 0,
									textTransform: 'none',
									color: '#0b0f31 !important',
								}}>
								{i18n.t('general.send')} <img src={pp} alt="send" />
							</p>
						</Button>
					</div>
				</div>
			</Paper>
			{/* )} */}
		</React.Fragment>
	);
};

export default SendReq;
