import React /* , { useEffect }  */ from 'react';
import MaterialTable from 'material-table';
//import Dialog from '@mui/material/Dialog';
//import Slide from '@mui/material/Slide';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import { tableIcons } from '../../searchFlow/data/searchFlowText';
import i18n from '../../../utils/i18n';
//import ReqTableProfile from '../components/reqTableProfile';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

const ReqTable = ({ columns, data, openProfile, setProfileData }) => {
  // const [selectedRow, setSelectedRow] = React.useState({});
  // const [dialogState, setDialogState] = React.useState(false);

  const defaultMaterialTheme = createTheme();

  // const selectRow = (row) => {
  //   setSelectedRow(row);
  //   setDialogState(true);
  // };

  // const handleClose = () => {
  //   setDialogState(false);
  // };

  return (
    <div style={{ margin: '32px 0px' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title=''
            icons={tableIcons}
            columns={columns}
            data={data ? data : []}
            options={{
              headerStyle: {
                color: 'white',
              },
              sorting: true,
              toolbar: false,
              search: false,
              pageSize: 10000,
            }}
            // onRowClick={(event, selectedRow) => {
            //   selectRow(selectedRow);
            // }}
            localization={{
              pagination: {
                labelRowsPerPage: i18n.t('general.lineperpage'),
                labelRowsSelect: i18n.t('general.line'),
                firstAriaLabel: i18n.t('general.firstpage'),
                firstTooltip: i18n.t('general.firstpage'),
                previousAriaLabel: i18n.t('general.previouspage'),
                previousTooltip: i18n.t('general.previouspage'),
                nextAriaLabel: i18n.t('general.nextpage'),
                nextTooltip: i18n.t('general.nextpage'),
                lastAriaLabel: i18n.t('general.lastpage'),
                lastTooltip: i18n.t('general.lastpage'),
              },
              body: {
                emptyDataSourceMessage: i18n.t('general.nocontent'),
              },
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      {/* <Dialog
        // fullScreen
        open={dialogState}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <ReqTableProfile providerInfo={selectedRow} />
      </Dialog> */}
    </div>
  );
};

export default ReqTable;
