import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { providerExtraInfoText } from '../../../../data/providerText';
import i18n from '../../../../../utils/i18n';

//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const ExtraInfo = ({ submitToDB }) => {
  const [providerExtraInfo] = useSelector((state) => [state.provider_info]);

  const dispatch = useDispatch();

  const handleCheck = (event) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'provider_info',
      })
    );
  };

  return (
    <div className='FiltersContentContainer'>
      <h2>{i18n.t('general.extrainfoLabel')}</h2>
      <div className='ServicesContainer'>
        <p>{i18n.t('general.discountslabel')}</p>
        <>
          {providerExtraInfoText().map((item, i) => {
            return (
              <div className='ExtraInfoCheckBox' key={i}>
                <FormGroup>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    control={
                      <Checkbox
                        id={item.id}
                        checked={providerExtraInfo[item.id]}
                        onChange={(event) => {
                          handleCheck(event);
                        }}
                      />
                    }
                    label={
                      <span className='SFCheckboxLabel'>{item.label}</span>
                    }
                  />
                </FormGroup>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default ExtraInfo;
