import React from "react";
import { Navigate } from "react-router-dom";
import auth from "../auth/auth";
import { useDispatch } from "react-redux";
import ACTIONS from "../redux/action";

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  if (!auth.isAuthenticated()) {
    dispatch(
      ACTIONS.addProviderInfo({
        id: "status",
        data: true,
        stateObject: "loggedIn",
      })
    );
    return <Navigate to="/" replace />;
  } else return children;
};
