import React, { useEffect } from 'react';
import Description from './components/description';
import CompanyInfo from './components/companyInfo';
import PublicProfile from './components/publicProfile';
import Skills from './components/skills';
import IndustryExperience from './components/industryExperience';
import i18n from '../../../../utils/i18n';
//REDUX
import './profile.scss';
import Pricing from './components/pricing';

const expScores = [
  'Nincs még ilyen tapasztalatom',
  'Egy-két ügyfelem már volt',
  'Többször volt már ügyfelem',
  'Sok tapasztalatom van',
  'Profi vagyok benne',
];

const Profile = ({ submitToDB }) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className='DashboardProfileContainer'>
        <div className='ProfileContent'>
          <PublicProfile />
        </div>
        <div className='ProfileContent'>
          <Description submitToDB={submitToDB} />
        </div>
        <div className='ProfileContent'>
          <CompanyInfo submitToDB={submitToDB} />
        </div>
        <div className='ProfileContent'>
          <Skills submitToDB={submitToDB} />
        </div>
        <h2 style={{ marginLeft: '30px' }}>
          {i18n.t('provider.industryexperience')}
        </h2>
        <div className='ExpScoreBoard' style={{ margin: '14px 0 0 0' }}>
          {expScores.map((item, i) => {
            return (
              <div key={i}>
                <h2>{i + 1}</h2>
                <p>{item}</p>
              </div>
            );
          })}
        </div>
        <div className='ProfileContent'>
          <IndustryExperience submitToDB={submitToDB} />
        </div>
        <Pricing submitToDB={submitToDB} />
      </div>
    </>
  );
};

export default Profile;
