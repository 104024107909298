import React from 'react';
import { Paper } from '@mui/material';
import ProfileHeader from './components/profileHeader';
import ReviewAverage from './components/reviewAverage';
import ReviewPaper from './components/reviewPaper';
import Description from './components/description';
import Qualifications from './components/qualifications';
import liabilityInsIcon from '../../images/icons/liability-ins-new.svg';
import badgeIcon from '../../images/icons/digital-badge-new.svg';
import cpa from '../../images/icons/cpa-new.svg';
import mkoe from '../../images/icons/mkoe-new.svg';
import axios from '../../shared/axios/axiosProxy';
import { withRouter } from '../../withRouter';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import './providerPreview.scss';
import Services from './components/services';
import Languages from './components/languages';
const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const badgeType = {
  gold: {
    text: i18n.t('provider.golddigital'),
  },
  2: {
    text: i18n.t('provider.golddigital'),
  },
  silver: {
    text: i18n.t('provider.silverdigital'),
  },
  1: {
    text: i18n.t('provider.silverdigital'),
  },
  0: {
    text: i18n.t('general.none'),
  },
  none: {
    img: <IndeterminateCheckBoxIcon sx={{ color: 'lightgrey' }} />,
  },
  tbd: { img: <IndeterminateCheckBoxIcon sx={{ color: 'lightgrey' }} /> },
};

class ProviderPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providerData: {},
      reviewsFetched: false,
      reviews: false,
      reviewScores: '',
      showBlock: 'match',
      loaded: false,
    };
  }

  componentDidMount() {
    this.getProviderData();
  }

  getProviderData = () => {
    axios
      .get(`${BACKEND_API}/api/provider/${this.props.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //console.log(response);
        this.setState({
          providerData: response.data.provider,
          loaded: true,
        });
        this.getReviews();
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response);
      });
  };

  getReviews = () => {
    let data = JSON.stringify({
      providerID: this.props.params.id,
    });
    axios
      .post('/api/review/id', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //console.log(response, 'review response');
        this.setState({
          reviews: response.data.review,
          reviewScores: this.reviewScoreCalculator(response.data.review),
          reviewsFetched: true,
        });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  reviewScoreCalculator = (reviews) => {
    let reviewNr = 0;
    let reviewScore = 0;
    let reviewScoreAvg = 0;
    let flexibilityAvg = 0;
    let professionalismAvg = 0;
    let industryexperienceAvg = 0;
    let precisionAvg = 0;
    let pricevalueAvg = 0;
    reviews.forEach((review) => {
      reviewNr += 1;
      reviewScore +=
        review.flexibility +
        review.professionalism +
        review.industryexperience +
        review.precision +
        review.pricevalue;
      flexibilityAvg += review.flexibility;
      professionalismAvg += review.professionalism;
      industryexperienceAvg += review.industryexperience;
      precisionAvg += review.precision;
      pricevalueAvg += review.pricevalue;
    });
    if (reviewScore > 0 && reviewNr > 0) {
      reviewScoreAvg = Math.round((reviewScore / (reviewNr * 5)) * 10) / 10;
      flexibilityAvg = Math.round((flexibilityAvg / reviewNr) * 10) / 10;
      professionalismAvg =
        Math.round((professionalismAvg / reviewNr) * 10) / 10;
      industryexperienceAvg =
        Math.round((industryexperienceAvg / reviewNr) * 10) / 10;
      precisionAvg = Math.round((precisionAvg / reviewNr) * 10) / 10;
      pricevalueAvg = Math.round((pricevalueAvg / reviewNr) * 10) / 10;
    }
    return {
      reviewScoreAvg: reviewScoreAvg,
      flexibility: flexibilityAvg,
      professionalism: professionalismAvg,
      industryexperience: industryexperienceAvg,
      precision: precisionAvg,
      pricevalue: pricevalueAvg,
      reviewNr: reviewNr,
    };
  };

  displayReviews = () => {
    let reviewsToDisplay = [];
    this.state.reviews.forEach((item) => {
      // if (item.review.length !== 0) {
      reviewsToDisplay.push(
        <div style={{ margin: '16px 0px' }}>
          <ReviewPaper review={item} />
        </div>
      );
      // }
    });
    return reviewsToDisplay;
  };

  checkQualifications = () => {
    const { providerData } = this.state;
    let insurance = false;
    for (let i in providerData.provider_liability_insurance) {
      if (providerData.provider_liability_insurance[i]) {
        insurance = true;
      }
    }
    if (insurance) {
      return true;
    } else if (
      providerData.digital_badge ||
      providerData.provider_qualifications
    ) {
      if (providerData.digital_badge) {
        if (
          providerData.digital_badge !== '0' ||
          providerData.digital_badge !== 'tbd'
        ) {
          return true;
        }
      }
      if (providerData.provider_qualifications) {
        if (
          providerData.provider_qualifications.cpa ||
          providerData.provider_qualifications.mkoe
        ) {
          return true;
        }
      }
    } else return false;
  };

  setInsurance = () => {
    const { providerData } = this.state;
    let insurance = false;
    for (let i in providerData.provider_liability_insurance) {
      if (providerData.provider_liability_insurance[i]) {
        insurance = true;
        return insurance;
      }
    }
    return insurance;
  };

  render() {
    const { reviewScores, providerData, loaded, reviewsFetched } = this.state;
    return (
      <div className='ProvProfileContainer'>
        {loaded ? (
          <>
            <ProfileHeader
              reviewScores={reviewScores}
              providerData={providerData}
            />
            <div className='ProvProfileContentContainer'>
              <div className='ProvProfileContent'>
                {this.checkQualifications() && window.innerWidth > 550 ? (
                  <div className='ProvProfileBadgesContainer'>
                    {!this.setInsurance() ? null : (
                      <>
                        <p className='MarginHorizontal FlexCenter'>
                          <span
                            style={{ marginRight: '16px' }}
                            className='MarginVertical'
                          >
                            <img src={liabilityInsIcon} alt='' />
                          </span>
                          {i18n.t('provider.gotinspt1')}
                          <br />
                          {i18n.t('provider.gotinspt2')}
                        </p>
                      </>
                    )}
                    {providerData.provider_qualifications &&
                    providerData.provider_qualifications.mkoe ? (
                      <>
                        <p className='MarginHorizontal FlexCenter'>
                          <span
                            style={{ marginRight: '16px' }}
                            className='MarginVertical'
                          >
                            <img src={mkoe} alt='' />
                          </span>
                          {i18n.t('provider.MK')} <br />
                          {i18n.t('provider.OET')}
                        </p>
                      </>
                    ) : null}
                    {providerData.provider_qualifications &&
                    providerData.provider_qualifications.cpa ? (
                      <>
                        <p className='MarginHorizontal FlexCenter'>
                          <span
                            style={{ marginRight: '16px' }}
                            className='MarginVertical'
                          >
                            <img src={cpa} alt='' />
                          </span>
                          {i18n.t('general.certified')} <br />
                          {i18n.t('general.accountant')}
                        </p>
                      </>
                    ) : null}
                    {providerData.digital_badge &&
                    providerData.digital_badge !== 'tbd' &&
                    providerData.digital_badge !== '0' &&
                    providerData.digital_badge !== 'none' ? (
                      <>
                        <p className='MarginHorizontal FlexCenter'>
                          <span
                            style={{ marginRight: '16px' }}
                            className='MarginVertical'
                          >
                            <img src={badgeIcon} alt='' />
                          </span>
                          {badgeType[providerData.digital_badge].text}
                          <br />
                          {i18n.t('provider.qualifications')}
                        </p>
                      </>
                    ) : null}
                  </div>
                ) : null}
                <div
                  className='FlexFullCenter'
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Description
                    providerDescription={providerData.provider_info.description}
                  />
                </div>
                <div
                  className='FlexFullCenter'
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Services data={providerData.provider_services} />
                </div>
                <div
                  className='FlexFullCenter'
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Languages data={providerData.provider_languages} />
                </div>
                <Paper
                  className='ProvProfileContentPaper'
                  style={{
                    display: 'block',
                  }}
                >
                  <h2>{i18n.t('general.reviews')}</h2>
                  <ReviewAverage reviewScores={reviewScores} />
                  {reviewScores.reviewNr > 0 && reviewsFetched ? (
                    <div className='ProvProfileReviewsContainer'>
                      {this.displayReviews()}
                    </div>
                  ) : null}
                </Paper>
                <div
                  className='FlexFullCenter'
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Qualifications
                    digitalBadge={
                      providerData.digital_badge
                        ? providerData.digital_badge
                        : 'tbd'
                    }
                    providerInsurance={
                      providerData.provider_liability_insurance
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(withRouter(ProviderPreview));
