import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import {
  foreignTradeExtra,
  invoicesExtra,
  providerCompanyTypeList,
  revenueExtra,
  vatExtra,
} from '../../../../data/providerText';
import { NumberFormatCustom } from '../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import ExtraFeeInputs from './extraFeeInputs';
import i18n from '../../../../../utils/i18n';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const AccountingInputs = ({ ...props }) => {
  const [inputError, setInputError] = React.useState({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const [accountingPrice, companyTypeFilter, specialRestr, services] =
    useSelector((state) => [
      state.provider_acc_monthly_price,
      state.provider_business_filter,
      state.provider_special_restrictions,
      state.provider_services,
    ]);

  const handleChange = (event, storeObj) => {
    props.setModified(true);
    props.checkReqFields(`acc${event.target.id}`, event.target.value);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };
  return (
    <div className='PriceInputContainer'>
      {providerCompanyTypeList().map((item, i) => {
        if (!services.accounting) {
          props.setRequiredFieldsTrue(`acc${item.id}price`);
          return null;
        }
        if (
          companyTypeFilter[item.id] &&
          !Number.isInteger(props.pricesCopy[`${item.id}price`]) &&
          services.accounting
        ) {
          if (!Number.isInteger(accountingPrice[`${item.id}price`])) {
            props.setRequiredFields(`acc${item.id}price`);
          }
          return (
            <div className='AdornmentInputContainer Price' key={i}>
              <TextField
                className={classes.root}
                id={`${item.id}price`}
                variant='outlined'
                type='text'
                /*   placeholder={item.label} */
                value={accountingPrice[`${item.id}price`]}
                label={`${i18n.t('general.accounting')}: ${item.label}`}
                onChange={(event) => {
                  handleChange(event, 'provider_acc_monthly_price');
                  if (event.target.value && inputError[`${item.id}price`]) {
                    setInputError({
                      ...inputError,
                      [`${item.id}price`]: false,
                    });
                  }
                }}
                error={inputError[`${item.id}price`]}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setInputError({ ...inputError, [`${item.id}price`]: true });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span
                        style={{
                          color: inputError[`${item.id}price`]
                            ? 'red'
                            : '#2D46B9',
                        }}
                      >
                        {i18n.t('general.currency/month')}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        } else return null;
      })}
      <ExtraFeeInputs
        dataText={invoicesExtra()}
        title={i18n.t('provider.invoiceextrafees2')}
        endAdornment={i18n.t('general.currency/piece')}
        setRequiredFieldsTrue={props.setRequiredFieldsTrue}
        setRequiredFields={props.setRequiredFields}
        checkReqFields={props.checkReqFields}
        pricesCopy={props.pricesCopy}
        setModified={props.setModified}
      />
      {specialRestr.insideEU || specialRestr.outsideEU ? (
        <ExtraFeeInputs
          dataText={foreignTradeExtra()}
          endAdornment='%'
          title=''
          setRequiredFieldsTrue={props.setRequiredFieldsTrue}
          setRequiredFields={props.setRequiredFields}
          checkReqFields={props.checkReqFields}
          pricesCopy={props.pricesCopy}
          setModified={props.setModified}
        />
      ) : null}
      <ExtraFeeInputs
        revenue
        dataText={revenueExtra()}
        title={i18n.t('provider.revenueextrafee')}
        endAdornment='%'
        setRequiredFieldsTrue={props.setRequiredFieldsTrue}
        setRequiredFields={props.setRequiredFields}
        checkReqFields={props.checkReqFields}
        pricesCopy={props.pricesCopy}
        setModified={props.setModified}
      />
      <ExtraFeeInputs
        setRequiredFieldsTrue={props.setRequiredFieldsTrue}
        setRequiredFields={props.setRequiredFields}
        checkReqFields={props.checkReqFields}
        pricesCopy={props.pricesCopy}
        setModified={props.setModified}
        dataText={vatExtra()}
        title={i18n.t('general.vatlabel')}
        endAdornment='%'
      />
    </div>
  );
};

export default AccountingInputs;
