import React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Paper } from '@mui/material';
import SendReqFakeCherry from '../sendReq/sendReqFakeCherry';
import CardContentMobile from './cardContent/selectedCardContentMobile';
import CardContentWeb from './cardContent/selectedCardContentWeb';
import './providerCardSelected.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ProviderCardWeb = ({ data, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        TransitionComponent={Transition}
        fullScreen={window.innerWidth < 600 ? true : false}
      >
        <SendReqFakeCherry
          handleClose={() => setOpen(false)}
          createRequest={props.createRequest}
          createAspRequest={props.createAspRequest}
          updateRequest={props.updateRequest}
          updateFakeCherryRequest={props.updateFakeCherryRequest}
          data={data}
          reqID={props.reqID}
        />
      </Dialog>
      <Paper
        onClick={(e) => {
          e.stopPropagation();
          props.openProfile();
          props.setProfileData(data);
          props.setProfileIndex(props.index);
        }}
        className='SelectedProviderCardPaper'
      >
        <CardContentMobile data={data} setOpen={setOpen} reqID={props.reqID} />
        <CardContentWeb
          data={data}
          setOpen={setOpen}
          reqID={props.reqID}
        />{' '}
      </Paper>
    </>
  );
};

export default ProviderCardWeb;
