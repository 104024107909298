import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { trackerData } from '../../data/searchFlowText';
//import SearchParams from '../searchParams/searchParams';
import { StepConnector } from '@mui/material';
import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 !important',
    width: '80%',
    '& .MuiStepLabel-active': {
      fontWeight: 'bold',
      opacity: '1 !important',
      color: '#ffffff !important',
    },
    '& .MuiStepLabel-completed': {
      opacity: '1 !important',
    },
    '& .MuiStepLabel-label': {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '10px',

      fontFamily: 'Satoshi, sans-serif !important',
      marginBottom: '0',
      marginRight: '8px',
      opacity: '0.5',
      width: 'fit-content',
    },
    '& .MuiStepIcon-text': { visibility: 'hidden' },
    '& .MuiPaper-root': { background: 'transparent' },
    '& .MuiStepper-root': { padding: '0' },
  },
  button: {
    marginRight: 'theme.spacing(y)',
  },
  instructions: {
    marginTop: '0',
    marginBottom: '0',
  },
  stepIcon: {
    fontSize: '0',
    // margin: '0 0.5em 0.5em 0.3em',
    color: 'transparent',
    border: 'none',

    '&.Mui-completed': {
      color: '#2AAD4E',
      border: 'none',
    },
    '&.Mui-active': {
      color: '#2D46B9',
      border: 'none',
    },
  },
  connectorLine: {
    height: '2px',
    width: '2px',
    border: '2px solid #ffffff80 !important',
    borderRadius: '50%',
  },

  active: {}, //needed so that the &$active tag works
  completed: {},
});

class HorizontalLinearStepper extends React.Component {
  render() {
    const { classes, activeStep } = this.props;
    // console.log(activeStep, 'AS');
    return (
      <>
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            orientation='horizontal'
            connector={
              <StepConnector
                classes={{
                  line: classes.connectorLine,
                }}
              />
            }
          >
            {trackerData().map((item, i) => {
              const props = {};
              return (
                <Step
                  style={{ cursor: 'pointer' }}
                  key={`${i}${item.label}`}
                  id={i}
                  {...props}
                  onClick={() => {
                    if (i <= this.props.completedSteps) {
                      this.props.handleTracker(i);
                    }
                  }}
                  completed={
                    (i < this.props.completedSteps + 1 &&
                      i !== 15 &&
                      i !== 0) ||
                    (i === 15 && this.props.reqID.length > 0) ||
                    (i === 0 && i !== activeStep)
                      ? true
                      : false
                  }
                >
                  <StepLabel
                    style={{ cursor: 'pointer' }}
                    StepIconProps={{
                      classes: {
                        root: classes.stepIcon,
                        completed: classes.completed,
                        active: classes.active,
                      },
                    }}
                  >
                    <span
                      style={{
                        color: i === activeStep ? '#ffffff' : '#ffffff80',
                      }}
                    >
                      {item.label}
                    </span>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withTranslation()(withStyles(styles)(HorizontalLinearStepper));
