import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CustomTp = withStyles({
  tooltip: {
    fontSize: '1em',
    maxWidth: 400,
    position: 'relative',
    zIndex: 9999,
    backgroundColor: '#ffffff',
    color: '#505581',
    border: '1px solid #E3E5E9',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: 12,

    fontWeight: 500,
  },
})(Tooltip);

const ControlledTp = withStyles({
  tooltip: {
    fontSize: '1em',
    maxWidth: 400,
    position: 'relative',
    zIndex: 9999,
    backgroundColor: '#ffffff',
    color: '#505581',
    border: '1px solid #E3E5E9',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: 12,

    fontWeight: 500,
  },
})(Tooltip);

const ClickTp = withStyles({
  tooltip: {
    fontSize: '0.8em',
    maxWidth: 400,
    position: 'relative',
    zIndex: -9999,
  },
})(Tooltip);

const CustomTooltip = ({
  title,
  children,
  interactive,
  placement = 'top',
  controlled,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  return (
    <CustomTp
      title={title}
      placement={placement}
      interactive={interactive}
      open={controlled ? open : undefined}
      onClick={handleOpen}
      onClose={handleClose}
    >
      {children}
    </CustomTp>
  );
};

export const ControlledTooltip = ({ children, title = '', placement }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  return (
    <ControlledTp
      open={open}
      title={title}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      placement={placement}
    >
      {children}
    </ControlledTp>
  );
};
export default CustomTooltip;

export function ClickTooltip({ ...props }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <ClickTp
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={props.title}
            interactive={'true'}
          >
            <div onClick={handleTooltipOpen}>{props.children}</div>
          </ClickTp>
        </div>
      </ClickAwayListener>
    </div>
  );
}
