import React, { useEffect } from 'react';
import logo from '../../../../images/bluelogo.png';
import logout from '../../../../images/icons/signoutWhite.png';
import libramWhite from '../../../../images/icons/libramWhite.png';
import {
  dashboardItems,
  dashboardItemsMarketing,
} from '../../../data/providerText';
import './navigation.scss';
import { useNavigate } from 'react-router';
import i18n from '../../../../utils/i18n';

const Navigation = ({ activeStep, handleStepChange, providerData }) => {
  const navigate = useNavigate();
  const [active, setActive] = React.useState('dashboard');
  useEffect(() => {
    setActive(activeStep);
  }, [active, setActive, activeStep]);

  return (
    <div className='NavigationContainer'>
      <div style={{ width: '100px', marginBottom: '80px' }}>
        <img
          style={{ width: '120px', backgroundColor: '#fff9f0' }}
          src={logo}
          alt='BK logo'
        />
      </div>
      {providerData.provider_package.packageType === 'advanced'
        ? dashboardItemsMarketing().map((item, i) => {
            return (
              <div
                onClick={() => {
                  setActive(item.id);
                  handleStepChange(item.id);
                }}
                className={active === item.id ? 'NavigationActive' : ''}
                key={i}
              >
                {active === item.id ? (
                  <img
                    className='Icon16'
                    src={item.imgActive}
                    alt='item icon'
                  />
                ) : (
                  <img className='Icon16' src={item.img} alt='item icon' />
                )}
                {/* <img className='Icon16' src={item.img} alt='item icon' /> */}
                <p>{item.label}</p>
              </div>
            );
          })
        : dashboardItems().map((item, i) => {
            return (
              <div
                onClick={() => {
                  setActive(item.id);
                  handleStepChange(item.id);
                }}
                className={active === item.id ? 'NavigationActive' : ''}
                key={i}
              >
                {active === item.id ? (
                  <img
                    className='Icon16'
                    src={item.imgActive}
                    alt='item icon'
                  />
                ) : (
                  <img className='Icon16' src={item.img} alt='item icon' />
                )}
                {/* <img className='Icon16' src={item.img} alt='item icon' /> */}
                <p>{item.label}</p>
              </div>
            );
          })}

      <a
        href='https://www.digitaliskonyvelok.hu/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='badgeTest'>
          <img className='Icon16' src={libramWhite} alt='' />
          <p>{i18n.t('providerdashboard.digitalbadge')}</p>
        </div>
      </a>
      <div className='NavigationBorder'></div>
      <div className='Logout' onClick={() => navigate('/signout')}>
        <img className='Icon16' src={logout} alt='' />
        <p>{i18n.t('general.signout')}</p>
      </div>
    </div>
  );
};

export default Navigation;
