import React, { useCallback } from 'react';
import PackageContent from './content';
import { subscriptionPackages, couponCodes } from '../../data/providerText';
//REDUX
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '../../../shared/components/switch/switch';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderPackage = ({ activeStep, handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const [displayedPrice, setDisplayedPrice] = React.useState('');
  const [providerPackage, providerSubscription] = useSelector((state) => [
    state.provider_package,
    state.provider_subscription,
  ]);
  const discount =
    providerSubscription.partnerCode &&
    couponCodes[providerSubscription.partnerCode.toLowerCase()]
      ? true
      : false;
  const mkoestart =
    providerSubscription.partnerCode &&
    providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false;
  const reduction = discount
    ? couponCodes[providerSubscription.partnerCode.toLowerCase()]
    : undefined;
  const numberWithCommas = (x) => {
    if (x || x === 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };
  console.log(providerPackage.packageType, 'providerPackage');
  const setPrice = useCallback(() => {
    let price = providerPackage.yearly
      ? subscriptionPackages()[providerPackage.packageSize][
          `${providerPackage.packageType}_yearlyPrice`
        ]
      : subscriptionPackages()[providerPackage.packageSize][
          `${providerPackage.packageType}_monthly`
        ];
    setDisplayedPrice(price);
    if (discount) {
      price = Math.round(price * reduction);
    }
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'packagePrice',
        data: price,
        stateObject: 'provider_package',
      })
    );
  }, [
    dispatch,
    providerPackage.packageSize,
    providerPackage.packageType,
    providerPackage.yearly,
    setDisplayedPrice,
    discount,
    reduction,
  ]);
  const setPackageType = useCallback(() => {
    if (mkoestart) {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'packageType',
          data: 'mkoestart',
          stateObject: 'provider_package',
        })
      );
      if (providerSubscription.cardPayment) {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'cardPayment',
            data: false,
            stateObject: 'provider_subscription',
          })
        );
      }
    } else if (
      !providerPackage.packageType ||
      (providerPackage.packageType === 'mkoestart' && !mkoestart)
    ) {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'packageType',
          data: 'advanced',
          stateObject: 'provider_package',
        })
      );
    }
  }, [
    dispatch,
    providerPackage.packageType,
    providerSubscription.cardPayment,
    mkoestart,
  ]);

  React.useEffect(() => {
    setPackageType();
    setPrice();
  }, [setPrice, setPackageType]);
  return (
    <>
      <div className='PackageContainer PaddingBot100'>
        <Paper className='providerPaper'>
          <div className='PackageHeader'>
            <div className='PackageHeaderLeft'>
              <p>{i18n.t('provider.choosepackage')}</p>
            </div>
            <div className='PackageHeaderRight'>
              {mkoestart ? (
                <div>
                  <p>
                    {i18n.t('provider.mkoestart')} <br />{' '}
                    <span>{i18n.t('general.free')}</span>
                  </p>
                </div>
              ) : null}
              <div>
                <p>
                  {i18n.t('provider.entrypackage')} <br />{' '}
                  <span style={{ fontSize: '20px' }}>
                    {i18n.t('general.free')}
                  </span>
                  <br /> <span>{i18n.t('provider.entrypackagelabel')}</span>
                </p>
              </div>
              <div>
                <p>
                  {i18n.t('provider.marketingpackage')} <br />{' '}
                  <span>{i18n.t('provider.startingjan')}</span> <br />{' '}
                  <span style={{ fontSize: '20px' }}>{`${i18n.t(
                    'general.monthly'
                  )} ${
                    providerPackage.yearly
                      ? numberWithCommas(
                          subscriptionPackages()[providerPackage.packageSize]
                            .advanced_yearlyPricePerMonth
                        )
                      : numberWithCommas(
                          subscriptionPackages()[providerPackage.packageSize]
                            .advanced_monthly / 6
                        )
                  } ${i18n.t('general.fromcurrency')}`}</span>
                  <br /> <span>{i18n.t('provider.marketingpackagelabel')}</span>
                </p>
              </div>
              <div>
                <p>
                  {i18n.t('provider.guaranteedpackage')} <br />{' '}
                  <span style={{ fontSize: '20px' }}>{`${i18n.t(
                    'general.monthly'
                  )} ${
                    providerPackage.yearly
                      ? numberWithCommas(
                          subscriptionPackages()[providerPackage.packageSize]
                            .premium_yearlyPricePerMonth
                        )
                      : numberWithCommas(
                          subscriptionPackages()[providerPackage.packageSize]
                            .premium_monthly / 6
                        )
                  } ${i18n.t('general.fromcurrency')}`}</span>{' '}
                  <br />{' '}
                  <span>{i18n.t('provider.guaranteedpackagelabel')}</span>
                </p>
              </div>
            </div>
          </div>
          <div className='PackageContent'>
            <PackageContent />
          </div>{' '}
          {mkoestart || providerPackage.packageType === 'basic' ? null : (
            <div className='PaymentIntervalContainer'>
              <h2>{i18n.t('provider.paymentoccurance')}</h2>
              <p>
                {i18n.t('provider.paymentyearly')} <br />{' '}
                {i18n.t('provider.paymentsaving')}{' '}
                {discount ? (
                  <span>{`${numberWithCommas(
                    Math.round(
                      subscriptionPackages()[providerPackage.packageSize][
                        `${providerPackage.packageType}_discountIfYearly`
                      ] * reduction
                    )
                  )} ${i18n.t('general.currency')}`}</span>
                ) : (
                  <span>{`${numberWithCommas(
                    subscriptionPackages()[providerPackage.packageSize][
                      `${providerPackage.packageType}_discountIfYearly`
                    ]
                  )} ${i18n.t('general.currency')}`}</span>
                )}
              </p>
              <Switch
                checked={providerPackage.yearly}
                word1={i18n.t('general.yearly')}
                word2={i18n.t('general.biannual')}
                id='yearly'
                onChange={(event) => {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: 'yearly',
                      data: event.target.checked,
                      stateObject: 'provider_package',
                    })
                  );
                }}
              />
              {mkoestart ? null : (
                <div className='PriceToPayContainer'>
                  <p className={discount ? 'LineThrough' : ''}>
                    {`${
                      providerPackage.yearly
                        ? numberWithCommas(
                            subscriptionPackages()[providerPackage.packageSize][
                              `${providerPackage.packageType}_yearlyPricePerMonth`
                            ]
                          )
                        : numberWithCommas(displayedPrice / 6)
                    }${i18n.t('general.currency/month')}`}
                  </p>
                  {discount ? (
                    <b>
                      {`${
                        providerPackage.yearly
                          ? numberWithCommas(
                              Math.round(
                                subscriptionPackages()[
                                  providerPackage.packageSize
                                ][
                                  `${providerPackage.packageType}_yearlyPricePerMonth`
                                ] * reduction
                              )
                            )
                          : numberWithCommas(
                              Math.round((displayedPrice / 6) * reduction)
                            )
                      } ${i18n.t('general.currency/month')}`}
                    </b>
                  ) : null}
                  <p className={discount ? 'LineThrough' : ''}>
                    {providerPackage.yearly
                      ? `${numberWithCommas(displayedPrice)} ${i18n.t(
                          'general.currency/year'
                        )}`
                      : `${numberWithCommas(displayedPrice)} ${i18n.t(
                          'general.currency/biannual'
                        )}`}
                  </p>
                  {discount ? (
                    <span>
                      {providerPackage.yearly
                        ? `${numberWithCommas(
                            Math.round(displayedPrice * reduction)
                          )} ${i18n.t('general.currency/year')}`
                        : `${numberWithCommas(
                            Math.round(displayedPrice * reduction)
                          )} ${i18n.t('general.currency/biannual')}`}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </Paper>
      </div>
      <ProviderNavigation
        handleNext={() => {
          handleNext();
        }}
        handleBack={() => {
          handleBack();
        }}
        step={activeStep}
      />
    </>
  );
};

export default withTranslation()(ProviderPackage);
