import React from 'react';
import {
  bankingPrefText,
  invoicingPrefText,
  softwarePrefText,
   providerSpecialPreferencesList,
   providerEtradePreferencesList,
   providerVehiclePreferencesList,
  
} from '../../../../data/providerText';
import PreferencesInputs from '../inputs/preferencesInputs';
import PreferencesInputs2 from '../inputs/preferencesInputs2';
import i18n from '../../../../../utils/i18n';

const Preferences = ({ submitToDB }) => {
  return (
    <div className='PaddingBot100'>
      <div className='ProviderInputContainerCentered'>
        {/* <p className='ProviderInputTitle'>
          {i18n.t('providerdashboard.restrictionstext1')}
        </p>
        <p className='ProviderInputSubTitle'>
          {i18n.t('providerdashboard.restrictionstext2')}
        </p> */}
        <div className='RestrictionsNotification'>
          <h2>{i18n.t('provider.warning')}</h2>
          <p>{i18n.t('providerdashboard.restrictionstext3')}</p>
        </div>
        <p className='RestrictionTitle'>{i18n.t('provider.bankingrestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={bankingPrefText()}
          storeObj='provider_banking_filter'
        />
        <p className='RestrictionTitle'>{i18n.t('provider.invoicingrestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={invoicingPrefText()}
          storeObj='provider_invoicing_filter'
        />
        <p className='RestrictionTitle'>{i18n.t('provider.softwarerestr')}</p>
        <PreferencesInputs
          submitToDB={submitToDB}
          dataText={softwarePrefText()}
          storeObj='provider_software_filter'
        />
        <p className='RestrictionTitle'>Extra szürők</p>
        <PreferencesInputs2
          submitToDB={submitToDB}
          dataText={providerSpecialPreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Webshop szürők</p>
        <PreferencesInputs2
          submitToDB={submitToDB}
          dataText={providerEtradePreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        <p className='RestrictionTitle'>Gépjárműipari szürő</p>
        <PreferencesInputs2
          submitToDB={submitToDB}
          dataText={providerVehiclePreferencesList()}
          storeObj='provider_special_industry_restrictions'
        />
        
      </div>
    </div>
  );
};

export default Preferences;
