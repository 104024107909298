import React from 'react';
import './switch.scss';
import { useState } from 'react';
import i18n from '../../../utils/i18n';

const Switch = ({ word1, word2, onChange, id, checked, disabled }) => {
  const [switchState, changeSwitchState] = useState(false);
  return (
    <div className={`switchContainer`}>
      <label className='switch'>
        <input
          checked={checked}
          id={id}
          type='checkbox'
          onClick={() => changeSwitchState(!switchState)}
          onChange={onChange}
          disabled={disabled}
          default
        />
        <div className='switchSlider'>
          <span className={checked ? `true` : `false`}>
            {checked ? word1 : word2}
          </span>
          <span className={`inactiveRight ${!checked ? 'hide' : 'fadeIn'}`}>
            {i18n.t('general.biannual')}
          </span>
          <span className={`inactiveLeft ${checked ? 'hide' : 'fadeIn'}`}>
            {i18n.t('general.yearly')}
          </span>
        </div>
      </label>
    </div>
  );
};

export default Switch;
