import React from 'react';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { Alert, Snackbar } from '@mui/material';
//REDUX
import { useSelector } from 'react-redux';
import {
  accountingTaxPricing,
  //auditPricing,
  foreignTradeExtra,
  invoicesExtra,
  liquidationPricing,
  payrollPricing,
  providerCompanyTypeList,
  revenueExtra,
  vatExtra,
  severalLocationsExtra,
  etradeExtra,
  tourismExtra,
} from '../../../../data/providerText';
import AccountingInputs from './inputs/accountingInputs';
import PricingInputs from './inputs/pricingInputs';
//import FoundingInputs from './inputs/foundingInputs';
import i18n from '../../../../../utils/i18n';

const Pricing = ({ submitToDB }) => {
  const [error, setError] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState({
    accounting: false,
    payroll: false,
    taxconsulting: false,
    //audit: false,
    //legalentityfounding: false,
    liquidation: false,
  });
  const [modified, setModified] = React.useState({
    accounting: false,
    payroll: false,
    taxconsulting: false,
    // audit: false,
    //legalentityfounding: false,
    liquidation: false,
  });
  const [
    businessFilter,
    services,
    accountingPrice,
    payrollPrice,
    // foundingPrice,
    taxPrice,
    //auditPrice,
    liquidationPrice,
    specialRestrictions,
    liabilityInsurance,
    industryRestriction
  ] = useSelector((state) => [
    state.provider_business_filter,
    state.provider_services,
    state.provider_acc_monthly_price,
    state.provider_payroll_price,
    //state.provider_founding_price,
    state.provider_tax_consulting_price,
    //state.provider_audit_price,
    state.provider_liquidation_price,
    state.provider_special_restrictions,
    state.provider_liability_insurance,
    state.provider_industry_restrictions,
  ]);
  const setModifiedPricing = (service, bool) => {
    setModified({ ...modified, [`${service}`]: bool });
  };
  const numberWithCommas = (x) => {
    if (x || x === 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };
  const showButton = (storeObj, value, service, checkPrices) => {
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
    console.log(industryRestriction, 'industryRestriction')
    return (
      <div className='FullWidth'>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          style={{ width: '50%' }}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            className='AlertWidth'
          >
            {i18n.t('general.savedmessage')}
          </Alert>
        </Snackbar>
        <StyledButton
          onClick={() => {
            if (!modified[`${service}`]) {
              setEdit({ ...edit, [`${service}`]: !edit[`${service}`] });
            }

            if (edit[`${service}`] && modified[`${service}`] && checkPrices()) {
              submitToDB({
                [`${storeObj}`]: value,
                provider_liability_insurance: liabilityInsurance,
              });
              setModified({ ...modified, [`${service}`]: false });
              setError({
                ...error,
                [`${service}`]: '',
              });
              setEdit({ ...edit, [`${service}`]: false });
              setOpen(true);
            } else if (
              edit[`${service}`] &&
              modified[`${service}`] &&
              !checkPrices()
            ) {
              setError({
                ...error,
                [`${service}`]: i18n.t(
                  'providerdashboard.backbfillbeforesave2utton'
                ),
              });
            }
          }}
          color={'Primary'}
        >
          {edit[`${service}`]
            ? modified[`${service}`]
              ? i18n.t('general.save')
              : i18n.t('general.backbutton')
            : i18n.t('general.modify')}
        </StyledButton>
      </div>
    );
  };
  const checkAccounting = () => {
    let filled = true;
    providerCompanyTypeList().forEach((item) => {
      if (
        businessFilter[item.id] &&
        !accountingPrice[`${item.id}price`] &&
        accountingPrice[`${item.id}price`] !== 0
      ) {
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkInvoices = () => {
    let filled = true;
    invoicesExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        filled = false;
        return;
      }
    });

    return filled;
  };
  const checkForeignTrade = () => {
    let filled = true;
    foreignTradeExtra().forEach((item) => {
      if (
        (specialRestrictions.insideEU &&
          !accountingPrice.foreigntradeextra &&
          accountingPrice.foreigntradeextra !== 0) ||
        (specialRestrictions.outsideEU &&
          !accountingPrice.foreigntradeextra &&
          accountingPrice.foreigntradeextra !== 0)
      ) {
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkRevenue = () => {
    let filled = true;
    revenueExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkVat = () => {
    let filled = true;
    vatExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkAccPrices = () => {
    if (!checkAccounting()) {
      console.log('acc');
      return false;
    }
    if (!checkForeignTrade()) {
      console.log('ft');
      return false;
    }
    if (!checkRevenue()) {
      console.log('rev');
      return false;
    }
    if (!checkInvoices()) {
      console.log('inv');
      return false;
    }
    if (!checkVat()) {
      console.log('vat');
      return false;
    }
    return true;
  };
  // const checkFounding = () => {
  //   let filled = true;
  //   providerCompanyTypeList().forEach((item) => {
  //     if (
  //       item.id !== 'citycouncil' &&
  //       businessFilter[item.id] &&
  //       !foundingPrice[`${item.id}price`] &&
  //       foundingPrice[`${item.id}price`] !== 0
  //     ) {
  //       filled = false;
  //       return;
  //     }
  //   });
  //   return filled;
  // };
  const checkPricing = (text, storeObj) => {
    let filled = true;
    text.forEach((item) => {
      if (!storeObj[item.id] && storeObj[item.id] !== 0) {
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkPayroll = () => {
    return checkPricing(payrollPricing(), payrollPrice);
  };
  const checkTax = () => {
    return checkPricing(accountingTaxPricing(), taxPrice);
  };
  // const checkAudit = () => {
  //   return checkPricing(auditPricing(), auditPrice);
  // };
  const checkLiquidation = () => {
    return checkPricing(liquidationPricing(), liquidationPrice);
  };
  return (
    <div className='PricingContainer'>
      <h2>{i18n.t('general.pricing')}</h2>
      {services.accounting ? (
        <div className='Pricing'>
          {edit.accounting ? (
            <>
              <AccountingInputs setModified={setModifiedPricing} />
            </>
          ) : (
            <>
              <h3>
                {i18n.t('general.accounting')}
                <span>
                  {liabilityInsurance.accounting
                    ? i18n.t('provider.accountingins')
                    : i18n.t('provider.noliabilityaccounting')}
                </span>
              </h3>
              {providerCompanyTypeList().map((item, i) => {
                if (businessFilter[item.id]) {
                  return (
                    <p key={i}>
                      {item.label} <br />
                      <span>{`${numberWithCommas(
                        accountingPrice[`${item.id}price`]
                      )} ${i18n.t('general.currency/month')}`}</span>
                    </p>
                  );
                } else return null;
              })}
              <h3>{i18n.t('provider.extrafees')}</h3>
              {invoicesExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {`${item.label} ${i18n.t(
                      'providerdashboard.extrafeelabel'
                    )}`}{' '}
                    <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )} ${i18n.t('general.currency/month')}`}</span>
                  </p>
                );
              })}
              {specialRestrictions.insideEU || specialRestrictions.outsideEU ? (
                <>
                  {foreignTradeExtra().map((item, i) => {
                    return (
                      <p key={i}>
                        {item.label} <br />
                        <span>{`${numberWithCommas(
                          accountingPrice[`${item.id}`]
                        )}%`}</span>
                      </p>
                    );
                  })}
                </>
              ) : null}
              <h3>{i18n.t('provider.revenueextrafees')}</h3>
              {revenueExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )}%`}</span>
                  </p>
                );
              })}
              <h3>{i18n.t('provider.vatextrafees')}</h3>
              {vatExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )}%`}</span>
                  </p>
                );
              })}
              <h3>Egyéb felár</h3>
              {severalLocationsExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )} Ft / telephely`}</span>
                  </p>
                );
              })}
              {
                industryRestriction.some(e =>  e.id === 'etrade' && e.on) &&
              <>
              <h3>Webshop felárak</h3>
              {etradeExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )} Ft`}</span>
                  </p>
                );
              })}</>}
              {
                industryRestriction.some(e =>  e.id === 'travel' && e.on) &&
              <>
              <h3>Szállás / vendéglátás felárak</h3>
              {tourismExtra().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      accountingPrice[`${item.id}`]
                    )} Ft`}</span>
                  </p>
                );
              })}</>}
            </>
          )}
          {error.accounting ? (
            <p className='ErrorText FullWidth'>{error.accounting}</p>
          ) : null}
          {showButton(
            'provider_acc_monthly_price',
            accountingPrice,
            'accounting',
            checkAccPrices
          )}
        </div>
      ) : null}
      {services.payroll ? (
        <div className='Pricing'>
          {edit.payroll ? (
            <PricingInputs
              title={i18n.t('general.payroll')}
              liabilityID='payroll'
              liabilityTitle={i18n.t('provider.liabilitypayroll')}
              endAdornment={i18n.t('general.currency/person')}
              dataText={payrollPricing()}
              storeObj='provider_payroll_price'
              setModified={setModifiedPricing}
            />
          ) : (
            <>
              <h3>
                {i18n.t('general.payroll')}
                <span>
                  {liabilityInsurance.payroll
                    ? i18n.t('provider.liabilitypayroll')
                    : i18n.t('provider.noliabilitypayroll')}
                </span>
              </h3>
              {payrollPricing().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      payrollPrice[`${item.id}`]
                    )} ${i18n.t('general.currency/person')}`}</span>
                  </p>
                );
              })}
            </>
          )}
          {error.payroll ? (
            <p className='ErrorText FullWidth'>{error.payroll}</p>
          ) : null}
          {showButton(
            'provider_payroll_price',
            payrollPrice,
            'payroll',
            checkPayroll
          )}
        </div>
      ) : null}
      {services.taxconsulting ? (
        <div className='Pricing'>
          {edit.taxconsulting ? (
            <PricingInputs
              title={i18n.t('general.taxconsulting')}
              liabilityID='taxconsulting'
              liabilityTitle={i18n.t('provider.liabilitytaxconsulting')}
              endAdornment={i18n.t('general.currency')}
              dataText={accountingTaxPricing()}
              storeObj='provider_tax_consulting_price'
              setModified={setModifiedPricing}
            />
          ) : (
            <>
              <h3>
                {i18n.t('general.taxconsulting')}
                <span>
                  {liabilityInsurance.taxconsulting
                    ? i18n.t('provider.liabilitytaxconsulting')
                    : i18n.t('provider.noliabilitytaxconsulting')}
                </span>
              </h3>
              {accountingTaxPricing().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      taxPrice[`${item.id}`]
                    )} ${i18n.t('general.currency')}`}</span>
                  </p>
                );
              })}
            </>
          )}
          {error.taxconsulting ? (
            <p className='ErrorText FullWidth'>{error.taxconsulting}</p>
          ) : null}
          {showButton(
            'provider_tax_consulting_price',
            taxPrice,
            'taxconsulting',
            checkTax
          )}
        </div>
      ) : null}
      {/* {services.audit ? (
        <div className='Pricing'>
          {edit.audit ? (
            <PricingInputs
              title={i18n.t('general.audit')}
              liabilityID='audit'
              liabilityTitle={i18n.t('provider.liabilityaudit')}
              endAdornment={i18n.t('general.currency/year')}
              dataText={auditPricing()}
              storeObj='provider_audit_price'
              setModified={setModifiedPricing}
            />
          ) : (
            <>
              <h3>
                {i18n.t('general.audit')}
                <span>
                  {liabilityInsurance.audit
                    ? i18n.t('provider.liabilityaudit')
                    : i18n.t('provider.noliabilityaudit')}
                </span>
              </h3>
              {auditPricing().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      auditPrice[`${item.id}`]
                    )} ${i18n.t('general.currency')}`}</span>
                  </p>
                );
              })}
            </>
          )}
          {error.audit ? (
            <p className='ErrorText FullWidth'>{error.audit}</p>
          ) : null}
          {showButton('provider_audit_price', auditPrice, 'audit', checkAudit)}
        </div>
      ) : null} */}
      {/* {services.legalentityfounding ? (
        <div className='Pricing'>
          {edit.legalentityfounding ? (
            <FoundingInputs setModified={setModifiedPricing} />
          ) : (
            <>
              <h3>
                {i18n.t('general.legalentityfounding')}
                <span>
                  {liabilityInsurance.legalentityfounding
                    ? i18n.t('provider.liabilityfounding')
                    : i18n.t('provider.noliabilityfounding')}
                </span>
              </h3>
              {providerCompanyTypeList().map((item, i) => {
                if (businessFilter[item.id] && item.id !== 'citycouncil') {
                  return (
                    <p key={i}>
                      {item.label} <br />
                      <span>{`${numberWithCommas(
                        foundingPrice[`${item.id}price`]
                      )} ${i18n.t('general.currency')}`}</span>
                    </p>
                  );
                } else return null;
              })}
            </>
          )}
          {error.legalentityfounding ? (
            <p className='ErrorText FullWidth'>{error.legalentityfounding}</p>
          ) : null}
          {showButton(
            'provider_founding_price',
            foundingPrice,
            'legalentityfounding',
            checkFounding
          )}
        </div>
      ) : null} */}
      {services.liquidation ? (
        <div className='Pricing'>
          {edit.liquidation ? (
            <PricingInputs
              title={i18n.t('general.liquidation')}
              liabilityID='liquidation'
              liabilityTitle={i18n.t('provider.liabilityliquidation')}
              endAdornment={i18n.t('general.currency')}
              dataText={liquidationPricing()}
              storeObj='provider_liquidation_price'
              setModified={setModifiedPricing}
            />
          ) : (
            <>
              <h3>
                {i18n.t('general.liquidation')}
                <span>
                  {liabilityInsurance.liquidation
                    ? i18n.t('provider.liabilityliquidation')
                    : i18n.t('provider.noliabilityliquidation')}
                </span>
              </h3>
              {liquidationPricing().map((item, i) => {
                return (
                  <p key={i}>
                    {item.label} <br />
                    <span>{`${numberWithCommas(
                      liquidationPrice[`${item.id}`]
                    )} ${i18n.t('general.currency')}`}</span>
                  </p>
                );
              })}
            </>
          )}
          {error.liquidation ? (
            <p className='ErrorText FullWidth'>{error.liquidation}</p>
          ) : null}
          {showButton(
            'provider_liquidation_price',
            liquidationPrice,
            'liquidation',
            checkLiquidation
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Pricing;
