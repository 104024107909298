import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import ProviderLogin from './provider/providerEntry/providerLogin';
import ProviderSignOut from './provider/providerEntry/providerSignOut';
// import Header from './shared/header/header';
import companyRoutes from './company/companyRoutes.js';
import providerRoutes from './provider/providerRoutes.js';
//MUI
import Container from '@mui/material/Container';
//STYLES
import './shared/styles/GlobalStyling.scss';

export default class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <Header /> */}
        <Container
          maxWidth='lg'
          id='content-container'
          style={{
            minHeight: '600px',
            padding: '0px !important',
          }}
        >
          <Routes>
            <Route path='/' exact element={<ProviderLogin />} />
            <Route path='/signout' element={<ProviderSignOut />} />
            {/* ------------------- PROVIDER PATHS ------------------- */}
            {providerRoutes}
            {/* ------------------- COMPANY PATHS ------------------- */}
            {companyRoutes}
            <Route
              path='*'
              //element={<ProviderLogin />}
              element={<div className='flex-center'>404 NOT FOUND</div>}
            />
            <Route
              path='/undefined'
              element={<ProviderLogin />}
              // element={<div className='flex-center'>404 NOT FOUND</div>}
            />
          </Routes>
        </Container>
        {/*         <Footer /> */}
      </BrowserRouter>
    );
  }
}
