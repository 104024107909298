import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { companySizeText } from '../../../data/providerText';
import { withTranslation } from 'react-i18next';

const CompanySizeInput = () => {
  const dispatch = useDispatch();
  const providerPackage = useSelector((state) => state.provider_package);
  const [selectedValue, setSelectedValue] = React.useState('');

  React.useEffect(() => {
    if (!providerPackage.packageSize) {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'packageSize',
          data: 'entrepreneur',
          stateObject: 'provider_package',
        })
      );
      setSelectedValue('entrepreneur');
    } else setSelectedValue(providerPackage.packageSize);
  }, [dispatch, providerPackage.packageSize]);
  const handleChange = (value) => {
    setSelectedValue(value);
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'packageSize',
        data: value,
        stateObject: 'provider_package',
      })
    );
  };
  const controlProps = (item) => ({
    checked: selectedValue === item.value,
    onChange: (event) => {
      handleChange(item.value);
    },
    value: item.value,
    name: 'color-radio-button-demo',
    id: item.id,
    inputProps: { 'aria-label': item },
  });

  return (
    <FormControl component='fieldset'>
      <RadioGroup
        column
        aria-label='position'
        name='position'
        defaultValue='top'
      >
        {companySizeText().map((item, i) => {
          return (
            <FormControlLabel
              className='MarginH12'
              key={i}
              control={
                <Radio
                  {...controlProps(item)}
                  sx={{
                    color: '#1A00BD',
                    '&.Mui-checked': {
                      color: '#1A00BD',
                    },
                  }}
                />
              }
              label={
                <span
                  style={{
                    color: '#424559',
                    fontFamily: 'Satoshi, sans-serif',
                  }}
                >
                  {item.label}
                </span>
              }
              labelPlacement='right'
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default withTranslation()(CompanySizeInput);
