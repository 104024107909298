import React from 'react';
import {
  providerContactReqFields,
  providerInfoReqFields,
} from '../../data/providerText';
import InfoInputs from './input/infoInputs';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

const requiredInputIDs = [
  'zip',
  'contatctzip',
  'taxnumber',
  'companyname',
  'city',
  'phone',
  'address',
  'contactcity',
  'contactaddress',
  //'noofemployees',
  'authorizedperson',
  'registrationno',
];

const ProviderInfo = ({ activeStep, handleNext, handleBack }) => {
  const providerInfo = useSelector((state) => state.provider_info);
  const providerDiffC = useSelector((state) => state.provider_diff_contact);
  const providerContact = useSelector((state) => state.provider_contact);
  const [error, setError] = React.useState(false);

  const checkRequired = () => {
    let requiredFilled = true;
    providerInfoReqFields.forEach((id) => {
      if (
        requiredInputIDs.includes(id) &&
        providerInfo[id] !== undefined &&
        providerInfo[id] !== null
      ) {
        switch (id) {
          case 'zip':
            if (providerInfo.zip.toString().length !== 4) {
              requiredFilled = false;
            }
            break;
          // case 'noofemployees':
          //   if (providerInfo.zip.toString().length === 0) {
          //     requiredFilled = false;
          //   }
          //   break;
          case 'phone':
            if (providerInfo.zip.toString().length === 0) {
              requiredFilled = false;
            }
            break;
          case 'taxnumber':
            if (providerInfo.taxnumber.length !== 13) {
              requiredFilled = false;
            }
            break;
          case 'authorizedperson':
            if (providerInfo.authorizedperson.length === 0) {
              requiredFilled = false;
            }
            break;
          case 'registrationno':
            if (providerInfo.registrationno.length === 0) {
              requiredFilled = false;
            }
            break;
          default:
            if (providerInfo[id].length === 0) {
              requiredFilled = false;
            }
        }
      }
      if (
        (requiredInputIDs.includes(id) && providerInfo[id] === undefined) ||
        (requiredInputIDs.includes(id) && providerInfo[id] === null) ||
        (requiredInputIDs.includes(id) && providerInfo[id] === '')
      ) {
        requiredFilled = false;
      }
    });
    if (providerDiffC.diffc) {
      providerContactReqFields.forEach((id) => {
        if (
          requiredInputIDs.includes(id) &&
          providerContact[id] !== undefined &&
          providerContact[id] !== null
        ) {
          switch (id) {
            case 'contactzip':
              if (providerContact.contactzip.toString().length !== 4) {
                requiredFilled = false;
              }
              break;
            default:
              if (providerContact[id].length === 0) {
                requiredFilled = false;
              }
          }
        }
      });
    }
    setError(!requiredFilled);
    return requiredFilled;
  };
  return (
    <div className='PaddingBot100'>
      <div className='ProviderDataStepContent'>
        <InfoInputs error={error} checkRequired={checkRequired} />
      </div>
      <ProviderNavigation
        handleNext={() => {
          if (!checkRequired()) {
            setError(true);
          }
          if (checkRequired()) {
            handleNext();
          }
        }}
        handleBack={() => {
          handleBack();
        }}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderInfo);
