import React from 'react';
import { Route } from 'react-router-dom';
//COMPONENTS
import RequestOverview from './requestOverview/requestOverview';
/* import CompanyRequestStepper from "./companyRequest/companyRequest"; */
import SearchFlow from './searchFlow/searchFlow';
import VafFlow from './vafFlow/vafFlow';
import RegFlow from './regFlow/regFlow';
import ReferralDetails from './referral/refDetails';
import ReferralPage from './referral/referralPage';
import ProviderFeedback from './providerFeedback/providerFeedback';
import DlpProviderProfile from './dynamicListPages/dlpProviderProfile/dlpProviderProfile';
import DynamicListPages from './dynamicListPages/dynamicListPages';

const companyRoutes = (
	<Route path="/company">
		<Route path="/company/request/1" element={<SearchFlow />} />
		<Route path="/company/request/vaf" element={<VafFlow />} />
		<Route path="/company/request/reg" element={<RegFlow />} />
		<Route path="/company/request/feedback" element={<ProviderFeedback />} />
		<Route path="/company/request_overview/:id" element={<RequestOverview />} />
		<Route path="/company/refdetails" element={<ReferralDetails />} />
		<Route path="/company/referral" element={<ReferralPage />} />
		<Route
			path="/company/providerprofile/:id"
			element={<DlpProviderProfile />}
		/>
		<Route path="/company/providerlists" element={<DynamicListPages />} />
	</Route>
);

export default companyRoutes;
