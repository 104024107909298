import React from 'react';
import liabilityInsIcon from '../../../images/icons/liability-ins-new.svg';
import badgeIcon from '../../../images/icons/digital-badge-new.svg';
import { Paper } from '@mui/material';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const badgeType = {
  gold: {
    text: i18n.t('provider.golddigital'),
  },
  2: {
    text: i18n.t('provider.golddigital'),
  },
  silver: {
    text: i18n.t('provider.silverdigital'),
  },
  1: {
    text: i18n.t('provider.silverdigital'),
  },
  0: {
    text: '-',
  },
  none: {
    text: '-',
  },
  tbd: { text: '-' },
};

const Qualifications = ({ digitalBadge, providerInsurance }) => {
  const setInsurance = () => {
    let insurance = false;
    for (let i in providerInsurance) {
      if (providerInsurance[i]) {
        insurance = true;
        return insurance;
      }
    }
    return insurance;
  };
  return (
    <>
      {setInsurance() || digitalBadge !== 'none' ? (
        <>
          <Paper className='ProvProfileContentPaper'>
            <h2>{i18n.t('provider.qualifications')}</h2>
            {!setInsurance() ? null : (
              <div className='QualificationsContainer'>
                <div className='Flex1'>
                  {window.innerWidth < 600 ? (
                    <>
                      <img src={liabilityInsIcon} alt='' />
                      <p className='MarginHorizontal FlexCenter Bold'>
                        {i18n.t('provider.gotinssurance')}
                      </p>
                    </>
                  ) : (
                    <p className='MarginHorizontal FlexCenter'>
                      <span
                        style={{ marginRight: '16px' }}
                        className='MarginVertical'
                      >
                        <img src={liabilityInsIcon} alt='' />
                      </span>
                      {i18n.t('provider.gotinspt1')}
                      <br />
                      {i18n.t('provider.gotinspt2')}
                    </p>
                  )}
                </div>
                <div style={{ flex: 2 }}>
                  <p>{i18n.t('provider.insurrancedescription')}</p>
                </div>
              </div>
            )}
            {digitalBadge !== 'tbd' &&
            digitalBadge !== '0' &&
            digitalBadge !== 'none' ? (
              <div className='QualificationsContainer'>
                <div className='Flex1'>
                  {window.innerWidth < 600 ? (
                    <>
                      <img src={badgeIcon} alt='' />
                      <p className='MarginHorizontal FlexCenter Bold'>
                        {badgeType[digitalBadge].text}
                        <br />
                        {i18n.t('provider.gotqualifications')}
                      </p>
                    </>
                  ) : (
                    <p className='MarginHorizontal FlexCenter'>
                      <span
                        style={{ marginRight: '16px' }}
                        className='MarginVertical'
                      >
                        <img src={badgeIcon} alt='' />
                      </span>
                      {badgeType[digitalBadge].text}
                      <br />
                      {i18n.t('provider.gotqualifications')}
                    </p>
                  )}
                </div>
                <div style={{ flex: 2 }}>
                  <p>{i18n.t('provider.qualifdescription')}</p>
                </div>
              </div>
            ) : null}
          </Paper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default withTranslation()(Qualifications);
