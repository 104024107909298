import React from 'react';
import LanguageSelector from './inputs/languageSelector';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { languagesText } from '../../../../data/providerText';
import i18n from '../../../../../utils/i18n';
import { Alert, Snackbar } from '@mui/material';
//REDUX
import { useSelector } from 'react-redux';

const Skills = ({ submitToDB }) => {
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const [providerLanguages] = useSelector((state) => [
    state.provider_languages,
  ]);
  const checkModified = (modified) => {
    setModified(modified);
  };
  const displayLanguages = () => {
    let foreignLg = false;
    let spokenLanguages = [];
    languagesText().forEach((item, i) => {
      if (providerLanguages[item.id]) {
        foreignLg = true;
        spokenLanguages.push(
          <p key={i}>
            {item.name}
            <img src={item.flag} alt='' />
          </p>
        );
      }
    });
    if (!foreignLg) {
      spokenLanguages.push(<p>{i18n.t('general.empty')}</p>);
    }
    return spokenLanguages;
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <div className='PublicProfile'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <div className='FullWidth'>
        <h2>{i18n.t('provider.spokenlanguages')}</h2>
        {edit ? (
          <LanguageSelector checkModified={checkModified} />
        ) : (
          <div className='LanguagesContainer'> {displayLanguages()}</div>
        )}
        <StyledButton
          onClick={() => {
            setEdit(!edit);
            if (edit && modified) {
              submitToDB({
                provider_languages: providerLanguages,
              });
              setModified(false);
              setOpen(true);
            }
          }}
          color={'Primary'}
        >
          {edit
            ? modified
              ? i18n.t('general.save')
              : i18n.t('general.backbutton')
            : i18n.t('general.modify')}
        </StyledButton>
      </div>
    </div>
  );
};

export default Skills;
