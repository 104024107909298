import * as React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import {
  providerCompanyInfo,
  providerAddressText,
  providerContactText,
} from '../../../data/providerText';
import { CleaveInput } from '../../../../shared/components/formattedInput/formattedInputs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});
const requiredInputIDs = [
  'zip',
  'contactzip',
  'taxnumber',
  'companyname',
  'city',
  'address',
  'contactcity',
  'contactaddress',
  'noofemployees',
  'authorizedperson',
  'registrationno',
];
const InfoInputs = ({ error, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerInfo = useSelector((state) => state.provider_info);
  const providerDiffC = useSelector((state) => state.provider_diff_contact);
  const providerContact = useSelector((state) => state.provider_contact);
  const [errors, setErrors] = React.useState({
    companyname: false,
    taxnumber: false,
    city: false,
    phone: false,
    zip: false,
    address: false,
    contactaddress: false,
    contactzip: false,
    contactcity: false,
    noofemployees: false,
    authorizedperson: false,
    registrationno: false,
  });
  const handleError = (id) => {
    if (
      (requiredInputIDs.includes(id) &&
        providerContact[id] !== undefined &&
        providerContact[id] !== null) ||
      (requiredInputIDs.includes(id) &&
        providerInfo[id] !== undefined &&
        providerInfo[id] !== null)
    ) {
      switch (id) {
        case 'noofemployees':
          if (providerInfo[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'companyname':
          if (providerInfo[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'city':
          if (providerInfo[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'phone':
          if (providerInfo[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'address':
          if (providerInfo[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'zip':
          if (providerInfo[id].toString().length !== 4) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactzip':
          if (providerContact[id].toString().length !== 4) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactcity':
          if (providerContact[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactaddress':
          if (providerContact[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'authorizedperson':
          if (providerContact[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'registrationno':
          if (providerContact[id].toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'taxnumber':
          if (providerInfo[id].length !== 13) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        default:
          return;
      }
    } else if (
      (requiredInputIDs.includes(id) && providerContact[id] === undefined) ||
      (requiredInputIDs.includes(id) && providerContact[id] === null) ||
      (requiredInputIDs.includes(id) && providerInfo[id] === undefined) ||
      (requiredInputIDs.includes(id) && providerInfo[id] === null)
    ) {
      setErrors({ ...errors, [id]: true });
    }
  };

  const handleChange = (event, storeObj) => {
    let value = event.target.value;
    if (
      (event.target.id === 'zip' && event.target.value !== '') ||
      (event.target.id === 'noofemployees' && event.target.value !== '')
    ) {
      value = parseInt(value);
    }
    if (event.target.id === 'phone' || event.target.id === 'contactphone') {
      if (!/[a-z]/i.test(value) && value.length <= 15) {
        dispatch(
          ACTIONS.addProviderInfo({
            id: event.target.id,
            data: value,
            stateObject: storeObj,
          })
        );
      }
    } else {
      dispatch(
        ACTIONS.addProviderInfo({
          id: event.target.id,
          data: value,
          stateObject: storeObj,
        })
      );
    }
  };
  return (
    <div className='ProviderInfoInputs'>
      <Paper className='providerPaper'>
        <p className='ProviderInputTitle'>{i18n.t('provider.companyinfo')}</p>
        <div className='ProviderInputContainer'>
          {providerCompanyInfo().map((item, i) => {
            if (item.id !== 'email') {
              return (
                <div
                  key={i}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                >
                  <TextField
                    className={classes.root}
                    id={item.id}
                    label={item.label}
                    variant='outlined'
                    type={item.type}
                    value={providerInfo[item.id]}
                    onChange={(event) => {
                      handleChange(event, 'provider_info');
                    }}
                    InputProps={{
                      inputComponent:
                        item.id === 'phone' ? undefined : CleaveInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors[item.id]}
                    onBlur={() => {
                      handleError(item.id);
                      if (error) {
                        props.checkRequired();
                      }
                    }}
                  />
                </div>
              );
            } else return null;
          })}
        </div>
      </Paper>
      <Paper className='providerPaper'>
        <p className='ProviderInputTitle'>
          {i18n.t('provider.companyaddress')}
        </p>
        <div className='ProviderInputContainer'>
          {providerAddressText().map((item, i) => {
            let classN = '';
            if (item.id === 'zip' || item.id === 'city') {
              classN = item.id === 'zip' ? 'ZipInput' : 'CityInput';
            }
            return (
              <div
                className={classN}
                key={i}
                onWheel={(e) => {
                  e.target.blur();
                }}
              >
                <TextField
                  className={classes.root}
                  id={item.id}
                  label={item.label}
                  variant='outlined'
                  type={item.type}
                  value={providerInfo[item.id]}
                  onChange={(event) => {
                    handleChange(event, 'provider_info');
                  }}
                  InputProps={{ inputComponent: CleaveInput }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors[item.id]}
                  onBlur={() => {
                    handleError(item.id);
                    if (error) {
                      props.checkRequired();
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <FormGroup>
            <FormControlLabel
              style={{
                // margin: '6px 0px 6px -11px',
                width: 'fit-content',
              }}
              control={
                <Checkbox
                  id='diffc'
                  checked={providerDiffC.diffc}
                  onChange={(event) => {
                    if (error) {
                      props.checkRequired();
                    }
                    dispatch(
                      ACTIONS.addProviderInfo({
                        id: event.target.id,
                        data: event.target.checked,
                        stateObject: 'provider_diff_contact',
                      })
                    );
                  }}
                />
              }
              label={
                <span className='SFCheckboxLabel'>
                  {i18n.t('provider.moreaddress')}
                </span>
              }
            />
          </FormGroup>
        </div>
        {providerDiffC.diffc ? (
          <div className='ProviderInputContainer'>
            {providerContactText().map((item, i) => {
              let classN = '';
              if (item.id === 'contactzip' || item.id === 'contactcity') {
                classN = item.id === 'contactzip' ? 'ZipInput' : 'CityInput';
              }
              return (
                <div
                  className={classN}
                  key={i}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                >
                  <TextField
                    className={classes.root}
                    id={item.id}
                    label={item.label}
                    variant='outlined'
                    type={item.type}
                    value={providerContact[item.id]}
                    onChange={(event) => {
                      handleChange(event, 'provider_contact');
                    }}
                    InputProps={{
                      inputComponent:
                        item.id === 'contactphone' ? undefined : CleaveInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors[item.id]}
                    onBlur={() => {
                      if (error) {
                        props.checkRequired();
                      }
                      handleError(item.id);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        {Object.keys(errors).some((k) => errors[k]) || error ? (
          <span className='ErrorText'>
            {i18n.t('provider.fillouttocontinue')}
          </span>
        ) : null}
      </Paper>
    </div>
  );
};

export default withTranslation()(InfoInputs);
