import * as React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import {
  providerCompanyInfo,
  providerAddressText,
  providerContactText,
  validateInput,
} from '../../../../../data/providerText';
import { CleaveInput } from '../../../../../../shared/components/formattedInput/formattedInputs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//REDUX
import ACTIONS from '../../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../../../../utils/i18n';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});
const requiredInputIDs = [
  'zip',
  'contactzip',
  'taxnumber',
  'companyname',
  'city',
  'address',
  'contactcity',
  'contactaddress',
  'noofemployees',
  'email',
  'phone',
];
const InfoInputs = ({ error = false, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerInfo = useSelector((state) => state.provider_info);
  const providerDiffC = useSelector((state) => state.provider_diff_contact);
  const providerContact = useSelector((state) => state.provider_contact);
  const [emailError, setEmailError] = React.useState('');
  const [errors, setErrors] = React.useState({
    companyname: false,
    taxnumber: false,
    city: false,
    phone: false,
    zip: false,
    address: false,
    contactaddress: false,
    contactzip: false,
    contactcity: false,
    //noofemployees: false,
    email: false,
  });

  const checkError = props.checkError;

  React.useEffect(() => {
    /*     console.log('called'); */
    let error = false;
    for (let i in errors) {
      if (errors[i] === true) {
        error = true;
        checkError(error);
        return;
      }
    }
    checkError(error);
    //causes infinite loop if props is added to dependency array
  }, [errors, checkError]);

  const handleError = (id, value) => {
    if (
      requiredInputIDs.includes(id) &&
      value !== undefined &&
      value !== null
    ) {
      switch (id) {
        // case 'noofemployees':
        //   if (value.length === 0) {
        //     setErrors({ ...errors, [id]: true });
        //   } else setErrors({ ...errors, [id]: false });
        //   break;
        case 'companyname':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'phone':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'city':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'address':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'zip':
          if (value.toString().length !== 4) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactzip':
          if (value.toString().length !== 4) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactcity':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'contactaddress':
          if (value.toString().length === 0) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        case 'email':
          let emailCheck = validateInput('email', value);
          setEmailError(emailCheck.email);
          if (emailCheck.email.length > 0) {
            setErrors({ ...errors, [id]: true });
          } else {
            setErrors({ ...errors, [id]: false });
          }
          break;
        case 'taxnumber':
          if (value.length !== 13) {
            setErrors({ ...errors, [id]: true });
          } else setErrors({ ...errors, [id]: false });
          break;
        default:
          return;
      }
    } else if (
      (requiredInputIDs.includes(id) && value === undefined) ||
      (requiredInputIDs.includes(id) && value === null)
    ) {
      setErrors({ ...errors, [id]: true });
    }
  };

  const handleChange = (event, storeObj) => {
    props.checkModified(true);
    handleError(event.target.id, event.target.value);
    let value = event.target.value;
    if (
      (event.target.id === 'zip' && event.target.value !== '') ||
      (event.target.id === 'noofemployees' && event.target.value !== '')
    ) {
      value = parseInt(value);
    }
    if (event.target.id === 'phone' || event.target.id === 'contactphone') {
      if (!/[a-z]/i.test(value) && value.length <= 15) {
        dispatch(
          ACTIONS.addProviderInfo({
            id: event.target.id,
            data: value,
            stateObject: storeObj,
          })
        );
      }
    } else {
      dispatch(
        ACTIONS.addProviderInfo({
          id: event.target.id,
          data: value,
          stateObject: storeObj,
        })
      );
    }
  };
  return (
    <div className='ProviderInfoInputs'>
      <div className='ProviderInputContainer'>
        {providerCompanyInfo().map((item, i) => {
          return (
            <div key={i}>
              <TextField
                className={classes.root}
                id={item.id}
                label={item.label}
                variant='outlined'
                type={item.type}
                value={providerInfo[item.id]}
                onChange={(event) => {
                  handleChange(event, 'provider_info');
                }}
                InputProps={{
                  inputComponent: item.id === 'phone' ? undefined : CleaveInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors[item.id]}
              />
              {emailError && item.id === 'email' ? (
                <span className='ErrorText'>{emailError}</span>
              ) : null}
            </div>
          );
        })}
      </div>
      <p className='ProviderInputTitle'>{i18n.t('provider.companyaddress')}</p>
      <div className='ProviderInputContainer'>
        {providerAddressText().map((item, i) => {
          let classN = '';
          if (item.id === 'zip' || item.id === 'city') {
            classN = item.id === 'zip' ? 'ZipInput' : 'CityInput';
          }
          return (
            <div className={classN} key={i}>
              <TextField
                className={classes.root}
                id={item.id}
                label={item.label}
                variant='outlined'
                type={item.type}
                value={providerInfo[item.id]}
                onChange={(event) => {
                  handleChange(event, 'provider_info');
                }}
                InputProps={{ inputComponent: CleaveInput }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors[item.id]}
              />
            </div>
          );
        })}
      </div>
      <div>
        <FormGroup>
          <FormControlLabel
            style={{
              // margin: '6px 0px 6px -11px',
              width: 'fit-content',
            }}
            control={
              <Checkbox
                id='diffc'
                checked={providerDiffC.diffc}
                onChange={(event) => {
                  if (error) {
                    props.checkRequired();
                  }
                  dispatch(
                    ACTIONS.addProviderInfo({
                      id: event.target.id,
                      data: event.target.checked,
                      stateObject: 'provider_diff_contact',
                    })
                  );
                }}
              />
            }
            label={
              <span className='SFCheckboxLabel'>
                {i18n.t('provider.moreaddress')}
              </span>
            }
          />
        </FormGroup>
      </div>
      {providerDiffC.diffc ? (
        <div className='ProviderInputContainer'>
          {providerContactText().map((item, i) => {
            let classN = '';
            if (item.id === 'cotactzip' || item.id === 'contactcity') {
              classN = item.id === 'zip' ? 'ZipInput' : 'CityInput';
            }
            return (
              <div className={classN} key={i}>
                <TextField
                  className={classes.root}
                  id={item.id}
                  label={item.label}
                  variant='outlined'
                  type={item.type}
                  value={providerContact[item.id]}
                  onChange={(event) => {
                    handleChange(event, 'provider_contact');
                  }}
                  InputProps={{
                    inputComponent:
                      item.id === 'contactphone' ? undefined : CleaveInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors[item.id]}
                />
              </div>
            );
          })}
        </div>
      ) : null}
      {error ? (
        <span className='ErrorText'>
          {i18n.t('provider.fillouttocontinue')}
        </span>
      ) : null}
    </div>
  );
};

export default InfoInputs;
