import React, { useCallback } from 'react';
import {
  companyTypeInput,
  companyTypeFoundingInput,
} from '../data/regFlowText';
import { useSelector } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import RadioInput from '../components/searchInputs/radioInput';

const CompanyType = ({ ...props }) => {
  // const [companyError, setCompanyError] = React.useState(false);
  const companyStore = useSelector((state) => state.company_store);

  const navigate = useNavigate();

  const handleNext = useCallback(
    (inc) => {
      if (companyStore.companytype === 'idk') {
        navigate('/company/request/vaf');
      } else {
        props.handleNext(inc);
      }
    },
    [props, companyStore, navigate]
  );
  // const handleBack = useCallback(
  //   (decr) => {
  //     props.handleBack(decr);
  //   },
  //   [props]
  // );

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>{i18n.t('company.companyform')}</span>{' '}
          {/* <span className='questionTracker'>1/4</span> */}
        </p>
        {companyStore.legalentityfounding ? (
          <RadioInput
            storeID='companytype'
            dataText={companyTypeFoundingInput()}
          />
        ) : (
          <RadioInput storeID='companytype' dataText={companyTypeInput()} />
        )}
        {/* {companyError ? (
          <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
            {i18n.t('searchflow.choosetocontinue')}
          </span>
        ) : null} */}
      </div>
      <Navigation
        addCompanyInfo={props.addCompanyInfo}
        step={0}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={companyStore.companytype === '' ? true : false}
      />
    </div>
  );
};

export default withTranslation()(CompanyType);
