import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Paper } from '@mui/material';
import i18n from '../../../../../utils/i18n';
/* import placeholder from "../../../../../images/icons/placeholder-new.png"; */
import StyledButton from '../../../../../shared/components/styledButton/styledButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 0 32px 0',
  },
  accordion: {
    background:
      'linear-gradient(278.21deg,#9976b5 -6.75%, #16009b 20.28%, #012abc 52.23%,#2d46b9 96.68%)',
    borderRadius: '25px !important',
  },
  heading: {
    fontSize: '18px',
    fontWeight: '600',
    color: 'white',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    color: 'white',
  },
}));

export default function RequestList({ data, status, ...props }) {
  const [itemsToShow, setItemsToShow] = React.useState(2);
  const classes = useStyles();
  return (
    <div className={classes.root} key={props.key}>
      <Accordion
        defaultExpanded={status === 'open' ? true : false}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={
            <IconButton style={{ background: 'white', padding: 0 }} size="large">
              <ExpandMoreIcon sx={{ color: '#2D46B9', fontSize: '32px' }} />
            </IconButton>
          }
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography className={classes.heading}>
            {props.title}
            {/*             <img alt="" src={placeholder} className="Icon16 MarginVertical" /> */}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper className='ReqListPaper'>
            {data.map((item, i) => {
              if (i < itemsToShow) {
                return (
                  <div className='ReqListItem' key={i}>
                    <p>{item.email}</p>
                    <span>
                      {item.timePassed <= 24
                        ? `${item.timePassed} ${i18n.t(
                            'providerdashboard.hours'
                          )}`
                        : `${Math.floor(item.timePassed / 24)} ${i18n.t(
                            'providerdashboard.days'
                          )}`}
                      {/*      <img src={placeholder} alt="" className="Icon16" /> */}
                    </span>
                    <div>
                      <StyledButton
                        color='Primary'
                        onClick={() => {
                          props.handleSelect(item);
                        }}
                      >
                        {i18n.t('providerdashboard.checkingthis')}
                      </StyledButton>
                    </div>
                    {/*{status === "open" ? (
                        <div>
                          <StyledButton color="Primary"  >
                            Válaszolok
                          </StyledButton>
                        </div>
                      ) : null} */}
                  </div>
                );
              } else return null;
            })}
          </Paper>
          {data.length > 2 ? (
            <div
              style={{ marginTop: '10px' }}
              className='ToRequests'
              onClick={() => {
                let nr = itemsToShow > 2 ? 2 : data.length;
                setItemsToShow(nr);
              }}
            >
              <p>
                {itemsToShow > 2
                  ? i18n.t('general.backbutton')
                  : i18n.t('general.forwardbutton')}
              </p>
              {/*      <img src={placeholder} alt="" className="Icon16" /> */}
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
