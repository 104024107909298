import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { revenueRangesInput } from '../data/searchFlowText';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import RadioInput from '../components/searchInputs/radioInput';
import ACTIONS from '../../../redux/action';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from '@mui/material/styles';

const Income = ({ ...props }) => {
  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);
  const [customInput, setCustomInput] = React.useState(
    companyStore.noofemployees
  );
  const [employeesError, setEmployeesError] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const checkHandler = (event) => {
    setChecked(event.target.checked);
  };

  const sliderMarks = [
    { value: 0, label: '0' },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5, label: '5' },
  ];

  useEffect(() => {
    setCustomInput(companyStore.noofemployees);
  }, [companyStore.noofemployees]);

  useEffect(() => {
    // console.log(companyStore, 'companyStore');
    if (
      (companyStore.accounting &&
        companyStore.noofemployees > 1 &&
        (companyStore.companytype === 'kiva' ||
          companyStore.companytype === 'singlesecondary' ||
          companyStore.companytype === 'singleprimary' ||
          companyStore.companytype === 'vszja')) ||
      (companyStore.accounting &&
        companyStore.noofemployees > 0 &&
        companyStore.companytype === 'bt') ||
      (companyStore.accounting &&
        companyStore.noofemployees > 0 &&
        companyStore.companytype === 'kft') ||
      (companyStore.accounting &&
        companyStore.noofemployees > 0 &&
        companyStore.companytype === 'zrt')
    ) {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'payroll',
          data: true,
          stateObject: 'company_store',
        })
      );
    }
  }, [companyStore.accounting, companyStore.noofemployees, dispatch]);

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  const employeeNrChange = useCallback(
    (event) => {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'noofemployees',
          data: event.target.value,
          stateObject: 'company_store',
        })
      );
      if (
        (companyStore.accounting &&
          companyStore.noofemployees > 1 &&
          (companyStore.companytype === 'kiva' ||
            companyStore.companytype === 'singlesecondary' ||
            companyStore.companytype === 'singleprimary' ||
            companyStore.companytype === 'vszja')) ||
        (companyStore.accounting &&
          companyStore.noofemployees > 0 &&
          companyStore.companytype === 'bt') ||
        (companyStore.accounting &&
          companyStore.noofemployees > 0 &&
          companyStore.companytype === 'kft') ||
        (companyStore.accounting &&
          companyStore.noofemployees > 0 &&
          companyStore.companytype === 'zrt')
      ) {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'payroll',
            data: true,
            stateObject: 'company_store',
          })
        );
      }
    },
    [companyStore.noofemployees]
  );

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <div>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('searchflow.generaltext5')}
            </span>{' '}
            <span className='questionTracker'>2/6</span>
          </p>
          {!checked && (
            <div
              style={{
                display: 'flex',
                flexWrap: 'warp',
                alignItems: 'center',
                height: '44px',
              }}
            >
              <Box sx={{ width: 300, marginLeft: '24px' }}>
                <Slider
                  aria-label='noofemployees'
                  defaultValue={0}
                  value={companyStore.noofemployees}
                  valueLabelDisplay='auto'
                  step={1}
                  marks={sliderMarks}
                  min={0}
                  max={5}
                  onChange={(event) => employeeNrChange(event)}
                  disabled={checked}
                />
              </Box>

              <span
                style={{
                  margin: '0 28px ',
                  fontFamily: 'Satoshi',
                  fontSize: '18px',
                  fontWeight: 700,
                }}
              >
                {companyStore.noofemployees} {i18n.t('general.percapita')}
              </span>
            </div>
          )}
          <div
            style={{
              margin: '12px 16px ',
              fontFamily: 'Satoshi',
              fontSize: '18px',
              fontWeight: 700,
              display: 'flex',
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={checkHandler}
                    inputProps={{ 'aria-label': 'noofemployees5+' }}
                  />
                }
                label={i18n.t('general.noofemployees5')}
              />
            </FormGroup>

            {checked && (
              <div
                style={{
                  margin: '0px auto',
                  width: '80px',

                  borderRadius: '6px',
                }}
              >
                <TextField
                  variant='outlined'
                  value={customInput}
                  onChange={(event) => {
                    setCustomInput(event.target.value);
                  }}
                  onBlur={(event) => {
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: event.target.id,
                        data: parseInt(customInput),
                        stateObject: 'company_store',
                      })
                    );
                  }}
                  type='number'
                  error={employeesError}
                  id='noofemployees'
                  name='noofemployees'
                  label={i18n.t('general.percapita')}
                />
              </div>
            )}
          </div>
          {employeesError && companyStore.noofemployees <= 250 ? (
            <span
              style={{
                color: 'red',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {i18n.t('company.employeeerrormsg')}
            </span>
          ) : null}
        </div>
        <hr />
        <div>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('searchflow.incometitle')}
            </span>{' '}
            <span className='questionTracker'>3/6</span>
          </p>
          <RadioInput storeID='yearlyrevenue' dataText={revenueRangesInput()} />
        </div>
      </div>

      <Navigation
        step={8}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={companyStore.yearlyrevenue !== 0 ? false : true}
      />
    </div>
  );
};

export default withTranslation()(Income);
