import React from 'react';
import Rating from '@mui/material/Rating';
import { Avatar } from '@mui/material';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  setExpRating,
  //companyType,
  badgeType,
} from '../../../../utils/helperFunctions';
import { setColor } from '../../data/searchFlowText';
//import NoIns from '../../../../images/icons/red-cross-new.svg';
import i18n from '../../../../utils/i18n';

export const providerColumns = (showPrices) => [
  {
    get title() {
      return i18n.t('general.providerlabel');
    },
    field: 'providerName',
    align: 'left',
    render: (rowData) => {
      return (
        <div className='PTIconText'>
          <Avatar
            src={rowData.providerImage}
            style={{
              width: '40px',
              height: '40px',
              margin: '0px 12px 0px 12px',
            }}
          />
          <p>{rowData.providerName}</p>
        </div>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.review');
    },
    field: 'providerExperience',
    align: 'left',
    render: (rowData) => {
      return (
        <p>
          <span>
            <Rating
              name='half-rating-read'
              defaultValue={rowData.reviewScores.reviewScoreAvg}
              precision={0.1}
              readOnly
              size='small'
            />
          </span>
          {/* <br />
          {rowData.reviewScores.reviewScoreAvg} /{' '}
          {rowData.reviewScores.reviewNr} */}
        </p>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.pricemonthly');
    },
    field: 'providerPrice',
    align: 'left',
    hidden: !showPrices,
    render: (rowData) => {
      return <span>{`${rowData.proposedContPrice} Ft`}</span>;
    },
  },
  {
    get title() {
      return i18n.t('general.pricecalculation');
    },
    field: 'providerPrice',
    align: 'left',
    hidden: !showPrices,
    render: (rowData) => {
      return <span>{`${rowData.proposedSinglePrice} Ft`}</span>;
    },
  },
  // {
  //   get title() {
  //     return i18n.t('general.distance');
  //   },
  //   field: 'providerDistance',
  //   align: 'left',
  //   render: (rowData) => {
  //     return (
  //       <p className='PTAddress'>
  //         {rowData.providerDistance} <br />
  //         <span>
  //           {`${rowData.providerZip} ${rowData.providerCity},`}
  //           <br />
  //           {rowData.providerAddress}
  //         </span>
  //       </p>
  //     );
  //   },
  // },
  {
    get title() {
      return i18n.t('general.experiencelabel');
    },
    field: 'providerExperience',
    align: 'left',
    render: (rowData) => {
      return <span>{setExpRating(rowData.providerExperience)}</span>;
    },
  },
  // {
  //   get title() {
  //     return i18n.t('general.typelabel');
  //   },
  //   field: 'packageSize',
  //   align: 'left',
  //   render: (rowData) => {
  //     return <span>{companyType(rowData.packageSize)}</span>;
  //   },
  // },
  // {
  //   get title() {
  //     return i18n.t('general.digitalbadge');
  //   },
  //   field: 'digitalBadge',
  //   align: 'left',
  //   customSort: (a, b) => {
  //     let order = {
  //       tbd: 0,
  //       none: 1,
  //       silver: 2,
  //       gold: 3,
  //     };
  //     return order[a.digitalBadge] - order[b.digitalBadge];
  //   },
  //   render: (rowData) => {
  //     return (
  //       <div className='PTIconText'>
  //         {badgeType(rowData.digitalBadge).img}
  //         <p>{badgeType(rowData.digitalBadge).text}</p>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   get title() {
  //     return i18n.t('general.insurrancelabel');
  //   },
  //   field: 'providerInsurance',
  //   align: 'left',
  //   render: (rowData) => {
  //     return rowData.providerInsurance !== 'none' ? (
  //       <CheckBoxIcon sx={{ color: 'green' }} />
  //     ) : (
  //       <img style={{ width: '20px', height: '20px' }} src={NoIns} alt='' />
  //     );
  //   },
  // },

  {
    get title() {
      return i18n.t('general.matchscore');
    },
    field: 'totalmatchscore',
    align: 'left',
    defaultSort: 'desc',
    customSort: (a, b) =>
      parseInt(a.totalmatchscore) - parseInt(b.totalmatchscore),
    render: (rowData) => {
      return (
        <span
          style={{
            color: setColor(parseInt(rowData.totalmatchscore)),
            fontWeight: 'bold',
          }}
        >
          {rowData.totalmatchscore}
        </span>
      );
    },
  },
];
