import React, { useEffect } from 'react';
/* import { Button } from "@mui/material"; */
import LoginInputs from './loginInputs/loginInputs';
import auth from '../../auth/auth';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

import './providerEntry.scss';
import '../../shared/styles/providerPreview.scss';
import { useNavigate } from 'react-router';
import standingFigure from '../../images/standingfigure.png';
import misc from '../../images/misc.png';

const ProviderLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    /*     console.log(auth.isAuthenticated()); */
    if (auth.isAuthenticated()) {
      navigate('/provider/dashboard');
    }
  }, [navigate]);

  return (
    <div className='EntryContainer'>
      <div className='LoginBlueBox'>
        {/* <div className='EntryHelpBox'>
            {entryHelpText().map((item) => {
            return (
              <>
                <p>{item.text1}</p>
                <span>{item.text2}</span>
              </>
            );
          })} 
          <Button className="TutorialVideoBtn">
            Mutasd az ingyenes tutorial videót
            <img className="Icon16" src={placeholder} alt="" />
          </Button>
        </div>*/}
      </div>
      <div className='EntryFields'>
        <div className='miscContainer'>
          <img src={misc} alt='misc' />
        </div>
        <div className='figureContainer'>
          <img src={standingFigure} alt='standing figure' />
        </div>
        <div className='EntryFieldsBox loginPaper'>
          <h2 className='loginTitle'>{i18n.t('provider.login')}</h2>
          {/*  <Button className="FacebookEntryBtn">
            <img className="Icon32" src={placeholder} alt="" />
            Bejelentkezés Facebook fiókkal
          </Button>
          <Button className="GoogleEntryBtn">
            <img className="Icon32" src={placeholder} alt="" />
            Bejelentkezés Google fiókkal
          </Button>
          <p className="EntrySeperator">
            <span>vagy</span>
          </p> */}

          <Paper className='providerLoginPaper '>
            <LoginInputs />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ProviderLogin);
