import React, { useRef } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import AccountingInputs from './input/accountingInputs';
import {
  accountingTaxPricing,
  //auditPricing,
  foreignTradeExtra,
  invoicesExtra,
  liquidationPricing,
  payrollPricing,
  providerCompanyTypeList,
  revenueExtra,
  vatExtra,
  
} from '../../data/providerText';
import MkoeIcon from '../../../images/icons/mkoe-icon-new.png';
import StyledButton from '../../../shared/components/styledButton/styledButton';
// import FoundingInputs from './input/foundingInputs';
import PricingInputs from './input/pricingInputs';
import PricingWizard from './pricingWizard';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';
//REDUX
import { useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ProviderPricing = ({ handleNext, handleBack, activeStep, setGuide }) => {
  const accPrices = useRef(null);
  const payrollPrices = useRef(null);
  const taxPrices = useRef(null);
  //const auditPrices = useRef(null);
  //const foundingPrices = useRef(null);
  const liquidationPrices = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [
    services,
    accountingPrice,
    payrollPrice,
    taxPrice,
    //auditPrice,
    //foundingPrice,
    liquidationPrice,
    companyTypeFilter,
    specialRestr,
    providerSubscription,
  ] = useSelector((state) => [
    state.provider_services,
    state.provider_acc_monthly_price,
    state.provider_payroll_price,
    state.provider_tax_consulting_price,
    //state.provider_audit_price,
    //state.provider_founding_price,
    state.provider_liquidation_price,
    state.provider_business_filter,
    state.provider_special_restrictions,
    state.provider_subscription,
  ]);
  const [dialogOpen, setDialogOpen] = React.useState(
    providerSubscription.partnerCode &&
      providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false
  );
  const executeScroll = (ref) => ref.current.scrollIntoView();

  React.useEffect(() => {
    const pricingGuide = (
      <div className='ProviderDataHelpContent PricingHelp'>
        <h2>{i18n.t('provider.pricingguide1')}</h2>
        <p>{i18n.t('provider.pricingguide2')} </p>
        {services.accounting ? (
          <>
            <h3>{i18n.t('provider.pricingguide3')}</h3>
            <p>{i18n.t('provider.pricingguide4')}</p>
            <p>{i18n.t('provider.pricingguide5')} </p>
            <p>{i18n.t('provider.pricingguide6')}</p>
            <p>{i18n.t('provider.pricingguide7')}</p>
            <p>{i18n.t('provider.pricingguide8')}</p>
            <p>{i18n.t('provider.pricingguide9')}</p>
            <p>{i18n.t('provider.pricingguide10')}</p>
            <p>{i18n.t('provider.pricingguide11')}</p>
            <p>{i18n.t('provider.pricingguide12')}</p>
            <p>{i18n.t('provider.pricingguide13')} </p>
          </>
        ) : null}
        {services.payroll ? (
          <>
            <h3>{i18n.t('provider.pricingguide14')}</h3>
            <p>{i18n.t('provider.pricingguide15')}</p>
          </>
        ) : null}
        {services.taxconsulting ? (
          <>
            <h3>{i18n.t('provider.pricingguide16')}</h3>
            <p>{i18n.t('provider.pricingguide17')} </p>
            <p>{i18n.t('provider.pricingguide18')}</p>
          </>
        ) : null}
        {/* {services.audit ? (
          <>
            <h3>{i18n.t('provider.pricingguide19')}</h3>
            <p>{i18n.t('provider.pricingguide20')}</p>
          </>
        ) : null} */}
        {/* {services.legalentityfounding ? (
          <>
            <h3>{i18n.t('provider.pricingguide21')}</h3>
            <p>{i18n.t('provider.pricingguide22')}</p>
          </>
        ) : null} */}
        {services.liquidation ? (
          <>
            <h3>{i18n.t('provider.pricingguide23')}</h3>
            <p>{i18n.t('provider.pricingguide24')}</p>
          </>
        ) : null}
      </div>
    );
    setGuide(pricingGuide);
  }, [
    setGuide,
    services.accounting,
    services.payroll,
    services.taxconsulting,
    //services.audit,
    //services.legalentityfounding,
    services.liquidation,
  ]);

  const checkAccounting = () => {
    let filled = true;
    providerCompanyTypeList().forEach((item) => {
      if (
        companyTypeFilter[item.id] &&
        !accountingPrice[`${item.id}price`] &&
        accountingPrice[`${item.id}price`] !== 0
      ) {
        executeScroll(accPrices);
        filled = false;
        return;
      }
    });

    return filled;
  };
  const checkInvoices = () => {
    let filled = true;
    invoicesExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        executeScroll(accPrices);
        filled = false;
        return;
      }
    });

    return filled;
  };
  const checkForeignTrade = () => {
    let filled = true;
    foreignTradeExtra().forEach((item) => {
      if (
        (specialRestr.insideEU &&
          !accountingPrice.foreigntradeextra &&
          accountingPrice.foreigntradeextra !== 0) ||
        (specialRestr.outsideEU &&
          !accountingPrice.foreigntradeextra &&
          accountingPrice.foreigntradeextra !== 0)
      ) {
        executeScroll(accPrices);
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkRevenue = () => {
    let filled = true;
    revenueExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        executeScroll(accPrices);
        filled = false;
        return;
      }
    });
    return filled;
  };
  const checkVat = () => {
    let filled = true;
    vatExtra().forEach((item) => {
      if (!accountingPrice[item.id] && accountingPrice[item.id] !== 0) {
        executeScroll(accPrices);
        filled = false;
        return;
      }
    });
    return filled;
  };
  // const checkFounding = () => {
  //   let filled = true;
  //   providerCompanyTypeList().forEach((item) => {
  //     if (
  //       item.id !== 'citycouncil' &&
  //       companyTypeFilter[item.id] &&
  //       !foundingPrice[`${item.id}price`] &&
  //       foundingPrice[`${item.id}price`] !== 0
  //     ) {
  //       executeScroll(foundingPrices);
  //       filled = false;
  //       return;
  //     }
  //   });
  //   return filled;
  // };
  const checkPricing = (text, ref, storeObj) => {
    let filled = true;
    text.forEach((item) => {
      if (!storeObj[item.id] && storeObj[item.id] !== 0) {
        executeScroll(ref);
        filled = false;
        return;
      }
    });
    return filled;
  };
  const validatePricing = () => {
    if (services.accounting) {
      if (!checkAccounting()) {
        console.log('acc');
        return false;
      }
      if (!checkForeignTrade()) {
        console.log('ft');
        return false;
      }
      if (!checkRevenue()) {
        console.log('rev');
        return false;
      }
      if (!checkInvoices()) {
        console.log('inv');
        return false;
      }
      if (!checkVat()) {
        console.log('vat');
        return false;
      }
    }
    if (
      services.payroll &&
      !checkPricing(payrollPricing(), payrollPrices, payrollPrice)
    ) {
      console.log('payroll');
      return false;
    }
    if (
      services.taxconsulting &&
      !checkPricing(accountingTaxPricing(), taxPrices, taxPrice)
    ) {
      console.log('tax');
      return false;
    }
    // if (
    //   services.audit &&
    //   !checkPricing(auditPricing(), auditPrices, auditPrice)
    // ) {
    //   console.log('audit');
    //   return false;
    // }
    // if (services.legalentityfounding && !checkFounding()) {
    //   console.log('founding');
    //   return false;
    // }
    if (
      services.liquidation &&
      !checkPricing(liquidationPricing(), liquidationPrices, liquidationPrice)
    ) {
      console.log('liq');
      return false;
    }
    return true;
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby='responsive-dialog-title'
      >
        <div className='Mkoepopup' style={{ height: 'auto' }}>
          <img src={MkoeIcon} alt='' />
          <h2>{i18n.t('provider.mkoepartnerlabel')}</h2>
          <p>
            {i18n.t('provider.mkoetext1')}{' '}
            <b>{i18n.t('provider.mkoetext2')} </b>
            {i18n.t('provider.mkoetext3')} <b>{i18n.t('provider.mkoetext4')}</b>{' '}
            {i18n.t('provider.mkoetext5')} <b>{i18n.t('provider.mkoetext6')}</b>
            {i18n.t('general.dot')}
            <br />
            <b>{i18n.t('provider.mkoetext7')}</b>
          </p>
          <StyledButton color='Primary' onClick={handleDialogClose}>
            {i18n.t('general.ok')}
          </StyledButton>
        </div>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        style={{ width: '100%' }}
      >
        <Alert
          open={open}
          onClose={handleClose}
          severity='error'
          className='AlertWidth'
        >
          {i18n.t('provider.pricingwarning')}
        </Alert>
      </Snackbar>
      <div className='PaddingBot100'>
        <Paper className='providerPaper'>
          <PricingWizard />
        </Paper >
        <div >
          <div className='ProviderPricingContainer' style={{fontSize: '1.3rem', color: 'red', fontWeight: '600'}}>{i18n.t('providerdashboard.pricingwarning')}</div>
        </div>
        {services.accounting ? (
          <Paper className='providerPaper'>
            <div ref={accPrices} className='ProviderPricingContainer'>
              <AccountingInputs />
            </div>
          </Paper>
        ) : null}
        {services.payroll ? (
          <Paper className='providerPaper'>
            <div ref={payrollPrices} className='ProviderPricingContainer'>
              <PricingInputs
                title={i18n.t('general.payroll')}
                liabilityID='payroll'
                liabilityTitle={i18n.t('provider.liabilitypayroll')}
                endAdornment={i18n.t('general.currency/person')}
                dataText={payrollPricing()}
                storeObj='provider_payroll_price'
              />
            </div>
          </Paper>
        ) : null}
        {services.taxconsulting || services.taxconsultingcomplex ? (
          <Paper className='providerPaper'>
            <div ref={taxPrices} className='ProviderPricingContainer'>
              <PricingInputs
                title={i18n.t('general.taxconsulting')}
                liabilityID='taxconsulting'
                liabilityTitle={i18n.t('provider.liabilitytaxconsulting')}
                endAdornment={i18n.t('general.currency')}
                dataText={accountingTaxPricing()}
                storeObj='provider_tax_consulting_price'
              />
            </div>
          </Paper>
        ) : null}
        
        {/* <Paper className='providerPaper'>
          {services.audit ? (
            <div ref={auditPrices} className='ProviderPricingContainer'>
              <PricingInputs
                title={i18n.t('general.audit')}
                liabilityID='audit'
                liabilityTitle={i18n.t('provider.liabilityaudit')}
                endAdornment={i18n.t('general.currency/year')}
                dataText={auditPricing()}
                storeObj='provider_audit_price'
              />
            </div>
          ) : null}
        </Paper> */}
        {/* {services.legalentityfounding ? (
          <div ref={foundingPrices} className='ProviderPricingContainer'>
            <FoundingInputs />
          </div>
        ) : null} */}
        {services.liquidation ? (
          <Paper className='providerPaper'>
            <div ref={liquidationPrices} className='ProviderPricingContainer'>
              <PricingInputs
                title={i18n.t('general.liquidation')}
                liabilityID='liquidation'
                liabilityTitle={i18n.t('provider.liabilityliquidation')}
                endAdornment={i18n.t('general.currency')}
                dataText={liquidationPricing()}
                storeObj='provider_liquidation_price'
              />
            </div>
          </Paper>
        ) : null}

        <ProviderNavigation
          handleBack={() => {
            handleBack();
          }}
          handleNext={() => {
            if (
              providerSubscription.partnerCode &&
              providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
            ) {
              handleNext();
            } else {
              if (validatePricing()) {
                handleNext();
              } else if (!validatePricing()) {
                setOpen(true);
              }
            }
          }}
          step={activeStep}
        />
      </div>
    </>
  );
};

export default withTranslation()(ProviderPricing);
