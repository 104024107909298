import React from 'react';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import LanguageSelector from './input/languageSelector';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderSkills = ({ handleNext, activeStep, handleBack }) => {
  return (
    <div className='PaddingBot100'>
      <div className='ProviderDataStepContent'>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>
            {i18n.t('provider.foreignlanguage')} <br />
            <span>{i18n.t('provider.multiplelangselect')}</span>
          </p>
          <LanguageSelector />
        </Paper>
      </div>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => {
          handleNext();
        }}
        step={activeStep}
      />{' '}
    </div>
  );
};

export default withTranslation()(ProviderSkills);
