import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { providerServicesText } from '../../../data/providerText';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { ControlledTooltip } from '../../../../shared/components/tooltip/tooltip';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const ServicesInput = ({ dataText }) => {
  const dispatch = useDispatch();
  const providerServices = useSelector((state) => state.provider_services);

  const handleCheck = (event) => {
    dispatch(
      ACTIONS.addCompanyInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'provider_services',
      })
    );
  };
  return (
    <div className='ProviderServicesContainer'>
      {providerServicesText().map((item, i) => {
        return (
          <div key={i}>
            {item.id === 'taxconsultingsimple' ? (
              <ControlledTooltip
                placement='top'
                title={i18n.t('general.taxconsultinghint')}
              >
                <FormGroup>
                  <FormControlLabel
                    style={{
                      // margin: "6px 0px 6px -11px",
                      width: 'fit-content',
                    }}
                    control={
                      <Checkbox
                        id={item.id}
                        checked={providerServices[item.id]}
                        onChange={(event) => {
                          handleCheck(event);
                        }}
                      />
                    }
                    label={
                      <span className='SFCheckboxLabel'>{item.label}</span>
                    }
                  />
                </FormGroup>
              </ControlledTooltip>
            ) : (
              <FormGroup>
                <FormControlLabel
                  style={{
                    // margin: "6px 0px 6px -11px",
                    width: 'fit-content',
                  }}
                  control={
                    <Checkbox
                      id={item.id}
                      checked={providerServices[item.id]}
                      onChange={(event) => {
                        handleCheck(event);
                      }}
                    />
                  }
                  label={<span className='SFCheckboxLabel'>{item.label}</span>}
                />
              </FormGroup>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(ServicesInput);
