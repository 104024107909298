import React, { useCallback } from 'react';
import Radio from '@mui/material/Radio';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { providerCompanyIndustryList } from '../../../data/providerText';

const experienceRatings = [1, 2, 3, 4, 5];

export default function ExperienceInput() {
  const dispatch = useDispatch();
  const providerExperience = useSelector(
    (state) => state.provider_industry_experience
  );
  const providerIndustryRestrictions = useSelector(
    (state) => state.provider_industry_restrictions
  );
  console.log(providerIndustryRestrictions, 'providerIndustryRestrictions');
  const handleChange = (event) => {
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: parseInt(value),
        stateObject: 'provider_industry_experience',
      })
    );
  };

  const industryCheck = useCallback(() => {
    let industryShown = [];
    providerCompanyIndustryList().forEach((item, i) => {
      let filteredArr = providerIndustryRestrictions.filter((ind) => ind.on);
      filteredArr.forEach((filtereditem) => {
        if (filtereditem.id === item.id) {
          industryShown.push(
            <div className='CenteredInput InputColumn' key={i + item}>
              <p>{item.label}</p>
              {experienceRatings.map((exp) => {
                return (
                  <Radio
                    id={item.id}
                    checked={providerExperience[item.id] === exp ? true : false}
                    onChange={handleChange}
                    value={exp}
                    inputProps={{ 'aria-label': 'A' }}
                    sx={{
                      color: '#1A00BD',
                      '&.Mui-checked': {
                        color: '#1A00BD',
                      },
                    }}
                  />
                );
              })}
            </div>
          );
        }
      });
    });
    return industryShown;
  }, [providerIndustryRestrictions, providerExperience]);

  return (
    <div className='ProviderInputContainerCentered'>
      <div className='ExperienceInputRatings'>
        {experienceRatings.map((exp, i) => {
          return <p key={exp + i}>{exp}</p>;
        })}
      </div>
      <div className='ProviderInputContainer' style={{ padding: '12px 24px' }}>
        {industryCheck()}
        {/* {providerCompanyIndustryList().map((item, i) => {
          if (providerIndustryRestrictions.filter((ind) => ind.on)) {
            return (
              <div className='CenteredInput InputColumn' key={i + item}>
                <p>{item.label}</p>
                {experienceRatings.map((exp) => {
                  return (
                    <Radio
                      id={item.id}
                      checked={
                        providerExperience[item.id] === exp ? true : false
                      }
                      onChange={handleChange}
                      value={exp}
                      inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: '#1A00BD',
                        '&.Mui-checked': {
                          color: '#1A00BD',
                        },
                      }}
                    />
                  );
                })}
              </div>
            );
          } else return null;
        })} */}
      </div>
    </div>
  );
}
