import React, { useState, useEffect } from "react";
import "./providerFeedback.scss";
import { useLocation } from "react-router-dom";
import { feedback } from "./data/feedbackData.js";
import StyledButton from "../../shared/components/styledButton/styledButton";
import { FormControl } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { Radio } from '@mui/material';
import { TextField } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Checkbox } from '@mui/material';
import blueaccountant from '../../images/BlueAccountant.png';
import i18n from '../../utils/i18n';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from '../../redux/action.js'
import { gdprLabelSF } from '../searchFlow/data/searchFlowText.js';
import { shuffle } from 'lodash';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const FeedbackForm = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const respondentID = [...urlParams.keys()].pop() || '';
  const currentURL = window.location.href;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("feedbackFormData");
    return savedFormData ? JSON.parse(savedFormData) : {};
  });
  const [formCompleted, setFormCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [otherText, setOtherText] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inputError, setInputError] = useState({ name: false, email: false, phone: false });
  const [gdprError, setGdprError] = React.useState(false);
  const dispatch = useDispatch();
  const companyStore = useSelector(state => state.company_store);
  const [directedToSF, setDirectedToSF] = useState(false);
  const [shuffledOptions, setShuffledOptions] = useState({});

  useEffect(() => {
    const feedbackContentForm = document.querySelector('.feedbackFormContent');
    if (feedbackContentForm) {
      feedbackContentForm.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const firstOptionElement = document.querySelector('.feedbackRadioOptionContainer');
    if (firstOptionElement) {
      firstOptionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (formCompleted || currentQuestionIndex === feedback.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [formCompleted, currentQuestionIndex]);

  useEffect(() => {
    const initialOptions = feedback.reduce((acc, question, index) => {
      if (question.options.includes("Egyéb")) {
        let options = question.options.filter(option => option !== "Egyéb");
        options = shuffle(options);
        const priceIndex = options.indexOf("Ár");
        if (priceIndex > -1 && priceIndex < 4) {
          options.splice(priceIndex, 1);
          options.splice(4, 0, "Ár");
        }
        options = options.concat("Egyéb");
        acc[index + 1] = options;
      } else {
        acc[index + 1] = question.options;
      }
      return acc;
    }, {});
    setShuffledOptions(initialOptions);
  }, []);

  let options = shuffledOptions[currentQuestionIndex];

  useEffect(() => {
    localStorage.setItem("feedbackFormData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const handleBeforeUnload = async () => {
      if (!formCompleted && Object.keys(formData).length > 0) {
        prepareFormData();
        await postFormData();
        localStorage.removeItem("feedbackFormData");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  const handleOptionChange = (event, option) => {
    const { name } = event.target;
    setFormData({
      ...formData,
      [name]: option,
    });
    setErrorMessage(null);
  };

  const handleOptionChangeCheckbox = (event, option) => {
    const { name } = event.target;
    const currentValues = formData[name] || [];
    const newValues = currentValues.includes(option)
      ? currentValues.filter((value) => value !== option)
      : [...currentValues, option];

    setFormData({
      ...formData,
      [name]: newValues,
    });

    if (!newValues.includes("Egyéb")) {
      setOtherText("");
    }
    setErrorMessage(null);
  };

  const handleOtherTextChange = (event) => {
    const value = event.target.value;
    setOtherText(value);
    setFormData((prevData) => ({
      ...prevData,
      otherText: value,
      [`question${currentQuestionIndex}`]: [
        ...(prevData[`question${currentQuestionIndex}`] || []).filter(option => option !== "Egyéb"),
        "Egyéb"
      ],
    }));
  };

  const handleNext = async (isDirectedToSF = false) => {
    setDirectedToSF(isDirectedToSF);
    if (currentQuestionIndex === 8 && directedToSF) {
      return;
    }

    if (currentQuestionIndex < feedback.length && formData[`question${currentQuestionIndex}`] === undefined) {
      setErrorMessage(i18n.t("general.pleasechooseanoption"));
      setTimeout(() => {
        document.querySelector('.feedbackErrorMessage').scrollIntoView({ behavior: 'smooth' });
      }, 0);
      return;
    }

    if (formData[`question${currentQuestionIndex}`] === "Nem rendelkezem könyvelővel") {
      prepareFormData();
      await postFormData();
      setFormCompleted(true);
      return;
    }

    if (currentQuestionIndex === 7 && formData[`question${currentQuestionIndex}`] !== "Inkább igen" && formData[`question${currentQuestionIndex}`] !== "Teljesen") {
      prepareFormData();
      await postFormData();
      setFormCompleted(true);
      return;
    }

    if (currentQuestionIndex === 8 && !companyStore.gdpr) {
      setGdprError(true);
      setErrorMessage(null);
      setTimeout(() => {
        document.querySelector('.gdprErrorMessage').scrollIntoView({ behavior: 'smooth' });
      }, 0);
      return;
    } else {
      setGdprError(false);
    }

    if (currentQuestionIndex === 8) {
      if (!name || !email || !phone) {
        setInputError({
          name: !name,
          email: !email,
          phone: !phone,
        });
        setErrorMessage(i18n.t("provider.fillouttocontinue"));
        setTimeout(() => {
          document.querySelector('.feedbackErrorMessage').scrollIntoView({ behavior: 'smooth' });
        }, 0);
        return;
      }

      if (!name.trim()) {
        setInputError({
          ...inputError,
          name: true,
        });
        setErrorMessage(i18n.t("provider.fillouttocontinue"));
        // setTimeout(() => {
        //   document.querySelector('.feedbackErrorMessage').scrollIntoView({ behavior: 'smooth' });
        // }, 0);
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        setInputError({
          ...inputError,
          email: true,
        });
        setErrorMessage(i18n.t("provider.fillouttocontinue"));
        return;
      }

      const phoneRegex = /^\+\d{1,12}$/;
      if (!phoneRegex.test(phone.trim())) {
        setInputError({
          ...inputError,
          phone: true,
        });
        setErrorMessage(i18n.t("provider.fillouttocontinue"));
        return;
      }

      prepareFormData();

      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'callbacksource',
          data: 'feedbackform',
          stateObject: 'company_store',
        })
      );

      if (Object.keys(formData).length > 0) {
        try {
          await postFormData();
          setFormCompleted(true);
        } catch (error) {
          console.log(error.message);
        }
      }
      return;
    }

    if (currentQuestionIndex < feedback.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      prepareFormData();
      if (Object.keys(formData).length > 0) {
        try {
          await postFormData();
          setFormCompleted(true);
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 1) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setErrorMessage(null);
    }
  };

  const prepareFormData = () => {
    const currentDate = new Date().toISOString();
    const screenWidth = window.innerWidth;
    formData.date = currentDate;
    formData.screenWidth = screenWidth;
    formData.otherText = otherText;
    formData.name = name;
    formData.email = email;
    formData.phone = phone;
    formData.respondentID = respondentID;
    formData.currentURL = currentURL;
    formData.directedToSF = directedToSF;
  };


  const postFormData = async () => {
    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };
    // const response = await fetch(`http://localhost:3001/company/request/feedback`, postOptions);
    const response = await fetch(`${BACKEND_API}/api/feedback`, postOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    setFormCompleted(true);
    localStorage.removeItem("feedbackFormData");
  };

  useEffect(() => {
    if (formCompleted) {
      setTimeout(() => {
        window.location.href = "https://www.bookkeepie.com/hu";
      }, 5000);
    }
  }, [formCompleted]);

  return (
      <div className="feedbackForm">
        {!formCompleted ? (
          <>
            <form style={{ height: "100%" }}>
              {currentQuestionIndex !== feedback.length && (
                <div className="feedbackContentHeader">
                  <div>
                    <img
                      className="feedbackFormHeaderImg"
                      alt="header"
                      src={blueaccountant}
                    />
                  </div>
                  <div className="feedbackTitle">
                    {i18n.t("providerfeedback.feedbacktitle")}
                  </div>
                  <div className="feedbackSubTitle">
                    {i18n.t("providerfeedback.feedbacksubtitle")}
                    <br />
                    {i18n.t("providerfeedback.feedbackthankyou")}
                  </div>
                </div>
              )}

              {/* {currentQuestionIndex !== feedback.length && ( // OPTION: REMOVE HEADER ON LAST PAGE
                <div className="feedbackContentHeader">
                  <div>
                    <img
                      className="feedbackFormHeaderImg"
                      alt="header"
                      src={blueaccountant}
                    />
                  </div>
                  <div className="feedbackTitle">
                    {i18n.t("providerfeedback.feedbacktitle")}
                  </div>
                  <div className="feedbackSubTitle">
                    {i18n.t("providerfeedback.feedbacksubtitle")}
                    <br />
                    {i18n.t("providerfeedback.feedbackthankyou")}
                  </div>
                </div>
              )} */}

              {currentQuestionIndex !== 8 && (
                <div className="feedbackQuestionContainer">
                  {feedback[currentQuestionIndex - 1].question}
                </div>
              )}

              {currentQuestionIndex === 8 ? (
                <div className="feedbackFinalPage">
                  <div>
                    <p style={{ fontSize: "1.3rem", marginTop: 12 }}>{i18n.t("providerfeedback.marketingtitle")}</p>
                    <p style={{ fontSize: "0.9rem", color: "#505581" }}>{i18n.t("providerfeedback.marketingtext1")}</p>
                    <p style={{ fontSize: "0.9rem", color: "#505581" }}>{i18n.t("providerfeedback.marketingtext2")}</p>
                    <p style={{ fontSize: "0.9rem", fontWeight: "800" }}>{i18n.t("providerfeedback.marketingtext3")}</p>
                    <p style={{ fontSize: "0.9rem", color: "#505581" }}>{i18n.t("providerfeedback.marketingtext4")}</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <StyledButton
                        color={"NewNavForwardWide100"}
                        onClick={async () => {
                          try {
                            await handleNext(true);
                            // window.location.href = "https://dashboard-hu.bookkeepie.com/company/request/1";
                            window.location.href = "https://dashboard-hu.bookkeepie.com/company/request/1?utm_source=feedback-form&utm_medium=redirect&utm_campaign=feedback";
                          } catch (error) {
                            console.log(error.message);
                          }
                        }}
                      >
                        {i18n.t("providerfeedback.fillform")}
                      </StyledButton>
                    </div>
                    <p style={{ fontSize: "0.9rem", color: "#505581", marginBottom: "12px" }}>{i18n.t("providerfeedback.marketingtext5")}</p>
                  </div>
                  <TextField
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setInputError({ ...inputError, name: false });
                      setErrorMessage(null);
                    }}
                    label={i18n.t("general.name")}
                    error={inputError.name}
                    helperText={inputError.name && i18n.t("providervafform.warningfield")}
                    fullWidth
                    InputProps={{
                      style: { color: inputError.name ? 'red' : 'black' },
                    }}
                  />
                  <TextField
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                      setInputError({ ...inputError, email: false });
                      setErrorMessage(null);
                    }}
                    label={i18n.t("general.email")}
                    error={inputError.email}
                    helperText={inputError.email && i18n.t("providerfeedback.validateemail")}
                    fullWidth
                    InputProps={{
                      style: { color: inputError.email ? 'red' : 'black' },
                    }}
                  />
                  <TextField
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value.trim());
                      setInputError({ ...inputError, phone: false });
                      setErrorMessage(null);
                    }}
                    label={i18n.t("general.phone")}
                    error={inputError.phone}
                    helperText={inputError.phone && i18n.t("providerfeedback.validatephone")}
                    inputProps={{ maxLength: 12 }}
                    fullWidth
                    InputProps={{
                      style: { color: inputError.phone ? 'red' : 'black' },
                    }}
                  />
                  <div style={{ padding: 0 }}>
                    <p className='noprovtitle' style={{ fontSize: "1.2rem", marginTop: 4 }}>{i18n.t('searchflow.sfresulttext2')}</p>
                    <p className='noprovtext' style={{ fontSize: "0.9rem", marginBottom: 0 }}>{i18n.t('searchflow.whatnowtext')}</p>
                    <div style={{ margin: '12px 0', color: "#505581" }}>
                      <FormControlLabel
                        key={'gdpr'}
                        control={
                          <Checkbox
                            id='gdpr'
                            name='gdpr'
                            checked={companyStore.gdpr}
                            onChange={(event) => {
                              dispatch(
                                ACTIONS.addCompanyInfo({
                                  id: event.target.id,
                                  data: event.target.checked,
                                  stateObject: 'company_store',
                                })
                              );
                            }}
                            color='primary'
                            required
                          />
                        }
                        label={gdprLabelSF()}
                      />
                      <FormControlLabel
                        key={'marketing'}
                        control={
                          <Checkbox
                            id='marketing'
                            name='checked'
                            checked={companyStore.marketing}
                            onChange={(event) => {
                              dispatch(
                                ACTIONS.addCompanyInfo({
                                  id: event.target.id,
                                  data: event.target.checked,
                                  stateObject: 'company_store',
                                })
                              );
                            }}
                            color='primary'
                          />
                        }
                        label={
                          <span className='SFSmallText' style={{ color: "#505581" }}>
                            {i18n.t('searchflow.newslettersubtext')}
                          </span>
                        }
                      />
                    </div>
                    {gdprError ? (
                      <span
                        style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
                        className="gdprErrorMessage"
                      >
                        {i18n.t('searchflow.plsacceptgdpr')}
                      </span>
                    ) : null}
                  </div>
                  <div>
                    {errorMessage && (
                      <p className="feedbackErrorMessage">{errorMessage}</p>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <StyledButton
                      color="NewNavForwardWide"
                      onClick={async () => await handleNext(false)}
                    >
                      {i18n.t("providerfeedback.makeconnection")}
                    </StyledButton>
                  </div>
                </div>
              ) : (
                <div className="feedbackFormContent">
                  <FormControl component="fieldset">
                    {currentQuestionIndex === 6 ? (
                      options.map((option, index) => (
                        <div
                          key={index}
                          className="feedbackRadioOptionContainer"
                        >
                          <FormControlLabel
                            key={option}
                            control={
                              <Checkbox
                                key={option}
                                checked={(formData[`question${currentQuestionIndex}`] || []).includes(option)}
                                onChange={(event) => handleOptionChangeCheckbox(event, option)}
                                name={`question${currentQuestionIndex}`}
                              />
                            }
                            label={option}
                            onClick={(event) => handleOptionChangeCheckbox(event, option)}
                            className="feedbackFullWidthClickable"
                          />
                          {option === "Egyéb" && (formData[`question${currentQuestionIndex}`] || []).includes("Egyéb") && (
                            <TextField
                              value={otherText}
                              onChange={handleOtherTextChange}
                              placeholder={i18n.t("general.youranswerhere")}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <RadioGroup
                        aria-label={`question${currentQuestionIndex}`}
                        name={`question${currentQuestionIndex}`}
                        value={formData[`question${currentQuestionIndex}`] || ""}
                      >
                        {feedback[currentQuestionIndex - 1].options.map((option, index) => (
                          <div
                            key={index}
                            className="feedbackRadioOptionContainer"
                            onClick={() =>
                              handleOptionChange(
                                { target: { name: `question${currentQuestionIndex}` } },
                                option
                              )
                            }
                          >
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={<Radio key={option} />}
                              label={option}
                            />
                            {option === "Egyéb" && formData[`question${currentQuestionIndex}`] === "Egyéb" && (
                              <TextField
                                value={otherText}
                                onChange={handleOtherTextChange}
                                placeholder={i18n.t("general.youranswerhere")}
                                fullWidth
                                margin="normal"
                              />
                            )}
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                  </FormControl>
                  {currentQuestionIndex === 8 && (
                    <div style={{ padding: 0 }}>
                      <p className='noprovtitle' style={{ fontSize: "1.2rem", marginTop: 4 }}>{i18n.t('searchflow.sfresulttext2')}</p>
                      <p className='noprovtext' style={{ fontSize: "0.9rem", marginBottom: 0 }}>{i18n.t('searchflow.whatnowtext')}</p>
                      <div style={{ margin: '12px 0', color: "#505581" }}>
                        <FormControlLabel
                          key={'gdpr'}
                          control={
                            <Checkbox
                              id='gdpr'
                              name='gdpr'
                              checked={companyStore.gdpr}
                              onChange={(event) => {
                                dispatch(
                                  ACTIONS.addCompanyInfo({
                                    id: event.target.id,
                                    data: event.target.checked,
                                    stateObject: 'company_store',
                                  })
                                );
                              }}
                              color='primary'
                              required
                            />
                          }
                          label={gdprLabelSF()}
                        />
                        <FormControlLabel
                          key={'marketing'}
                          control={
                            <Checkbox
                              id='marketing'
                              name='checked'
                              checked={companyStore.marketing}
                              onChange={(event) => {
                                dispatch(
                                  ACTIONS.addCompanyInfo({
                                    id: event.target.id,
                                    data: event.target.checked,
                                    stateObject: 'company_store',
                                  })
                                );
                              }}
                              color='primary'
                            />
                          }
                          label={
                            <span className='SFSmallText' style={{ color: "#505581" }}>
                              {i18n.t('searchflow.newslettersubtext')}
                            </span>
                          }
                        />
                      </div>
                      {gdprError ? (
                        <span
                          style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
                          className="gdprErrorMessage"
                        >
                          {i18n.t('searchflow.plsacceptgdpr')}
                        </span>
                      ) : null}
                    </div>
                  )}
                  <div>
                    {errorMessage && (
                      <p className="feedbackErrorMessage">{errorMessage}</p>
                    )}
                  </div>

                  {currentQuestionIndex === feedback.length && (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <StyledButton
                        color="NewNavForwardWide"
                        onClick={async () => await handleNext(false)}
                      >
                        {i18n.t("providerfeedback.makeconnection")}
                      </StyledButton>
                    </div>
                  )}
                </div>
              )}
            </form>

            {currentQuestionIndex !== feedback.length && (
              <div className="feedbackButtonContainer">
                {currentQuestionIndex !== feedback.length && (
                  <StyledButton
                    color={"NewNavBack"}
                    onClick={handlePrevious}
                    isDisabled={currentQuestionIndex === 1}
                    style={{ flex: 1 }}
                  >
                    {i18n.t("general.backbutton")}
                  </StyledButton>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                  <StyledButton
                    color={currentQuestionIndex === feedback.length ? "NewNavForwardWide" : "NewNavForward"}
                    onClick={async () => await handleNext(false)}
                  >
                    {currentQuestionIndex === feedback.length
                      ? i18n.t("providerfeedback.makeconnection")
                      : i18n.t("general.nextbutton")}
                  </StyledButton>
                </div>
              </div>
            )}

          </>
        ) : (
          <>
            <div className="feedbackSuccess">
              <p className="thankYouFeedback">
                {i18n.t("general.thankyoufeedback")}
              </p>
              <br />
              <p>{i18n.t("general.redirecttohome")}</p>
              <br />
              <CircularProgress size={20} />
            </div>
          </>
        )}
      </div>
  );

};

export default FeedbackForm;