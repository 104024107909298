import React from 'react';
import {
  bankingPrefText,
  invoicingPrefText,
  softwarePrefText,
} from '../../data/providerText';
import PreferencesInputs from './input/preferencesInputs';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import Dialog from '@mui/material/Dialog';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import MkoeIcon from '../../../images/icons/mkoe-icon-new.png';
import { useSelector } from 'react-redux';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderPreferences = ({ handleNext, handleBack, activeStep }) => {
  const [providerSubscription] = useSelector((state) => [
    state.provider_subscription,
  ]);
  const [open, setOpen] = React.useState(
    providerSubscription.partnerCode &&
      providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='PaddingBot100'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <div className='Mkoepopup'>
          <img src={MkoeIcon} alt='' />
          <h2>{i18n.t('provider.mkoepartnerlabel')}</h2>
          <p>
            {i18n.t('provider.mkoetext1')}
            <b>{i18n.t('provider.mkoetext2')}</b>
            {i18n.t('provider.mkoetext3')} <b>{i18n.t('provider.mkoetext4')}</b>
            {i18n.t('provider.mkoetext5')}
            <b>{i18n.t('provider.mkoetext6')}</b>.
          </p>
          <StyledButton color='Primary' onClick={handleClose}>
            {i18n.t('general.ok')}
          </StyledButton>
        </div>
      </Dialog>
      <div className='ProviderInputContainerCentered'>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>
            {i18n.t('provider.restrictionsoptions')}
          </p>
          <p className='ProviderInputSubTitle'>
            {i18n.t('provider.restrictionstext')}
          </p>
          <div className='RestrictionsNotification'>
            <h2>{i18n.t('provider.warning')}</h2>
            <p>{i18n.t('provider.restrictionstext2')}</p>
          </div>
        </Paper>
        <Paper className='providerPaper'>
          <p className='RestrictionTitle'>{i18n.t('provider.bankingrestr')}</p>
          <PreferencesInputs
            dataText={bankingPrefText()}
            storeObj='provider_banking_filter'
          />
        </Paper>
        <Paper className='providerPaper'>
          <p className='RestrictionTitle'>
            {i18n.t('provider.invoicingrestr')}
          </p>
          <PreferencesInputs
            dataText={invoicingPrefText()}
            storeObj='provider_invoicing_filter'
          />
        </Paper>
        <Paper className='providerPaper'>
          <p className='RestrictionTitle'>{i18n.t('provider.softwarerestr')}</p>
          <PreferencesInputs
            dataText={softwarePrefText()}
            storeObj='provider_software_filter'
          />
        </Paper>
      </div>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => {
          handleNext();
        }}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderPreferences);
