import React, { useState, useCallback, useEffect } from 'react';
import { industryInput1 } from '../data/searchFlowText';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import TextField from '@mui/material/TextField';
import ACTIONS from '../../../redux/action';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';

const Industry = ({ ...props }) => {
  const [industryError, setIndustryError] = React.useState(false);
  const companyStore = useSelector((state) => state.company_store);
  const [inputError, setInputError] = useState(false);

  const dispatch = useDispatch();

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  console.log(companyStore, 'CS');

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  useEffect(() => {
    const inputField = document.getElementById('industryinput');
    if (companyStore.industry === 'otherservices') {
      window.scrollTo(0, document.body.scrollHeight);
      inputField.focus();
    }
  }, [companyStore.industry]);

  const inputCheck = () => {
    if (
      companyStore.industry === 'otherservices' &&
      companyStore.industryinput === ''
    ) {
      setInputError(true);
      return false;
    } else if (
      companyStore.industry === 'otherservices' &&
      companyStore.industryinput !== ''
    ) {
      setInputError(false);
      return true;
    }
    if (companyStore.industry === '') {
      setIndustryError(true);
      return false;
    } else {
      setIndustryError(false);
      return true;
    }
  };

  return (
    <div className='stepContainer'>
      <div>
        <p className='SFSubTitle'>{i18n.t('searchflow.vaflabelmessage1')}</p>

        <span>{i18n.t('searchflow.vaflabelmessage2')}</span>
        <br />
        <p style={{ fontSize: '1.2rem', fontWeight: '600' }}>
          {i18n.t('searchflow.vafprice')}
        </p>
        <hr />
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>
            {i18n.t('searchflow.vafmessagelabel')} -{' '}
            {i18n.t('general.obligartory')}
          </span>{' '}
          <span className='questionTracker'>1/2</span>
        </p>
        <div className='SFInput'>
          <TextField
            placeholder={i18n.t('searchflow.regindustryreqmessageph')}
            multiline
            maxRows={4}
            minRows={2}
            variant='outlined'
            value={companyStore.reqMessage}
            onChange={(event) => {
              dispatch(
                ACTIONS.addCompanyInfo({
                  id: event.target.id,
                  data: event.target.value,
                  stateObject: 'company_store',
                })
              );
            }}
            id='reqMessage'
            name='reqMessage'
            // label={i18n.t('company.companyindustrylabel')}
          />
        </div>
        <hr />
        <div className='SFInput'>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('company.showindustrya')}
            </span>{' '}
            <span className='questionTracker'>2/2</span>
          </p>
          <RadioInput
            storeID='industry'
            dataText={industryInput1()}
            error={industryError}
          />

          {companyStore.industry === 'otherservices' && (
            <div
              className='SFMarginTop1rem SFInputWidth100'
              style={{ marginBottom: '16px' }}
            >
              <TextField
                variant='outlined'
                value={companyStore.industryinput}
                onChange={(event) => {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: event.target.id,
                      data: event.target.value,
                      stateObject: 'company_store',
                    })
                  );
                }}
                error={inputError}
                id='industryinput'
                name='industryinput'
                label={i18n.t('company.companyindustrylabel')}
              />
            </div>
          )}
          {industryError ? (
            <span
              style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}
            >
              {i18n.t('searchflow.choosetocontinue')}
            </span>
          ) : null}
          {inputError ? (
            <span
              style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}
            >
              {i18n.t('searchflow.choosetocontinue2')}
            </span>
          ) : null}
        </div>
      </div>
      <Navigation
        step={3}
        handleBack={() => {
          companyStore.companytype === 'singleprimary' ||
          companyStore.companytype === 'kiva' ||
          companyStore.companytype === 'singlesecondary' ||
          companyStore.companytype === 'vszja'
            ? handleBack(2)
            : handleBack(1);
        }}
        handleNext={() => inputCheck() && handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={
          companyStore.industry !== '' && companyStore.reqMessage !== ''
            ? false
            : true
        }
      />
    </div>
  );
};

export default withTranslation()(Industry);
