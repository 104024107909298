import React from 'react';
import { providerFilterSliders } from '../../../data/providerText';
import TextField from '@mui/material/TextField';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const RestrictionRangeInputs = () => {
  const dispatch = useDispatch();
  const providerRetrictions = useSelector(
    (state) => state.provider_restrictions
  );
  const handleChange = (event, index, id) => {
    let restrArray = providerRetrictions[id];
    let value = event.target.value;
    if (id === 'incominginvoices' || id === 'outgoinginvoices') {
      if (parseInt(event.target.value) <= 20000 || !event.target.value) {
        value = event.target.value;
      } else value = 20000;
    }
    if (id === 'noofemployees') {
      if (parseInt(event.target.value) <= 250 || !event.target.value) {
        value = event.target.value;
      } else value = 250;
    }
    if (id === 'yearlyrevenue') {
      if (parseInt(event.target.value) <= 2000 || !event.target.value) {
        value = event.target.value;
      } else value = 2000;
    }
    restrArray.splice(index, 1, parseInt(value));
    dispatch(
      ACTIONS.addProviderInfo({
        id: id,
        data: restrArray,
        stateObject: 'provider_restrictions',
      })
    );
  };
  return (
    <div className='RestrictionRangeContainer'>
      {providerFilterSliders().map((item, i) => {
        return (
          <div className='RangeInputContainer' key={i}>
            <p>{item.label}</p>
            <div className='RangeInput'>
              <TextField
                id={`${item.id}0`}
                label={i18n.t('general.min')}
                variant='outlined'
                type='number'
                value={providerRetrictions[item.id][0]}
                onChange={(event) => {
                  handleChange(event, 0, item.id);
                }}
                error={
                  !providerRetrictions[item.id][0] &&
                  providerRetrictions[item.id][0] !== 0
                    ? true
                    : false
                }
              />
            </div>
            <div className='RangeInput'>
              <TextField
                id={`${item.id}1`}
                label={i18n.t('general.max')}
                variant='outlined'
                type='number'
                value={providerRetrictions[item.id][1]}
                onChange={(event) => {
                  handleChange(event, 1, item.id);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(RestrictionRangeInputs);
