import i18n from '../../../utils/i18n';
import Navigation from '../components/navigation/navigation';
import { softwareInput } from '../data/searchFlowText';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from '../../../redux/action';

import { withTranslation } from 'react-i18next';
import CheckboxInput from '../components/searchInputs/newCheckboxInput';

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #1c32f3',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#1c32f3',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#1c31f38b',
    },
  },
});

const Software = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const [showList, setShowList] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  const handleChange = (event) => {
    let temp = {
      opt1: companyStore.softwarepref.opt1,
      opt2: companyStore.softwarepref.opt2,
      opt3: companyStore.softwarepref.opt3,
      opt4: companyStore.softwarepref.opt4,
      opt5: companyStore.softwarepref.opt5,
      opt6: companyStore.softwarepref.opt6,
      opt7: companyStore.softwarepref.opt7,
      opt8: companyStore.softwarepref.opt8,
      opt9: companyStore.softwarepref.opt9,
      opt10: companyStore.softwarepref.opt10,
      opt11: companyStore.softwarepref.opt11,
      opt12: companyStore.softwarepref.opt12,
      opt13: companyStore.softwarepref.opt13,
      opt14: companyStore.softwarepref.opt14,
      opt15: companyStore.softwarepref.opt15,
      opt16: companyStore.softwarepref.opt16,
      opt17: companyStore.softwarepref.opt17,
      opt18: companyStore.softwarepref.opt18,
      opt19: companyStore.softwarepref.opt19,
      opt20: companyStore.softwarepref.opt20,
      other: companyStore.softwarepref.other,
    };
    softwareInput().forEach((item) => {
      if (event.target.value === item.id) {
        temp[item.id] = !temp[item.id];
      }
    });
    console.log(temp, 'TEMP');
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'softwarepref',
        data: temp,
        stateObject: 'company_store',
      })
    );
    console.log(companyStore, 'KOMPANI');
  };

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        handleNext();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, handleNext]);

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>
            {i18n.t('searchflow.bankingtext3x')}
          </span>{' '}
          <span className='questionTracker'>6/6</span>
        </p>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='showList'
            name='showList'
            value={showList}
            onChange={(event) => {
              setShowList(event.target.value);
            }}
          >
            <FormControlLabel
              labelPlacement='start'
              className='SFRadioInput'
              value={true}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color='default'
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  {...props}
                />
              }
              label={i18n.t('general.yes')}
            />
            <FormControlLabel
              labelPlacement='start'
              className='SFRadioInput'
              value={false}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color='default'
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  {...props}
                />
              }
              label={i18n.t('general.no')}
            />
          </RadioGroup>
        </FormControl>

        {showList === 'true' && (
          <>
            <hr />
            <p className='SFSubTitle'>{i18n.t('searchflow.bankingtext3')}</p>
            <CheckboxInput
              handleCheck={handleChange}
              dataText={softwareInput()}
              storeId={'softwarepref'}
            />
          </>
        )}
      </div>
      <Navigation
        step={9}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
      />
    </div>
  );
};

export default withTranslation()(Software);
