import { useState, useEffect, useCallback } from 'react';
import i18n from '../../utils/i18n';
import { TextField } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import StyledButton from '../../shared/components/styledButton/styledButton';
import './referral.scss';
import announce from '../../images/announce.png';
import copy from '../../images/copy.png';
import fb from '../../images/social3.png';
import twit from '../../images/social2.png';
import andrisjr from '../../images/referral3.png';
import { legalDocuments } from '../../shared/data/legalDocuments';
import { statusText } from './referralText';
import axios from '../utils/axiosCompanyProxy';
import Snack from './components/snackBar';

import { withTranslation } from 'react-i18next';

const FRONT_END_URL =
  process.env.REACT_APP_FRONT_END_URL || 'https://dashboard-hu.bookkeepie.com';

const gdprLabelSF = () => [
  <span className='SFSmallText'>
    {i18n.t('gdpr.part1')}
    <a
      className='SFSmallText'
      style={{ color: '#3797f0' }}
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.gdpr}
      key='1'
    >
      {' '}
      {i18n.t('gdpr.part2')}
    </a>{' '}
    {i18n.t('gdpr.part3')}{' '}
    <a
      className='SFSmallText'
      style={{ color: '#3797f0' }}
      rel='noopener noreferrer'
      target='_blank'
      href={legalDocuments.aszf}
      key='2'
    >
      {i18n.t('gdpr.part4')}
    </a>
    {i18n.t('gdpr.part5')}
    <span
      style={{
        color: '#FF0000',
        fontSize: '0.8rem',
        marginLeft: '0.3em',
        fontWeight: 'bold',
      }}
      key='3'
    >
      {i18n.t('gdpr.part6')}
    </span>
  </span>,
];

const ReferralPage = () => {
  let [emailMsg, setEmailMsg] = useState(i18n.t('referral.refmailmsg'));
  const [refereeEmail, setRefereeEmail] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const [referral, setReferral] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [refEmailError, setRefEmailError] = useState(false);
  const [disableAll, setDisableAll] = useState(true);
  const [gdprError, setGdprError] = useState(false);
  // eslint-disable-next-line
  const [gdpr, setGdpr] = useState(false);
  const [terms, setTerms] = useState(false);
  // eslint-disable-next-line
  const [charity, setCharity] = useState(false);
  // eslint-disable-next-line
  let [emailSubject, setEmailSubject] = useState(
    i18n.t('referral.refmailsubj')
  );
  const [refObj, setRefObj] = useState({
    referees: [],
    reqID: '',
    refererEmail: '',
    discount: 3000,
    discountLarge: 8000,
    singleDeduction: true,
    companyType: 'any',
    flow: 'searchflow',
    message: emailMsg,
    subject: emailSubject,
  });

  const [newRefs, setNewRefs] = useState([]);
  const [webUrl, setWebUrl] = useState('');
  const reqID = useState(sessionStorage.getItem('reqID'));
  const reqEmail = useState(sessionStorage.getItem('reqEmail'));
  const [open, setOpen] = useState(false);
  const tweetText =
    'Nekem bejött! Találd meg TE is a SZÁMODRA leginkább megfelelő KÖNYVELŐT egyszerűen, gyorsan, online.';

  const createReferral = () => {
    //mivel jó szarul van megcsinálva a flow, ezért szépen minden refereen átiterálunk és beállítjuk ezeket a fieldeket,mert lehet h amikor hozzáadta a refereet akkor nem voltak még megadva
    let data = refObj;
    data.referees.forEach((item) => {
      item.charity = charity;
      // data.lang = this.props.company_store.chosenLanguage;
    });
    axios
      .post(`/api/referral/create`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
        if (response.data && response.data.referral) {
          setReferral(response.data.referral);
        }
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  useEffect(() => {
    setWebUrl(
      `${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`
    );
    // eslint-disable-next-line
  }, [referral.refCode]);

  const linkCopy = () => {
    navigator.clipboard.writeText(
      `${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`
    );
  };

  const codeCopy = () => {
    navigator.clipboard.writeText(referral.refCode);
  };

  const searchReferral = useCallback(async (refererEmail) => {
    let refFound = false;
    await axios
      .post(
        `/api/referral/get/mail`,
        { refererEmail: refererEmail },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
        if (response.data) {
          refFound = true;
          console.log('referral found');
          setReferral(response.data);
          setDisableAll(false);
        }
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
    return refFound;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reqEmail) {
      setRefObj({ ...refObj, refererEmail: reqEmail[0], reqID: reqID[0] });
      searchReferral(reqEmail[0]);
    }
    // eslint-disable-next-line
  }, []);

  const checkGdpr = () => {
    if (!terms) {
      setGdprError(true);
      return false;
    } else {
      setGdprError(false);
      return true;
    }
  };

  useEffect(() => {
    if (refObj.refererEmail || referral.refererEmail) {
      setDisableAll(false);
    }
    // eslint-disable-next-line
  }, [refObj, referral]);

  useEffect(() => {
    checkGdpr();
    // eslint-disable-next-line
  }, [gdpr, terms]);

  const validateInput = (event) => {
    const validEmailRegex = RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    if (event.target.value.length > 0) {
      if (validEmailRegex.test(event.target.value)) {
        if (event.target.id === 'refererEmail') {
          //setDisableAll(false);
          setEmailError(false);
          console.log('EMAILERROR FALSE');
          return true;
        } else if (event.target.id === 'refereeEmail') {
          setRefEmailError(false);
          // setDisableAll(false);
          console.log('REFERROR FALSE');
          return true;
        }
      } else {
        if (event.target.id === 'refererEmail') {
          setDisableAll(true);
          setEmailError(true);
          return false;
        } else if (event.target.id === 'refereeEmail') {
          setRefEmailError(true);
          //setDisableAll(true);
          return false;
        }
      }
    }
  };

  const referermailhandler = async (event) => {
    let refresponse = await searchReferral(event.target.value);
    if (validateInput(event)) {
      if (!refresponse) {
        //console.log(refresponse, '!response');
        await createReferral();
      } else {
        //console.log(refresponse, 'response!!!!!!!!');
      }
    }
  };

  // const subjectHandler = (e) => {
  //   setEmailSubject(e.target.value);
  // };

  const messageHandler = (e) => {
    setEmailMsg(e.target.value);
  };

  const refereeHandler = (e) => {
    setRefereeEmail(e.target.value);
  };

  const handleRefObjChange = (e) => {
    if (e.target) setRefObj({ ...refObj, [e.target.id]: e.target.value });
  };

  const addNewReferee = () => {
    let refereesClone = newRefs;
    refereesClone.push({
      email: refereeEmail,
      bonus: '',
      status: 'sent',
      statusDate: new Date(),
      charity: charity,
      message: emailMsg,
      subject: emailSubject,
    });
    setRefObj({ ...refObj, referees: refereesClone });
    setNewRefs(refereesClone);
    setRefereeEmail('');
  };

  const deleteReferee = (idx) => {
    let refereesClone = newRefs;
    refereesClone.splice(idx, 1);
    setNewRefs(refereesClone);
    setRefObj({ ...refObj, referees: refereesClone });
  };

  const addRefereeToReferral = async (newReferees) => {
    let newRefs = newReferees.map((ref) => {
      return { ...ref, message: emailMsg, subject: emailSubject };
    });
    axios
      .patch(
        `/api/referral/add/${referral._id}`,
        {
          newReferees: newRefs,
          uniqueLink: `${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`,
          referralCode: referral.refCode,
          refererEmail: referral.refererEmail,
          message: emailMsg,
          subject: emailSubject,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        console.log('AXIOS RESPONSE: ', response);
        await searchReferral(refObj.refererEmail);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Snack handleClick={handleClick} open={open} setOpen={setOpen} />
      {!isClicked ? (
        <div className='refContainer'>
          <div>
            <h1 className='refTitle marginTop2 marginBot32'>
              {i18n.t('referral.referraltext1')}
              <span className='black'>{i18n.t('referral.referraltext1a')}</span>
            </h1>
            <p className='refInputTitle'>{i18n.t('referral.referraltext2')}</p>
            <p>
              {i18n.t('referral.referalhint')}
              <a
                href='https://dashboard-hu.bookkeepie.com/company/refDetails'
                target='_blank'
                rel='noreferrer'
              >
                <span>{` ${i18n.t('referral.popuptext10')}`}</span>
              </a>
            </p>
          </div>
          <div className='refFlex marginTop2'>
            <div className='bgBlue flexbasis'>
              <div className='marginBot32'>
                <p className='refInputTitle'>
                  {i18n.t('referral.referraltext2a')}
                </p>
                <div className='marginBot32'>
                  <TextField
                    //className={classes.refinput}
                    variant='outlined'
                    type={'text'}
                    label='E-mail cím'
                    id='refererEmail'
                    name={'refererEmail'}
                    onChange={(e) => {
                      handleRefObjChange(e);
                    }}
                    value={refObj.refererEmail}
                    //disabled={referral ? true : false}
                    onBlur={(event) => {
                      referermailhandler(event);
                    }}
                    error={emailError}
                  />
                  {emailError && (
                    <p className='errorMsg'>{i18n.t('provider.emailerror')}</p>
                  )}
                </div>
                {window.innerWidth < 600 ? (
                  <>
                    <div className='marginBot32'>
                      <p className='refInputTitle'>
                        {i18n.t('referral.referraltext14')}
                      </p>
                      <p>
                        {referral ? (
                          <span style={{ fontSize: '12px' }}>
                            {referral.refCode}{' '}
                            <span>
                              <img
                                style={{ cursor: 'pointer' }}
                                src={copy}
                                alt={'copy'}
                                onClick={() => {
                                  codeCopy();
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    <div className='marginBot32'>
                      <p className='refInputTitle'>
                        {i18n.t('referral.referraltext6')}
                      </p>
                      <p>
                        {referral ? (
                          <span style={{ fontSize: '12px' }}>
                            {`${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`}{' '}
                            <span>
                              <img
                                style={{ cursor: 'pointer' }}
                                src={copy}
                                alt={'copy'}
                                onClick={() => {
                                  linkCopy();
                                }}
                              />
                            </span>
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <p className='refInputTitle'>
                  {i18n.t('referral.referraltext3')}
                </p>
                {newRefs.map((e, idx) => {
                  return (
                    <div key={idx}>
                      <p>
                        {e.email}
                        <span
                          className='deleteX'
                          style={{ cursor: 'pointer' }}
                          onClick={() => deleteReferee(idx)}
                        >
                          X
                        </span>
                      </p>
                    </div>
                  );
                })}

                <TextField
                  variant='outlined'
                  type={'text'}
                  label='E-mail cím'
                  id='refereeEmail'
                  name={'refereeEmail'}
                  onChange={refereeHandler}
                  value={refereeEmail}
                  onBlur={(event) => {
                    validateInput(event) &&
                      addNewReferee() &&
                      setRefereeEmail('');
                  }}
                  disabled={disableAll}
                  error={refEmailError}
                />
                {refEmailError && (
                  <p className='errorMsg'>{i18n.t('provider.emailerror')}</p>
                )}
              </div>
              {/* <div className='marginBot32'>
                <p className='refInputTitle'>
                  {i18n.t('referral.referraltext4')}
                </p>
                <TextField
                  variant='outlined'
                  id='subject'
                  onChange={(e) => {
                    subjectHandler(e);
                  }}
                  type={'text'}
                  value={emailSubject}
                  name={'subject'}
                  autoComplete='off'
                  disabled={disableAll}
                />
              </div> */}
              <div className='marginBot32'>
                <p className='refInputTitle'>
                  {i18n.t('referral.referraltext5')}
                </p>
                <TextField
                  multiline={true}
                  minRows={5}
                  maxRows={5}
                  variant='outlined'
                  id='message'
                  onChange={(e) => {
                    messageHandler(e);
                  }}
                  value={emailMsg}
                  type={'text'}
                  name={'message'}
                  disabled={disableAll}
                  //error={error}
                />
              </div>

              <FormControlLabel
                className='marginBot32'
                key={'terms'}
                control={
                  <Checkbox
                    id='gdpr'
                    name='gdpr'
                    checked={terms}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setTerms(true);
                      } else if (!event.target.checked) {
                        setTerms(false);
                      }
                    }}
                    color='primary'
                    required
                  />
                }
                label={gdprLabelSF()}
                iconStyle={{ fill: 'white' }}
              />
              {referral && gdprError && (
                <p className='errorMsg marginBot32'>
                  {i18n.t('referral.refergdprerror')}
                </p>
              )}

              <div className='marginBot32 marginTop2'>
                <StyledButton
                  color='Primary'
                  onClick={() => {
                    checkGdpr() &&
                      addRefereeToReferral(refObj.referees) &&
                      handleClick();
                    setNewRefs([]);
                  }}
                  isDisabled={
                    !gdprError && newRefs.length > 0 && !disableAll
                      ? false
                      : true
                  }
                >
                  {i18n.t('referral.referralbtn1')}
                </StyledButton>
              </div>
            </div>
            <div className='flexbasis'>
              <div className='sticky'>
                <div className='bgBlue marginBot32'>
                  {/* <div
                  className='marginBot32'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <RadioInput
                    dataText={charityText}
                    selectedValue={charity}
                    setSelectedValue={setCharity}
                    //disabled={disableAll}
                  />
                </div> */}
                  {window.innerWidth >= 600 ? (
                    <>
                      <div className='marginBot32'>
                        <p className='refInputTitle'>
                          {i18n.t('referral.referraltext14')}
                        </p>
                        <p>
                          {referral ? (
                            <span>
                              {referral.refCode}{' '}
                              <span>
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={copy}
                                  alt={'copy'}
                                  onClick={() => {
                                    codeCopy();
                                  }}
                                />
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                      <div className='marginBot32'>
                        <p className='refInputTitle'>
                          {i18n.t('referral.referraltext6')}
                        </p>
                        <p>
                          {referral ? (
                            <span style={{ fontSize: '12px' }}>
                              {`${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`}{' '}
                              <span>
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={copy}
                                  alt={'copy'}
                                  onClick={() => {
                                    linkCopy();
                                  }}
                                />
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>{' '}
                    </>
                  ) : (
                    ''
                  )}

                  <div className='referralSocial'>
                    <p>{i18n.t('referral.referraltext15')}</p>
                    <div className='refSocialCont'>
                      <div
                        className='fb-share-button'
                        data-href={webUrl}
                        data-layout='button'
                        data-size='large'
                      >
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={
                            `https://www.facebook.com/sharer/sharer.php?u=` +
                            webUrl
                          }
                        >
                          <img src={fb} alt='facebook link' />
                        </a>
                      </div>
                      {/* <a
                        href='https://www.instagram.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={ig} alt='insta link' />
                      </a> */}
                      <a
                        href={
                          'https://www.twitter.com/intent/tweet?text=' +
                          tweetText +
                          '&url=' +
                          webUrl
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={twit} alt='twitter link' />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='bgDarkBlue announceContainer'>
                  <div>
                    <p>
                      {i18n.t('referral.referraltext16')}
                      {disableAll || !referral ? (
                        <>
                          <span>{i18n.t('referral.referraltext16a')}</span>
                          <span className='refYellowText'>
                            {i18n.t('referral.referraltext17')}
                          </span>
                        </>
                      ) : (
                        <span
                          onClick={() => {
                            setIsClicked(true);
                            window.scrollTo(0, 0);
                          }}
                          className='refYellowText'
                          style={{
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                          }}
                        >
                          {i18n.t('referral.referraltext17')}
                        </span>
                      )}
                      {i18n.t('referral.referraltext18')}
                    </p>
                    {!referral && <p>{i18n.t('referral.referraltext19')}</p>}
                  </div>
                  <img className='detailsPic' src={announce} alt='attention' />
                </div>
              </div>
            </div>
          </div>
          <a
            href={'https://www.bookkeepie.com/hu'}
            className='refLastBtn marginBot32'
          >
            <StyledButton color='Transparent'>
              {i18n.t('referral.referraltext20')}
            </StyledButton>
          </a>
        </div>
      ) : (
        <div className='refContainer'>
          <div>
            <h1 className='refTitle marginTop2 marginBot32'>
              {i18n.t('referral.referralstatus1')}
              <span className='black'>
                {i18n.t('referral.referralstatus1a')}
              </span>
            </h1>
          </div>
          <div className='refFlex'>
            <div className='flexbasis'>
              <p className='refInputTitle '>
                {i18n.t('referral.referralstatus2')}
              </p>
              <p className='marginBot16'>{referral.refererEmail}</p>
              <p className='refInputTitle'>
                {i18n.t('referral.referralstatus3')}
              </p>
              <p className='marginBot16'>
                {referral.refCode}{' '}
                <span>
                  <img
                    style={{ cursor: 'pointer' }}
                    src={copy}
                    alt={'copy'}
                    onClick={() => {
                      codeCopy();
                    }}
                  />
                </span>
              </p>
              <p className='refInputTitle'>
                {i18n.t('referral.referralstatus4')}
              </p>
              <p className='marginBot16' style={{ fontSize: '12px' }}>
                {`${FRONT_END_URL}/company/request/1?utm_source=ajanloi&utm_medium=referral&utm_campaign=${referral.refCode}`}{' '}
                <span>
                  <img
                    style={{ cursor: 'pointer' }}
                    src={copy}
                    alt={'copy'}
                    onClick={() => {
                      linkCopy();
                    }}
                  />
                </span>
              </p>
            </div>
            {/* <div className='bgBlue flexbasis'>
              <p>{i18n.t('referral.referralstatus5')}</p>
              <p>
                {i18n.t('referral.referralstatus6')}
                <span>AJANLOI STATUSZ</span>
                <p>STEPPER</p>
                {/* <Stepper activeStep={1} /> 
              </p>
            </div> */}
          </div>
          <hr className='blueHr' />
          <div>
            <p className='tableTitles'>
              <span className='tableCellfirst blue'>
                {i18n.t('referral.referralstatus10')}
              </span>
              <span className='tableCell blue'>
                {i18n.t('referral.referralstatus11')}
              </span>
              <span className='tableCell blue'>
                {i18n.t('referral.referralstatus12')}
              </span>
              {/* <span className='tableCell blue'>
                {i18n.t('referral.referralstatus13')}
              </span> */}
              {/* <span className='tableCell blue'>
                {i18n.t('referral.referralstatus14')}
              </span> */}
            </p>

            {referral &&
              referral.referees.map((ref, idx) => {
                // console.log(ref, 'REF');
                return (
                  <p key={idx} className='tableTitles'>
                    <span className='tableCellfirst'>{ref.email}</span>
                    <span className='tableCell'>{statusText[ref.status]}</span>
                    <span className='tableCell'>
                      {ref.statusDate.slice(0, 10)}
                    </span>
                    {/* <span className='tableCell'>{ref.bonus}</span> */}
                    {/* <span className='tableCell'>
                      {charity
                        ? i18n.t('referral.refcharityopt1')
                        : i18n.t('referral.refcharityopt2')}
                    </span> */}
                  </p>
                );
              })}
          </div>
          <hr className='blueHr' />
          <div className='refFlex marginTop2 '>
            <div className='bgDarkBlue announceContainer flexbasis'>
              <div>
                <p>{i18n.t('referral.referralstatus15a')}</p>
                <StyledButton
                  color='Primary'
                  onClick={() => {
                    setIsClicked(false);
                    window.scrollTo(0, 0);
                  }}
                >
                  {i18n.t('referral.referralstatus25')}
                </StyledButton>
              </div>
              <div>
                <img className='detailsPic' src={andrisjr} alt='handsome guy' />
              </div>
            </div>
            <div className='flexbasis alignRight'>
              {/* <p>
                {i18n.t('referral.referralstatus20')}{' '}
                <span className='black'>OSSZEG</span>
              </p>
              <span className='blue'>
                {i18n.t('referral.referralstatus21')}
              </span>
              <p>
                {i18n.t('referral.referralstatus20')}{' '}
                <span className='black'>OSSZEG</span>
              </p>
              <span className='blue'>
                {i18n.t('referral.referralstatus22')}
              </span>
              <p>
                {i18n.t('referral.referralstatus20')}{' '}
                <span className='black'>OSSZEG</span>
              </p>
              <span className='blue'>
                {i18n.t('referral.referralstatus23')}
              </span> */}
            </div>
          </div>
          <hr className='blueHr' />
          <div className='refLastBtn marginBot32'>
            <a href='https://www.bookkeepie.com/hu'>
              <StyledButton color='Transparent'>
                {i18n.t('referral.referralstatus24')}
              </StyledButton>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(ReferralPage);
