import React from 'react';
import { Paper } from '@mui/material';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const Description = ({ providerDescription }) => {
  return (
    <Paper className='ProvProfileContentPaper'>
      <h2>{i18n.t('general.description')}</h2>
      <p className='DescriptionText'>
        {providerDescription.length > 0
          ? providerDescription
          : i18n.t('general.nointro')}
      </p>
    </Paper>
  );
};

export default withTranslation()(Description);
