import { Paper } from '@mui/material';
import congratsImg from '../../../../images/referral1.png';
import React from 'react';
import './reqSent.scss';
import StyledButton from '../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../utils/i18n';

const ReqSentCherry = ({ ...props }) => {
  React.useEffect(() => {
    document.cookie = 'reqsent=true';
  }, []);
  return (
    <Paper className='ReqSentPaper'>
      <h2>{i18n.t('general.congratulations')}</h2>
      <p style={{ fontWeight: '500' }}>
        {i18n.t('searchflow.reqsent')}
        <br />
        {i18n.t('searchflow.spwillcontact')}
        <br />
        {i18n.t('searchflow.thankyouforpatience')}
      </p>
      <p className='WS' style={{ color: '#3797f0' }}>
        {i18n.t('searchflow.getmorerfp')}
      </p>
      <img style={{ height: '200px' }} src={congratsImg} alt='' />
      <p>
        {i18n.t('general.If')}
        <span>{i18n.t('general.mailprovider')}</span>
        {i18n.t('general.using')} <span>{i18n.t('general.promofolder')}</span>{' '}
        {i18n.t('general.mayarrive')}
      </p>
      <StyledButton
        style={{ margin: '16px 0px' }}
        color='Primary'
        className='  XXLong'
        onClick={() => props.handleClose()}
      >
        {i18n.t('general.morerfp')}
      </StyledButton>
      <a href='https://www.bookkeepie.com/hu'>
        <StyledButton
          style={{ margin: '16px 0px' }}
          color='Transparent'
          className='  XXLong'
        >
          {i18n.t('general.backtoprofile')}
        </StyledButton>
      </a>
    </Paper>
  );
};

export default ReqSentCherry;
