import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function PreferencesInputs({ storeObj, dataText }) {
  const [allChecked, setAllChecked] = React.useState(true);
  const dispatch = useDispatch();
  const providerPreferences = useSelector((state) => state[storeObj]);

  const incrHandler = (e, item, inc) => {
    let tempObj = providerPreferences.filter((i) => i.id === item.id)[0];
    let value = tempObj.value + inc;
    tempObj.value = value;
    //console.log(tempObj);
    dispatch(
      ACTIONS.updateArrayofObjProviderInfo({
        updatedObj: tempObj,
        arrOfObj: storeObj,
      })
    );
  };

  const checkInputValue = (e, item) => {
    let tempObj = providerPreferences.filter((i) => i.id === item.id)[0];
    if (tempObj.value > 20) {
      tempObj.value = 20;
    } else if (tempObj.value < -20) {
      tempObj.value = -20;
    } else {
      tempObj.value = parseInt(e.target.value);
    }
    dispatch(
      ACTIONS.updateArrayofObjProviderInfo({
        updatedObj: tempObj,
        arrOfObj: storeObj,
      })
    );
  };

  const inputChangeHandler = (e, item) => {
    //comment to force rebuild
    let tempObj = providerPreferences.filter((i) => i.id === item.id)[0];
    tempObj.value = parseInt(e.target.value);
    dispatch(
      ACTIONS.updateArrayofObjProviderInfo({
        updatedObj: tempObj,
        arrOfObj: storeObj,
      })
    );
  };

  React.useEffect(() => {
    dataText.forEach((item) => {
      if (providerPreferences.filter((e) => e.on === false).length > 0) {
        setAllChecked(false);
        return;
      } else {
        setAllChecked(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerPreferences]);
  const handleChange = (event, item) => {
    let tempObj = providerPreferences.filter((i) => i.id === item.id)[0];
    tempObj.on = event.target.checked;
    dispatch(
      ACTIONS.updateArrayofObjProviderInfo({
        updatedObj: tempObj,
        arrOfObj: storeObj,
      })
    );
  };
  const checkAll = (event) => {
    let status = event.target.checked;
    dataText.forEach((item) => {
      let tempObj = providerPreferences.filter((i) => i.id === item.id)[0];
      tempObj.on = status;
      dispatch(
        ACTIONS.updateArrayofObjProviderInfo({
          updatedObj: tempObj,
          arrOfObj: storeObj,
        })
      );
    });
  };
  //console.log(providerPreferences, 'providerPreferences');

  const getObject = (id, array) => {
    return array.filter((e) => e.id === id)[0];
  };

  return (
    <div className='ProviderInputContainerCentered'>
      <div className='PreferencesInputAll'>
        <p style={{ color: '#2D46B9' }}>{i18n.t('provider.tickall')}</p>
        <GreenSwitch
          {...label}
          id='all'
          checked={allChecked}
          /*   checked={providerRestrictions[item.id]} */
          onChange={(event) => {
            setAllChecked(!allChecked);
            checkAll(event);
          }}
        />
        <span style={{ color: !allChecked ? 'grey' : '#28b446' }}>
          {!allChecked
            ? `${i18n.t('provider.idontwantit')}`
            : `${i18n.t('provider.iwantit')}`}
        </span>
        <span className='modHint'>{i18n.t('provider.modhint')}</span>
      </div>
      <div className='ProviderInputContainer' style={{ padding: '12px 24px' }}>
        {dataText.map((item, i) => {
          let fetchedData = getObject(item.id, providerPreferences);
          return (
            <div className='CenteredInput FullWidth' key={i}>
              <p>{item.label}</p>
              <GreenSwitch
                {...label}
                id={item.id}
                checked={fetchedData.on}
                onChange={(event) => {
                  handleChange(event, item);
                }}
              />
              {fetchedData.on ? (
                <>
                  <span style={{ color: '#28b446' }}>
                    {i18n.t('provider.iwantit')}
                  </span>
                </>
              ) : (
                <span style={{ color: 'grey' }}>
                  {i18n.t('provider.idontwantit')}
                </span>
              )}
              <div className='modifierInput'>
                {fetchedData.on && (
                  <>
                    <button
                      className={'incrBtn'}
                      // className={
                      //   fetchedData.value === -20 ? 'disBtn' : 'incrBtn'
                      // }
                      onClick={(e) => incrHandler(e, item, -10)}
                      //disabled={fetchedData.value === -20}
                    >
                      -10%
                    </button>
                    <input
                      type='number'
                      className='incrInput'
                      id={fetchedData.id}
                      value={fetchedData.value}
                      onChange={(e) => {
                        inputChangeHandler(e, item);
                      }}
                      onBlur={(e) => {
                        checkInputValue(e, item);
                      }}
                    />
                    <button
                      className={'incrBtn'}
                      // className={
                      //   fetchedData.value === 20 ? 'disBtn' : 'incrBtn'
                      // }
                      onClick={(e) => incrHandler(e, item, +10)}
                      //disabled={fetchedData.value === 20}
                    >
                      +10%
                    </button>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withTranslation()(PreferencesInputs);
