import React from 'react';
import { Paper } from '@mui/material';
import { languagesText } from '../../data/providerText';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const Languages = ({ data }) => {
  return (
    <>
      <Paper className='ProvProfileContentPaper ProvDarkGreyText'>
        <h2>{i18n.t('provider.spokenlanguages')}</h2>
        <div className='ProvProfileServices'>
          {languagesText().map((item, idx) => {
            if (item.id !== 'idk' && data[item.id]) {
              return (
                <p style={{ flexBasis: '10%' }} key={idx}>
                  {item.name}
                  <img
                    className='Icon32 MarginVertical'
                    src={item.flag}
                    alt='language flag'
                  />
                </p>
              );
            } else return null;
          })}
        </div>
      </Paper>
    </>
  );
};

export default withTranslation()(Languages);
