import React from 'react';
import Navigation from './components/navigation/navigation';
import './providerDashboard.scss';
import Dashboard from './content/dashboard/dashboard';
import Requests from './content/requests/requests';
//import { dashboardLabels } from '../data/providerText';
import ProviderProfile from './content/profile/profile';
// import ProfileInfo from './components/profileInfo';
import axios from '../../shared/axios/axiosProxy';
//REDUX
import { connect } from 'react-redux';
import ACTIONS, {
  fetchProviderData,
  fetchProviderRequests,
} from '../../redux/action';
import Filters from './content/filters/filters';
import Reviews from './content/reviews/reviews';
import Subscription from './content/subscription/subscription';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
  store: state,
  loading: state.loading,
  userData: state.userData,
  provider_info: state.provider_info,
  requests: state.requests,
  reqInfo: state.reqInfo,
});

const mapDispatchToProps = (dispatch) => ({
  addProviderInfo: (item) => dispatch(ACTIONS.addProviderInfo(item)),
  addReqInfo: (item) => dispatch(ACTIONS.addReqInfo(item)),
  fetchProviderRequests: (JWT) => dispatch(fetchProviderRequests(JWT)),
  fetchProviderData: (JWT) => dispatch(fetchProviderData(JWT)),
});

class ProviderDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      JWT: localStorage.getItem('JWT'),
      ID: '',
      activeStep: 'profile',
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.fetchProviderData(this.state.JWT);
    let data = { JWT: this.state.JWT, id: this.props.store.userData.id };
    await this.props.fetchProviderRequests(data);
    this.sortRequests(this.props.requests);
    this.setState({ reqSorted: true });
  }

  setContent = () => {
    return {
      dashboard: (
        <Dashboard
          handleStepChange={this.handleStepChange}
          fetchRequests={() => {
            this.componentDidMount();
          }}
        />
      ),
      requests: <Requests updateRequests={this.updateRequests} />,
      profile: <ProviderProfile submitToDB={this.submitToDB} />,
      filters: (
        <Filters submitToDB={this.submitToDB} providerData={this.props.store} />
      ),
      reviews: <Reviews />,
      /*       partneroffers: null, */
      subscription: <Subscription submitToDB={this.submitToDB} />,
      /*       preferences: null, */
    };
  };

  updateRequests = async () => {
    await this.props.fetchProviderRequests({
      JWT: this.state.JWT,
      id: this.props.store.userData.id,
    });
    this.sortRequests(this.props.requests);
  };

  sortRequests = (requests) => {
    let reqInfo = {
      reqNumber: 0,
      open: 0,
      newCustomer: 0,
      reqSorted: {
        open: [],
        accepted: [],
        modified: [],
        confirmed: [],
        declined: [],
        rejected: [],
      },
    };
    let ID = this.props.store.userData.id;
    requests.forEach((req) => {
      reqInfo.reqNumber += 1;
      let reqStatus = '';
      let today = Date.parse(new Date());
      req.selected_providers.forEach((prov) => {
        req.timePassed = Math.floor(
          Math.abs(Date.parse(req.rfpdate) - today) / (1000 * 3600)
        );
        if (prov.providerID === ID) {
          reqStatus = prov.requestStatus;
          if (prov.requestStatus === 'open') {
            reqInfo.open += 1;
          }
          if (prov.requestStatus === 'confirmed') {
            reqInfo.newCustomer += 1;
          }
          req.selected_providers = [prov];
        }
      });
      reqInfo.reqSorted[reqStatus] = [...reqInfo.reqSorted[reqStatus], req];
    });
    for (let i in reqInfo.reqSorted) {
      reqInfo.reqSorted[i] = reqInfo.reqSorted[i].reverse();
    }
    this.props.addReqInfo({
      data: reqInfo,
      stateObject: 'reqInfo',
    });
  };

  handleStepChange = (step) => {
    window.scrollTo(0, 0);
    this.setState({ activeStep: step });
  };

  getStepContent = (step) => {
    return this.setContent()[step];
  };

  submitToDB = async (field) => {
    let data = field ? field : this.props.store;
    const JWT = this.state.JWT;
    axios
      .patch('/api/provider', JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${JWT}`,
          'Content-Type': 'application/json',
        },
      })

      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.statusText);
        /*      this.props.fetchProviderData(this.state.JWT); */
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      })
      .catch((error) => console.log('submitToDB error', error));
  };

  render() {
    return (
      <div className='DashboardContainer'>
        <div className='DashboardNavigationContainer'>
          <Navigation
            providerData={this.props.store}
            handleStepChange={this.handleStepChange}
            activeStep={this.state.activeStep}
          />
        </div>
        <div className='DashboardContentContainer'>
          <h2 className='DashboardActiveLabel'>
            {/* {dashboardLabels()[this.state.activeStep]} <br /> */}
            <span>
              {new Date().toLocaleString('hu-HU', {
                weekday: 'long',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </span>
          </h2>
          {this.state.reqSorted
            ? this.getStepContent(this.state.activeStep)
            : null}
        </div>
        {/* <div className="DashboardProfileInfo">
          <ProfileInfo handleStepChange={this.handleStepChange} />
        </div> */}
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProviderDashboard)
);
