import React, { useEffect } from 'react';
import { Avatar } from '@mui/material';
import { dbTextPairs } from '../../../../../shared/data/sharedText';
import ShareIcon from '@mui/icons-material/Share';
import axios from '../../../../../shared/axios/axiosProxy';
import i18n from '../../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
const FRONT_END_URL =
  process.env.REACT_APP_FRONT_END_URL || 'https://dashboard-hu.bookkeepie.com';

const PublicProfile = () => {
  const dispatch = useDispatch();
  const [providerServices, providerMedia, providerInfo, userData, providerID] =
    useSelector((state) => [
      state.provider_services,
      state.provider_media,
      state.provider_info,
      state.userData,
      state.providerID,
    ]);
  useEffect(() => {}, [providerMedia.profileImage]);
  const [profileImg, setProfileImg] = React.useState(
    providerMedia.profileImage
  );
  const getServices = () => {
    let services = [];
    for (let i in providerServices) {
      if (providerServices[i]) {
        services.push(dbTextPairs()[i]);
      }
    }
    return services;
  };
  const uploadInput = React.useRef(null);
  const randomNr = Math.floor(Math.random() * 1000000000);
  const handleClick = () => {
    uploadInput.current.click();
  };
  const handleUpload = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    /* eslint-disable no-unused-vars */
    var url = reader.readAsDataURL(file);
    /* eslint-disable no-unused-vars */
    let updatedImg = `https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_${providerID}?${randomNr}`;
    reader.onloadend = function (e) {
      dispatch(
        ACTIONS.addProviderInfo({
          id: 'profileImage',
          data: reader.result,
          stateObject: 'provider_media',
        })
      );
      setProfileImg(reader.result);
      const JWT = localStorage.getItem('JWT');
      axios
        .patch(
          '/api/provider/picture',
          {
            provider_media: {
              profileImage: updatedImg,
            },
            image: reader.result,
          },
          {
            headers: {
              Authorization: `Bearer ${JWT}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((error) => {
          console.log('AXIOS ERROR: ', error.response.data);
        })
        .catch((error) => console.log('submitToDB error', error));
    };
  };

  return (
    <div className='PublicProfile'>
      <Avatar
        src={profileImg}
        style={{ width: '148px', height: '148px', cursor: 'pointer' }}
        onClick={handleClick}
      />
      <input
        accept='image/*'
        id='contained-button-file'
        multiple
        type='file'
        onChange={(event) => handleUpload(event)}
        ref={uploadInput}
        style={{ display: 'none' }}
      />
      <div className='PublicProfileContent' style={{ marginLeft: '20px' }}>
        <h2>{providerInfo.companyname}</h2>
        <p className='FlexCenter'>{providerInfo.email}</p>
        {getServices().map((item, i) => {
          return (
            <div className='ServiceLabel' key={i}>
              {item}
            </div>
          );
        })}
        <a
          href={`${FRONT_END_URL}/provider/preview/${userData.id}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {i18n.t('providerdashboard.showandshareprofile')}
          <ShareIcon sx={{ color: '#2d46b9' }} />
        </a>
      </div>
    </div>
  );
};

export default PublicProfile;
