import React, { useCallback } from 'react';
import { TaxconformInput } from '../data/regFlowText';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';
import { useSelector } from 'react-redux';

const TaxconForm = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>
            {i18n.t('searchflow.taxconformlabel')}
          </span>{' '}
          {/* <span className='questionTracker'>1/1</span> */}
        </p>
        <RadioInput
          storeID='taxconform'
          dataText={TaxconformInput()}
          stateObject='company_store'
        />
      </div>

      <Navigation
        addCompanyInfo={props.addCompanyInfo}
        step={2}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={companyStore.taxconform !== '' ? false : true}
      />
    </div>
  );
};

export default withTranslation()(TaxconForm);
