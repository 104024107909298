import React, { useEffect, useCallback } from 'react';
import { foreignTradeInput, foreignTradeEUInput } from '../data/searchFlowText';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from '../../../redux/action';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import RadioInput from '../components/searchInputs/radioInput';

const ForeignTrade = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const dispatch = useDispatch();

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );
  const handleBack = useCallback(
    (decr) => {
      if (
        companyStore.companytype === 'bt' ||
        companyStore.companytype === 'kft' ||
        companyStore.companytype === 'zrt'
      ) {
        props.handleBack(1);
      } else {
        props.handleBack(2);
      }
    },
    [props]
  );

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        handleNext();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, handleNext]);

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>
            {i18n.t('searchflow.sfspecialinfotext3')}
          </span>{' '}
          <span className='questionTracker'>1/6</span>
        </p>
        <RadioInput storeID='foreign_trade' dataText={foreignTradeInput()} />
        {companyStore.foreign_trade === true && (
          <>
            <hr />
            <p className='SFSubTitle'>
              {i18n.t('searchflow.sfspecialinfotext4')}
            </p>
            <RadioInput
              storeID='foreigntradeEU'
              dataText={foreignTradeEUInput()}
            />
          </>
        )}
      </div>
      <Navigation
        step={7}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
      />
    </div>
  );
};

export default withTranslation()(ForeignTrade);
