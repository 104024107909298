import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { withTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StyledButton from '../styledButton/styledButton';
import { gdprLabelSmall } from '../../data/sharedText';
import EmailInput from '../emailInput/emailInput.jsx';
import { TextField } from '@mui/material';
import i18n from '../../../utils/i18n';
import axios from '../../axios/axiosProxy';
import ACTIONS from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

const KnetPlusForm = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gdpr, setGdpr] = useState(false);
  const [error, setError] = useState(false);

  const submitContact = () => {
    if (email.length === 0 || phone.length === 0) {
      setError(true);
    } else {
      if (email.length > 0 && phone.length > 0 && gdpr) {
        const data = {
          email: email,
          phone: phone,
          searchID: companyStore.searchID,
        };
        props.handleKnetPlusContact();

        axios
          .post('/api/contact/knetplus', data)
          .then((response) => {
            console.log('AXIOS RESPONSE: ', response);
            props.handleKnetPlusContact();
          })
          .catch((error) => {
            console.log('AXIOS ERROR: ', error.response.data);
          })
          .catch((error) => console.log('submitToDB error', error));
      }
    }
  };
  return (
    <>
      <Paper elevation={1} className='SFKnetPlusContainer'>
        {props.kNetPlusSent ? (
          <>
            <p
              style={{ paddingTop: '0.5em' }}
              className='MediumBabyBlueBold TextCenter'
            >
              {i18n.t('general.recievedinfo')} <br />{' '}
              {i18n.t('general.willcontactyou')}
            </p>
            <StyledButton
              style={{ margin: '32px 0px 32px 0px' }}
              color='Primary'
              onClick={() => {
                if (companyStore.idk === true) {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: 'idk',
                      data: false,
                      stateObject: 'company_store',
                    })
                  );
                }
                if (props.helpForm === true) {
                  props.closeHelpForm();
                }
              }}
            >
              {i18n.t('general.ok')}
            </StyledButton>
          </>
        ) : (
          <>
            {' '}
            <p
              style={{ paddingTop: '0.5em' }}
              className='MediumBabyBlueBold TextCenter'
            >
              {i18n.t('general.ifyouneedhelpctcus')}
            </p>
            <form noValidate className='SFFormContainer'>
              <EmailInput
                name='email'
                placeholder={i18n.t('general.email')}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                required
                style={{
                  width: '100%',
                }}
              />
              <TextField
                style={{
                  width: '100%',
                  margin: '2rem 0',
                }}
                id='phone'
                type='text'
                name='phone'
                label={
                  <p style={{ marginTop: '8.5px' }}>
                    {i18n.t('general.phone')}
                  </p>
                }
                placeholder={i18n.t('general.phone')}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                variant='outlined'
              />
              {error && (
                <span
                  style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}
                >
                  {i18n.t('general.dataerroralert')}
                </span>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    id='gdpr'
                    name='gdpr'
                    checked={gdpr}
                    onChange={(event) => setGdpr(event.target.checked)}
                    color='primary'
                    required
                  />
                }
                label={gdprLabelSmall()}
              />
            </form>
            <StyledButton
              style={{ margin: '32px 0px 32px 0px' }}
              color='Secondary'
              onClick={() => {
                if (companyStore.idk === true) {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: 'idk',
                      data: false,
                      stateObject: 'company_store',
                    })
                  );
                }
                if (props.helpForm === true) {
                  props.closeHelpForm();
                }
              }}
            >
              {i18n.t('general.backbutton')}
            </StyledButton>
            {gdpr && (
              <StyledButton color='Primary' onClick={submitContact}>
                {i18n.t('general.send')}
              </StyledButton>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default withTranslation()(KnetPlusForm);
