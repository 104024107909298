import React, { Component } from 'react';
import MaterialTable, { MTableCell, MTableBody } from 'material-table';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material';
import { providerColumns } from './providerColumns';
import LoadingIndicator from '../../../../shared/LoadingIndicator/loadingIndicator';

import { connect } from 'react-redux';
import { tableIcons } from '../../data/searchFlowText';

import i18n from '../../../../utils/i18n';

const mapStateToProps = (state) => ({
  loading: state.loading,
});

class ProviderTable extends Component {
  constructor(props) {
    super(props);
    this.state = { dialogState: false, selectedRow: {} };
  }
  setSelectedRow(row) {
    this.setState({ dialogState: true, selectedRow: row });
  }
  handleClose() {
    this.setState({ dialogState: false });
  }
  updateRowData = (ID) => {
    this.props.data.forEach((row) => {
      if (this.state.selectedRow[ID] === row[ID]) {
        this.setState({ selectedRow: row });
      }
    });
  };
  render() {
    const defaultMaterialTheme = createTheme();
    return (
      <div>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              title=''
              icons={tableIcons}
              style={{ margin: '16px 0px' }}
              columns={
                this.props.columns
                  ? this.props.columns
                  : providerColumns(this.props.showPrices)
              }
              data={this.props.data ? this.props.data : []}
              options={{
                headerStyle: {
                  color: 'black',
                },
                sorting: true,
                toolbar: false,
                search: false,
                pageSize: 10,
              }}
              onRowClick={(event, selectedRow) => {
                this.setSelectedRow(selectedRow);
                this.props.setProfileData(selectedRow);
                this.props.openProfile(true);
              }}
              localization={{
                pagination: {
                  labelRowsPerPage: i18n.t('general.linesperpage'),
                  labelRowsSelect: i18n.t('general.line'),
                  firstAriaLabel: i18n.t('general.firstpage'),
                  firstTooltip: i18n.t('general.firstpage'),
                  previousAriaLabel: i18n.t('general.previouspage'),
                  previousTooltip: i18n.t('general.previouspage'),
                  nextAriaLabel: i18n.t('general.nextpage'),
                  nextTooltip: i18n.t('general.nextpage'),
                  lastAriaLabel: i18n.t('general.lastpage'),
                  lastTooltip: i18n.t('general.lastpage'),
                },
              }}
              components={{
                Cell: (props) => <MTableCell {...props} />,
                Body: (props) => (
                  <MTableBody
                    {...props}
                    {...(props.localization.emptyDataSourceMessage = (
                      <>
                        {!this.props.loading && this.props.data.length === 0 ? (
                          i18n.t('searchflow.noprovidersindb')
                        ) : (
                          <LoadingIndicator />
                        )}
                      </>
                    ))}
                  />
                ),
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
}
export default connect(mapStateToProps)(ProviderTable);
