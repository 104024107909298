import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//REDUX

import { useSelector } from 'react-redux';

const CheckboxInput = ({
  dataText,
  handleCheck,
  stateObject = 'company_store',
}) => {
  const companyStore = useSelector((state) => state[stateObject]);
  // console.log(companyStore, 'KOMPANI IN CHECK');
  return (
    <div>
      {dataText.map((item, i) => {
        return (
          <div key={i}>
            <FormGroup>
              <FormControlLabel
                className='SFRadioInput'
                labelPlacement='start'
                control={
                  <Checkbox
                    id={item.id}
                    checked={companyStore[item.id]}
                    onChange={handleCheck}
                  />
                }
                label={<span className='SFCheckboxLabel'>{item.label}</span>}
              />
            </FormGroup>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxInput;
