import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Radio from '@mui/material/Radio';
/* import placeHolder from "../../../images/icons/placeholder-new.png"; */
import {
  providerPackageList,
  providerPackageProperties,
} from '../../data/providerText';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const packageTypes = () => [
  { label: i18n.t('provider.mkoestart'), id: 'mkoestart' },
  { label: i18n.t('provider.entry'), id: 'basic' },
  { label: i18n.t('provider.marketing'), id: 'advanced' },
  { label: i18n.t('provider.guaranteed'), id: 'premium' },
];

const PackageContent = () => {
  const dispatch = useDispatch();
  const [providerPackage, providerSubscription] = useSelector((state) => [
    state.provider_package,
    state.provider_subscription,
  ]);

  return (
    <div className='PackageContent'>
      <div className='PackageProperties'>
        {providerPackageProperties().map((item, i) => {
          return (
            <div className='PackageRow PropertiesBorder' key={item + i}>
              <p>
                {item}
                {/*       <span>
                  <img
                    src={placeHolder}
                    className="Icon16 MarginVertical"
                    alt=""
                  />
                </span> */}
              </p>
            </div>
          );
        })}
        <div className='PackageButtonContainer'></div>
      </div>
      <div className='PackageList'>
        {providerPackageList.map((item, i) => {
          return (
            <div className='PackageRow ListBorder' key={i}>
              {providerSubscription.partnerCode &&
              providerSubscription.partnerCode.toLowerCase() === 'mkoestart' ? (
                <div>
                  {item['1'] ? (
                    <CheckCircleIcon sx={{ color: '#2D46B9' }} />
                  ) : (
                    <CancelIcon sx={{ color: '#828282' }} />
                  )}
                </div>
              ) : null}
              <div>
                {item['2'] ? (
                  <CheckCircleIcon sx={{ color: '#2D46B9' }} />
                ) : (
                  <CancelIcon sx={{ color: '#828282' }} />
                )}
              </div>
              <div>
                {item['3'] ? (
                  <CheckCircleIcon sx={{ color: '#2D46B9' }} />
                ) : (
                  <CancelIcon sx={{ color: '#828282' }} />
                )}
              </div>
              <div>
                {item['4'] ? (
                  <CheckCircleIcon sx={{ color: '#2D46B9' }} />
                ) : (
                  <CancelIcon sx={{ color: '#828282' }} />
                )}
              </div>
            </div>
          );
        })}
        <div className='PackageButtonContainer'>
          {packageTypes().map((item, i) => {
            if (
              providerSubscription.partnerCode &&
              providerSubscription.partnerCode.toLowerCase() === 'mkoestart' &&
              i === 0
            ) {
              return (
                <div
                  onClick={() =>
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: 'packageType',
                        data: item.id,
                        stateObject: 'provider_package',
                      })
                    )
                  }
                  key={i}
                >
                  <Radio
                    checked={
                      providerPackage.packageType === item.id ? true : false
                    }
                    id={item.id}
                    name='color-radio-button-demo'
                    sx={{
                      color: '#1A00BD',
                      '&.Mui-checked': {
                        color: '#1A00BD',
                      },
                    }}
                  />
                  <p>
                    {item.label}
                    <br />
                    <span>
                      {i18n.t('provider.packagepick1')}
                      <br /> {i18n.t('provider.packagepick2')}
                    </span>
                  </p>
                </div>
              );
            } else if (i !== 0) {
              return (
                <div
                  onClick={() =>
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: 'packageType',
                        data: item.id,
                        stateObject: 'provider_package',
                      })
                    )
                  }
                >
                  <Radio
                    disabled={
                      providerSubscription.partnerCode &&
                      providerSubscription.partnerCode.toLowerCase() ===
                        'mkoestart'
                        ? true
                        : false
                    }
                    checked={
                      providerPackage.packageType === item.id ? true : false
                    }
                    id={item.id}
                    name='color-radio-button-demo'
                    sx={{
                      color: '#1A00BD',
                      '&.Mui-checked': {
                        color: '#1A00BD',
                      },
                    }}
                  />
                  <p>
                    {item.label}
                    <br />
                    <span>
                      {i18n.t('provider.packagepick1')} <br />{' '}
                      {i18n.t('provider.packagepick2')}
                    </span>
                  </p>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PackageContent);
