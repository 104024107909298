import * as React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

import i18n from '../../../../utils/i18n';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.9',
      fontFamily: 'Satoshi, sans-serif !important',
    },
    '& .MuiOutlinedInput-input': {
      color: '#5A5A5A !important',
      fontWeight: '700',
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
    '& .MuiFilledInput-root': {
      background: 'transparent !important',
    },
  },
});

const TextInput = ({ storeID, dataText, error = false, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [numberErrorMessage, setNumberErrorMessage] = React.useState('');

  const handleError = (id) => {
    setErrorMessage('');
    switch (id) {
      case 'noofemployees':
        if (companyStore.noofemployees > 250) {
          setErrorMessage(i18n.t('company.employeenrerror'));
        }
        break;
      case 'incominginvoices':
      case 'outgoinginvoices':
        if (
          companyStore.incominginvoices > 20000 ||
          companyStore.outgoinginvoices > 20000
        ) {
          setErrorMessage(i18n.t('company.valueinputerror'));
        }

        break;
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    setNumberErrorMessage('');
    let value = event.target.value;
    if (event.target.type === 'number') {
      value = parseInt(value);
    }
    if (
      event.target.id === 'noofemployees' ||
      event.target.id === 'incominginvoices' ||
      event.target.id === 'outgoinginvoices'
    ) {
      if (Number.isNaN(value)) {
        setNumberErrorMessage(i18n.t('searchflow.numbererrormsg'));
        // value = '';
      }
    }
    dispatch(
      ACTIONS.addCompanyInfo({
        id: event.target.id,
        data: value,
        stateObject: 'company_store',
      })
    );
  };
  return (
    <div>
      {dataText.map((item, i) => {
        return (
          <TextField
            className={classes.root}
            id={item.id}
            label={item.label}
            variant='outlined'
            type={props.type}
            value={companyStore[item.id].toString()}
            onChange={(event) => {
              if (
                props.characterLimit &&
                event.target.value.length <= props.characterLimit
              ) {
                handleChange(event);
              } else if (!props.characterLimit) {
                handleChange(event);
              }
            }}
            error={error ? true : false}
            onBlur={() => {
              handleError(item.id);
            }}
            key={i}
          />
        );
      })}
      {errorMessage.length > 0 ? (
        <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
          {errorMessage}
        </span>
      ) : null}
      {numberErrorMessage && (
        <span style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
          {numberErrorMessage}
        </span>
      )}
    </div>
  );
};

export default TextInput;
