import i18n from '../../../utils/i18n';
import Navigation from '../components/navigation/navigation';
import { TextField } from '@mui/material';
import { invoicingInput1 } from '../data/searchFlowText';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from '../../../redux/action';
import { withTranslation } from 'react-i18next';

import CheckboxInput from '../components/searchInputs/newCheckboxInput';

const Accounts = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const [inputError, setInputError] = useState(false);

  const handleNext = useCallback(
    (inc) => {
      if (
        companyStore.companytype === 'bt' ||
        companyStore.companytype === 'kft' ||
        companyStore.companytype === 'zrt'
      ) {
        props.handleNext(1);
      } else {
        props.handleNext(2);
      }
    },
    [props, companyStore.companytype]
  );
  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  const dispatch = useDispatch();

  const handleChange = (event) => {
    let temp = {
      opt1: companyStore.invoicingpref.opt1,
      opt2: companyStore.invoicingpref.opt2,
      opt3: companyStore.invoicingpref.opt3,
      opt4: companyStore.invoicingpref.opt4,
      opt5: companyStore.invoicingpref.opt5,
      opt6: companyStore.invoicingpref.opt6,
      other: companyStore.invoicingpref.other,
      invoicingidk: companyStore.invoicingpref.invoicingidk,
    };
    invoicingInput1().forEach((item) => {
      if (event.target.id === item.id) {
        temp[item.id] = !temp[item.id];
      }
    });

    //console.log(temp, 'TEMP');
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'invoicingpref',
        data: temp,
        stateObject: 'company_store',
      })
    );
  };

  const handleNested = (event, storeId) => {
    dispatch(
      ACTIONS.updateNestedObject({
        stateObject: 'company_store',
        stateObjectID: storeId,
        id: event.target.id,
        data: event.target.value,
      })
    );
  };

  const inputCheck = () => {
    if (
      companyStore.invoicingpref.other &&
      companyStore.invoicingpref.invoicingOtherInput === ''
    ) {
      setInputError(true);
      return false;
    } else {
      setInputError(false);
      return true;
    }
  };

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        if (
          companyStore.companytype === 'bt' ||
          companyStore.companytype === 'kft' ||
          companyStore.companytype === 'zrt'
        ) {
          handleNext();
        } else {
          handleNext(2);
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, handleNext, companyStore.companytype]);
  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          <span className='SFSubTitle'>
            {i18n.t('searchflow.bankingtext2')}
          </span>{' '}
          <span className='questionTracker'>5/6</span>
        </p>
        <CheckboxInput
          dataText={invoicingInput1()}
          storeId={'invoicingpref'}
          handleCheck={handleChange}
        />
      </div>
      {companyStore.invoicingpref.other && (
        <div
          className='SFMarginTop40 SFInputWidth100'
          style={{ margin: '16px auto' }}
        >
          <TextField
            value={companyStore.invoicingpref.invoicingOtherInput}
            variant='outlined'
            type={'text'}
            label={i18n.t('industrylabels.other')}
            id='invoicingOtherInput'
            name='invoicingOtherInput'
            onChange={(event) => {
              handleNested(event, 'invoicingpref');
            }}
          />
        </div>
      )}
      {inputError ? (
        <p
          style={{
            color: 'red',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {i18n.t('company.inputerrormsg')}
        </p>
      ) : null}

      <Navigation
        step={8}
        handleBack={() => handleBack()}
        handleNext={() => {
          inputCheck() && handleNext();
        }}
        openHelpForm={() => props.openHelpForm()}
      />
    </div>
  );
};

export default withTranslation()(Accounts);
