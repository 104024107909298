import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckboxInput = ({ dataText, handleCheck, textTranslation }) => {
	//console.log(dataText, 'datatext');
	return textTranslation ? (
		<div>
			{Object.keys(dataText).map((item, i) => {
				//console.log(item, 'item');

				return (
					<div key={i}>
						<FormGroup>
							<FormControlLabel
								className="SFRadioInput"
								labelPlacement="start"
								control={
									<Checkbox
										id={item}
										name={item}
										checked={dataText[item]}
										onChange={handleCheck}
									/>
								}
								label={
									<span className="SFCheckboxLabel">
										{textTranslation[item]}
									</span>
								}
							/>
						</FormGroup>
					</div>
				);
			})}
		</div>
	) : (
		<div>
			{Object.keys(dataText).map((item, i) => {
				//console.log(item, 'item');

				return (
					<div key={i}>
						<FormGroup>
							<FormControlLabel
								className="SFRadioInput"
								labelPlacement="start"
								control={
									<Checkbox
										id={item}
										name={item}
										checked={dataText[item]}
										onChange={handleCheck}
									/>
								}
								label={<span className="SFCheckboxLabel">{item}</span>}
							/>
						</FormGroup>
					</div>
				);
			})}
		</div>
	);
};

export default CheckboxInput;
