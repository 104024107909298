import React from "react";
/* import Rating from "@mui/lab/Rating"; */
import ReviewAverage from "./reviewAverage";

const ReviewPaper = ({ review }) => {
  const [reviewScores, setReviewScores] = React.useState();

  const reviewScoreCalculator = (review) => {
    return {
      flexibility: review.flexibility,
      professionalism: review.professionalism,
      industryexperience: review.industryexperience,
      precision: review.precision,
      pricevalue: review.pricevalue,
    };
  };

  React.useEffect(() => {
    setReviewScores(reviewScoreCalculator(review));
  }, [review]);
  /* 
  const getReviewAvg = () => {
    let avg = 0;
    avg += review.professionalism;
    avg += review.flexibility;
    avg += review.pricevalue;
    avg += review.industryexperience;
    avg += review.precision;
    avg = avg / 5;
    return avg;
  }; */
  return (
    <div className="ProviderReviewPaper">
      <div className="ProviderReviewText">
        <div className="ProviderReviewHeadingContainer">
          <p style={{ marginRight: "32px", fontWeight: "600" }}>
            {review.reviewer}{" "}
            {/*             {window.innerWidth > 600 ? null : (
              <span
                style={{
                  float: "right",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                {review.date}
              </span>
            )} */}
          </p>
          {/*           <Rating
            name="half-rating-read"
            defaultValue={getReviewAvg()}
            precision={0.1}
            readOnly
            size="small"
          />
          {window.innerWidth < 600 ? null : (
            <p style={{ marginLeft: "auto", fontSize: "16px" }}>
              {review.date}
            </p>
          )} */}
        </div>
        <p>{review.review}</p>
      </div>
      <div className="ProviderReviewScores">
        {reviewScores ? (
          <ReviewAverage individual reviewScores={reviewScores} />
        ) : null}
        {/*    <ReviewAverage reviewScores={reviewScores} /> */}
      </div>
    </div>
  );
};

export default ReviewPaper;
