import React from 'react';
import Dialog from '@mui/material/Dialog';
import StyledButton from '../../../../shared/components/styledButton/styledButton';
import './subscription.scss';
import SubscriptionPackage from './components/subscriptionPackage';
import PaymentMethod from './components/paymentMethod';
import axios from '../../../../shared/axios/axiosProxy';
import i18n from '../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const Subscription = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [providerSubscription, providerInfo, providerID] = useSelector(
    (state) => [
      state.provider_subscription,
      state.provider_info,
      state.userData.id,
    ]
  );

  const suspendSubscription = async () => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'activeSubscription',
        data: false,
        stateObject: 'provider_subscription',
      })
    );
    await submitToDB(
      {
        'provider_subscription.activeSubscription': false,
        'provider_subscription.statusChanged': new Date(),
      },
      false
    );
    await updateSubscription({ 'subscription.activeSubscription': false });
    setOpen(false);
  };

  const continueSubscription = async () => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'activeSubscription',
        data: true,
        stateObject: 'provider_subscription',
      })
    );
    await submitToDB(
      {
        'provider_subscription.activeSubscription': true,
        'provider_subscription.statusChanged': new Date(),
      },
      true
    );
    await updateSubscription({ 'subscription.activeSubscription': true });
  };
  const submitToDB = async (field, value) => {
    let data = field;
    const JWT = localStorage.getItem('JWT');
    axios
      .patch('/api/provider', JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${JWT}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.statusText);
        sendEmailNotification(value);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      })
      .catch((error) => console.log('submitToDB error', error));
  };

  const sendEmailNotification = (suspended) => {
    const data = {
      user: providerInfo.companyname,
      suspended: suspended,
    };
    axios
      .post('/api/contact/suspend', data)
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.statusText);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      })
      .catch((error) => console.log('submitToDB error', error));
  };

  const updateSubscription = async (data) => {
    axios
      .patch(`/api/subscription/${providerID}`, JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      })
      .catch((error) => console.log('submitToDB error', error));
  };

  return (
    <div className='SubscriptionContainer'>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
      >
        <div className='CancelSubscriptionContainer'>
          <h2>{i18n.t('providerdashboard.subtext1')}</h2>
          <p>{i18n.t('providerdashboard.subtext2')}</p>
          <StyledButton
            onClick={() => {
              setOpen(true);
              suspendSubscription();
            }}
            color='TransparentOrange'
          >
            {i18n.t('providerdashboard.yescancelsub')}
          </StyledButton>
          <StyledButton
            onClick={() => {
              setOpen(false);
            }}
            color='Primary'
          >
            {i18n.t('providerdashboard.undo')}
          </StyledButton>
        </div>
      </Dialog>
      <div className='SubscriptionContent'>
        <SubscriptionPackage />
      </div>
      <div className='SubscriptionContent'>
        <PaymentMethod submitToDB={props.submitToDB} />
      </div>
      {providerSubscription.activeSubscription ? (
        <StyledButton
          onClick={() => {
            setOpen(true);
          }}
          color='TransparentOrange'
        >
          {i18n.t('providerdashboard.cancelsub')}
        </StyledButton>
      ) : (
        <StyledButton
          onClick={() => {
            continueSubscription();
          }}
          color='TransparentGreen'
        >
          {i18n.t('providerdashboard.continuesub')}
        </StyledButton>
      )}
    </div>
  );
};

export default Subscription;
