import React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {
    NewCouponCheck: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '106px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: '#4D5BEC',
        color: '#ffffff !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    NewCouponCheckDis: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '106px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: 'lightgrey',
        color: '#50558180 !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },

    NewReqBtn: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '164px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: '#FDB400',
        color: '#0B0F31 !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    NewNavForward: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '100px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: '#FDB400',
        color: '#ffffff !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    NewNavForwardWide: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '170px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: '#FDB400',
        color: '#ffffff !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    NewNavForwardWide100: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '100vw',
        height: '44px',
        padding: '12px 16px 12px 16px',
        borderRadius: '6px',
        gap: '8px',

        backgroundColor: '#FDB400',
        color: '#ffffff !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    NewNavBack: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '100px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        backgroundColor: 'white',
        gap: '8px',
        border: 'none',
        boxShadow: 'none',
        color: '#505581 !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#d2d3e0',
        color: '#505581',
      },
    },
    NewNavBackWide: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        width: '170px',
        height: '44px',
        padding: '12px 16px 12px 16px',
        backgroundColor: 'white',
        gap: '8px',
        border: 'none',
        boxShadow: 'none',
        color: '#505581 !important',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px !important',

        letterSpacing: '0.02em',
        //maxWidth: '250px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#d2d3e0',
        color: '#505581',
      },
    },
    Primary: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: '#FDB400',
        borderRadius: '33px',
        color: '#111E92 !important',
        textTransform: 'none',
        fontSize: '18px',
        lineHeight: '30px !important',
        padding: '12px 32px',
        gap: '10px',
        letterSpacing: '0.02em',
        //maxWidth: '250px',
        width: 'fit-content',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    Individual: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: '#FFFFFF',
        border: '1px solid #111E92',
        borderRadius: '33px',
        color: '#111E92 !important',
        textTransform: 'none',
        fontSize: '18px',
        lineHeight: '30px !important',
        padding: '12px 32px',
        gap: '10px',
        letterSpacing: '0.02em',
        //maxWidth: '250px',
        width: 'fit-content',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    FinalBtn: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: '#FDB400',
        borderRadius: '33px',
        color: '#111E92',
        textTransform: 'none',
        fontSize: '18px',
        lineHeight: '30px !important',
        padding: '12px 32px',
        gap: '10px',
        letterSpacing: '0.02em',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    White: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        color: '#111E92',
        backgroundColor: 'white',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: 'rgba(26, 0, 189, 0.6)',
        color: 'white',
      },
    },
    Success: {
      '&.MuiButton-contained': {
        textTransform: 'none',
      },
    },
    Transparent: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: '#111E92',
        textTransform: 'none',
        textDecoration: 'none',
        fontSize: '16px',
        lineHeight: '24px !important',
        padding: '10px 2px',
        border: 'none',
      },
      '&.MuiButton-contained:hover': {
        fontFamily: 'Satoshi, sans-serif !important',
        color: '#111E92',
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
      },
    },
    TransparentBordered: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        boxShadow: 'none',
        backgroundColor: 'white',
        color: '#111E92',
        textTransform: 'none',
        borderRadius: '33px',
        fontSize: '16px',
        lineHeight: '24px !important',
        padding: '12px 32px',
        border: '2px solid #1c32f3 !important',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDB500',
      },
    },
    Secondary: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: 'transparent',
        borderRadius: '33px',
        color: '#111E92 !important',
        textTransform: 'none',
        fontSize: '18px',
        lineHeight: '30px !important',
        padding: '12px 32px',
        gap: '10px',
        letterSpacing: '0.02em',
        border: '2px solid #FDB400 !important',
        //margin: '0 2em 0 0  ',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: '#111E92',
      },
    },
    TransparentOrange: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        color: '#EC5649',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'none',
      },
    },
    TransparentGreen: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        color: '#009b60',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'none',
      },
    },
    TransparentWhite: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        color: '#009b60 !important',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        textTransform: 'none',
        padding: '5px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: 'transparent',
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
      },
    },
    AcceptReqBtn: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: '#FDB400',
        boxShadow: '0px 4px 8px rgba(253, 180, 0, 0.25)',
        color: '#111E92',
        textTransform: 'none',
        fontSize: '16px',
        lineHeight: '24px !important',
        padding: '8px 16px',
        border: '1px solid #FDB400',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FDD500',
        color: 'white',
      },
    },
    RefuseReqBtn: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: 'transparent',
        boxShadow: '0px 4px 8px rgba(253, 180, 0, 0.25)',
        color: '#111E92',
        textTransform: 'none',
        fontSize: '16px',
        lineHeight: '24px !important',
        padding: '8px 16px',
        border: '1px solid #111E92',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#111e92',
        color: 'white',
      },
    },
    BlueWhiteBtn: {
      '&.MuiButton-contained': {
        fontFamily: 'Satoshi, sans-serif !important',
        backgroundColor: '#FFFFFF',
        borderRadius: '33px',
        color: '#1C32F3',
        border: '1px solid #1C32F3',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '18px !important',
        padding: '12px',
        gap: '10px',
        letterSpacing: '0.02em',
        maxWidth: '250px',
        width: 'fit-content',
        marginTop: '12px',
      },
      '&.MuiButton-contained:hover': {
        backgroundColor: '#FFFFFF',
        color: '#1C32F3',
      },
    },
  };
});

const StyledButton = ({
  isDisabled,
  onClick,
  color,
  className,
  style,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      style={style}
      className={`${classes[color]} ${className} ${
        isDisabled ? 'disabledReqBtn' : null
      }`}
      variant='contained'
      onClick={onClick}
      disabled={isDisabled}
      value={props.value}
      name={props.name}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
