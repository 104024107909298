import React, { useCallback } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { NumberFormatCustom } from '../../../../../shared/components/formattedInput/formattedInputs';
import i18n from '../../../../../utils/i18n';
import { Alert, Snackbar } from '@mui/material';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function RestrictionInputs({ storeObj, dataText, ...props }) {
  const dispatch = useDispatch();
  const [inputError, setInputError] = React.useState({});
  const [emptyFields, setEmptyFields] = React.useState({});
  const [modified, setModified] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [
    providerRestrictions,
    accountingPrice,
    foundingPrice,
    providerServices,
  ] = useSelector((state) => [
    state[storeObj],
    state.provider_acc_monthly_price,
    state.provider_founding_price,
    state.provider_services,
  ]);
  const [accPricesCopy] = React.useState(accountingPrice);
  const [foundingPricesCopy] = React.useState(foundingPrice);

  const [open, setOpen] = React.useState(false);

  const setEmptyFieldsObject = useCallback(() => {
    dataText.forEach((item) => {
      if (
        providerServices.accounting &&
        providerRestrictions[item.id] &&
        !Number.isInteger(accountingPrice[`${item.id}price`]) &&
        !emptyFields[`${item.id}price`]
      ) {
        setEmptyFields({ ...emptyFields, [`${item.id}price`]: true });
      } else if (
        (providerServices.accounting &&
          providerRestrictions[item.id] &&
          Number.isInteger(accountingPrice[`${item.id}price`]) &&
          emptyFields[`${item.id}price`]) ||
        (providerServices.accounting &&
          !providerRestrictions[item.id] &&
          emptyFields[`${item.id}price`])
      ) {
        setEmptyFields({ ...emptyFields, [`${item.id}price`]: false });
      }
      if (
        providerServices.legalentityfounding &&
        providerRestrictions[item.id] &&
        !Number.isInteger(foundingPrice[`${item.id}price`]) &&
        !emptyFields[`founding${item.id}price`] &&
        item.id !== 'citycouncil'
      ) {
        setEmptyFields({
          ...emptyFields,
          [`founding${item.id}price`]: true,
        });
      } else if (
        (providerServices.legalentityfounding &&
          providerRestrictions[item.id] &&
          Number.isInteger(foundingPrice[`${item.id}price`]) &&
          emptyFields[`founding${item.id}price`] &&
          item.id !== 'citycouncil') ||
        (providerServices.legalentityfounding &&
          !providerRestrictions[item.id] &&
          emptyFields[`founding${item.id}price`])
      ) {
        setEmptyFields({
          ...emptyFields,
          [`founding${item.id}price`]: false,
        });
      }
    });
  }, [
    dataText,
    accountingPrice,
    foundingPrice,
    emptyFields,
    providerRestrictions,
    providerServices.accounting,
    providerServices.legalentityfounding,
  ]);

  React.useEffect(() => {
    if (storeObj === 'provider_business_filter') {
      setEmptyFieldsObject();
    }
  }, [setEmptyFieldsObject, storeObj]);

  const handleChange = (event, storeObj) => {
    setModified(true);
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: storeObj,
      })
    );
  };
  const checkAll = (event) => {
    setModified(true);
    dataText.forEach((item) => {
      dispatch(
        ACTIONS.addProviderInfo({
          id: item.id,
          data: event.target.checked,
          stateObject: storeObj,
        })
      );
    });
  };

  const checkEmptyFieldsObject = () => {
    for (let i in emptyFields) {
      if (emptyFields[i]) {
        setError(true);
        return true;
      }
    }
    setError(false);
    return false;
  };

  const handleChangeTextField = (event, storeObject) => {
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObject,
      })
    );
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <div className='ProviderInputContainerCentered'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <div className='RestrictionInputAll'>
        <p style={{ color: '#2D46B9' }}>{i18n.t('provider.tickall')}</p>
        <GreenSwitch
          {...label}
          id='all'
          defaultChecked={true}
          /*   checked={providerRestrictions[item.id]} */
          onChange={(event) => {
            checkAll(event);
          }}
        />
        <span style={{ color: '#28b446' }}>{i18n.t('provider.iwantit')}</span>
      </div>
      <div
        className='ProviderInputContainerDashboard'
        style={{ padding: '12px 24px' }}
      >
        {dataText.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <div className='CenteredInput FullWidth'>
                <p>{item.label}</p>
                <GreenSwitch
                  {...label}
                  id={item.id}
                  checked={providerRestrictions[item.id]}
                  onChange={(event) => {
                    handleChange(event, storeObj);
                  }}
                />
                {providerRestrictions[item.id] ? (
                  <span style={{ color: '#28b446' }}>
                    {i18n.t('provider.iwantit')}
                  </span>
                ) : (
                  <span style={{ color: 'grey' }}>
                    {i18n.t('provider.idontwantit')}
                  </span>
                )}
              </div>
              {storeObj === 'provider_business_filter' ? (
                <>
                  {providerServices.accounting &&
                  providerRestrictions[item.id] &&
                  !Number.isInteger(accPricesCopy[`${item.id}price`]) ? (
                    <div
                      className='AdornmentInputContainer Price'
                      style={{ marginRight: '12px' }}
                    >
                      <TextField
                        id={`${item.id}price`}
                        style={{ flex: 'none' }}
                        variant='outlined'
                        type='text'
                        /*   placeholder={item.label} */
                        value={accountingPrice[`${item.id}price`]}
                        label={`Könyvelés: ${item.label}`}
                        onChange={(event) => {
                          handleChangeTextField(
                            event,
                            'provider_acc_monthly_price'
                          );
                          if (
                            event.target.value &&
                            inputError[`${item.id}price`]
                          ) {
                            setInputError({
                              ...inputError,
                              [`${item.id}price`]: false,
                            });
                          }
                        }}
                        error={inputError[`${item.id}price`]}
                        onBlur={(event) => {
                          if (!event.target.value) {
                            setInputError({
                              ...inputError,
                              [`${item.id}price`]: true,
                            });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <span
                                style={{
                                  color: inputError[`${item.id}price`]
                                    ? 'red'
                                    : '#2D46B9',
                                }}
                              >
                                Ft / hó
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ) : null}
                  {providerServices.legalentityfounding &&
                  providerRestrictions[item.id] &&
                  item.id !== 'citycouncil' &&
                  !Number.isInteger(foundingPricesCopy[`${item.id}price`]) ? (
                    <div className='AdornmentInputContainer Price'>
                      <TextField
                        id={`${item.id}price`}
                        variant='outlined'
                        type='text'
                        /*   placeholder={item.label} */
                        value={foundingPrice[`${item.id}price`]}
                        label={`${i18n.t('general.legalentityfounding')}: ${
                          item.label
                        }`}
                        onChange={(event) => {
                          handleChangeTextField(
                            event,
                            'provider_founding_price'
                          );
                          if (
                            event.target.value &&
                            inputError[`founding${item.id}price`]
                          ) {
                            setInputError({
                              ...inputError,
                              [item.id]: false,
                            });
                          }
                        }}
                        error={inputError[`founding${item.id}price`]}
                        onBlur={(event) => {
                          if (!event.target.value) {
                            setInputError({
                              ...inputError,
                              [`founding${item.id}price`]: true,
                            });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <span
                                style={{
                                  color: inputError[item.id]
                                    ? 'red'
                                    : '#2D46B9',
                                }}
                              >
                                {i18n.t('general.currency')}
                              </span>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
      {error ? (
        <p className='ErrorText'>
          {i18n.t('providerdashboard.fillbeforesave')}
        </p>
      ) : null}
      {modified ? (
        <StyledButton
          onClick={() => {
            if (modified && !checkEmptyFieldsObject()) {
              props.submitToDB({
                provider_acc_monthly_price: accountingPrice,
                provider_founding_price: foundingPrice,
                [`${storeObj}`]: providerRestrictions,
              });
              setModified(false);
              setOpen(true);
            }
          }}
          color={'Primary'}
        >
          {i18n.t('general.save')}
        </StyledButton>
      ) : null}
    </div>
  );
}
