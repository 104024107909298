import React from 'react';
import ProfileImageInput from './input/profileImageInput';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import SocialInputs from './input/socialInputs';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';

const ProviderSocial = ({ handleNext, handleBack, activeStep }) => {
  return (
    <div className='ProviderInputContainerCentered PaddingBot100'>
      <Paper className='providerPaper'>
        <div>
          <p className='StandardTextBold'>
            {i18n.t('provider.uploadapicture')}
          </p>
          <div className='ProfileImageInput'>
            <ProfileImageInput />
          </div>
        </div>
      </Paper>
      <Paper className='providerPaper'>
        <p className='StandardTextBold'>{i18n.t('provider.socialmediashow')}</p>
        <div className='ProfileImageInput'>
          <SocialInputs />
        </div>
      </Paper>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => handleNext()}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderSocial);
