import React, { useCallback, useState } from 'react';
import { outgoingInvoicesInput } from '../data/searchFlowText';
import { useSelector } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import RadioInput from '../components/searchInputs/radioInput';

const InvoicesOut = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);
  const [invoiceError, setInvoiceError] = useState(false);

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  return (
    <div className='stepContainer'>
      <div>
        <div className='SFInput'>
          <p className='SFSubTitle'>
            <span className='SFSubTitle'>
              {i18n.t('company.outgoinglabel1')}{' '}
            </span>{' '}
            <span className='questionTracker'>5/6</span>
          </p>
          <p className='SFSubTitleHelper'>
            {i18n.t('company.outgoinginvoicestooltip')}
          </p>
          <RadioInput
            storeID='outgoinginvoices'
            dataText={outgoingInvoicesInput()}
          />
          {invoiceError ? (
            <p
              style={{
                color: 'red',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {i18n.t('company.invoiceerrormsg')}
            </p>
          ) : null}
        </div>
      </div>
      <Navigation
        step={10}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
        disabledProp={companyStore.outgoinginvoices !== '' ? false : true}
      />
    </div>
  );
};

export default withTranslation()(InvoicesOut);
