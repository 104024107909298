import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #1c32f3',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#1c32f3',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#1c31f38b',
    },
  },
});

const RadioInput = ({
  storeID,
  dataText,
  stateObject = 'company_store',
  ...props
}) => {
  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state[stateObject]);
  const [selectedValue, setSelectedValue] = React.useState(
    companyStore[storeID].toString()
  );

  //console.log(companyStore, 'KOMPANI');

  const classes = useStyles();

  React.useEffect(() => {
    if (props.disabled && companyStore[storeID] !== '') {
      setSelectedValue('');
      dispatch(
        ACTIONS.addCompanyInfo({
          id: storeID,
          data: '',
          stateObject: stateObject,
        })
      );
    }
  }, [companyStore, dispatch, props.disabled, storeID, stateObject]);

  //TODO lecserelni az elseif rengeteget egy objectre!

  const handleChange = (event, value) => {
    //console.log(event.target.id, event.target.value, 'ETIDV');
    setSelectedValue(event.target.value);
    if (event.target.id === 'onlyBrowsing') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'chosendate',
          data: 'idk',
          stateObject: stateObject,
        })
      );
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '0',
          stateObject: 'company_store',
        })
      );
    }
    if (event.target.id === 'deadline') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'startingdate',
          data: new Date().setDate(new Date().getDate()),
          stateObject: 'company_store',
        })
      );

      if (event.target.value === '0') {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'chosendate',
            data: 'asap',
            stateObject: 'company_store',
          })
        );
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'deadline',
            data: '0',
            stateObject: 'company_store',
          })
        );
      } else if (event.target.value === '14') {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'chosendate',
            data: '2weeks',
            stateObject: 'company_store',
          })
        );
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'deadline',
            data: '14',
            stateObject: 'company_store',
          })
        );
      } else if (event.target.value === '60') {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'chosendate',
            data: '2months',
            stateObject: 'company_store',
          })
        );
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'deadline',
            data: '60',
            stateObject: 'company_store',
          })
        );
      } else if (event.target.value === '180') {
        dispatch(
          ACTIONS.addCompanyInfo({
            id: 'chosendate',
            data: '6months',
            stateObject: 'company_store',
          })
        );
      }
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '180',
          stateObject: 'company_store',
        })
      );
    } else {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: event.target.id,
          data: value,
          stateObject: stateObject,
        })
      );
    }
  };
  const controlProps = (item) => ({
    checked: selectedValue.toString() === item.value.toString(),
    onChange: (event) => {
      handleChange(event, item.data);
    },
    value: item.value.toString(),
    name: 'color-radio-button-demo',
    id: item.id || storeID,
    inputProps: { 'aria-label': item },
  });

  return (
    <FormControl component='fieldset'>
      <RadioGroup row aria-label='position' name='position' defaultValue='top'>
        {dataText.map((item, i) => {
          return (
            <>
              <FormControlLabel
                className='SFRadioInput'
                key={i}
                labelPlacement='start'
                control={
                  <Radio
                    {...controlProps(item)}
                    key={i}
                    disabled={props.disabled}
                    className={classes.root}
                    disableRipple
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                  />
                }
                label={
                  <span
                    style={{
                      width: '100%',
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      textAlign: 'left',

                      color: '#424559',
                      fontFamily: 'Satoshi, sans-serif',
                      marginLeft: '0',
                      marginRight: 'Auto',
                    }}
                  >
                    {item.label}
                  </span>
                }
              />
            </>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInput;
