import React from 'react';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import '../providerPreview.scss';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
const ProfileHeader = ({ reviewScores, providerData }) => {
  const [phone, showPhone] = React.useState(true);
  const [email, showEmail] = React.useState(true);

  const companyType = (size) => {
    let types = {
      entrepreneur: i18n.t('provider.entrepreneur'),
      small_office: i18n.t('provider.small_office'),
      office: i18n.t('provider.office'),
      corporate: i18n.t('provider.corporate'),
    };
    return types[size];
  };
  console.log(providerData, 'providerDataproviderDataproviderData');
  return (
    <div className='ProvProfileHeaderContainer'>
      <div className='ProvProfileAvatarContainer'>
        <div style={{ position: 'relative' }}>
          <Avatar
            src={providerData.provider_media.profileImage}
            style={{ width: '100px', height: '100px' }}
          />
        </div>
        <div className='ProvProfileRating'>
          <Rating
            name='half-rating-read'
            defaultValue={reviewScores.reviewScoreAvg}
            precision={0.1}
            readOnly
            size='small'
          />
        </div>
        <div>
          <h2>{providerData.provider_info.companyname}</h2>
          <p style={{ textAlign: 'center' }}>{`(${companyType(
            providerData.provider_package.packageSize
          )})`}</p>
          <p className='FlexFullCenter'>
            <span style={{ marginBottom: '-4px' }}>
              <LocationOnIcon />
            </span>
            {`${providerData.provider_info.zip} ${providerData.provider_info.city},`}
            {providerData.provider_info.address}
          </p>
        </div>
      </div>
      <div className='ProvProfileContactContainer'>
        {phone ? (
          <span className='MarginVertical ContactInfo'>
            {providerData.provider_info.phone}
          </span>
        ) : window.innerWidth < 600 ? (
          <a
            href={`tel:${providerData.provider_info.phone}`}
            onClick={() => {
              showPhone(true);
            }}
          >
            <Button className='ProvProfileContact'>
              {i18n.t('general.phone')}
            </Button>
          </a>
        ) : (
          <Button
            onClick={() => {
              showPhone(true);
            }}
            className='ProvProfileContact'
          >
            {i18n.t('general.phone')}
          </Button>
        )}
        {email ? (
          <span className='MarginVertical ProvContactInfo'>
            {providerData.provider_info.email}
          </span>
        ) : (
          <Button
            onClick={() => {
              showEmail(true);
            }}
            className='ProvProfileContact'
          >
            {i18n.t('general.emaillabel')}
          </Button>
        )}
        {providerData.provider_info.companywebpage ? (
          <a
            className='ProvContactInfo MarginRight16'
            href={
              providerData.provider_info.companywebpage.includes('https://')
                ? providerData.provider_info.companywebpage
                : `https://${providerData.provider_info.companywebpage}`
            }
            target='_blank'
            rel='noopener noreferrer'
          >
            {providerData.provider_info.companywebpage.includes('https://')
              ? providerData.provider_info.companywebpage
              : `https://${providerData.provider_info.companywebpage}`}
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default withTranslation()(ProfileHeader);
