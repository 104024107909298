import React from 'react';
import AppRoutes from './routes.js';
import './App.css';
import './shared/styles/variables.scss';
import './shared/styles/materialTable.scss';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import LanguageSelector from './utils/LanguageSelector.js';
import { withTranslation } from 'react-i18next';
//import makeStyles from '@mui/styles/makeStyles';

import * as Sentry from '@sentry/react';

const theme = createTheme();

// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that accesses the theme
//   }
// });

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className='App'>
          {/* <div className='langselector'>
            <LanguageSelector />
          </div> */}
          <AppRoutes />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Sentry.withProfiler(withTranslation()(App));
