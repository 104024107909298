import React, { useEffect, useCallback, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import {
  oneTimeServicesInput,
  contServicesInput,
} from '../../data/searchFlowText';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../../utils/i18n';
import info from '../../../../images/info.png';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    lineHeight: '20px',
    padding: '16px',
  },
}));

const CheckboxInput3 = ({ dataText, lastStep, priceObj }) => {
  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);
  const [checkboxToDisable, setCheckboxToDisable] = useState('');
  const [servicesText, setServicesText] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const infoObj = {
    accounting: i18n.t('searchflow.hint1'),
    payroll: i18n.t('searchflow.hint2'),
    taxconsultingsimple: i18n.t('searchflow.hint4'),
    legalentityfounding: i18n.t('searchflow.hint3'),
  };

  const setDisableCheckboxId = useCallback(
    (servicesText) => {
      let checkedServices = 0;
      let checkboxId = '';
      servicesText.forEach((item) => {
        if (companyStore[item.id] === true) {
          checkedServices += 1;
          checkboxId = item.id;
        }
      });
      if (checkedServices === 1) {
        setCheckboxToDisable(checkboxId);
      } else if (checkedServices > 1) {
        setCheckboxToDisable('');
      }
    },
    [companyStore]
  );

  useEffect(() => {
    setServicesText([...oneTimeServicesInput(), ...contServicesInput()]);
  }, []);

  useEffect(() => {
    if (lastStep) {
      setDisableCheckboxId(servicesText);
    }
  }, [setDisableCheckboxId, servicesText, lastStep]);

  const handleCheck = (event) => {
    dispatch(
      ACTIONS.addCompanyInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'company_store',
      })
    );
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  };

  return (
    <div>
      {dataText.map((item, i) => {
        return (
          <div key={i}>
            <FormGroup>
              <FormControlLabel
                style={{ height: '56px', padding: '0' }}
                className='SFRadioInput'
                labelPlacement='start'
                control={
                  <Checkbox
                    id={item.id}
                    checked={companyStore[item.id]}
                    onChange={(event) => {
                      handleCheck(event);
                    }}
                    disabled={checkboxToDisable === item.id ? true : false}
                  />
                }
                label={
                  <p className='SFCheckboxLabelContainer'>
                    <span className='SFCheckboxLabel'>
                      <span
                        style={{
                          width: '30px',
                          display: 'inline-flex',
                          height: '55px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpen();
                        }}
                      >
                        <LightTooltip
                          fullscreen
                          title={infoObj[item.id]}
                          placement='bottom-end'
                          enterTouchDelay={0}
                        >
                          <img
                            alt='tooltip'
                            src={info}
                            style={{
                              marginRight: '6px',
                              width: '16px',
                              height: '16px',
                            }}
                          />
                        </LightTooltip>{' '}
                      </span>
                      {item.label}
                    </span>
                    {/* {item.id === 'accounting' && companyStore.discount && (
                      <span
                        className='SFCheckboxLabelPrice'
                        style={{ textDecoration: 'line-through' }}
                      >
                        {numberWithCommas(priceObj.accountingBefore)}{' '}
                        {i18n.t('general.currencymonthly')}
                      </span>
                    )} */}
                    {(item.id === 'taxconsultingsimple' ||
                      item.id === 'legalentityfounding') && (
                      <span className='SFCheckboxLabelPrice'>
                        {numberWithCommas(priceObj[item.id])}{' '}
                        {i18n.t('general.currency')} (bruttó)
                      </span>
                    )}
                    {item.id === 'accounting' && !companyStore.accounting && (
                      <span className='SFCheckboxLabelPrice'>
                        {numberWithCommas(priceObj[item.id])}{' '}
                        {i18n.t('general.currency/month')} (nettó) -tól
                      </span>
                    )}
                  </p>
                }
              />
            </FormGroup>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxInput3;
