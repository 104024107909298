import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import i18n from '../../../utils/i18n';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function Snack({ handleClick, open, setOpen }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const horizontal = 'center';
  const vertical = 'top';

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        onClose={handleClose}
        severity='success'
        sx={{ width: '70vw', textAlign: 'center', backgroundColor: '#5dbb63' }}
      >
        {i18n.t('referral.refsenttext')}
      </Alert>
    </Snackbar>
  );
}
