import React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { validateInput, gdprLabelReg } from '../../data/providerText';
import axios from '../../../shared/axios/axiosProxy';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

class RegistryInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordError: '',
      emailError: '',
      accountCreated: false,
      showPassword: false,
      gdpr: false,
      gdprError: '',
    };
  }

  registerProvider = async () => {
    const { password, email } = this.state;
    await axios
      .post('/api/auth/register/provider', {
        email,
        password,
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.data);
        this.setState({ accountCreated: true });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
        if (error.response.data) {
          if (error.response.data === 'email already taken') {
            this.setState({
              emailError:
                error.response.data === 'email already taken'
                  ? i18n.t('provider.alreadyregistered')
                  : '',
            });
          }
        }
      });
  };
  handleChange = (event) => {
    if (this.state[`${event.target.id}Error`].length > 0) {
      this.setState({
        [`${event.target.id}Error`]: validateInput(
          event.target.id,
          event.target.value
        )[event.target.id],
      });
    }
    this.setState({ [event.target.id]: event.target.value });
  };

  handleBlur = (event) => {
    if (event.target.id === 'password') {
      this.setState({
        passwordError: validateInput('password', event.target.value).password,
      });
    }
    if (event.target.id === 'email') {
      this.setState({
        emailError: validateInput('email', event.target.value).email,
      });
    }
  };
  render() {
    return (
      <>
        {this.state.accountCreated ? (
          <div>
            <p>
              {i18n.t('provider.thxforregtext1')}{' '}
              <span className='BabyBlue'>
                {i18n.t('provider.thxforregtext2')}
              </span>{' '}
              {i18n.t('provider.thxforregtext3')}
            </p>
          </div>
        ) : (
          <div>
            <TextField
              id='email'
              type='email'
              name='email'
              value={this.state.email}
              label={i18n.t('general.emaillabel')}
              onChange={(event) => {
                this.handleChange(event);
              }}
              onBlur={(event) => {
                this.handleBlur(event);
              }}
              error={this.state.emailError}
            />
            <span className='ErrorText'>{this.state.emailError}</span>
            <TextField
              style={{ marginTop: '24px' }}
              id='password'
              autoComplete='new-password'
              type={this.state.showPassword ? 'text' : 'password'}
              name='password'
              label={i18n.t('general.pw')}
              value={this.state.password}
              onChange={(event) => {
                this.handleChange(event);
              }}
              onBlur={(event) => {
                this.handleBlur(event);
              }}
              error={this.state.passwordError ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' tabIndex='-1'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                      size='large'
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <p
              style={{ fontSize: '14px', fontWeight: 'bold', margin: '6px 0' }}
            >
              {i18n.t('provider.pwcriteria')}
            </p>
            {/* <span className="ErrorText">{this.state.passwordError}</span> */}
            <FormControlLabel
              style={{ marginTop: '24px' }}
              control={
                <Checkbox
                  id='gdpr'
                  name='gdpr'
                  checked={this.state.gdpr}
                  onChange={(event) => {
                    if (
                      this.state.gdprError.length > 0 &&
                      event.target.checked
                    ) {
                      this.setState({
                        gdprError: '',
                      });
                    }
                    this.setState({ gdpr: event.target.checked });
                  }}
                  color='primary'
                  required
                />
              }
              label={gdprLabelReg()}
            />
            <span className='ErrorText'>{this.state.gdprError}</span>
            <div className='MarginH12 FullWidth FlexFullCenter'>
              <StyledButton
                color='Primary'
                onClick={() => {
                  if (this.state.gdpr) {
                    if (
                      this.state.passwordError.length === 0 &&
                      this.state.emailError.length === 0
                    ) {
                      this.registerProvider();
                    }
                  } else if (!this.state.gdpr) {
                    this.setState({
                      gdprError: i18n.t('general.gdprerror'),
                      emailError: validateInput('email', this.state.email)
                        .email,
                      passwordError: validateInput(
                        'password',
                        this.state.password
                      ).password,
                    });
                  }
                }}
              >
                {i18n.t('provider.iregister')}
              </StyledButton>
            </div>
            <div className='MarginH12 FullWidth FlexFullCenter'>
              <span className='StandardText'>
                {i18n.t('provider.ugotaccount')}{' '}
                <span>
                  <a className='SecondaryBlue' href='/'>
                    {i18n.t('provider.dologin')}
                  </a>
                </span>
              </span>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(RegistryInputs);
