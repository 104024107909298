/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import '../../styles/GlobalStyling.scss';

const cleaveOptions = {
  taxnumber: { blocks: [8, 1, 2], delimiter: '-', numericOnly: true },
  companytaxnumber: { blocks: [8, 1, 2], delimiter: '-', numericOnly: true },
  phone: {
    blocks: [3, 2, 3, 4],
    delimiter: ' ',
  },
  zip: { blocks: [4], numericOnly: true },
  default: { blocks: [100], delimiter: '' },
  contactzip: { blocks: [4], numericOnly: true },
  contactphone: { blocks: [3, 2, 3, 4], delimiter: ' ' },
  fax: { blocks: [20], numericOnly: true },
  description: { blocks: [500], delimiter: '' },
};

export const CleaveInput = (props) => {
  const { id, inputRef, ...rest } = props;

  return (
    <Cleave
      ref={inputRef}
      options={cleaveOptions[id] ? cleaveOptions[id] : cleaveOptions.default}
      id={id}
      {...rest}
    />
  );
};

CleaveInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, id, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
            id: id,
            name: name,
          },
        });
      }}
      thousandSeparator
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
