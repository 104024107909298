import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';

import { NumberFormatCustom } from '../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const ExtraFeeInputs = ({ ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const accountingPrice = useSelector(
    (state) => state.provider_acc_monthly_price
  );
  const providerRestrictions = useSelector(
    (state) => state.provider_restrictions
  );
  const handleChange = (event, storeObj) => {
    props.setModified(true);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };
  return (
    <>
      {props.dataText.map((item, i) => {
        if (props.revenue) {
          if (
            providerRestrictions['yearlyrevenue'][0] <= item.range[1] &&
            providerRestrictions['yearlyrevenue'][1] >= item.range[0] &&
            !Number.isInteger(props.pricesCopy[item.id])
          ) {
            if (!Number.isInteger(accountingPrice[item.id])) {
              props.setRequiredFields(item.id);
            }
            return (
              <div className='AdornmentInputContainer Price' key={i}>
                <TextField
                  className={classes.root}
                  id={item.id}
                  variant='outlined'
                  type='text'
                  /*   placeholder={item.label} */
                  value={accountingPrice[item.id]}
                  label={`${props.title}: ${item.label}`}
                  onChange={(event) => {
                    handleChange(event, 'provider_acc_monthly_price');
                    if (
                      (event.target.value && inputError[item.id]) ||
                      (event.target.value === 0 && inputError[item.id])
                    ) {
                      setInputError({
                        ...inputError,
                        [item.id]: false,
                      });
                    }
                  }}
                  error={inputError[item.id]}
                  onBlur={(event) => {
                    if (!event.target.value) {
                      setInputError({ ...inputError, [item.id]: true });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span
                          style={{
                            color: inputError[item.id] ? 'red' : '#2D46B9',
                          }}
                        >
                          {props.endAdornment}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            );
          } else {
            props.setRequiredFieldsTrue(item.id);
            return null;
          }
        } else if (!Number.isInteger(props.pricesCopy[item.id])) {
          if (!Number.isInteger(accountingPrice[item.id])) {
            props.setRequiredFields(item.id);
          }
          return (
            <div className='AdornmentInputContainer Price' key={i}>
              <TextField
                className={classes.root}
                id={item.id}
                variant='outlined'
                type='text'
                /*placeholder={item.label} */
                value={accountingPrice[item.id]}
                label={`${props.title}: ${item.label}`}
                onChange={(event) => {
                  handleChange(event, 'provider_acc_monthly_price');
                  if (
                    (event.target.value && inputError[item.id]) ||
                    (event.target.value === 0 && inputError[item.id])
                  ) {
                    setInputError({
                      ...inputError,
                      [item.id]: false,
                    });
                  }
                }}
                error={inputError[item.id]}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setInputError({ ...inputError, [item.id]: true });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span
                        style={{
                          color: inputError[item.id] ? 'red' : '#2D46B9',
                        }}
                      >
                        {props.endAdornment}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        } else {
          props.setRequiredFieldsTrue(item.id);
          return null;
        }
      })}
    </>
  );
};

export default ExtraFeeInputs;
