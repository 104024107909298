export const sortProviderList = (providers, category, ascending) => {
  let ppArray = providers;
  if (ascending) {
    ppArray = ppArray.sort(
      (a, b) => parseFloat(a[category]) - parseFloat(b[category])
    );
    ppArray = ppArray.sort(function (a, b) {
      if (parseFloat(a[category]) > parseFloat(b[category])) {
        return 1;
      }
      if (parseFloat(a[category]) < parseFloat(b[category])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) > parseFloat(b['totalmatchscore'])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) < parseFloat(b['totalmatchscore'])) {
        return 1;
      }
      return ppArray;
    });
  } else if (!ascending) {
    ppArray = ppArray.sort(function (a, b) {
      if (parseFloat(a[category]) > parseFloat(b[category])) {
        return -1;
      }
      if (parseFloat(a[category]) < parseFloat(b[category])) {
        return 1;
      }
      if (parseFloat(a['totalmatchscore']) > parseFloat(b['totalmatchscore'])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) < parseFloat(b['totalmatchscore'])) {
        return 1;
      }
      return ppArray;
    });
  }
  return ppArray;
};

export const sortProviders = (providers, category, ascending) => {
  let ppArray = [];
  for (var i in providers) {
    ppArray.push(providers[i]);
  }
  if (ascending) {
    ppArray = ppArray.sort(
      (a, b) => parseFloat(a[category]) - parseFloat(b[category])
    );
    ppArray = ppArray.sort(function (a, b) {
      if (parseFloat(a[category]) > parseFloat(b[category])) {
        return 1;
      }
      if (parseFloat(a[category]) < parseFloat(b[category])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) > parseFloat(b['totalmatchscore'])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) < parseFloat(b['totalmatchscore'])) {
        return 1;
      }
      return ppArray;
    });
  } else if (!ascending) {
    ppArray = ppArray.sort(function (a, b) {
      if (parseFloat(a[category]) > parseFloat(b[category])) {
        return -1;
      }
      if (parseFloat(a[category]) < parseFloat(b[category])) {
        return 1;
      }
      if (parseFloat(a['totalmatchscore']) > parseFloat(b['totalmatchscore'])) {
        return -1;
      }
      if (parseFloat(a['totalmatchscore']) < parseFloat(b['totalmatchscore'])) {
        return 1;
      }
      return ppArray;
    });
  }
  return ppArray;
};

export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
};

export const setTotalMatch = (provider, msWeights) => {
  let weightSum =
    msWeights.distanceWeight +
    msWeights.priceWeight +
    msWeights.reviewWeight +
    msWeights.experienceWeight;
  let totalMatch =
    (msWeights.distanceWeight * provider['distanceScore'] +
      msWeights.priceWeight * provider['priceScore'] +
      msWeights.reviewWeight * provider['reviewScore'] +
      msWeights.experienceWeight * provider['experienceScore']) /
    weightSum;
  return totalMatch;
};

export const setTotalMatchArray = (providers, msWeights) => {
  let totalMatchArray = [];
  for (var i in providers) {
    totalMatchArray.push(
      Math.round(setTotalMatch(providers[i], msWeights) * 10) / 10
    );
  }
  return totalMatchArray;
};

export const quantile = (arr, q) => {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const getFinalScore = (quantile, array, score) => {
  let maxValue = 0;
  maxValue = quantile(array, 0.9);
  let totalmatchscore = (score / maxValue) * 100;
  if (totalmatchscore >= 100) {
    return `${100}%`;
  } else return `${Math.round(totalmatchscore)}%`;
};

const insuranceWeights = {
  1: {
    none: 0,
    partial: 5,
    full: 15,
    extract: 15,
  },
  0: {
    none: 0,
    partial: 0,
    full: 0,
    extract: 0,
  },
  3: {
    none: 0,
    partial: 10,
    full: 30,
    extract: 30,
  },
  5: {
    none: 0,
    partial: 30,
    full: 60,
    extract: 60,
  },
};

export const addMatchScore = (pp, msWeights) => {
  let matchScoreArray = setTotalMatchArray(pp, msWeights);
  for (let i in pp) {
    let totalMatch = setTotalMatch(pp[i], msWeights);
    let matchScore = getFinalScore(quantile, matchScoreArray, totalMatch);
    matchScore = parseInt(matchScore);
    matchScore =
      matchScore - insuranceWeights[msWeights.liabilityInsWeight].extract;
    switch (pp[i].providerInsurance) {
      case 'none':
        matchScore =
          matchScore + insuranceWeights[msWeights.liabilityInsWeight].none;
        break;
      case 'partial':
        matchScore =
          matchScore + insuranceWeights[msWeights.liabilityInsWeight].partial;
        break;
      case 'full':
        matchScore =
          matchScore + insuranceWeights[msWeights.liabilityInsWeight].full;
        break;
      default:
        return matchScore;
    }
    matchScore = `${matchScore}%`;
    pp[i].totalmatchscore = matchScore;
  }

  return pp;
};
