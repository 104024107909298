import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { NumberFormatCustom } from '../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { providerSpecialRestrictions } from '../../../../data/providerText';
import i18n from '../../../../../utils/i18n';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function SpecialRestrictionInputs({ ...props }) {
  const dispatch = useDispatch();
  const [inputError, setInputError] = React.useState({});
  const [emptyFields, setEmptyFields] = React.useState({});
  const [modified, setModified] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [providerRestrictions, accountingPrice] = useSelector((state) => [
    state.provider_special_restrictions,
    state.provider_acc_monthly_price,
  ]);
  const [accPricesCopy] = React.useState(accountingPrice);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      (providerRestrictions.insideEU &&
        !Number.isInteger(accountingPrice.foreigntradeextra) &&
        !emptyFields.foreigntradeextra) ||
      (providerRestrictions.outsideEU &&
        !Number.isInteger(accountingPrice.foreigntradeextra) &&
        !emptyFields.foreigntradeextra)
    ) {
      setEmptyFields({ ...emptyFields, foreigntradeextra: true });
    } else if (
      (providerRestrictions.insideEU &&
        Number.isInteger(accountingPrice.foreigntradeextra) &&
        emptyFields.foreigntradeextra) ||
      (providerRestrictions.outsideEU &&
        Number.isInteger(accountingPrice.foreigntradeextra) &&
        emptyFields.foreigntradeextra) ||
      (!providerRestrictions.insideEU &&
        !providerRestrictions.outsideEU &&
        emptyFields.foreigntradeextra)
    )
      setEmptyFields({ ...emptyFields, foreigntradeextra: false });
  }, [
    setEmptyFields,
    accountingPrice.foreigntradeextra,
    emptyFields,
    providerRestrictions.insideEU,
    providerRestrictions.outsideEU,
  ]);

  const handleCheck = (event, item) => {
    setModified(true);
    dispatch(
      ACTIONS.addProviderInfo({
        id: item.id1,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
    dispatch(
      ACTIONS.addProviderInfo({
        id: item.id2,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
  };
  const handleChange = (event) => {
    setModified(true);
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
  };
  const checkAll = (event) => {
    setModified(true);
    providerSpecialRestrictions().forEach((item) => {
      dispatch(
        ACTIONS.addProviderInfo({
          id: item.id1,
          data: event.target.checked,
          stateObject: 'provider_special_restrictions',
        })
      );
      dispatch(
        ACTIONS.addProviderInfo({
          id: item.id2,
          data: event.target.checked,
          stateObject: 'provider_special_restrictions',
        })
      );
    });
  };
  const handleChangeTextField = (event, storeObject) => {
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObject,
      })
    );
  };
  const checkEmptyFieldsObject = () => {
    for (let i in emptyFields) {
      if (emptyFields[i]) {
        setError(true);
        return true;
      }
    }
    setError(false);
    return false;
  };
  return (
    <div className='ProviderInputContainerCentered'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <div className='RestrictionInputAll'>
        <p style={{ color: '#2D46B9' }}>{i18n.t('provider.tickall')}</p>
        <GreenSwitch
          {...label}
          id='all'
          defaultChecked={true}
          onChange={(event) => {
            checkAll(event);
          }}
        />
        <span style={{ color: '#28b446' }}>{i18n.t('provider.iwantit')}</span>
      </div>
      <div
        className='ProviderInputContainerDashboard'
        style={{ padding: '12px 24px' }}
      >
        {providerSpecialRestrictions().map((item, i) => {
          if (item.label === i18n.t('general.newlyfoundedcomps')) {
            return (
              <div className='CenteredInput FullWidth' key={item + i}>
                <p>{item.label}</p>
                <GreenSwitch
                  {...label}
                  id={item.id}
                  checked={providerRestrictions[item.id]}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                {providerRestrictions[item.id] ? (
                  <span style={{ color: '#28b446' }}>
                    {i18n.t('provider.iwantit')}
                  </span>
                ) : (
                  <span style={{ color: 'grey' }}>
                    {i18n.t('provider.idontwantit')}
                  </span>
                )}
              </div>
            );
          } else
            return (
              <React.Fragment>
                <div className='CenteredInput FullWidth' key={i + item}>
                  <p>{item.label}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id}
                    checked={
                      providerRestrictions[item.id1] ||
                      providerRestrictions[item.id2]
                    }
                    onChange={(event) => {
                      handleCheck(event, item);
                    }}
                  />
                  {providerRestrictions[item.id1] ||
                  providerRestrictions[item.id2] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
                {(item.label === i18n.t('general.foreigntrade') &&
                  providerRestrictions.insideEU &&
                  !accPricesCopy.foreigntradeextra) ||
                (item.label === i18n.t('general.foreigntrade') &&
                  providerRestrictions.outsideEU &&
                  !accPricesCopy.foreigntradeextra) ? (
                  <div className='AdornmentInputContainer Price'>
                    <TextField
                      id={`foreigntradeextra`}
                      variant='outlined'
                      type='text'
                      /*   placeholder={item.label} */
                      value={accountingPrice[`foreigntradeextra`]}
                      label={i18n.t('general.foreigntradeextra2')}
                      onChange={(event) => {
                        handleChangeTextField(
                          event,
                          'provider_acc_monthly_price'
                        );
                        if (
                          event.target.value &&
                          inputError[`foreigntradeextra`]
                        ) {
                          setInputError({
                            ...inputError,
                            foreigntradeextra: false,
                          });
                        }
                      }}
                      error={inputError[`foreigntradeextra`]}
                      onBlur={(event) => {
                        if (!event.target.value) {
                          setInputError({
                            ...inputError,
                            [`foreigntradeextra`]: true,
                          });
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <span
                              style={{
                                color: inputError[item.id] ? 'red' : '#2D46B9',
                              }}
                            >
                              Ft
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                ) : null}
                <div className='CenteredInput FullWidth'>
                  <p>{item.label1}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id1}
                    checked={providerRestrictions[item.id1]}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  {providerRestrictions[item.id1] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
                <div className='CenteredInput FullWidth'>
                  <p>{item.label2}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id2}
                    checked={providerRestrictions[item.id2]}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  {providerRestrictions[item.id2] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
              </React.Fragment>
            );
        })}
      </div>
      {error ? (
        <p className='ErrorText'>
          {i18n.t('providerdashboard.fillbeforesave')}
        </p>
      ) : null}
      {modified ? (
        <StyledButton
          onClick={() => {
            if (modified && !checkEmptyFieldsObject()) {
              props.submitToDB({
                provider_acc_monthly_price: accountingPrice,
                provider_special_restrictions: providerRestrictions,
              });
              setOpen(true);
              setModified(false);
            }
          }}
          color={'Primary'}
        >
          {i18n.t('general.save')}
          {/*  {edit ? (modified ? "Mentés" : "Vissza") : "Módosítás"} */}
        </StyledButton>
      ) : null}
    </div>
  );
}
