import React, { useEffect, useCallback } from 'react';
import StyledButton from '../../../shared/components/styledButton/styledButton.jsx';
import sorry from '../../../images/peaking4.png';
import logo from '../../../images/logo.png';
import pp from '../../../images/pp.png';
import i18n from '../../../utils/i18n.js';
import { withTranslation } from 'react-i18next';
import ContactInfo from '../components/contactInfo/contactInfo.jsx';
import ACTIONS from '../../../redux/action.js';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { gdprLabelSF } from '../data/searchFlowText.js';
import axios from '../../utils/axiosCompanyProxy.js';
import Alert from '@mui/material/Alert';

const VafContactForm = () => {
  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [gdprError, setGdprError] = React.useState(false);
  const [isReqSent, setIsReqSent] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);
  const nav = () => {
    window.location.replace(
      `https://dashboard-hu.bookkeepie.com/company/request/1`
    );
  };

  const requestCallBack = (companyData) => {
    let data = Object.assign({}, companyData);
    //console.log(data, 'ASPO REQ DATA');

    axios
      .post(`/api/aspreq/requestcallback/new`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //console.log('AXIOS RESPONSE: ', response);
        this.setState({ reqID: response.data.result._id });
        this.props.addCompanyInfo({
          id: 'reqID',
          data: response.data.result._id,
          stateObject: 'company_store',
        });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  const dataLayerUpdate = () => {
    window.dataLayer.push({
      event: 'sf_step_no_offer_request_sent',
    });
  };

  //console.log(requestCallBack, 'requestCallBack');

  useEffect(() => {
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'reqFromBupa',
        data: false,
        stateObject: 'company_store',
      })
    );
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'callbacksource',
        data: 'noprovider',
        stateObject: 'company_store',
      })
    );
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'isCallBackReq',
        data: true,
        stateObject: 'company_store',
      })
    );
  }, [dispatch]);

  const validateInput = useCallback(() => {
    const validEmailRegex = RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    return validEmailRegex.test(companyStore.email);
  }, [companyStore.email]);
  const validateName = useCallback(() => {
    if (companyStore.companyctcname.trim().length > 0) {
      return true;
    }
    return false;
  }, [companyStore.companyctcname]);

  const validatePhone = useCallback(() => {
    if (companyStore.companyPhone.length >= 8) {
      return true;
    }
    return false;
  }, [companyStore.companyPhone]);

  useEffect(() => {
    if (emailError && validateInput()) {
      setEmailError(false);
    }
    if (gdprError && companyStore.gdpr) {
      setGdprError(false);
    }
    if (phoneError && validatePhone()) {
      setPhoneError(false);
    }
    if (nameError && validateName()) {
      setNameError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyStore.gdpr,
    validateInput,
    companyStore,
    emailError,
    gdprError,
    phoneError,
    nameError,
    validateName,
    validatePhone,
  ]);

  return (
    <>
      <div className='noprovcontainer'>
        {alertOpen && (
          <Alert
            severity='success'
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: 'fit-content',
              marginBottom: '30px',
              marginLeft: '30px',
              cursor: 'pointer',
            }}
            onClick={() => setAlertOpen(false)}
          >
            {i18n.t('searchflow.callBackReqSent')}
          </Alert>
        )}
        <div className='noprovheader' style={{ height: '100%' }}>
          <div
            className='stepperContainer'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {i18n.t('searchflow.sfresultsubtext4')}
            <img src={logo} alt='logo' />
          </div>
          <div>
            <p className='headerText' style={{ margin: '24px auto 8px 0px' }}>
              {i18n.t('searchflow.vafformtitle')}
            </p>
            <p
              className='headerSubText'
              style={{ marginTop: '0', maxWidth: '420px' }}
            >
              {i18n.t('searchflow.vafformtext')}
            </p>
          </div>
          {window.innerWidth > 600 && (
            <img className='headerImg' src={sorry} alt='sorry' />
          )}
        </div>
        <div style={{ padding: '0 12px' }}>
          <p className='noprovtitle'>{i18n.t('searchflow.sfresulttext2')}</p>
          <p className='noprovtext'>{i18n.t('searchflow.whatnowtext')}</p>
          <ContactInfo />
          <div style={{ margin: '12px 0' }}>
            <FormControlLabel
              key={'gdpr'}
              control={
                <Checkbox
                  id='gdpr'
                  name='gdpr'
                  checked={companyStore.gdpr}
                  onChange={(event) => {
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: event.target.id,
                        data: event.target.checked,
                        stateObject: 'company_store',
                      })
                    );
                  }}
                  color='primary'
                  required
                  /*  disabled={this.state.submitted} */
                />
              }
              label={gdprLabelSF()}
            />
            <FormControlLabel
              key={'marketing'}
              control={
                <Checkbox
                  id='marketing'
                  name='checked'
                  checked={companyStore.marketing}
                  onChange={(event) => {
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: event.target.id,
                        data: event.target.checked,
                        stateObject: 'company_store',
                      })
                    );
                  }}
                  color='primary'
                  /*    disabled={this.state.submitted} */
                />
              }
              label={
                <span className='SFSmallText'>
                  {i18n.t('searchflow.newslettersubtext')}
                </span>
              }
            />
          </div>
          {gdprError ? (
            <span
              style={{ color: 'red', fontSize: '14px', fontWeight: 'bold' }}
            >
              {i18n.t('searchflow.plsacceptgdpr')}
            </span>
          ) : null}
        </div>
        <div className='noprovbuttoncontainer'>
          <StyledButton
            color='NewNavBack'
            onClick={(e) => {
              nav();
            }}
          >
            {i18n.t('searchflow.tryagain')}
          </StyledButton>
          {!isReqSent ? (
            <StyledButton
              isDisabled={
                companyStore.companyctcname.trim().length === 0 ||
                companyStore.companyPhone.trim().length < 11 ||
                companyStore.email.trim().length === 0 ||
                !companyStore.gdpr
              }
              color='NewReqBtn'
              onClick={() => {
                requestCallBack(companyStore);
                dispatch(
                  ACTIONS.addCompanyInfo({
                    id: 'submitted',
                    data: true,
                    stateObject: 'company_store',
                  })
                );
                setIsReqSent(true);
                setAlertOpen(true);
                dataLayerUpdate();
                setTimeout(() => {
                  window.location.replace(
                    'http://www.bookkeepie.com/hu/thank-you-no-result'
                  );
                }, 3000);
              }}
            >
              {i18n.t('searchflow.vafformbtn')} <img src={pp} alt='send' />
            </StyledButton>
          ) : (
            <StyledButton
              color='NewReqBtn'
              onClick={() => {
                window.open('https://www.bookkeepie.com/hu/blog', '_blank');
              }}
            >
              {i18n.t('searchflow.readblog')} <img src={pp} alt='send' />
            </StyledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(VafContactForm);
