import React from 'react';
import StyledButton from '../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../utils/i18n';
// import NeedHelp from '../contactInfo/needHelp';

const Navigation = ({
  handleBack,
  handleNext,
  step,
  disabledProp,
  onClickHandler,
  onClickHandlerFalse,
}) => {
  //console.log(step, 'SZTEP');
  return (
    <div
      className={`SFButtonContainer ${
        step === 15 || step === 16 ? 'SFResultNavContainer' : ''
      }`}
      style={{
        justifyContent: 'space-between',
        //step !== 0 && window.innerWidth > 600 ? 'space-between' : 'center',
      }}
    >
      <div>
        <StyledButton
          className='btnMaxWidth130 Medium '
          color='NewNavBack'
          onClick={() => {
            step === 0
              ? window.location.replace('https://www.bookkeepie.com/hu')
              : handleBack();
          }}
        >
          {step !== 0
            ? i18n.t('general.backbutton')
            : i18n.t('providerdashboard.undo')}
        </StyledButton>
        {step === 15 && (
          <StyledButton
            className='btnMaxWidth130 Medium'
            color='NewNavBack'
            onClick={() => {
              onClickHandlerFalse();
            }}
          >
            {i18n.t('searchflow.sfresbuttontext2')}
          </StyledButton>
        )}
      </div>
      {step !== 15 && step !== 16 && (
        <StyledButton
          className='btnMaxWidth130 Medium'
          color='NewNavForward'
          onClick={() => {
            handleNext();
          }}
          isDisabled={disabledProp}
        >
          {i18n.t('general.forwardbutton')}
        </StyledButton>
      )}
      {step === 15 && (
        <StyledButton
          className='rfpButton'
          color='NewNavForward'
          onClick={() => {
            onClickHandler();
          }}
          isDisabled={disabledProp}
        >
          {i18n.t('searchflow.reqmsgtitle')}
        </StyledButton>
      )}
      {step === 16 && null}
    </div>
  );
};

export default Navigation;
