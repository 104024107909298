import StyledButton from '../../../shared/components/styledButton/styledButton';
import sorry from '../../../images/Sad.svg';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
const Temporary = ({ step }) => {
  return (
    <>
      <div className='stepContainer'>
        <div className='SFTitleContainer'>
          <p className='SFTitleBlue maringBottom2rem'>
            {i18n.t('searchflow.wearesorry')}
          </p>
        </div>
        <div className='SFPosRel'>
          <hr className='HRBlue SFMarginTop70' />
        </div>
        <div>
          <p className='SFQuestion'>{i18n.t('general.maintenance')}</p>
        </div>
        <div className='maringBottom2rem'>
          <img src={sorry} alt='sorry' style={{ maxHeight: '250px' }} />
        </div>
        <p className='SFQuestion'>{i18n.t('general.maintenance2')}</p>
      </div>
      <hr className='yellowHR SFInputWidth85' />
    </>
  );
};

export default withTranslation()(Temporary);
