import React, { useEffect, useCallback } from 'react';

import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import CheckboxInput from '../components/searchInputs/newCheckboxInput';
import RadioInput from '../components/searchInputs/radioInput';
import { languagesText, languageFilterInput } from '../data/searchFlowText';

import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const Languages = ({ ...props }) => {
  const companyStore = useSelector((state) => state.company_store);

  const dispatch = useDispatch();

  const handleNext = useCallback(
    (inc) => {
      props.handleNext(inc);
    },
    [props]
  );

  const handleChange = (event) => {
    let temp = {
      hungarian: companyStore.languages.hungarian,
      english: companyStore.languages.english,
      german: companyStore.languages.german,
      french: companyStore.languages.french,
      chinese: companyStore.languages.chinese,
      russian: companyStore.languages.russian,
      romanian: companyStore.languages.romanian,
      spanish: companyStore.languages.spanish,
    };
    languagesText().forEach((item) => {
      if (event.target.id === item.id) {
        temp[item.id] = !temp[item.id];
      }
    });

    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'languages',
        data: temp,
        stateObject: 'company_store',
      })
    );
    event.target.blur();
  };

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        props.handleNext();
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, handleNext]);

  const handleBack = useCallback(
    (decr) => {
      props.handleBack(decr);
    },
    [props]
  );

  return (
    <div className='stepContainer'>
      <div className='SFInput'>
        <p className='SFSubTitle'>
          {' '}
          <span className='SFSubTitle'>
            {i18n.t('searchflow.providerskillstext1')}
          </span>{' '}
          <span className='questionTracker'>1/3</span>
        </p>
        <RadioInput
          storeID={'languagefilter'}
          handleCheck={handleChange}
          dataText={languageFilterInput()}
        />

        {companyStore.languagefilter === true && (
          <>
            <p className='SFSubTitle'>
              {i18n.t('searchflow.providerskillstext11')}
            </p>
            <CheckboxInput
              handleCheck={handleChange}
              dataText={languagesText()}
            />
          </>
        )}
      </div>
      <Navigation
        step={12}
        handleBack={() => handleBack()}
        handleNext={() => handleNext()}
        openHelpForm={() => props.openHelpForm()}
      />
    </div>
  );
};

export default withTranslation()(Languages);
