import React from 'react';
import i18n from '../../../../../utils/i18n';
/* import placeHolder from "../../../../../images/icons/placeholder-new.png"; */
/* import StyledButton from "../../../../../shared/components/styledButton/styledButton"; */

const ReviewsInfo = () => {
  return (
    <div className='ReviewsInfoContainer'>
      <div className='ReviewsInfo'>
        <h2>{i18n.t('providerdashboard.revtext1')}</h2>
        <p>
          <span>
            {i18n.t('providerdashboard.revtext2')}
            <span className='SecondaryBlue'>
              {i18n.t('providerdashboard.revtext3')}
            </span>{' '}
            {i18n.t('providerdashboard.revtext4')}
            <span className='SecondaryBlue'>
              {i18n.t('providerdashboard.revtext5')}
            </span>{' '}
            {i18n.t('providerdashboard.revtext6')}
          </span>
        </p>
        <p>
          {i18n.t('providerdashboard.revtext7')}
          <span>{i18n.t('providerdashboard.revtext8')}</span>
          {i18n.t('providerdashboard.revtext9')}
        </p>
        <p>{i18n.t('providerdashboard.revtext10')}</p>
        <p>{i18n.t('providerdashboard.revtext11')}</p>
      </div>
      {/*       <div className="ProfileExample">
        <p>Így fest egy profil, amin elhanyagolták az értékeléseket....</p>
        <img src={placeHolder} alt="" />
        <p>
          És ilyen egy hozott értékelésekkel büszkélkedő profil. Szembetűnő a
          különbség, igaz?{" "}
        </p>
        <img src={placeHolder} alt="" />
      </div> */}
      <div className='ReviewFormSendContainer'>
        <p>
          {i18n.t('providerdashboard.revtext12')}
          <br />
          {i18n.t('providerdashboard.revtext13')}
        </p>
        {/*         <a href="mailto:?subject=Értékelő lap&body=https://docs.google.com/forms/d/1iAj_Br4O_12pC5dUAbYVUBvi1eaWM2mkYAclUTEeI44/edit">
          <StyledButton color="Primary"  >
            Elküldöm az értékeléskérőt
          </StyledButton>
        </a> */}
      </div>
    </div>
  );
};

export default ReviewsInfo;
