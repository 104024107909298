import React from 'react';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import simplePayImg from '../../../../../images/simplepay_200x50.png';
import { cardPaymentConfirm } from '../../../../data/providerText';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import axios from '../../../../../shared/axios/axiosProxy';
import i18n from '../../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const PaymentMethod = ({ ...props }) => {
  const JWT = localStorage.getItem('JWT');
  const [open, setOpen] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [cardPaymentOpen, setCardPaymentOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [providerPackage, providerSubscription, provData, store] = useSelector(
    (state) => [
      state.provider_package,
      state.provider_subscription,
      state.provider_info,
      state,
    ]
  );
  const openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };
  const handleCheck = (event) => {
    setChanged(true);
    let value = false;
    if (event.target.id === 'cardPayment') {
      value = true;
    }
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'cardPayment',
        data: value,
        stateObject: 'provider_subscription',
      })
    );
  };
  const startSubscription = (payment) => {
    var paymentData = {
      name: provData.companyname,
      email: provData.email,
      city: provData.city,
      address: provData.address,
      zip: provData.zip,
      phone: provData.phone,
      fee: providerPackage.packagePrice,
      packageType: providerPackage.packageType,
      packageSize: providerPackage.packageSize,
      packageYearlyPayment: providerPackage.yearly,
    };
    axios
      .post(
        '/api/provider/subscription',
        { transactionData: paymentData, cardPayment: payment },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('startSubscription');
        if (payment === true) {
          openInNewTab(response.data.result);
          props.submitToDB({
            'provider_subscription.cardPayment':
              providerSubscription.cardPayment,
          });
          handleClose();
        } else return;
      })
      .catch((error) => {
        console.log('startSubscription ERROR: ', error.response.data);
      });
  };

  const deactivate = () => {
    console.log('DSADSSADASDA');
    axios
      .post(
        '/api/provider/deactivate',
        {
          transactionData: {
            providerID: store.providerID,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('DEACTIVATE RESPONSE: ', response);
        props.submitToDB({
          'provider_subscription.cardPayment': providerSubscription.cardPayment,
        });
        /* openInNewTab(response.data.result); */
      })
      .catch((error) => {
        console.log('DEACTIVATE ERROR: ', error.response);
      })
      .catch((error) => console.log('DEACTIVATE : ', error));
  };

  const handleClose = () => {
    setOpen(false);
    setCardPaymentOpen(false);
  };
  const handleCardPaymentClose = () => {
    setCardPaymentOpen(false);
  };
  return (
    <div className='PaymentMethodContainer'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        {cardPaymentOpen ? (
          cardPaymentConfirm(
            () => startSubscription(true),
            handleCardPaymentClose,
            simplePayImg
          )
        ) : (
          <div className='PaymentChangeContainer'>
            <p>
              <span>
                <Radio
                  checked={
                    providerSubscription.cardPayment === true ? true : false
                  }
                  id='cardPayment'
                  onChange={(event) => handleCheck(event)}
                  name='color-radio-button-demo'
                  sx={{
                    color: '#1A00BD',
                    '&.Mui-checked': {
                      color: '#1A00BD',
                    },
                  }}
                />
              </span>
              {i18n.t('provider.creditcard')}
            </p>
            <p>
              <span>
                <Radio
                  checked={
                    providerSubscription.cardPayment === false ? true : false
                  }
                  id='transfer'
                  onChange={(event) => handleCheck(event)}
                  name='color-radio-button-demo'
                  sx={{
                    color: '#1A00BD',
                    '&.Mui-checked': {
                      color: '#1A00BD',
                    },
                  }}
                />
              </span>
              {i18n.t('provider.transfer')}
            </p>
            <div className='PaymentChangeButtons'>
              {changed ? (
                <StyledButton
                  onClick={
                    providerSubscription.cardPayment === true
                      ? () => {
                          setCardPaymentOpen(true);
                        }
                      : () => {
                          props.submitToDB({
                            'provider_subscription.cardPayment':
                              providerSubscription.cardPayment,
                          });
                          setOpen(false);
                        }
                  }
                  color='Primary'
                >
                  {providerSubscription.cardPayment === true
                    ? i18n.t('provider.savepaymentmethod')
                    : i18n.t('provider.topayment')}
                </StyledButton>
              ) : null}
              <StyledButton onClick={() => deactivate()} color='Secondary'>
                {i18n.t('provider.stopcardpayment')}
              </StyledButton>
            </div>
          </div>
        )}
      </Dialog>
      <div className='PaymentDetails'>
        <h2>{i18n.t('provider.paymentmethod')}</h2>
        <div>
          <p>
            {!providerSubscription.cardPayment
              ? i18n.t('provider.transfer')
              : i18n.t('provider.creditcard')}
          </p>
        </div>
        <span onClick={() => setOpen(true)} className='ChangePaymentMethod'>
          {i18n.t('provider.changepaymentmethod')}
        </span>
      </div>
    </div>
  );
};

export default PaymentMethod;
