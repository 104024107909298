import ACTIONS from './action';
import _ from 'lodash';
import { PURGE } from 'redux-persist';
import storeInitialState from './storeInitialState';
const initialState = storeInitialState;

const providerReducers = (state = initialState, action) => {
  switch (action.type) {
    case PURGE: {
      return initialState;
    }
    /* ------------- ADD REQ INFO ------------- */
    case ACTIONS.Types.ADD_REQ_INFO: {
      let itemData = action.payload.data;
      let itemObject = action.payload.stateObject;

      return {
        ...state,
        [itemObject]: itemData,
      };
    }

    /* ------------- ADD PROVIDER INFO ------------- */
    case ACTIONS.Types.ADD_PROVIDER_INFO: {
      let itemID = action.payload.id;
      let itemData = action.payload.data;
      let itemObject = action.payload.stateObject;

      return {
        ...state,
        [itemObject]: {
          ...state[itemObject],
          [itemID]: itemData,
        },
      };
    }
    /* -------------- UPDATE ARRAY OF OBJECTS IN PROVIDER INFO ----------- */

    case ACTIONS.Types.UPDATE_PROVIDER_ARRAY_OF_OBJECTS:
      let arrOfObj = action.payload.arrOfObj;
      let updatedObj = action.payload.updatedObj;
      //console.log(action.payload, 'action.payload');
      return {
        ...state,
        [arrOfObj]: [
          ...state[arrOfObj].filter((x) => x.id !== updatedObj.id),

          updatedObj,
        ],
      };

    /* ------------- FETCH PROVIDER INFO ------------- */
    case ACTIONS.Types.FETCH_PROVIDER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.Types.FETCH_PROVIDER_DATA_SUCCESS:
      let provider = action.payload.provider;
      let providerState = [];
      for (let [key, value] of Object.entries(provider)) {
        providerState[key] = value;
      }
      var newState = {
        ...state,
        ...providerState,
        loading: false,
        providerDataFetched: true,
      };
      return newState;

    case ACTIONS.Types.FETCH_PROVIDER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    /* ------------- FETCH LIMITED PROVIDER INFO ------------- */
    case ACTIONS.Types.FETCH_LIMITED_PROVIDER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.Types.FETCH_LIMITED_PROVIDER_DATA_SUCCESS: {
      let providerLimited = action.payload.provider;
      let providerStateLimited = [];
      for (let [key, value] of Object.entries(providerLimited)) {
        providerStateLimited[key] = value;
      }
      let newState = {
        ...state,
        ...providerStateLimited,
        loading: false,
      };
      return newState;
    }

    case ACTIONS.Types.FETCH_LIMITED_PROVIDER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    /* ------------- FETCH PROVIDER REQUESTS ------------- */
    case ACTIONS.Types.FETCH_PROVIDER_REQUESTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.Types.FETCH_PROVIDER_REQUESTS_SUCCESS: {
      let request = action.payload;
      let requestState = [];
      for (let [key, value] of Object.entries(request)) {
        requestState[key] = value;
      }
      let newState = {
        ...state,
        ...requestState,
        loading: false,
      };
      return newState;
    }

    case ACTIONS.Types.FETCH_PROVIDER_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    /* ------------- ADD COMPANY INFO ------------- */
    case ACTIONS.Types.ADD_COMPANY_INFO: {
      let itemID = action.payload.id;
      let itemData = action.payload.data;
      let itemObject = action.payload.stateObject;

      return {
        ...state,
        [itemObject]: {
          ...state[itemObject],
          [itemID]: itemData,
        },
      };
    }
    /* ------------- ADD PROVIDER TO REQUEST ------------- */
    case ACTIONS.Types.ADD_PROV_TO_REQ: {
      let itemData = action.payload.data;
      return {
        ...state,
        company_store: {
          ...state['company_store'],
          selected_providers: itemData,
        },
      };
    }
    /* ------------- FETCH POTENTIAL PROVIDERS ------------- */
    case ACTIONS.Types.FETCH_POTENTIAL_PROVIDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.Types.FETCH_POTENTIAL_PROVIDER_SUCCESS: {
      /*  let matchingProviders = action.payload.potentialProviders; */
      /* console.log("PAYLOAD: ", action.payload.potentialProviders); */
      /* for (let [key, value] of Object.entries(matchingProviders)) {
        potential_providers[key] = value;
      } */
      let newState = {
        ...state,
        company_store: {
          ...state['company_store'],
          potential_providers: [...action.payload.potentialProviders],
        },
        loading: false,
      };
      return newState;
    }

    case ACTIONS.Types.FETCH_POTENTIAL_PROVIDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    /* ------------- FETCH COMPANY REQUEST DATA ------------- */
    case ACTIONS.Types.FETCH_COMPANY_REQ_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTIONS.Types.FETCH_COMPANY_REQ_SUCCESS: {
      let company_request = action.payload.requests;
      let company_requestState = [];
      for (let [key, value] of Object.entries(company_request)) {
        company_requestState[key] = value;
      }

      let newState = {
        ...state,
        request_data: { requestData: { ...company_request } },
        loading: false,
      };
      return newState;
    }

    case ACTIONS.Types.FETCH_COMPANY_REQ_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    /* ------------- OTHERS ------------- */
    case ACTIONS.Types.DELETE_ITEM: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.provider_info, {
        id: action.payload,
      });
      newState.provider_info.splice(index, 1);
      return newState;
    }

    case ACTIONS.Types.LIST_ITEM: {
      let newState = _.cloneDeep(state);
      let index = _.findIndex(newState.provider_info, {
        id: action.payload,
      });
      newState.provider_info.splice(index, 1);
      return newState;
    }

    // ----------- UPDATE NESTED OBJECT ------------------
    case ACTIONS.Types.UPDATE_NESTED_OBJECT: {
      let itemObject = action.payload.stateObject;
      let itemObjectID = action.payload.stateObjectID;
      let itemID = action.payload.id;
      let itemData = action.payload.data;

      return {
        ...state,
        [itemObject]: {
          ...state[itemObject],
          [itemObjectID]: {
            ...state[itemObject][itemObjectID],
            [itemID]: itemData,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default providerReducers;
