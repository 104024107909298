import i18n from '../../utils/i18n';

export const statusText = {
  sent: i18n.t('referral.referralstatustext1'),
  inprogress: i18n.t('referral.referralstatustext2'),
  contractsigned: i18n.t('referral.referralstatustext3'),
  bonuspaidcharity: i18n.t('referral.referralstatustext4'),
  bonuspaidself: i18n.t('referral.referralstatustext5'),
  rejected: i18n.t('referral.referralstatustext6'),
};

export const charityText = [
  {
    label: i18n.t('referral.referraltext10'),
    value: false,
    id: 'charity',
    data: 'false',
    text: i18n.t('referral.referraltext11'),
  },
  {
    label: i18n.t('referral.referraltext12'),
    value: true,
    id: 'charity',
    data: 'true',
    text: i18n.t('referral.referraltext13'),
  },
];

export const charityOutput = () => [
  { true: i18n.t('referral.refcharityopt1') },
  { false: i18n.t('referral.refcharityopt2') },
];

// export const emailInput = [
//   { label: i18n.t('referral.referraltext3'), id: 'refereemail' },
// ];
