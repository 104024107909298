import React, { useCallback } from "react";
//REDUX
import StyledButton from "../../../../shared/components/styledButton/styledButton";
import ReviewsInfo from "./components/importantInfo";
import ProviderReviews from "./components/providerReviews";
import axios from "../../../../shared/axios/axiosProxy";
import { useSelector } from "react-redux";
import "./reviews.scss";

const menuItems = [
  { label: "Miért fontos?", id: "info" },
  { label: "Értékeléseim", id: "reviews" },
  {
    label: "Megnézem az értékeléskérőt",
    url: "https://docs.google.com/forms/d/1iAj_Br4O_12pC5dUAbYVUBvi1eaWM2mkYAclUTEeI44/edit",
  },
  /*   {
    label: "Kérek értékelést",
    url: "mailto:?subject=Értékelő lap&body=https://docs.google.com/forms/d/1iAj_Br4O_12pC5dUAbYVUBvi1eaWM2mkYAclUTEeI44/edit",
  }, */
];

const Reviews = ({ ...props }) => {
  const [active, setActive] = React.useState("info");
  const [providerReviews, setProviderReviews] = React.useState(undefined);
  const [reviewScores, setReviewScores] = React.useState(undefined);
  const userData = useSelector((state) => state.userData);

  const getReviews = useCallback(() => {
    let data = JSON.stringify({
      providerID: userData.id,
    });
    axios
      .post("/api/review/id", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProviderReviews(response.data.review);
        setReviewScores(reviewScoreCalculator(response.data.review));
      })
      .catch((error) => {
        console.log("AXIOS ERROR: ", error.response);
      });
  }, [userData.id]);

  React.useEffect(() => {
    getReviews();
  }, [getReviews]);

  const reviewScoreCalculator = (reviews) => {
    let reviewNr = 0;
    let reviewScore = 0;
    let reviewScoreAvg = 0;
    let flexibilityAvg = 0;
    let professionalismAvg = 0;
    let industryexperienceAvg = 0;
    let precisionAvg = 0;
    let pricevalueAvg = 0;
    reviews.forEach((review) => {
      reviewNr += 1;
      reviewScore +=
        review.flexibility +
        review.professionalism +
        review.industryexperience +
        review.precision +
        review.pricevalue;
      flexibilityAvg += review.flexibility;
      professionalismAvg += review.professionalism;
      industryexperienceAvg += review.industryexperience;
      precisionAvg += review.precision;
      pricevalueAvg += review.pricevalue;
    });
    if (reviewScore > 0 && reviewNr > 0) {
      reviewScoreAvg = Math.round((reviewScore / (reviewNr * 5)) * 10) / 10;
      flexibilityAvg = Math.round((flexibilityAvg / reviewNr) * 10) / 10;
      professionalismAvg =
        Math.round((professionalismAvg / reviewNr) * 10) / 10;
      industryexperienceAvg =
        Math.round((industryexperienceAvg / reviewNr) * 10) / 10;
      precisionAvg = Math.round((precisionAvg / reviewNr) * 10) / 10;
      pricevalueAvg = Math.round((pricevalueAvg / reviewNr) * 10) / 10;
    }
    return {
      reviewScoreAvg: reviewScoreAvg,
      flexibility: flexibilityAvg,
      professionalism: professionalismAvg,
      industryexperience: industryexperienceAvg,
      precision: precisionAvg,
      pricevalue: pricevalueAvg,
      reviewNr: reviewNr,
    };
  };

  const setContent = (id) => {
    switch (id) {
      case "info":
        return <ReviewsInfo />;
      case "reviews":
        return (
          <ProviderReviews
            reviews={providerReviews}
            reviewScores={reviewScores}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="ReviewsContainer">
      <div className="ContentSelectorContainer">
        {menuItems.map((item) => {
          if (item.url) {
            return (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <StyledButton
                  onClick={() => setActive(item.id ? item.id : active)}
                  color={
                    item.id && active === item.id ? "Secondary" : "Primary"
                  }
                >
                  {item.label}
                </StyledButton>
              </a>
            );
          } else
            return (
              <StyledButton
                onClick={() => setActive(item.id ? item.id : active)}
                color={item.id && active === item.id ? "Secondary" : "Primary"}
              >
                {item.label}
              </StyledButton>
            );
        })}
      </div>
      {setContent(active)}
    </div>
  );
};

export default Reviews;
