import React from 'react';
import RequestList from './components/requestList';
/* import Slide from "@mui/material/Slide"; */
import Dialog from '@mui/material/Dialog';
import i18n from '../../../../utils/i18n';
//REDUX
import { useSelector } from 'react-redux';
import './requests.scss';
import RequestDialog from './components/requestDialog';

/* const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}); */

const requestSortingHelper = [
  { label: i18n.t('providerdashboard.openreqs2'), id: 'open' },
  { label: i18n.t('providerdashboard.modifiedreqs'), id: 'modified' },
  { label: i18n.t('providerdashboard.acceptedreqs'), id: 'accepted' },
  { label: i18n.t('providerdashboard.declinedreqs'), id: 'declined' },
  { label: i18n.t('providerdashboard.confirmedreqs'), id: 'confirmed' },
  { label: i18n.t('providerdashboard.rejectedreqs'), id: 'rejected' },
];

const displayRequestLists = (requests, handleSelect) => {
  let reqLists = [];
  requestSortingHelper.forEach((item, i) => {
    if (requests.reqSorted[item.id].length > 0) {
      reqLists.push(
        <RequestList
          title={item.label}
          data={requests.reqSorted[item.id]}
          status={item.id}
          handleSelect={handleSelect}
          key={i}
        />
      );
    }
  });
  return reqLists;
};
const Requests = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedReq, setSelectedReq] = React.useState({});
  const reqInfo = useSelector((state) => state.reqInfo);

  const handleSelect = (data) => {
    /*   console.log('parent data data', data); */
    setSelectedReq(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='RequestsContainer'>
      <Dialog open={open} disableEscapeKeyDown={true}>
        <RequestDialog
          data={selectedReq}
          handleClose={handleClose}
          updateRequests={props.updateRequests}
        />
      </Dialog>
      <div className='RequestListContainer'>
        {displayRequestLists(reqInfo, handleSelect)}
      </div>
    </div>
  );
};

export default Requests;
