import React, { useEffect, useCallback } from 'react';
import { Button, TextField } from '@mui/material';
import './sendReq.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import i18n from '../../../../utils/i18n';
import { gdprLabelSF } from '../../data/searchFlowText.js';
import sendreqpic from '../../../../images/sendreqpic.png';
import pp from '../../../../images/pp.png';
//REDUX
import ACTIONS from '../../../../redux/action.js';
import { useSelector, useDispatch } from 'react-redux';
import ReqSentCherry from '../reqSent/reqSentCherry.jsx';
import ContactInfo from '../contactInfo/contactInfo.jsx';

const ReqMessage = ({ data, ...props }) => {
	const [nameError, setNameError] = React.useState(false);
	const [emailError, setEmailError] = React.useState(false);
	const [phoneError, setPhoneError] = React.useState(false);
	const [gdprError, setGdprError] = React.useState(false);
	const [showReqSent, setShowReqSent] = React.useState(false);
	const [reqMsg, setReqMsg] = React.useState('');

	const dispatch = useDispatch();
	const companyStore = useSelector((state) => state.company_store);

	const validateInput = useCallback(() => {
		const validEmailRegex = RegExp(
			// eslint-disable-next-line no-useless-escape
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		);
		return validEmailRegex.test(companyStore.email);
	}, [companyStore.email]);

	useEffect(() => {
		setReqMsg(companyStore.reqMessage);
		window.scrollTo(0, 0);
		if (emailError && validateInput()) {
			setEmailError(false);
		}
		if (gdprError && companyStore.gdpr) {
			setGdprError(false);
		}
		if (phoneError && companyStore.companyPhone.length === 11) {
			setPhoneError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		companyStore.gdpr,
		validateInput,
		companyStore.companyPhone.length,
		emailError,
		gdprError,
		phoneError,
	]);

	const dataLayerUpdate = (data) => {
		window.dataLayer.push({
			event: 'sf_step_cherry_request_sent',
			event_category: 'aspflow',
			selected_provider: data,
		});
	};

	const handleChange = (event) => {
		setReqMsg(event.target.value);
	};

	// console.log(data, 'DATA IN SENDREQFAKECHERRY');

	return (
		<React.Fragment key="reqmessage">
			{showReqSent ? (
				<ReqSentCherry handleClose={props.handleClose} />
			) : (
				<div className="ReqMessagePaper">
					<div className="ReqMessageHeader">
						<img alt="send rfp" src={sendreqpic} className="sendrfp" />
						<p>{i18n.t('searchflow.reqmsgtitle')}</p>
					</div>
					<div style={{ padding: '0 16px' }}>
						<div className="ReqMessageText">
							<h2>{i18n.t('searchflow.reqmessagelabel')}</h2>
						</div>
						<div>
							<ContactInfo
								emailError={emailError}
								phoneError={phoneError}
								gdprError={gdprError}
								nameError={nameError}
							/>
						</div>
						<div>
							<p className="ReqMessageLength">
								<span>{i18n.t('searchflow.reqmsg')}:</span>{' '}
								<span>({reqMsg.length}/1500)</span>
							</p>
							<TextField
								id="outlined-multiline-static"
								multiline
								rows={4}
								value={reqMsg}
								onChange={(e) => {
									e.stopPropagation();
									handleChange(e);
								}}
								onBlur={(event) =>
									dispatch(
										ACTIONS.addCompanyInfo({
											id: 'reqMessage',
											data: event.target.value,
											stateObject: 'company_store',
										})
									)
								}
								inputProps={{ maxLength: 1500 }}
							/>
							<div style={{ marginTop: '12px' }}>
								<FormControlLabel
									key={'marketing'}
									control={
										<Checkbox
											id="marketing"
											name="checked"
											checked={companyStore.marketing}
											onChange={(event) => {
												dispatch(
													ACTIONS.addCompanyInfo({
														id: event.target.id,
														data: event.target.checked,
														stateObject: 'company_store',
													})
												);
											}}
											color="primary"
											/*    disabled={this.state.submitted} */
										/>
									}
									label={
										<span className="SFSmallText">
											{i18n.t('searchflow.newslettersubtext')}
										</span>
									}
								/>
								<FormControlLabel
									key={'gdpr'}
									control={
										<Checkbox
											id="gdpr"
											name="gdpr"
											checked={companyStore.gdpr}
											onChange={(event) => {
												dispatch(
													ACTIONS.addCompanyInfo({
														id: event.target.id,
														data: event.target.checked,
														stateObject: 'company_store',
													})
												);
											}}
											color="primary"
											required
											/*  disabled={this.state.submitted} */
										/>
									}
									label={gdprLabelSF()}
								/>
							</div>
							{props.gdprError ? (
								<span
									style={{
										color: 'red',
										fontSize: '14px',
										fontWeight: 'bold',
									}}>
									{i18n.t('searchflow.plsacceptgdpr')}
								</span>
							) : null}

							<div className="SpaceBetween MarginHorizontal">
								<Button onClick={props.handleClose}>
									<p
										style={{
											color: '#1a00bd',
											margin: 0,
											textTransform: 'none',
										}}>
										Vissza
									</p>
								</Button>
								<Button
									className="ReqMsgSend"
									onClick={() => {
										localStorage.removeItem('selectedSP');
										// console.log('REQUEST ID', companyStore.reqID);
										if (companyStore.email.length === 0 || !validateInput()) {
											setEmailError(true);
										}
										if (!companyStore.gdpr) {
											setGdprError(true);
										}
										if (
											companyStore.companyPhone.length > 0 &&
											companyStore.companyPhone.length < 11
										) {
											setPhoneError(true);
										}
										if (
											(companyStore.gdpr &&
												companyStore.email.length !== 0 &&
												companyStore.companyPhone.length === 0 &&
												validateInput()) ||
											(companyStore.gdpr &&
												companyStore.email.length !== 0 &&
												companyStore.companyPhone.length === 11 &&
												validateInput())
										) {
											dataLayerUpdate(data);
											let reqData = Object.assign({}, companyStore);
											reqData.selected_providers = [
												...companyStore.selected_providers,
												data,
											];
											dispatch(
												ACTIONS.addProviderToRequest({
													data: reqData.selected_providers,
												})
											);
											if (props.reqID?.length === 0) {
												console.log(reqData, 'REQDATA');
												console.log('reqID.length === 0');
												if (data.packageType === 'premium') {
													console.log('PREMIUM PROVIDER CREATING ASPREQ');
													dispatch(
														ACTIONS.addCompanyInfo({
															id: 'aspreqcreated',
															data: true,
															stateObject: 'company_store',
														})
													);
													props.createAspRequest(reqData);
													props.handleClose();
												} else if (data.packageType === 'advanced') {
													console.log('ADVANCED PROVIDER CREATING REQ');
													dispatch(
														ACTIONS.addCompanyInfo({
															id: 'cherryreqcreated',
															data: true,
															stateObject: 'company_store',
														})
													);
													props.createRequest(reqData);
													props.handleClose();
												}
											}
											if (props.reqID?.length > 0) {
												// console.log(props.reqID.length, 'reqID.length');
												if (
													data.packageType === 'premium' &&
													reqData.aspreqcreated === true
												) {
													if (reqData.cherryreqcreated === true) {
														props.updateRequest(reqData, companyStore.cherryID);
													}
													console.log('REQ FOUND PREMIUM PROVIDER');
													props.updateFakeCherryRequest(reqData);
													props.handleClose();
												} else if (
													data.packageType === 'premium' &&
													reqData.aspreqcreated === false
												) {
													console.log('PREMIUM PROVIDER  NO REQ FOUND');
													props.createAspRequest(reqData);
													dispatch(
														ACTIONS.addCompanyInfo({
															id: 'aspreqcreated',
															data: true,
															stateObject: 'company_store',
														})
													);
													props.handleClose();
												}
												if (
													data.packageType === 'advanced' &&
													reqData.cherryreqcreated === true
												) {
													if (reqData.aspreqcreated === true) {
														props.updateFakeCherryRequest(reqData);
													}
													console.log('REQ FOUND ADVANCED PROVIDER');
													props.updateRequest(reqData);
													props.handleClose();
												} else if (
													data.packageType === 'advanced' &&
													reqData.cherryreqcreated === false
												) {
													if (reqData.aspreqcreated === true) {
														props.updateFakeCherryRequest(reqData);
													}
													console.log('ADVANCED PROVIDER NO REQ FOUND');
													props.createRequest(reqData);
													dispatch(
														ACTIONS.addCompanyInfo({
															id: 'cherryreqcreated',
															data: true,
															stateObject: 'company_store',
														})
													);
													props.handleClose();
												}
											}
										}
									}}>
									<p
										style={{
											margin: 0,
											textTransform: 'none',
											color: '#0b0f31 !important',
										}}>
										{i18n.t('general.send')} <img src={pp} alt="send" />
									</p>
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default ReqMessage;
