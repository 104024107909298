import React from 'react';
import i18n from '../../../../../utils/i18n';
//REDUX
import { useSelector } from 'react-redux';

const SubscriptionPackage = ({ ...props }) => {
  const providerPackage = useSelector((state) => state.provider_package);
  console.log(providerPackage, 'PROVIDERPACKAGE');

  return (
    <div className='SubscriptionPackageContainer'>
      <div className='SubscriptionDetails'>
        {providerPackage.packageType === 'basic' && (
          <h2>{i18n.t('providerdashboard.entrypackage')}</h2>
        )}
        {providerPackage.packageType === 'advanced' && (
          <h2>{i18n.t('providerdashboard.marketingpackage')}</h2>
        )}
        {providerPackage.packageType === 'premium' && (
          <h2>{i18n.t('providerdashboard.guaranteedpackage')}</h2>
        )}
        <div>
          <p>
            {`${i18n.t('general.Payment')}: `}
            <span>
              {providerPackage.yearly
                ? i18n.t('general.yearly')
                : i18n.t('general.biannual')}
            </span>
          </p>
        </div>
        <p>
          {i18n.t('providerdashboard.substartdate')}
          <br /> <span>{providerPackage.startDate}</span>
        </p>
      </div>
    </div>
  );
};

export default SubscriptionPackage;
