import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
//UTIlS
import { withTranslation } from 'react-i18next';
import i18n from '../../../utils/i18n';

const useStyles = (theme) => ({
  error: {
    fontSize: '14px',
    color: 'red',
    fontWeight: 'bold',
    marginTop: '8px',
  },
});

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {
        email: '',
      },
      touched: {
        email: false,
      },
    };
    this.handleBlur = this.handleBlur.bind(this);
  }
  validateInput(event) {
    const validEmailRegex = RegExp(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const { name, value } = event.target;
    let errors = this.state.errors;
    errors.email = validEmailRegex.test(value)
      ? ''
      : i18n.t('provider.emailformatwarning');

    this.setState({ errors, [name]: value }, () => {});
  }
  handleBlur(event) {
    this.setState({
      touched: { ...this.state.touched, [event.target.name]: true },
    });
    this.validateInput(event);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <TextField
          variant='outlined'
          id={this.props.id}
          type='email'
          name={this.props.name}
          value={this.props.value}
          label={
            this.props.label ? this.props.label : i18n.t('general.emaillabel')
          }
          onChange={this.props.onChange}
          onBlur={(e) => {
            this.handleBlur(e);
            if (this.props.onBlur) {
              this.props.onBlur(e);
            }
          }}
          error={
            this.state.errors.email.length > 0
              ? true
              : false || this.props.error
          }
          disabled={this.props.disabled}
          style={this.props.style}
          placeholder={this.props.placeholder}
          InputLabelProps={this.props.InputLabelProps}
        />
        {this.state.errors.email.length > 0 ? (
          <p className={classes.error}>{this.state.errors.email}</p>
        ) : (
          ''
        )}
      </>
    );
  }
}
EmailInput.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withTranslation()(withStyles(useStyles)(EmailInput));
