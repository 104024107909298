import React from 'react';
import Radio from '@mui/material/Radio';
import wand from '../../../images/icons/wand-new.svg';
import {
  invoicesExtra,
  revenueExtra,
  vatExtra,
  accountingTaxPricing,
  auditPricing,
  foreignTradeExtra,
  liquidationPricing,
  payrollPricing,
} from '../../data/providerText';
import prices from './prices.json';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const priceCategories = [
  { label: `${i18n.t('general.cheap')}`, id: 'cheap' },
  { label: `${i18n.t('general.average')}`, id: 'standard' },
  { label: `${i18n.t('general.expensive')}`, id: 'expensive' },
];

const PricingWizard = () => {
  const [selected, setSelected] = React.useState('cheap');
  const [providerServices, companyTypeFilter, specialRestr] = useSelector(
    (state) => [
      state.provider_services,
      state.provider_business_filter,
      state.provider_special_restrictions,
    ]
  );
  const dispatch = useDispatch();

  const handleCheck = (event) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'category',
        data: event.target.id,
        stateObject: 'priceCategory',
      })
    );
    setSelected(event.target.id);
  };

  const autoFill = (id, storeObj, price) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: id,
        data: price,
        stateObject: storeObj,
      })
    );
  };
  const fillPrices = () => {
    for (let i in providerServices) {
      if (providerServices[i]) {
        switch (i) {
          case 'accounting':
            for (let item in companyTypeFilter) {
              if (companyTypeFilter[item]) {
                let id = `${[item]}price`;

                autoFill(
                  id,
                  'provider_acc_monthly_price',
                  prices[selected]['provider_acc_monthly_price'][id]
                );
              }
            }
            invoicesExtra().forEach((item) => {
              autoFill(
                item.id,
                'provider_acc_monthly_price',
                prices[selected]['provider_acc_monthly_price'][item.id]
              );
            });
            if (specialRestr.insideEU || specialRestr.outsideEU) {
              foreignTradeExtra().forEach((item) => {
                autoFill(
                  item.id,
                  'provider_acc_monthly_price',
                  prices[selected]['provider_acc_monthly_price'][item.id]
                );
              });
            }
            revenueExtra().forEach((item) => {
              // if (
              // restrictions.yearlyrevenue[0] <= item.range[1] &&
              //   restrictions.yearlyrevenue[1] >= item.range[0];
              // ) {
              autoFill(
                item.id,
                'provider_acc_monthly_price',
                prices[selected]['provider_acc_monthly_price'][item.id]
              );
              // }
            });
            vatExtra().forEach((item) => {
              autoFill(
                item.id,
                'provider_acc_monthly_price',
                prices[selected]['provider_acc_monthly_price'][item.id]
              );
            });
            break;
          case 'payroll':
            payrollPricing().forEach((item) => {
              autoFill(
                item.id,
                'provider_payroll_price',
                prices[selected]['provider_payroll_price'][item.id]
              );
            });
            break;
          case 'taxconsulting':
            accountingTaxPricing().forEach((item) => {
              autoFill(
                item.id,
                'provider_tax_consulting_price',
                prices[selected]['provider_tax_consulting_price'][item.id]
              );
            });
            break;
          case 'audit':
            auditPricing().forEach((item) => {
              autoFill(
                item.id,
                'provider_audit_price',
                prices[selected]['provider_audit_price'][item.id]
              );
            });
            break;
          case 'legalentityfounding':
            for (let item in companyTypeFilter) {
              if (
                companyTypeFilter[item] &&
                companyTypeFilter[item] !== 'citycouncilprice'
              ) {
                let id = `${[item]}price`;
                autoFill(
                  id,
                  'provider_founding_price',
                  prices[selected]['provider_founding_price'][id]
                );
              }
            }
            break;
          case 'liquidation':
            liquidationPricing().forEach((item) => {
              autoFill(
                item.id,
                'provider_liquidation_price',
                prices[selected]['provider_liquidation_price'][item.id]
              );
            });
            break;
          default:
            return;
        }
      }
    }
  };
  return (
    <div className='PricingWizardContainer'>
      <img
        src={wand}
        className='Icon32 WizardIcon'
        alt=''
        onClick={fillPrices}
      />
      <div className='PricingWizardText'>
        <h2>{i18n.t('provider.pricingwizlabel')}</h2>
        <p>
          {i18n.t('provider.pricingwiztext1')}{' '}
          <span className='Bold'>
            <img src={wand} alt='' className='Icon16 MarginVertical ' />
            {i18n.t('provider.pricingwiztext2')}
          </span>{' '}
          {i18n.t('provider.pricingwiztext3')}
        </p>
      </div>
      <div className='PricingWizardSelector'>
        <h2>{i18n.t('provider.pricingwiztext4')}</h2>
        {priceCategories.map((item, i) => {
          return (
            <p key={item + i}>
              <span>
                <Radio
                  checked={item.id === selected ? true : false}
                  id={item.id}
                  onChange={(event) => handleCheck(event)}
                  name='color-radio-button-demo'
                  sx={{
                    color: '#1A00BD',
                    '&.Mui-checked': {
                      color: '#1A00BD',
                    },
                  }}
                />
              </span>
              {item.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(PricingWizard);
