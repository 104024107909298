import * as React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      backgroundColor: 'white',
    },
    '& .MuiInputBase-root': { backgroundColor: 'white' },
  },
});

const DescriptionInput = ({ submitToDB }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerInfo = useSelector((state) => state.provider_info);

  const handleChange = (event) => {
    let value = event.target.value;

    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'description',
        data: value,
        stateObject: 'provider_info',
      })
    );
  };
  return (
    <div>
      <TextField
        className={classes.root}
        id='description'
        variant='outlined'
        type='text'
        multiline
        rows={12}
        value={providerInfo.description}
        onChange={(event) => {
          handleChange(event);
        }}
        inputProps={{ maxLength: 1500 }}
        onBlur={() => {
          submitToDB({ provider_info: providerInfo });
        }}
      />
      {/* <span className="ReqMessageLength">{`Hossz: ${providerInfo.description.length} / Max. 1500 Karakter`}</span> */}
    </div>
  );
};

export default withTranslation()(DescriptionInput);
