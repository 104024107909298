// types of action

import axios from '../shared/axios/axiosProxy';
const Types = {
  UPDATE_REG_STATUS: 'UPDATE_REG_STATUS',
  ADD_PROVIDER_INFO: 'ADD_PROVIDER_INFO',
  UPDATE_PROVIDER_ARRAY_OF_OBJECTS: 'UPDATE_PROVIDER_ARRAY_OF_OBJECTS',
  ADD_REQ_INFO: 'ADD_REQ_INFO',
  ADD_COMPANY_INFO: 'ADD_COMPANY_INFO',
  DELETE_ITEM: 'DELETE_ITEM',
  LIST_ITEM: 'LIST_ITEM',
  FETCH_PROVIDER_DATA_BEGIN: 'FETCH_PROVIDER_DATA_BEGIN',
  FETCH_PROVIDER_DATA_SUCCESS: 'FETCH_PROVIDER_DATA_SUCCESS',
  FETCH_PROVIDER_DATA_ERROR: 'FETCH_PROVIDER_DATA_ERROR',
  FETCH_PROVIDER_REQUESTS_BEGIN: 'FETCH_PROVIDER_REQUESTS_BEGIN',
  FETCH_PROVIDER_REQUESTS_SUCCESS: 'FETCH_PROVIDER_REQUESTS_SUCCESS',
  FETCH_PROVIDER_REQUESTS_ERROR: 'FETCH_PROVIDER_REQUESTS_ERROR',
  FETCH_POTENTIAL_PROVIDER_BEGIN: 'FETCH_POTENTIAL_PROVIDER_BEGIN',
  FETCH_POTENTIAL_PROVIDER_SUCCESS: 'FETCH_POTENTIAL_PROVIDER_SUCCESS',
  FETCH_POTENTIAL_PROVIDER_ERROR: 'FETCH_POTENTIAL_PROVIDER_ERROR',

  FETCH_LIMITED_PROVIDER_DATA_BEGIN: 'FETCH_PROVIDER_DATA_BEGIN',
  FETCH_LIMITED_PROVIDER_DATA_SUCCESS: 'FETCH_PROVIDER_DATA_SUCCESS',
  FETCH_LIMITED_PROVIDER_DATA_ERROR: 'FETCH_PROVIDER_DATA_ERROR',

  FETCH_COMPANY_REQ_BEGIN: 'FETCH_COMPANY_REQ_BEGIN',
  FETCH_COMPANY_REQ_SUCCESS: 'FETCH_COMPANY_REQ_SUCCESS',
  FETCH_COMPANY_REQ_ERROR: 'FETCH_COMPANY_REQ_ERROR',

  ADD_PROV_TO_REQ: 'ADD_PROV_TO_REQ',
  UPDATE_NESTED_OBJECT: 'UPDATE_NESTED_OBJECT',
};
const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const BACKEND_KEY = process.env.REACT_APP_BACKEND_KEY;

// actions
const addReqInfo = (task) => ({
  type: Types.ADD_REQ_INFO,
  payload: task,
});
const addProviderInfo = (task) => ({
  type: Types.ADD_PROVIDER_INFO,
  payload: task,
});
const updateArrayofObjProviderInfo = (task) => ({
  type: Types.UPDATE_PROVIDER_ARRAY_OF_OBJECTS,
  payload: task,
});
const addCompanyInfo = (task) => ({
  type: Types.ADD_COMPANY_INFO,
  payload: task,
});
const addProviderToRequest = (task) => ({
  type: Types.ADD_PROV_TO_REQ,
  payload: task,
});
const updateRegStatus = (task) => ({
  type: Types.UPDATE_REG_STATUS,
  payload: task,
});
const deleteItem = (id) => ({
  type: Types.DELETE_ITEM,
  payload: id,
});

const listItem = (task) => ({
  type: Types.LIST_ITEM,
  payload: task,
});

const updateNestedObject = (task) => ({
  type: Types.UPDATE_NESTED_OBJECT,
  payload: task,
});

/* ------------- REDUX THUNK FETCH PROVIDERS DATA ------------- */
const fetchProviderDataBegin = () => ({
  type: Types.FETCH_PROVIDER_DATA_BEGIN,
});

export const fetchProviderDataSuccess = (provider) => ({
  type: Types.FETCH_PROVIDER_DATA_SUCCESS,
  payload: { provider },
});

export const fetchProviderDataFailure = (error) => ({
  type: Types.FETCH_PROVIDER_DATA_ERROR,
  payload: { error },
});

export function fetchProviderData(JWT) {
  return (dispatch) => {
    dispatch(fetchProviderDataBegin());
    return axios
      .get(`${BACKEND_API}/api/provider/data`, {
        headers: {
          Authorization: `Bearer ${JWT}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(fetchProviderDataSuccess(response.data.provider));
        return response;
      })
      .catch((error) => {
        console.log('AXIOS ERROR DATA: ', error);
        dispatch(fetchProviderDataFailure(error));
      });
  };
}
/* ------------- REDUX THUNK FETCH LIMITED PROVIDERS DATA ------------- */
const fetchLimitedProviderDataBegin = () => ({
  type: Types.FETCH_LIMITED_PROVIDER_DATA_BEGIN,
});

export const fetchLimitedProviderDataSuccess = (provider) => ({
  type: Types.FETCH_LIMITED_PROVIDER_DATA_SUCCESS,
  payload: { provider },
});

export const fetchLimitedProviderDataFailure = (error) => ({
  type: Types.FETCH_LIMITED_PROVIDER_DATA_ERROR,
  payload: { error },
});

export function fetchLimitedProviderData(ids) {
  return (dispatch) => {
    dispatch(fetchLimitedProviderDataBegin());
    return axios
      .get(`${BACKEND_API}/api/provider/selected`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          ...ids,
        },
      })
      .then((response) => {
        console.log('REPONSE: ', response.data);
        dispatch(
          fetchLimitedProviderDataSuccess({
            providerList: response.data.provider,
          })
        );
        return response;
      })
      .catch((error) => {
        console.log('AXIOS ERROR DATA: ', error);
        dispatch(fetchLimitedProviderDataFailure(error));
      });
  };
}
/* ------------- REDUX THUNK FETCH PROVIDERS REQUESTS ------------- */
const fetchProviderRequestsBegin = () => ({
  type: Types.FETCH_PROVIDER_REQUESTS_BEGIN,
});

export const fetchProviderRequestsSuccess = (requests) => ({
  type: Types.FETCH_PROVIDER_REQUESTS_SUCCESS,
  payload: { requests },
});

export const fetchProviderRequestsFailure = (error) => ({
  type: Types.FETCH_PROVIDER_REQUESTS_ERROR,
  payload: { error },
});

export function fetchProviderRequests(data) {
  return (dispatch) => {
    dispatch(fetchProviderRequestsBegin());
    return axios
      .get(`${BACKEND_API}/api/request/data/${data.id}`, {
        headers: {
          Authorization: `Bearer ${data.JWT}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(fetchProviderRequestsSuccess(response.data.requests));
        return response.data.requests;
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error);
        dispatch(fetchProviderDataFailure(error));
      });
  };
}
/* ------------- REDUX THUNK FETCH POTENTIAL PROVIDERS ------------- */
const fetchPotentialProvidersBegin = () => ({
  type: Types.FETCH_POTENTIAL_PROVIDER_BEGIN,
});

export const fetchPotentialProvidersSuccess = (potentialProviders) => ({
  type: Types.FETCH_POTENTIAL_PROVIDER_SUCCESS,
  payload: { potentialProviders },
});

export const fetchPotentialProvidersFailure = (error) => ({
  type: Types.FETCH_POTENTIAL_PROVIDER_ERROR,
  payload: { error },
});

export function fetchAspPotentialProviders(companyData, matchScoreWeights) {
  console.log('PROVIDER FETCH CALLED');

  // PAY ATTENTION BECAUSE IT'S COMPLICATED
  return (dispatch) => {
    dispatch(fetchPotentialProvidersBegin());
    // FETCH POTENTIAL PROVIDERS PROPOSALS
    const company_req = {
      company_req: companyData,
      match_score_weights: matchScoreWeights,
    };

    let finalProviders = [[], [], []];
    return axios
      .post(`${BACKEND_API}/api/providerofferstop3`, company_req, {
        headers: {
          Authorization: BACKEND_KEY,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let matchingProviders = response.data.matchingProviders;
        let idList1 = [];
        let idList2 = [];
        let idList3 = [];
        // console.log(
        //   matchingProviders,
        //   'matchingProviders',
        //   matchingProviders.length,
        //   typeof matchingProviders
        // );

        for (let i = 0; i < matchingProviders[0].length; i++) {
          idList1.push(`ObjectId("${matchingProviders[0][i].providerID2}")`);
        }
        for (let i = 0; i < matchingProviders[1].length; i++) {
          idList2.push(`ObjectId("${matchingProviders[1][i].providerID2}")`);
        }
        for (let i = 0; i < matchingProviders[2].length; i++) {
          idList3.push(`ObjectId("${matchingProviders[2][i].providerID2}")`);
        }

        // FETCH EACH PROVIDER DATA
        let baseRequest1 = `{_id:{"$in":[${idList1.join(',')}]}}`;
        let baseRequest2 = `{_id:{"$in":[${idList2.join(',')}]}}`;
        let baseRequest3 = `{_id:{"$in":[${idList3.join(',')}]}}`;
        //console.log(baseRequest1, baseRequest2, baseRequest3, 'KAKUKK');
        axios
          .get(`${BACKEND_API}/api/provider`, baseRequest1, {
            'Content-Type': 'application/json',
          })
          .then((response2) => {
            let matchingProvidersInfo1 = response2.data.result;

            // CROSS CHECK PROVIDER IDS AND ASSIGN REQUIRED DATA TO finalProviders
            for (let i = 0; i < matchingProviders[0].length; i++) {
              for (let j = 0; j < matchingProvidersInfo1.length; j++) {
                if (
                  matchingProviders[0][i].providerID2 ===
                  matchingProvidersInfo1[j]._id
                ) {
                  finalProviders[0].push({
                    proposalData: matchingProviders[0][i],
                    providerData: matchingProvidersInfo1[j].provider_info,
                  });
                }
              }
            }
          });
        axios
          .get(`${BACKEND_API}/api/provider`, baseRequest2, {
            'Content-Type': 'application/json',
          })
          .then((response2) => {
            let matchingProvidersInfo2 = response2.data.result;

            // CROSS CHECK PROVIDER IDS AND ASSIGN REQUIRED DATA TO finalProviders
            for (let i = 0; i < matchingProviders[1].length; i++) {
              for (let j = 0; j < matchingProvidersInfo2.length; j++) {
                if (
                  matchingProviders[1][i].providerID2 ===
                  matchingProvidersInfo2[j]._id
                ) {
                  finalProviders[1].push({
                    proposalData: matchingProviders[1][i],
                    providerData: matchingProvidersInfo2[j].provider_info,
                  });
                }
              }
            }
          });
        axios
          .get(`${BACKEND_API}/api/provider`, baseRequest3, {
            'Content-Type': 'application/json',
          })
          .then((response2) => {
            let matchingProvidersInfo3 = response2.data.result;

            // CROSS CHECK PROVIDER IDS AND ASSIGN REQUIRED DATA TO finalProviders
            for (let i = 0; i < matchingProviders[2].length; i++) {
              for (let j = 0; j < matchingProvidersInfo3.length; j++) {
                if (
                  matchingProviders[2][i].providerID2 ===
                  matchingProvidersInfo3[j]._id
                ) {
                  finalProviders[2].push({
                    proposalData: matchingProviders[2][i],
                    providerData: matchingProvidersInfo3[j].provider_info,
                  });
                }
              }
            }
          });
        dispatch(fetchPotentialProvidersSuccess(matchingProviders));
        console.log(finalProviders, 'finalProviders');
        return finalProviders;
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error);
        dispatch(fetchPotentialProvidersFailure(error));
      });
  };
}

export function fetchCherryPotentialProviders(companyData, matchScoreWeights) {
  console.log('PROVIDER FETCH CALLED');
  // PAY ATTENTION BECAUSE IT'S COMPLICATED
  return (dispatch) => {
    dispatch(fetchPotentialProvidersBegin());
    console.log('PROVIDER FETCH BEGIN');
    // FETCH POTENTIAL PROVIDERS PROPOSALS
    const company_req = {
      company_req: companyData,
      match_score_weights: matchScoreWeights,
    };
    let finalProviders = [];
    return axios
      .post(`${BACKEND_API}/api/providerofferscherry`, company_req, {
        'Content-Type': 'application/json',
      })
      .then((response) => {
        let matchingProviders = response.data.matchingProviders;
        let idList = [];
        for (let i = 0; i < matchingProviders.length; i++) {
          idList.push(`ObjectId("${matchingProviders[i].providerID2}")`);
        }

        // FETCH EACH PROVIDER DATA
        let baseRequest = `{_id:{"$in":[${idList.join(',')}]}}`;
        axios
          .get(`${BACKEND_API}/api/provider`, baseRequest, {
            'Content-Type': 'application/json',
          })
          .then((response2) => {
            let matchingProvidersInfo = response2.data.result;
            // CROSS CHECK PROVIDER IDS AND ASSIGN REQUIRED DATA TO finalProviders
            for (let i = 0; i < matchingProviders.length; i++) {
              for (let j = 0; j < matchingProvidersInfo.length; j++) {
                if (
                  matchingProviders[i].providerID2 ===
                  matchingProvidersInfo[j]._id
                ) {
                  finalProviders.push({
                    proposalData: matchingProviders[i],
                    providerData: matchingProvidersInfo[j].provider_info,
                  });
                }
              }
            }
          });
        dispatch(fetchPotentialProvidersSuccess(matchingProviders));
        console.log('PROVIDER FETCH SUCCESS');
        return finalProviders;
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error);
        dispatch(fetchPotentialProvidersFailure(error));
      });
  };
}

/* ------------- REDUX THUNK FETCH COMPANY REQUEST DATA ------------- */
const fetchCompanyRequestBegin = () => ({
  type: Types.FETCH_COMPANY_REQ_BEGIN,
});

export const fetchCompanyRequestSuccess = (requests) => ({
  type: Types.FETCH_COMPANY_REQ_SUCCESS,
  payload: { requests },
});

export const fetchCompanyRequestFailure = (error) => ({
  type: Types.FETCH_COMPANY_REQ_ERROR,
  payload: { error },
});

export function fetchCompanyRequest(id) {
  return (dispatch) => {
    dispatch(fetchCompanyRequestBegin());
    return axios
      .get(`${BACKEND_API}/api/request/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(fetchCompanyRequestSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log('AXIOS ERROR FETCH COMPANY REQUEST: ', error);
        dispatch(fetchCompanyRequestFailure(error));
        return error.response.status;
      });
  };
}

export function fetchAspRequest(id) {
  return (dispatch) => {
    dispatch(fetchCompanyRequestBegin());
    return axios
      .get(`${BACKEND_API}/api/aspreq/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(fetchCompanyRequestSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log('AXIOS ERROR FETCH COMPANY REQUEST: ', error);
        dispatch(fetchCompanyRequestFailure(error));
      });
  };
}
/* eslint-disable */
export default {
  updateRegStatus,
  addProviderInfo,
  addReqInfo,
  addCompanyInfo,
  deleteItem,
  listItem,
  updateNestedObject,
  fetchLimitedProviderDataBegin,
  fetchLimitedProviderDataSuccess,
  fetchLimitedProviderDataFailure,
  fetchProviderDataBegin,
  fetchProviderDataSuccess,
  fetchProviderDataFailure,
  fetchPotentialProvidersBegin,
  fetchPotentialProvidersSuccess,
  fetchPotentialProvidersFailure,
  addProviderToRequest,
  fetchCompanyRequest,
  fetchAspRequest,
  fetchCompanyRequestBegin,
  fetchCompanyRequestSuccess,
  fetchCompanyRequestFailure,
  updateArrayofObjProviderInfo,
  Types,
};
