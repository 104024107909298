import React from 'react';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { experienceRating } from '../company/searchFlow/data/searchFlowText';
import SilverBadge from '../images/icons/silver-badge-new.svg';
import GoldBadge from '../images/icons/gold-badge-new.svg';
import i18n from './i18n';

export const formatDate = (date) => {
  date = new Date(date);
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let year = date.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
};

export const setExpRating = (exp) => {
  let rating = '';
  experienceRating().forEach((item) => {
    if (exp >= item.min && exp < item.max) {
      rating = item.label;
    }
  });
  return rating;
};
export const companyType = (size) => {
  let types = {
    entrepreneur: i18n.t('general.entrepreneur'),
    small_office: i18n.t('general.smalloffice'),
    office: i18n.t('general.office'),
    corporate: i18n.t('general.corporate'),
  };
  return types[size];
};

export const badgeType = (badge) => {
  let types = {
    gold: {
      img: (
        <img
          alt=''
          style={{
            width: '30px',
            height: '30px',
            margin: '0px 12px 0px 0px',
          }}
          src={GoldBadge}
        />
      ),
      text: i18n.t('provider.golddigital'),
    },
    silver: {
      img: (
        <img
          alt=''
          style={{
            width: '30px',
            height: '30px',
            margin: '0px 12px 0px 0px',
          }}
          src={SilverBadge}
        />
      ),
      text: i18n.t('provider.silverdigital'),
    },
    none: {
      img: <IndeterminateCheckBoxIcon sx={{ color: 'lightgrey' }} />,
    },
    tbd: {
      img: <IndeterminateCheckBoxIcon sx={{ color: 'lightgrey' }} />,
    },
  };

  return types[badge];
};
