import React from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import questionmark from '../../../../../images/icons/questionmark-yellow-new.svg';
import checkmark from '../../../../../images/icons/checkmark-green-new.svg';
import cross from '../../../../../images/icons/cross-red-new.svg';
import { dbTextPairs } from '../../../../../shared/data/sharedText';
import axios from '../../../../../shared/axios/axiosProxy';
import i18n from '../../../../../utils/i18n';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      padding: '4px',
      opacity: '0.7',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      fontWeight: 600,
      textAlign: 'center',
      padding: '10px 16px !important',
    },
  },
});

const reqButtonsText = [
  {
    img: cross,
    label: i18n.t('providerdashboard.declinelabel'),
    text: i18n.t('providerdashboard.declinetext'),
    status: 'declined',
  },
  {
    img: questionmark,
    label: i18n.t('providerdashboard.pricewizlabel'),
    text: i18n.t('providerdashboard.pricewiztext'),
  },
  {
    img: checkmark,
    label: i18n.t('providerdashboard.acceptlabel'),
    text: i18n.t('providerdashboard.accepttext'),
    status: 'accepted',
  },
];
const priceButtons = [
  {
    label: i18n.t('providerdashboard.pricebuttonmin'),
    id: 'pricemin',
  },
  {
    label: i18n.t('providerdashboard.pricebuttonavg'),
    id: 'priceavg',
  },
  {
    label: i18n.t('providerdashboard.pricebuttonmax'),
    id: 'pricemax',
  },
];

const RequestDialog = ({ data, handleClose, ...props }) => {
  const [price, setPrice] = React.useState('');
  const [prices, showPrices] = React.useState(false);

  React.useEffect(() => {
    setPrice(data.selected_providers[0].providerPrice);
  }, [data.selected_providers, setPrice]);

  const reqDataText = [
    { label: i18n.t('providerdashboard.reqcompmaillabel'), id: 'email' },
    {
      label: i18n.t('providerdashboard.reqtimelabel'),
      id: 'timePassed',
      value:
        data.timePassed <= 24
          ? `${data.timePassed} ${i18n.t('providerdashboard.hours')}`
          : `${Math.floor(data.timePassed / 24)} ${i18n.t(
              'providerdashboard.days'
            )}`,
    },
    { label: i18n.t('providerdashboard.companyphone'), id: 'companyphone' },
    {
      label: i18n.t('providerdashboard.yearlyrevenue'),
      id: 'yearlyincome',
      get value() {
        let label = '';
        revenueRanges.forEach((range) => {
          if (
            data.yearlyincome >= range.min &&
            data.yearlyincome <= range.max
          ) {
            label = range.label;
          }
        });
        return label;
      },
    },
    {
      label: i18n.t('providerdashboard.incominginvoices'),
      id: 'incominginvoices',
    },
    {
      label: i18n.t('providerdashboard.outgoinginvoices'),
      id: 'outgoinginvoices',
    },
    {
      label: i18n.t('general.industrylabel'),
      id: 'industry',
      get value() {
        return dbTextPairs()[data.industry];
      },
    },
    {
      label: i18n.t('general.companylabel'),
      id: 'companytype',
      get value() {
        return dbTextPairs()[data.companytype];
      },
    },
    { label: i18n.t('general.zip'), id: 'zip_code' },
    {
      label: i18n.t('providerdashboard.foreigntradelabel'),
      id: 'foreign_trade',
      get value() {
        return data.foreign_trade
          ? `${i18n.t('general.yes')} ${
              data.foreignTradeEU === 'inside'
                ? i18n.t('company.insideeu')
                : i18n.t('company.outsideeu')
            }`
          : i18n.t('general.no');
      },
    },
    {
      label: i18n.t('providerdashboard.whattheywant'),
      id: 'services',
      get value() {
        let text = '';
        data.services.forEach((service) => {
          if (service) {
            text = `${text} ${dbTextPairs()[service]}`;
          }
        });
        return text;
      },
    },
  ];

  const revenueRanges = [
    { min: 0, max: 5, label: i18n.t('general.revfeecat1') },
    { min: 6, max: 10, label: i18n.t('general.revfeecat2') },
    { min: 11, max: 50, label: i18n.t('general.revfeecat3') },
    { min: 51, max: 200, label: i18n.t('general.revfeecat4') },
    { min: 201, max: 500, label: i18n.t('general.revfeecat5') },
    { min: 501, max: 1999, label: i18n.t('general.revfeecat6') },
    { min: 2000, max: 2000, label: i18n.t('general.revfeecat7') },
  ];
  const updateReqStatus = (reqStatus) => {
    if (reqStatus === 'accepted') {
      reqStatus =
        data.selected_providers[0].providerPrice !== price
          ? 'modified'
          : 'accepted';
    }
    const JWT = localStorage.getItem('JWT');
    axios
      .patch(
        `/api/request/`,
        {
          reqID: data._id,
          providerID: data.selected_providers[0].providerID,
          companyEmail: data.email,
          reqStatus: reqStatus,
          providerName: data.selected_providers[0].providerName,
          modifiedPrice: reqStatus === 'modified' ? price : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('AXIOS valasz data: ', response);
        console.log('AXIOS RESPONSE: ', response.data.result);
        handleClose();
        props.updateRequests();
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };
  const numberWithCommas = (x) => {
    if (x || x === 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  };
  const classes = useStyles();
  return (
    <div className='ReqDialogContainer'>
      <CancelIcon
        onClick={() => {
          handleClose();
        }}
        className='ReqDialogClose'
        sx={{ color: '#2d46b9' }}
      />
      <div className='ReqDataContainer'>
        {reqDataText.map((item, i) => {
          if (item.id === 'companyphone' && data[item.id]) {
            return (
              <div className='CompanyPhoneContainer' key={i}>
                <p>{item.label}</p>
                <p>{data[item.id]}</p>
                <p style={{ flexBasis: '100%' }}>
                  {i18n.t('providerdashboard.wanttophone')}
                </p>
              </div>
            );
          } else if (item.id !== 'companyphone') {
            return (
              <React.Fragment key={i}>
                <div className='ReqDataLabel'>
                  <p>{item.label}</p>
                </div>
                <div className='ReqDataValue'>
                  <p>{item.value ? item.value : data[item.id]}</p>
                </div>
              </React.Fragment>
            );
          } else return null;
        })}
      </div>
      <div className='ReqDataPriceContainer'>
        <div>
          <p>{`${i18n.t('provider.total3')}:`}</p>
        </div>
        <div className='ProvPriceInput'>
          <TextField
            className={classes.root}
            id='providerPrice'
            variant='outlined'
            type='text'
            value={
              data?.selected_providers[0]?.modifiedPrice
                ? data.selected_providers[0].modifiedPrice
                : price
            }
            disabled={
              data.selected_providers[0].requestStatus !== 'open' ? true : false
            }
            onChange={(event) => {
              console.log('event value', event.target.value);
              setPrice(event.target.value);
            }}
            /* onBlur={() => {
              handleError(item.id);
            }} */
          />
        </div>
        <div>
          <p>{i18n.t('general.currencynet')}</p>
        </div>
      </div>
      {data.selected_providers[0].requestStatus === 'open' ? (
        <div className='ReqButtonsContainer'>
          <h2>{i18n.t('providerdashboard.wwyd')}</h2>
          <p>{i18n.t('providerdashboard.choosefrombelow')}</p>
          {prices ? (
            <div className='PriceCompare'>
              <CancelIcon
                onClick={() => {
                  showPrices(false);
                }}
                style={{ top: '-2%', right: '-2.5%' }}
                className='ReqDialogClose'
                sx={{ color: '#2d46b9' }}
              />
              {priceButtons.map((item, i) => {
                return (
                  <div key={i}>
                    <h2>{item.label}</h2>
                    <p>{`${numberWithCommas(data[item.id])} ${i18n.t(
                      'general.currency'
                    )}`}</p>
                    <span>{i18n.t('general.net')}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='ReqButtons'>
              {reqButtonsText.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      if (item.status) {
                        updateReqStatus(item.status);
                      } else showPrices(true);
                    }}
                    key={i}
                  >
                    <img src={item.img} alt='' /* className="Icon16" */ />
                    <h2>{item.label}</h2>
                    <p>{item.text}</p>
                    <p>{i18n.t('providerdashboard.chosseingthis')}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default RequestDialog;
