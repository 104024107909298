import React from 'react';
import Radio from '@mui/material/Radio';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import {
  subscriptionPackages,
  cardPaymentConfirm,
} from '../../data/providerText';
import Dialog from '@mui/material/Dialog';
import axios from '../../../shared/axios/axiosProxy';
import simplePayImg from '../../../images/simplepay_200x50.png';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';
//REDUX
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const SelectPayment = ({ activeStep, handleNext, handleBack, submitToDB }) => {
  const dispatch = useDispatch();
  const JWT = localStorage.getItem('JWT');
  const [open, setOpen] = React.useState(false);
  const [providerPackage, providerSubscription, provData] = useSelector(
    (state) => [
      state.provider_package,
      state.provider_subscription,
      state.provider_info,
    ]
  );
  const mkoestart =
    providerSubscription.partnerCode &&
    providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false;

  const numberWithCommas = (x) => {
    if (x || x === 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };
  const setPackageType = (type) => {
    switch (type) {
      case 'basic':
        return i18n.t('provider.entry');
      case 'advanced':
        return i18n.t('provider.marketing');
      case 'premium':
        return i18n.t('provider.guaranteed');
      case 'mkoestart':
        return i18n.t('provider.mkoestart');
      default:
        return '';
    }
  };
  const handleCheck = (event) => {
    let value = false;
    if (event.target.id === 'cardPayment') {
      value = true;
    }
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'cardPayment',
        data: value,
        stateObject: 'provider_subscription',
      })
    );
  };

  const openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
  };

  const startSubscription = (payment) => {
    var paymentData = {
      name: provData.companyname,
      email: provData.email,
      city: provData.city,
      address: provData.address,
      zip: provData.zip,
      phone: provData.phone,
      fee: providerPackage.packagePrice,
      packageType: providerPackage.packageType,
      packageSize: providerPackage.packageSize,
      packageYearlyPayment: providerPackage.yearly,
    };
    axios
      .post(
        '/api/provider/subscription',
        { transactionData: paymentData, cardPayment: payment },
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log('startSubscription');
        if (payment === true) {
          openInNewTab(response.data.result);
          handleNext();
        } else return;
      })
      .catch((error) => {
        console.log('startSubscription ERROR: ', error.response.data);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='PaddingBot100'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        {cardPaymentConfirm(
          () => startSubscription(true),
          submitToDB,
          handleClose,
          simplePayImg
        )}
      </Dialog>
      <div className='ProviderDataContent'>
        <Paper className='providerPaper'>
          <div className='PackageSummary'>
            <h2>{i18n.t('provider.total')}</h2>
            <div>
              <h3>{i18n.t('provider.chosenpackage')}</h3>
              <p>{setPackageType(providerPackage.packageType)}</p>
              {mkoestart || providerPackage.packageType === 'basic' ? null : (
                <>
                  <h3>{i18n.t('general.Payment')}</h3>
                  <p>
                    {providerPackage.yearly
                      ? i18n.t('general.yearly')
                      : i18n.t('general.biannual')}
                  </p>
                  <span>
                    {providerPackage.yearly
                      ? `${numberWithCommas(
                          subscriptionPackages()[providerPackage.packageSize][
                            `${providerPackage.packageType}_discountIfYearly`
                          ]
                        )} ${i18n.t('general.savingcurrency')}`
                      : ''}
                  </span>
                </>
              )}
            </div>
            <div>
              <h3>{i18n.t('provider.duepayment')}</h3>
              <p>
                {numberWithCommas(providerPackage.packagePrice)}{' '}
                {i18n.t('general.currency')}{' '}
                <span>{i18n.t('provider.duepayment')}</span>
              </p>
            </div>
          </div>
          {mkoestart || providerPackage.packageType === 'basic' ? null : (
            <div className='PaymentMethod'>
              <h2>{i18n.t('provider.paymentmethod')}</h2>
              <div className='PaymentSelector'>
                <p>
                  <span>
                    <Radio
                      checked={
                        providerSubscription.cardPayment === true ? true : false
                      }
                      id='cardPayment'
                      onChange={(event) => handleCheck(event)}
                      name='color-radio-button-demo'
                      sx={{
                        color: '#1A00BD',
                        '&.Mui-checked': {
                          color: '#1A00BD',
                        },
                      }}
                    />
                  </span>
                  {i18n.t('provider.creditcard')}
                </p>
                <p>
                  <span>
                    <Radio
                      checked={
                        providerSubscription.cardPayment === false
                          ? true
                          : false
                      }
                      id='transfer'
                      onChange={(event) => handleCheck(event)}
                      name='color-radio-button-demo'
                      sx={{
                        color: '#1A00BD',
                        '&.Mui-checked': {
                          color: '#1A00BD',
                        },
                      }}
                    />
                  </span>
                  {i18n.t('provider.transfer')}
                </p>
              </div>
            </div>
          )}
        </Paper>
      </div>
      <ProviderNavigation
        handleNext={() => {
          providerSubscription.cardPayment === true
            ? setOpen(true)
            : handleNext();
        }}
        handleBack={() => {
          handleBack();
        }}
        step={activeStep}
        buttonText={
          providerSubscription.cardPayment === true
            ? i18n.t('provider.pay')
            : i18n.t('provider.finish')
        }
      />
    </div>
  );
};

export default withTranslation()(SelectPayment);
