import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { providerSpecialRestrictions } from '../../../data/providerText';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const label = { inputProps: { 'aria-label': 'Switch demo' } };

function SpecialRestrictionInputs() {
  const [allChecked, setAllChecked] = React.useState(true);
  const dispatch = useDispatch();
  const providerRestrictions = useSelector(
    (state) => state.provider_special_restrictions
  );
  React.useEffect(() => {
    providerSpecialRestrictions().forEach((item) => {
      if (
        (item.id && !providerRestrictions[item.id]) ||
        (item.id1 && !providerRestrictions[item.id1]) ||
        (item.id2 && !providerRestrictions[item.id2])
      ) {
        setAllChecked(false);
        return;
      } else {
        setAllChecked(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = (event, item) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: item.id1,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
    dispatch(
      ACTIONS.addProviderInfo({
        id: item.id2,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
  };
  const handleChange = (event) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'provider_special_restrictions',
      })
    );
  };
  const checkAll = (event) => {
    providerSpecialRestrictions().forEach((item) => {
      if (item.id) {
        dispatch(
          ACTIONS.addProviderInfo({
            id: item.id,
            data: event.target.checked,
            stateObject: 'provider_special_restrictions',
          })
        );
      } else
        dispatch(
          ACTIONS.addProviderInfo({
            id: item.id1,
            data: event.target.checked,
            stateObject: 'provider_special_restrictions',
          })
        );
      dispatch(
        ACTIONS.addProviderInfo({
          id: item.id2,
          data: event.target.checked,
          stateObject: 'provider_special_restrictions',
        })
      );
    });
  };

  return (
    <div className='ProviderInputContainerCentered'>
      <div className='RestrictionInputAll'>
        <p style={{ color: '#2D46B9' }}>{i18n.t('provider.tickall')}</p>
        <GreenSwitch
          {...label}
          id='all'
          checked={allChecked}
          onChange={(event) => {
            setAllChecked(!allChecked);
            checkAll(event);
          }}
        />
        <span style={{ color: !allChecked ? 'grey' : '#28b446' }}>
          {!allChecked
            ? `${i18n.t('provider.idontwantit')}`
            : `${i18n.t('provider.iwantit')}`}
        </span>
      </div>
      <div className='ProviderInputContainer'>
        {providerSpecialRestrictions().map((item, i) => {
          if (item.label === 'Újonnan alapított vállalatok') {
            return (
              <div className='CenteredInput FullWidth' key={i}>
                <p>{item.label}</p>
                <GreenSwitch
                  {...label}
                  id={item.id}
                  checked={providerRestrictions[item.id]}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                {providerRestrictions[item.id] ? (
                  <span style={{ color: '#28b446' }}>
                    {i18n.t('provider.iwantit')}
                  </span>
                ) : (
                  <span style={{ color: 'grey' }}>
                    {i18n.t('provider.idontwantit')}
                  </span>
                )}
              </div>
            );
          } else
            return (
              <>
                <div className='CenteredInput FullWidth'>
                  <p>{item.label}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id}
                    checked={
                      providerRestrictions[item.id1] ||
                      providerRestrictions[item.id2]
                    }
                    onChange={(event) => {
                      handleCheck(event, item);
                    }}
                  />
                  {providerRestrictions[item.id1] ||
                  providerRestrictions[item.id2] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
                <div className='CenteredInput'>
                  <p>{item.label1}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id1}
                    checked={providerRestrictions[item.id1]}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  {providerRestrictions[item.id1] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
                <div className='CenteredInput'>
                  <p>{item.label2}</p>
                  <GreenSwitch
                    {...label}
                    id={item.id2}
                    checked={providerRestrictions[item.id2]}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  {providerRestrictions[item.id2] ? (
                    <span style={{ color: '#28b446' }}>
                      {i18n.t('provider.iwantit')}
                    </span>
                  ) : (
                    <span style={{ color: 'grey' }}>
                      {i18n.t('provider.idontwantit')}
                    </span>
                  )}
                </div>
              </>
            );
        })}
      </div>
    </div>
  );
}

export default withTranslation()(SpecialRestrictionInputs);
