import React from 'react';
import Rating from '@mui/material/Rating';
import { Avatar } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { setColor } from '../../searchFlow/data/searchFlowText';
import { setExpRating, badgeType } from '../../../utils/helperFunctions';
import NoIns from '../../../images/icons/red-cross-new.svg';
import ReqStatus from '../components/reqStatus';
// import ReqContact from '../components/reqContact';
import i18n from '../../../utils/i18n';

export const tableColumns = (reqData) => [
  // {
  //   get title() {
  //     return i18n.t('request.initiatecontact');
  //   },
  //   field: 'contactProvider',
  //   align: 'left',
  //   render: (rowData) => {
  //     return (
  //       <div>
  //         <ReqContact provData={rowData} compData={{ requestData: reqData }} />
  //       </div>
  //     );
  //   },
  // },
  {
    get title() {
      return i18n.t('request.requeststatus');
    },
    field: 'requestStatus',
    align: 'left',
    render: (rowData) => {
      return (
        <div>
          <ReqStatus
            rowData={rowData}
            updatedReqData={{
              reqID: reqData._id,
              providerID: rowData.providerID,
              providerEmail: rowData.providerEmail,
              providerName: rowData.providerName,
              providerPhone: rowData.providerPhone,
              providerPrice: Math.round(rowData.proposedContPrice / 100) * 100,
              modifiedPrice: rowData.modifiedPrice
                ? rowData.modifiedPrice.toString().replace(/\./g, '')
                : '',
              companyData: { requestData: reqData },
            }}
            updateParams={{
              reqID: reqData._id,
              providerID: rowData.providerID,
            }}
          />
        </div>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.matchslabel');
    },
    field: 'totalmatchscore',
    align: 'left',
    defaultSort: 'desc',
    customSort: (a, b) =>
      parseInt(a.totalmatchscore) - parseInt(b.totalmatchscore),
    render: (rowData) => {
      return (
        <span
          style={{
            color: setColor(parseInt(rowData.totalmatchscore)),
            fontWeight: 'bold',
          }}
        >
          {rowData.totalmatchscore}
        </span>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.providerlabel');
    },
    field: 'providerName',
    align: 'left',
    render: (rowData) => {
      return (
        <div className='PTIconText'>
          <Avatar
            src={rowData.providerImage}
            style={{
              width: '40px',
              height: '40px',
              margin: '0px 12px 0px 12px',
            }}
          />
          <p>{rowData.providerName}</p>
        </div>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.reviewavgperpiece');
    },
    field: 'providerExperience',
    align: 'left',
    render: (rowData) => {
      return (
        <p>
          <span>
            <Rating
              name='half-rating-read'
              defaultValue={
                rowData.providerReview ? parseInt(rowData.providerReview) : 0
              }
              precision={0.1}
              readOnly
              size='small'
            />
          </span>
          <br />
          {rowData.providerReview}
        </p>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.pricepermonth');
    },
    field: 'providerPrice',
    align: 'left',
    render: (rowData) => {
      return (
        <span
          style={{
            textDecoration: rowData.modifiedPrice ? 'line-through' : 'none',
          }}
        >{`${Math.round(rowData.proposedContPrice / 100) * 100} ${i18n.t(
          'general.currency'
        )}`}</span>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.modifiedpricepermonth');
    },
    field: 'modifiedPrice',
    align: 'left',
    render: (rowData) => {
      return (
        <span>{`${
          rowData.modifiedPrice
            ? `${rowData.modifiedPrice} ${i18n.t('general.currency')}`
            : ''
        }`}</span>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.distance');
    },
    field: 'providerDistance',
    align: 'left',
    render: (rowData) => {
      return (
        <p className='PTAddress'>
          {rowData.providerDistance} <br />
          <span>
            {`${rowData.providerZip} ${rowData.providerCity},`}
            <br />
            {rowData.providerAddress}
          </span>
        </p>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.experiencelabel');
    },
    field: 'providerExperience',
    align: 'left',
    render: (rowData) => {
      return <span>{setExpRating(rowData.providerExperience)}</span>;
    },
  },
  {
    get title() {
      return i18n.t('general.digitalbadge');
    },
    field: 'digitalBadge',
    align: 'left',
    customSort: (a, b) => {
      let order = {
        tbd: 0,
        none: 1,
        silver: 2,
        gold: 3,
      };
      return order[a.digitalBadge] - order[b.digitalBadge];
    },
    render: (rowData) => {
      return (
        <div className='PTIconText'>
          {rowData.digitalBadge ? (
            <>
              {badgeType(rowData.digitalBadge).img}
              <p>{badgeType(rowData.digitalBadge).text}</p>
            </>
          ) : (
            i18n.t('general.nodataavailable')
          )}
        </div>
      );
    },
  },
  {
    get title() {
      return i18n.t('general.insurrancelabel');
    },
    field: 'providerInsurance',
    align: 'left',
    render: (rowData) => {
      return rowData.providerInsurance !== 'none' ? (
        <CheckBoxIcon sx={{ color: 'green' }} />
      ) : (
        <img style={{ width: '20px', height: '20px' }} src={NoIns} alt='' />
      );
    },
  },
];
