import React, { useEffect } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import GeneralInfo from './components/generalInfo';
// import ReqStat from './components/reqStat';
import RequestInfo from './components/requestInfo';
import axios from '../../../../shared/axios/axiosProxy';
import './dashboard.scss';
import i18n from '../../../../utils/i18n';
//REDUX
import { useSelector } from 'react-redux';
// import Advertisements from './components/advertisements';
const queryString = require('query-string');

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Dashboard = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [qString] = React.useState(queryString.parse(location.search));
  /*   const [selectedReq, setSelectedReq] = React.useState({}); */
  const reqInfo = useSelector((state) => state.reqInfo);
  const findRequest = () => {
    let reqData = undefined;
    let ID = '';
    if (qString.accept) {
      ID = qString.accept;
    }
    if (qString.decline) {
      ID = qString.decline;
    }
    reqInfo.reqSorted.open.forEach((req) => {
      if (req._id === ID) {
        reqData = req;
        return;
      }
    });
    return reqData;
  };
  const updateReqStatus = (data, reqStatus) => {
    const JWT = localStorage.getItem('JWT');
    if (data) {
      axios
        .patch(
          `/api/request/`,
          {
            reqID: data._id,
            providerID: data.selected_providers[0].providerID,
            companyEmail: data.email,
            reqStatus: reqStatus,
            providerName: data.selected_providers[0].providerName,
          },
          {
            headers: {
              Authorization: `Bearer ${JWT}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('AXIOS RESPONSE: ', response.data.result);
          props.fetchRequests();
          navigate('/provider/dashboard');
        })
        .catch((error) => {
          console.log('AXIOS ERROR: ', error);
        });
    }
  };

  useEffect(() => {
    if (qString.accept && findRequest()) {
      updateReqStatus(findRequest(), 'accepted');
      setAlertMsg(i18n.t('providerdashboard.reqaccepted'));
      setOpen(true);
    } else if (qString.accept && !findRequest()) {
      setOpen(true);
      setAlertMsg(i18n.t('providerdashboard.reqnotfound'));
      navigate('/provider/dashboard', { replace: true });
    }
    if (qString.decline && findRequest()) {
      updateReqStatus(findRequest(), 'declined');
      setAlertMsg(i18n.t('providerdashboard.reqrefused'));
      setOpen(true);
    } else if (qString.decline && !findRequest()) {
      setOpen(true);
      setAlertMsg(i18n.t('providerdashboard.reqnotfound'));
      navigate('/provider/dashboard', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className='Dashboard'>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        style={{ width: '100%' }}
      >
        <Alert
          onClose={handleClose}
          severity={
            alertMsg === i18n.t('providerdashboard.reqnotfound')
              ? 'warning'
              : 'success'
          }
          className='AlertWidth'
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <div className='ProfileData'>
        <div className='GeneralInfo'>
          <GeneralInfo />
          <RequestInfo handleStepChange={props.handleStepChange} />
        </div>
        {/* <div className='ReqtStat'>{reqInfo.reqNumber ? <ReqStat /> : null}</div> */}
      </div>
      {/* <div className='Advertisements'>
        <Advertisements />
      </div> */}
    </div>
  );
};

export default Dashboard;
