// External Components
import React from 'react';
import PropTypes from 'prop-types';
//Components
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import PromiseLoadingIndicator from '../../shared/LoadingIndicator/promiseLoadingIndicator';
import { Checkmark } from 'react-checkmark';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

//MUI
import Container from '@mui/material/Container';
//STYLES
import { withStyles } from '@mui/styles';
//REDUX
import ACTIONS, { fetchProviderData } from '../../redux/action';
import { connect } from 'react-redux';
import axios from '../../shared/axios/axiosProxy';
import StyledButton from '../../shared/components/styledButton/styledButton';

const mapStateToProps = (state) => ({
  store: state,
});

const mapDispatchToProps = (dispatch) => ({
  addProviderInfo: (item) => dispatch(ACTIONS.addProviderInfo(item)),
  fetchProviderData: (JWT) => dispatch(fetchProviderData(JWT)),
});

const useStyles = (theme) => ({
  paper: {
    padding: 2 + 'em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 24 + 'em',
  },
  paper2: {
    padding: 2 + 'em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
    color: '#1e4e86',
  },
  button: {
    padding: '1em',
    width: '100%',
  },
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      email: '',
      showError: false,
      messageFromServer: '',
      showNullError: false,
      errors: {
        email: '',
        password: '',
      },
    };
    this.recoverPassword = this.recoverPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  recoverPassword = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email === '') {
      this.setState({
        showError: false,
        messageFromServer: '',
        showNullError: true,
      });
    } else {
      try {
        const response = await axios.post('/api/auth/recover', {
          email,
        });
        if (response.data.message === 'reset email sent') {
          this.setState({
            showError: false,
            messageFromServer: 'reset email sent',
            showNullError: false,
            submitted: true,
          });
        }
      } catch (error) {
        if (error.response.data === 'email not in db') {
          this.setState({
            showError: true,
            messageFromServer: i18n.t('provider.notregistered'),
            showNullError: false,
          });
        }
      }
    }
  };
  handleChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleBlur(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    const validEmailRegex = RegExp(
      //eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    /* CHECK EACH FIELD INDIVIDUALLY FOR ERRORS */
    switch (event.target.name) {
      case 'email':
        errors.email = validEmailRegex.test(value)
          ? ''
          : i18n.t('provider.emailerror');
        break;

      default:
        break;
    }
    this.setState({ errors, [name]: value }, () => {});
  }
  render() {
    const { classes } = this.props;
    return (
      <Container>
        <h1>{i18n.t('provider.recoverpw')}</h1>
        <p className='sub-title-normal'>
          {i18n.t('provider.recoverpwinstruction')}
        </p>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item>
                <Paper className={classes.paper} elevation={1}>
                  {this.state.submitted === false ? (
                    <form noValidate className={classes.form}>
                      <TextField
                        type='email'
                        name='email'
                        required
                        placeholder={i18n.t('general.emaillabel')}
                        value={this.state.email}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        error={
                          this.state.errors.email.length > 0 ? true : false
                        }
                        variant='outlined'
                      />
                      {this.state.showError ? (
                        <span className='ErrorText'>
                          {this.state.messageFromServer}
                        </span>
                      ) : (
                        ''
                      )}
                      <StyledButton
                        onClick={this.recoverPassword}
                        style={{ marginTop: '24px' }}
                        type='submit'
                        color='Primary'
                        className='  FullWidth'
                      >
                        {i18n.t('general.send')}
                      </StyledButton>
                    </form>
                  ) : (
                    <>
                      <Checkmark size={80} color='#336198' />
                    </>
                  )}
                </Paper>
              </Grid>
              <PromiseLoadingIndicator />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(useStyles)(ForgotPassword))
);
