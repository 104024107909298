import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { languagesTextProvider } from '../../../../company/searchFlow/data/searchFlowText';
import i18n from '../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const displayLanguages = (language) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <Avatar
          alt={``}
          src={language.flag}
          style={{ width: '20px', height: '20px', marginRight: '5px' }}
        />
      </div>
      <div>{language.name}</div>
    </div>
  );
};

function getStyles(name, languages, theme) {
  return {
    fontWeight:
      languages.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function LanguageSelector({ ...props }) {
  const dispatch = useDispatch();
  const providerLanguages = useSelector((state) => state.provider_languages);
  const theme = useTheme();
  const [languages, setLanguages] = React.useState([]);

  React.useEffect(() => {
    let selectedLanguages = [];
    for (let i in providerLanguages) {
      if (providerLanguages[i] === true) {
        languagesTextProvider().forEach((item) => {
          if (item.id === i) {
            selectedLanguages.push(item.name);
          }
        });
      }
    }
    setLanguages(selectedLanguages);
  }, [providerLanguages]);

  const handleChange = (event) => {
    let temp = {
      hungarian: providerLanguages.hungarian,
      english: providerLanguages.english,
      german: providerLanguages.german,
      french: providerLanguages.french,
      chinese: providerLanguages.chinese,
      russian: providerLanguages.russian,
      romanian: providerLanguages.romanian,
      spanish: providerLanguages.spanish,
    };
    languagesTextProvider().forEach((item) => {
      if (event.target.value.find((value) => value === item.name)) {
        temp[item.id] = true;
      } else {
        temp[item.id] = false;
      }
    });
    for (let i in temp) {
      dispatch(
        ACTIONS.addProviderInfo({
          id: i,
          data: temp[i],
          stateObject: 'provider_languages',
        })
      );
    }
    const {
      target: { value },
    } = event;
    setLanguages(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  return (
    <div className='FlexCenter'>
      <FormControl sx={{ m: 1, width: 400 }}>
        <InputLabel id='demo-multiple-chip-label'>
          {i18n.t('provider.langselector')}
        </InputLabel>
        <Select
          disabled={props.disabled}
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={languages}
          onChange={handleChange}
          input={
            <OutlinedInput
              id='select-multiple-chip'
              label={i18n.t('provider.langselector')}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {languagesTextProvider().map((item) => (
            <MenuItem
              key={item.id}
              value={item.name}
              style={getStyles(item.name, languages, theme)}
              id={item.id}
            >
              {displayLanguages(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
