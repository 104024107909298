import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

export default function LinearStepper({ activeStep }) {
  return (
    <MobileStepper
      variant='progress'
      steps={11}
      position='static'
      activeStep={activeStep}
      sx={{ width: '100%', flexGrow: 1 }}
    />
  );
}
