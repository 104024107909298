import React from 'react';
import Rating from '@mui/material/Rating';
import { reviewCategories } from '../../../../../company/searchFlow/data/searchFlowText';
import i18n from '../../../../../utils/i18n';

const ReviewAverage = ({ reviewScores, individual, ...props }) => {
  return (
    <>
      <div
        className={
          individual
            ? 'ProviderReviewAverageContainer Individual'
            : 'ProviderReviewAverageContainer'
        }
      >
        <h2>{i18n.t('provider.total2')}</h2>
        {reviewCategories().map((item) => {
          return (
            <>
              <div>
                <p className='MarginHorizontal'>{item.label}</p>
                <Rating
                  name='half-rating-read'
                  defaultValue={reviewScores[item.id]}
                  precision={0.1}
                  readOnly
                  size='small'
                />
              </div>
            </>
          );
        })}
        {/*           <div className="ProviderReviewScore">
            <p className="MarginHorizontal">Összesen</p>
            <div style={{ marginLeft: "auto" }}>
              <Rating
                name="half-rating-read"
                defaultValue={reviewScores.reviewScoreAvg}
                precision={0.1}
                readOnly
                size="small"
              />
            </div>
          </div> */}
      </div>
    </>
  );
};

export default ReviewAverage;
