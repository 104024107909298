import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import the two exports from the last code snippet.
import { persistor, store } from './redux/store';
import LoadingIndicator from './shared/LoadingIndicator/loadingIndicator';
//UTILS
import './utils/i18n';

import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

// ProcessingInstruction.env.REACT_APP_FRONT_END_URL.includes(
//   'https://dashboard-hu.bookkeepie.com/'
// ) &&
Sentry.init({
	dsn: 'https://ddd5e92ef714548f5faece52459cce1f@o4507472040296448.ingest.de.sentry.io/4507488448282704',
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	tracesSampleRate: 0.1,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')).render(
	<Provider store={store}>
		<PersistGate loading={<LoadingIndicator />} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
