//no-useless-escape
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PromiseLoadingIndicator from '../../shared/LoadingIndicator/promiseLoadingIndicator';
import { Checkmark } from 'react-checkmark';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { withRouter } from '../../withRouter';
//MUI
import Container from '@mui/material/Container';
//SCSS
import { withStyles } from '@mui/styles';
import { Paper } from '@mui/material';
//REDUX
import ACTIONS from '../../redux/action';
import { connect } from 'react-redux';

import axios from '../../shared/axios/axiosProxy';
import StyledButton from '../../shared/components/styledButton/styledButton';
const mapDispatchToProps = (dispatch) => ({
  addProviderInfo: (item) => dispatch(ACTIONS.addProviderInfo(item)),
  deleteItem: (id) => dispatch(ACTIONS.deleteItem(id)),
});

const mapStateToProps = (state) => ({
  provider_info: state.provider_info,
});

const useStyles = (theme) => ({
  paper: {
    email: '',
    padding: 2 + 'em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 24 + 'em',
  },
  paper2: {
    padding: 2 + 'em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1,
    color: '#1e4e86',
  },
  button: {
    padding: '1em',
    width: '100%',
  },
  error: {
    fontSize: '0.8em',
    color: '#f44336',
  },
});

class Register extends React.Component {
  constructor() {
    super();

    this.state = {
      successfull: false,
      registered: false,
      password: '',
      showError: false,
      registerError: false,
      loginError: false,
      password_confirmation: '',
      showPassword: false,
      touched: {
        password: false,
        password_confirmation: false,
      },
      errors: {
        password: '',
        password_confirmation: '',
        checked: '',
      },
      checked: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  async componentDidMount() {
    const {
      params: { JWT },
    } = this.props;
    //console.log("JWT RESET PASSWORD: ", JWT);
    try {
      const response = await axios.get('/api/auth/reset', {
        params: {
          resetPasswordToken: JWT,
        },
      });
      if (response.data.message === 'password reset link a-ok') {
        this.setState({
          email: response.data.email,
          updated: false,
          isLoading: false,
          error: false,
        });
      }
    } catch (error) {
      if (error.response.data) console.log(error.response.data);
      this.setState({
        updated: false,
        isLoading: false,
        error: true,
      });
    }
  }
  updatePassword = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const {
      params: { JWT },
    } = this.props;
    try {
      const data = { email, resetPasswordToken: JWT, password };
      const response = await axios.put('/api/auth/updatePassword', {
        data,
      });
      if (response.data.message === 'password updated') {
        this.setState({
          updated: true,
          error: false,
          successfull: true,
        });
      } else {
        this.setState({
          updated: false,
          error: true,
        });
      }
    } catch (error) {
      if (error.response.data) console.log(error.response.data);
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };
  handleBlur(event) {
    this.setState({
      touched: { ...this.state.touched, [event.target.name]: true },
    });
    this.validateInput(event);
  }
  handleChange(event) {
    let field = [event.target.name];
    if (this.state.touched[field] === true) {
      this.validateInput(event);
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  validateInput(event) {
    const validPassword = RegExp(/^(?=.*[A-Z])+(?=.*[a-z])+(?=.*[0-9])/);
    const validUppercase = RegExp(/^(?=.*[A-Z])/);
    const validLowercase = RegExp(/^(?=.*[a-z])/);
    const validNumber = RegExp(/^(?=.*[0-9])/);
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (event.target.name) {
      case 'password':
        if (validPassword.test(value)) {
          errors.password = '';
        }
        if (!validNumber.test(value)) {
          errors.password = i18n.t('provider.pwerrornumber');
        }
        if (!validUppercase.test(value)) {
          errors.password = i18n.t('provider.pwerroruppercase');
        }
        if (!validLowercase.test(value)) {
          errors.password = i18n.t('provider.pwerrorlowercase');
        }
        if (value.length < 8) {
          errors.password = i18n.t('provider.pwerrorlength');
        }
        if (this.state.password_confirmation !== value) {
          errors.password_confirmation = i18n.t('provider.pwerrornotmatching');
          break;
        }
        break;
      case 'password_confirmation':
        errors.password_confirmation =
          this.state.password !== value
            ? i18n.t('provider.pwerrornotmatching')
            : '';
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value }, () => {});
  }
  render() {
    const { classes } = this.props;
    return (
      <Container>
        <h2>{i18n.t('provider.newpassword')}</h2>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item>
                <Paper className={classes.paper} elevation={1}>
                  {this.state.successfull === false ? (
                    <form className={classes.form}>
                      {/* ---------- PASSWORD ---------- */}
                      <TextField
                        type={this.state.showPassword ? 'text' : 'password'}
                        name='password'
                        placeholder={i18n.t('general.pw')}
                        value={this.state.password}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        error={
                          this.state.errors.password.length > 0 ? true : false
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end' tabIndex='-1'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={this.handleClickShowPassword}
                                size='large'
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                      {this.state.errors.password.length > 0 ? (
                        <span className={classes.error}>
                          {' '}
                          {this.state.errors.password}
                        </span>
                      ) : (
                        ''
                      )}
                      {/* ---------- CONFIRM PASSWORD ---------- */}
                      <TextField
                        style={{ marginTop: '24px' }}
                        type='password'
                        name='password_confirmation'
                        placeholder={i18n.t('provider.pwagain')}
                        value={this.state.password_confirmation}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        error={
                          this.state.errors.password_confirmation.length > 0
                            ? true
                            : false
                        }
                        required
                      />
                      {this.state.errors.password_confirmation.length > 0 ? (
                        <span className={classes.error}>
                          {' '}
                          {this.state.errors.password_confirmation}
                        </span>
                      ) : (
                        ''
                      )}
                      <div style={{ marginTop: '24px' }}>
                        <StyledButton
                          color='Primary'
                          className='  FullWidth'
                          onClick={(e) => {
                            if (
                              this.state.errors.password.length === 0 &&
                              this.state.errors.password_confirmation.length ===
                                0
                            ) {
                              this.updatePassword(e);
                            }
                          }}
                        >
                          {i18n.t('general.nextbutton')}
                        </StyledButton>
                      </div>
                    </form>
                  ) : (
                    <Checkmark size={80} color='#336198' />
                  )}
                </Paper>
              </Grid>
              <PromiseLoadingIndicator />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withStyles(useStyles)(Register))
  )
);
