import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { tableColumns } from './reqTable/tableColumns';
import ReqTable from './reqTable/reqTable';
import LoadingIndicator from '../../shared/LoadingIndicator/loadingIndicator';
//REDUX
import ACTIONS from '../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './requestOverview.scss';

const RequestOverview = () => {
  const dispatch = useDispatch();
  const [reqData] = useSelector((state) => [state.request_data]);
  const reqID = useParams().id;

  const fetchReqData = useCallback(async () => {
    let resp = await dispatch(ACTIONS.fetchCompanyRequest(reqID));
    if (resp === 404) {
      dispatch(ACTIONS.fetchAspRequest(reqID));
    }
  }, [dispatch, reqID]);

  useEffect(() => {
    fetchReqData();
  }, [fetchReqData]);

  console.log(reqData, 'REQDATA');
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {reqData ? (
        <ReqTable
          data={
            reqData.requestData.selected_providers ||
            reqData.requestData.potential_providers ||
            []
          }
          columns={tableColumns(reqData.requestData)}
        />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default withTranslation()(RequestOverview);
