import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';

import { NumberFormatCustom } from '../../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const ExtraFeeInputs = ({ ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const accountingPrice = useSelector(
    (state) => state.provider_acc_monthly_price
  );
  const handleChange = (event, storeObj) => {
    props.setModified('accounting', true);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };
  return (
    <>
      <span className='MarginHorizontal Bold FullWidth'>{props.title}</span>
      {props.dataText.map((item, i) => {
        return (
          <div className='AdornmentInputContainer Price' key={i}>
            <TextField
              className={classes.root}
              id={item.id}
              variant='outlined'
              type='text'
              /*   placeholder={item.label} */
              value={accountingPrice[item.id]}
              label={item.label}
              onChange={(event) => {
                handleChange(event, 'provider_acc_monthly_price');
                if (
                  (event.target.value && inputError[item.id]) ||
                  (event.target.value === 0 && inputError[item.id])
                ) {
                  setInputError({
                    ...inputError,
                    [item.id]: false,
                  });
                }
              }}
              error={inputError[item.id]}
              onBlur={(event) => {
                if (!event.target.value) {
                  setInputError({ ...inputError, [item.id]: true });
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <InputAdornment position='end'>
                    <span
                      style={{
                        color: inputError[item.id] ? 'red' : '#2D46B9',
                      }}
                    >
                      {props.endAdornment}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default ExtraFeeInputs;
