import React, { useEffect, useCallback } from 'react';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import axios from '../../../shared/axios/axiosProxy';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';
import AnimatedCheckmark, { MODES } from 'react-animated-checkmark';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
//REDUX
import ACTIONS from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

const ReqStatus = ({ rowData, updatedReqData, updateParams }) => {
  const dispatch = useDispatch();
  const [loading] = useSelector((state) => [state.loading]);
  const [status, setStatus] = React.useState('');
  let reqStatus = rowData.requestStatus;

  const fetchReqData = useCallback(
    (reqID) => {
      dispatch(ACTIONS.fetchCompanyRequest(reqID));
    },
    [dispatch]
  );

  const fetchAspReqData = useCallback(
    (reqID) => {
      dispatch(ACTIONS.fetchAspRequest(reqID));
    },
    [dispatch]
  );

  const updateRequestOffer = (data) => {
    axios
      .patch(`/api/request/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
        if (response && response.statusText === 'OK') {
          fetchReqData(updatedReqData.reqID);
        }
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  const updateAspRequestOffer = (data) => {
    axios
      .patch(`/api/aspreq/${updateParams.reqID}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
        if (response && response.statusText === 'OK') {
          fetchAspReqData(updateParams.reqID);
        }
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  useEffect(() => {
    setStatus(reqStatus);
  }, [reqStatus]);

  const acceptRejectBtns = () => (
    <div>
      <StyledButton
        style={{ marginBottom: '6px' }}
        color='AcceptReqBtn'
        onClick={(e) => {
          e.stopPropagation();
          if (updatedReqData.companyData.requestData.potential_providers) {
            updateAspRequestOffer({
              ...updateParams,
              updateParams: [{ key: 'requestStatus', value: 'confirmed' }],
            });
          } else
            updateRequestOffer({
              ...updatedReqData,
              reqStatus: 'confirmed',
            });
        }}
      >
        {i18n.t('request.statusaccepted')}
      </StyledButton>
      <StyledButton
        color='RefuseReqBtn'
        onClick={(e) => {
          e.stopPropagation();
          if (updatedReqData.companyData.requestData.potential_providers) {
            updateAspRequestOffer({
              ...updateParams,
              updateParams: [{ key: 'requestStatus', value: 'rejected' }],
            });
          } else
            updateRequestOffer({
              ...updatedReqData,
              reqStatus: 'rejected',
            });
        }}
      >
        {i18n.t('general.decline')}
      </StyledButton>
    </div>
  );

  const setContent = (status) => {
    switch (status) {
      case 'open':
        return (
          <div style={{ color: 'orange' }}>{i18n.t('request.statusopen')}</div>
        );
      case 'accepted':
        return acceptRejectBtns();
      case 'modified':
        return acceptRejectBtns();
      case 'declined':
        return <div style={{ color: 'red' }}>{i18n.t('general.declined')}</div>;
      case 'rejected':
        return <div style={{ color: 'red' }}>{i18n.t('general.rejected')}</div>;
      case 'confirmed':
        return (
          <div className='FlexFullCenter'>
            <div style={{ width: '48x', height: '48px' }}>
              <AnimatedCheckmark
                mode={MODES.SUCCESS}
                collapseFactor={0.1}
                size={48}
              />
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };
  return loading ? (
    <div>
      <LoadingIndicator />
    </div>
  ) : (
    setContent(status)
  );
};

export default withTranslation()(ReqStatus);
