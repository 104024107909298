import React from 'react';
import StyledButton from '../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';
/* import helpIcon from "../../../../images/icons/help-icon-new.png"; */

const ProviderNavigation = ({ handleBack, handleNext, step, buttonText }) => {
  console.log(step, 'SZTEP');
  return (
    <>
      <div
        className={
          step > 0
            ? 'ProviderNavigationContainer'
            : 'ProviderNavigationContainer CenteredNav'
        }
        style={{
          justifyContent:
            step > 0 && window.innerWidth < 600 ? 'flex-end' : 'space-between',
        }}
      >
        {step !== 0 ? (
          <StyledButton
            className='  Medium'
            color='Transparent'
            onClick={handleBack}
          >
            {i18n.t('general.backbutton')}
          </StyledButton>
        ) : (
          <></>
        )}
        {step !== 10 ? (
          <StyledButton
            className='  Medium'
            color='Primary'
            onClick={handleNext}
          >
            {i18n.t('general.forwardbutton')}
          </StyledButton>
        ) : (
          <StyledButton
            className='  Medium'
            color='Primary'
            onClick={handleNext}
          >
            {buttonText}
          </StyledButton>
        )}
      </div>
      {/*       <div className="SFHelpContainer WS" onClick={openHelpForm}>
        <div style={{ marginBottom: "-8px" }}>
          <img src={helpIcon} alt="" />
        </div>
        <p className="SFNeedHelp">Segítségre van szükséged?</p>
      </div> */}
    </>
  );
};

export default withTranslation()(ProviderNavigation);
