import React from 'react';
import { Paper } from '@mui/material';
import Rating from '@mui/material/Rating';
import { reviewCategories } from '../../../company/searchFlow/data/searchFlowText';
import i18n from '../../../utils/i18n';

const ReviewAverage = ({ reviewScores, ...props }) => {
  return (
    <>
      <Paper className='ProvProfileContentPaper Mobile'>
        <p className='Bold'>{i18n.t('provider.total2')}</p>
        <div className='ProvProfileScoresContainer'>
          {reviewCategories().map((item) => {
            return (
              <>
                <div className='ProfileScore'>
                  <p className='MarginHorizontal'>{item.label}</p>
                  <div style={{ marginLeft: 'auto' }}>
                    <Rating
                      name='half-rating-read'
                      defaultValue={reviewScores[item.id]}
                      precision={0.1}
                      readOnly
                      size='small'
                    />
                  </div>
                </div>
              </>
            );
          })}
          <div className='ProfileScore'>
            <p className='MarginHorizontal'>{i18n.t('provider.total3')}</p>
            <div style={{ marginLeft: 'auto' }}>
              <Rating
                name='half-rating-read'
                defaultValue={reviewScores.reviewScoreAvg}
                precision={0.1}
                readOnly
                size='small'
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default ReviewAverage;
