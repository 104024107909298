import React from 'react';
import { Drawer, Dialog } from '@mui/material';
import DlpProviderProfile from '../../dlpProviderProfile/dlpProviderProfile';
import CardContentWeb from './cardContent/cardContentWeb';
import './providerCard.scss';
import infoCorner from '../../../../images/infocorner.svg';
import forwardGuy from '../../../../images/forwardguy.svg';
import times from '../../../../images/icons/times2.svg';
import StyledButton from '../../../../shared/components/styledButton/styledButton';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

const FRONT_END_URL =
	process.env.REACT_APP_FRONT_END_URL || 'https://dashboard-hu.bookkeepie.com';

const ProviderCardWeb = ({ data, ...props }) => {
	const [open, setOpen] = React.useState(false);
	const [openForward, setOpenForward] = React.useState(false);

	const dataLayerUpdate = (provider) => {
		window.dataLayer.push({
			event: 'sf_step_listing_offer_pressed',
			sourceprovider: provider,
			event_category: 'listingpage',
		});
	};

	return (
		<>
			<Drawer
				open={open}
				onClose={() => setOpen(false)}
				anchor="bottom"
				PaperProps={{
					sx: {
						width: '100vw',
						height: '100vh',
						margin: 0,
						padding: 0,
						boxSizing: 'border-box',
						display: 'flex',
						alignItems: 'center',
						'@media (max-width: 600px)': {
							width: '100vw',
							height: '100vh',
						},
					},
				}}
			>
				<DlpProviderProfile
					data={data}
					setOpen={setOpen}
					setOpenForward={setOpenForward}
				/>
			</Drawer>

			<Dialog
				open={openForward}
				onClick={() => setOpenForward(false)}
				PaperProps={{
					className: "dlpForwardDialogPaper"
				}}
			>
				<div className="dlpForwardDialog">
					<div className="dlpForwardHeader">
						<img className="dlpinfoCorner" alt="Info Corner" src={infoCorner} />
						<span
							className="dlpBold16"
							style={{ marginLeft: '12px', marginRight: 'auto' }}>
							Ajánlatkérés
						</span>
						<span
							onClick={() => setOpenForward(false)}
							className="dlpBold16"
							style={{ padding: '0 16px', cursor: 'pointer' }}>
							<img src={times} alt="closing button" />
						</span>
					</div>
					<div className="dlpForwardContent">
						<p className="dlpText14">
							Ahhoz hogy ajánlatot tudj kérni a könyvelőtől, megkell adnod a
							vállalkozásodról és igényeidről néhány fontos információt.
						</p>
						<p className="dlpText14">
							Ez maximum 5 percbe fog neked telni, viszont ezt követően pontosan
							árajánlatot fogsz kapni a könyvelőtől!
						</p>

						<img className="dlpForwardGuy" alt="Forward Guy" src={forwardGuy} />
					</div>

					<div className="dlpDividerProviderCard"></div>

					<div className="dlpBtnFooter">
						<p
							style={{ cursor: 'pointer' }}
							className="dlpText14"
							onClick={() => setOpenForward(false)}>
							Mégse
						</p>
						<StyledButton
							onClick={(e) => {
								e.stopPropagation();
								dataLayerUpdate(data.providerName);
								localStorage.setItem('selectedSP', data.providerName);
								window.location.replace(`${FRONT_END_URL}/company/request/1`);
							}}
							color={'NewReqBtn'}>
							Tovább
						</StyledButton>
					</div>
				</div>
			</Dialog>
			<div
				onClick={() => {
					setOpen(true);
				}}
				className="dlpProviderCardPaper">
				<CardContentWeb
					data={data}
					setOpenForward={setOpenForward}
				// reqID={props.reqID}
				/>{' '}
			</div>
		</>
	);
};

export default ProviderCardWeb;
