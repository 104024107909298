import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import { NumberFormatCustom } from '../../../../../shared/components/formattedInput/formattedInputs';
//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const PricingInputs = ({ dataText, storeObj, ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pricing, services] = useSelector((state) => [
    state[storeObj],
    state.provider_services,
  ]);

  const handleChange = (event, storeObj) => {
    props.setModified(true);
    props.checkReqFields(event.target.id, event.target.value);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };

  return (
    <div className='PriceInputContainer'>
      {dataText.map((item, i) => {
        if (
          !Number.isInteger(props.pricesCopy[item.id]) &&
          services[props.service]
        ) {
          if (!Number.isInteger(pricing[item.id])) {
            props.setRequiredFields(item.id);
          }
          return (
            <div className='AdornmentInputContainer Price' key={i}>
              <TextField
                className={classes.root}
                id={item.id}
                variant='outlined'
                type='text'
                /*   placeholder={item.label} */
                value={pricing[item.id]}
                label={`${props.title}${item.label}`}
                onChange={(event) => {
                  handleChange(event, storeObj);
                  if (event.target.value && inputError[item.id]) {
                    setInputError({
                      ...inputError,
                      [item.id]: false,
                    });
                  }
                }}
                error={inputError[item.id]}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setInputError({ ...inputError, [item.id]: true });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span
                        style={{
                          color: inputError[item.id] ? 'red' : '#2D46B9',
                        }}
                      >
                        {props.endAdornment}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        } else {
          props.setRequiredFieldsTrue(item.id);
          return null;
        }
      })}
    </div>
  );
};

export default PricingInputs;
