import React from 'react';
import ExperienceInput from './input/experienceInput';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import Dialog from '@mui/material/Dialog';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import MkoeIcon from '../../../images/icons/mkoe-icon-new.png';
import { useSelector } from 'react-redux';
import i18n from '../../../utils/i18n';
import { Paper } from '@mui/material';
import { withTranslation } from 'react-i18next';

const expScores = () => [
  i18n.t('provider.experience1'),
  i18n.t('provider.experience2'),
  i18n.t('provider.experience3'),
  i18n.t('provider.experience4'),
  i18n.t('provider.experience5'),
];

const ProviderExperience = ({ handleNext, handleBack, activeStep }) => {
  const [providerSubscription] = useSelector((state) => [
    state.provider_subscription,
  ]);
  const [open, setOpen] = React.useState(
    providerSubscription.partnerCode &&
      providerSubscription.partnerCode.toLowerCase() === 'mkoestart'
      ? true
      : false
  );
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='ProviderInputContainerCentered PaddingBot100'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <div className='Mkoepopup'>
          <img src={MkoeIcon} alt='' />
          <h2>{i18n.t('provider.mkoepartnerlabel')}</h2>
          <p>
            {i18n.t('provider.mkoetext1')}
            <b> {i18n.t('provider.mkoetext2')}</b>{' '}
            {i18n.t('provider.mkoetext3')}
            <b> {i18n.t('provider.mkoetext4')}</b>
            {i18n.t('provider.mkoetext5')}
            <b>{i18n.t('provider.mkoetext6')}</b>.
          </p>
          <StyledButton color='Primary' onClick={handleClose}>
            {i18n.t('general.ok')}
          </StyledButton>
        </div>
      </Dialog>
      <Paper className='providerPaper'>
        <p className='ProviderInputTitle'>{i18n.t('provider.industrylabel')}</p>
        <div className='ExpScoreBoard'>
          {expScores().map((item, i) => {
            return (
              <div>
                <h2>{i + 1}</h2>
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </Paper>
      <Paper className='providerPaper'>
        <ExperienceInput />
      </Paper>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => handleNext()}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderExperience);
