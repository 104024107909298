import React from 'react';
import '../dashboard.scss';
import i18n from '../../../../../utils/i18n';
//REDUX
import { useSelector } from 'react-redux';

const GeneralInfo = () => {
  const providerInfo = useSelector((state) => state.provider_info);
  const reqInfo = useSelector((state) => state.reqInfo);
  return (
    <div className='InfoContent'>
      <h2>{`${i18n.t('providerdashboard.goodday')} ${
        providerInfo.companyname
      }`}</h2>
      {reqInfo.open > 0 ? (
        <p>{`${i18n.t('providerdashboard.youhave')} ${reqInfo.open}${i18n.t(
          'providerdashboard.openreqs'
        )}`}</p>
      ) : null}
    </div>
  );
};

export default GeneralInfo;
