import detailsPicture from '../../images/referral2.png';
import i18n from '../../utils/i18n';
import { withTranslation } from 'react-i18next';
import StyledButton from '../../shared/components/styledButton/styledButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './referral.scss';
import { Link } from 'react-router-dom';

const ReferralDetails = () => {
  return (
    <div className='referral'>
      <div className='refTitleContainer marginTop2'>
        <h1 className='refTitle'>
          {i18n.t('referral.detailstext1')} <br />
          <span className='black'>{i18n.t('referral.detailstext2')}</span>
        </h1>
        <img src={detailsPicture} alt='' className='detailsPic' />
      </div>
      <div className='bgBlue'>
        <p className='refDetailRow'>
          <CheckCircleIcon style={{ color: '#7784F9', marginRight: '1rem' }} />
          {i18n.t('referral.detailstext3')}
        </p>
        <p className='refDetailRow'>
          <CheckCircleIcon style={{ color: '#7784F9', marginRight: '1rem' }} />
          {i18n.t('referral.detailstext4')}
        </p>
        <p className='refDetailRow'>
          <CheckCircleIcon style={{ color: '#7784F9', marginRight: '1rem' }} />
          {i18n.t('referral.detailstext5')}
        </p>
        <p className='refDetailRow'>
          <CheckCircleIcon style={{ color: '#7784F9', marginRight: '1rem' }} />
          <p className='refDetailText'>
            {i18n.t('referral.detailstext6')}
            <a
              href={
                'https://www.bookkeepie.com/hu/referral-oldal-partner-program'
              }
              target='_blank'
              rel='noreferrer'
            >
              {i18n.t('referral.detailstext7')}
            </a>
            {i18n.t('referral.detailstext8')}
          </p>
        </p>
        <p className='refDetailRow'>
          <CheckCircleIcon style={{ color: '#7784F9', marginRight: '1rem' }} />
          {i18n.t('referral.detailstext9')}
        </p>
      </div>
      <div className='refBtnCont marginTop2'>
        <a href={'https://www.bookkeepie.com/hu'}>
          <StyledButton style={{ margin: '14px 0px' }} color='Transparent'>
            {i18n.t('referral.detailstext12')}
          </StyledButton>
        </a>
        <Link to={'/company/referral'}>
          <StyledButton
            style={{ margin: '14px 0px', padding: '12px !important' }}
            color='Primary'
          >
            {i18n.t('referral.detailstext11')}
          </StyledButton>
        </Link>
      </div>
    </div>
  );
};

export default withTranslation()(ReferralDetails);
