import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../../utils/i18n';
import './providerRegForm.scss';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  Slider,
  Box,
} from '@mui/material';
import ACTIONS from '../../redux/action.js';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import StyledButton from '../../shared/components/styledButton/styledButton';
import dayjs from 'dayjs';
import blueaccountant from '../../images/BlueAccountant.png';
import { CleaveInput } from '../../shared/components/formattedInput/formattedInputs.jsx';
const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const RequestFormUpdate = () => {
  const { id } = useParams();
  const [tempFormData, setTempFormData] = useState({});
  const [industryinput, setIndustryinput] = useState('');
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPicked, setServicesPicked] = useState([]);

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handlePrevPage = () => {
    setCurrentPage(1);
  };

  const sliderMarks = [
    { value: 1, label: 'könnyű' },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5, label: 'nehéz' },
  ];

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return phoneNumber;
  };

  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const dispatch = useDispatch();
  const companyStore = useSelector((state) => state.company_store);

  const services = [
    // {
    //   value: 'legalentityfounding',
    //   label: i18n.t('providervafform.legalentityfounding'),
    // },
    { value: 'accounting', label: i18n.t('providervafform.accounting') },
    { value: 'payroll', label: i18n.t('providervafform.payroll') },
  ];

  const fetchData = (url, method, data) => {
    let fetchParams = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    return fetch(url, fetchParams);
  };

  const fetchData2 = (url, method, jwt, data) => {
    let fetchParams = {
      method: method,
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${jwt}`,
      },
    };
    data && (fetchParams.body = JSON.stringify(data));
    return fetch(url, fetchParams);
  };

  const fetchDataDetails = async () => {
    try {
      const response = await fetch(`${BACKEND_API}/api/aspreq/${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setTempFormData({
        ...data,
        companytype: data.companytype,
        industry: data.industry.toString(),
        industryinput: data.industryinput || '',
        // taxconform: data.taxconform || '',
        foreign_trade:
          data.foreign_trade === 'true' || data.foreign_trade === true
            ? true
            : data.foreign_trade === 'false' || data.foreign_trade === false
              ? false
              : '',
        foreigntradeEU: data.foreigntradeEU,
        yearlyrevenue: data.yearlyrevenue.toString(),
        incominginvoices: data.incominginvoices.toString(),
        outgoinginvoices: data.outgoinginvoices.toString(),
        vatFilter:
          data.vatFilter === 'true' || data.vatFilter === true
            ? true
            : data.vatFilter === 'false' || data.vatFilter === false
              ? false
              : '',
        vatPayment: data.vatPayment,
      });
      setServicesPicked(data.services || []);
      if (data.industry === 'otherservices') {
        setIndustryinput(data.industryinput || '');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    if (id) {
      fetchDataDetails().then(() => {
        setServicesPicked(tempFormData.services || []);
      });
    }
  }, [id]);

  useEffect(() => {
    if (tempFormData.companyPhone) {
      setTempFormData((prevFormData) => ({
        ...prevFormData,
        companyPhone: formatPhoneNumber(cleanPhoneNumber(prevFormData.companyPhone)),
      }));
    }
  }, [tempFormData.companyPhone]);

  useEffect(() => {
    const isFormValid = validateForm();
    setFormValid(isFormValid);
  }, [tempFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'noofemployees' && value < 0) {
      newValue = 0;
    }

    if (name === 'companyPhone') {
      newValue = value.replace(/\D/g, '');
      if (newValue.length <= 11) {
        setTempFormData({ ...tempFormData, [name]: formatPhoneNumber(newValue) });
      }
    }

    if (
      name === 'vatFilter' ||
      name === 'foreign_trade' ||
      name === 'wouldProviderTakeit'
    ) {
      newValue =
        value === 'true' || value === 'false' ? value === 'true' : value;
    }

    if (name === 'industry') {
      if (newValue === 'otherservices') {
        setIndustryinput('');
        setTempFormData({
          ...tempFormData,
          [name]: newValue,
          industryinput: '',
        });
      } else {
        setTempFormData({
          ...tempFormData,
          [name]: newValue,
          industryinput: '',
        });
      }
    } else if (name === 'vatFilter') {
      setTempFormData({
        ...tempFormData,
        [name]: newValue,
        vatPayment: newValue ? tempFormData.vatPayment : '',
      });
      setErrors({ ...errors, vatPayment: false });
    } else if (name === 'foreign_trade') {
      setTempFormData({
        ...tempFormData,
        [name]: newValue,
        foreigntradeEU: newValue ? tempFormData.foreigntradeEU : '',
      });
      setErrors({ ...errors, foreigntradeEU: false });
    } else {
      setTempFormData({ ...tempFormData, [name]: newValue });
    }
  };

  const handleOtherIndustryChange = (e) => {
    setIndustryinput(e.target.value);
    setTempFormData({ ...tempFormData, industryinput: e.target.value });
  };

  const handleServiceChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setServicesPicked((prevServices) => [...prevServices, value]);
      setTempFormData((prevFormData) => ({
        ...prevFormData,
        services: [...prevFormData.services, value],
      }));
    } else {
      setServicesPicked((prevServices) =>
        prevServices.filter((service) => service !== value)
      );
      setTempFormData((prevFormData) => ({
        ...prevFormData,
        services: prevFormData.services.filter((service) => service !== value),
      }));
    }
  };

  const saveData = async (isRegCompletedValue) => {
    try {
      const response = await fetch(`${BACKEND_API}/api/aspreq/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          updateParams: {
            ...tempFormData,
            companyPhone: cleanPhoneNumber(tempFormData.companyPhone),
            wouldProviderTakeit: Boolean(tempFormData.wouldProviderTakeit),
            isRegCompleted: isRegCompletedValue,
            leadtype: 'reg',
            industryinput: tempFormData.industry === 'otherservices' ? industryinput : '',
            foreign_trade: Boolean(tempFormData.foreign_trade),
            vatFilter: Boolean(tempFormData.vatFilter),
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await saveData(false);
    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form is valid');
      const updateResult = await saveData(true);
      if (updateResult) {
        await updateDueDate(id, {
          date: dayjs(Date.now()),
          todo: 'REG lezárult, emailt küldeni',
        });
        setTempFormData((prevFormData) => ({
          ...prevFormData,
          isRegCompleted: true,
        }));
        await sendMailOnDone(tempFormData);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};
    if (!tempFormData.companyctcname) newErrors.companyctcname = true;
    if (!tempFormData.email) newErrors.email = true;
    if (!tempFormData.email || !validateEmail(tempFormData.email)) { newErrors.email = true; }
    if (!tempFormData.companyPhone) newErrors.companyPhone = true;
    if (!tempFormData.reqMessage) newErrors.reqMessage = true;
    if (!tempFormData.industry) newErrors.industry = true;
    if (tempFormData.industry === 'otherservices' && !industryinput)
      newErrors.industryinput = true;
    if (!tempFormData.companytype) newErrors.companytype = true;
    // if (tempFormData.providerNotes === '') newErrors.providerNotes = true;
    if (tempFormData.taxconform === '') newErrors.taxconform = true;
    if (!tempFormData.companytaxnumber) newErrors.companytaxnumber = true;
    if (!tempFormData.companyfoundingdate) newErrors.companyfoundingdate = true;
    if (tempFormData.foreign_trade === '') newErrors.foreign_trade = true;
    if (tempFormData.foreign_trade && !tempFormData.foreigntradeEU)
      newErrors.foreigntradeEU = true;
    if (!tempFormData.yearlyrevenue) newErrors.yearlyrevenue = true;
    if (
      tempFormData.noofemployees === null ||
      tempFormData.noofemployees === undefined
    )
      newErrors.noofemployees = true;
    if (!tempFormData.incominginvoices) newErrors.incominginvoices = true;
    if (!tempFormData.outgoinginvoices) newErrors.outgoinginvoices = true;
    if (tempFormData.vatFilter === '') newErrors.vatFilter = true;
    if (tempFormData.vatFilter && !tempFormData.vatPayment)
      newErrors.vatPayment = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateDueDate = async (reqID, newTodo) => {
    await fetchData2(
      `${BACKEND_API}/api/aspreq/duedate/${reqID}`,
      'PATCH',
      localStorage.getItem('JWT'),
      {
        newTodo,
      }
    )
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const sendMailOnDone = async (data) => {
    await fetchData(`${BACKEND_API}/api/aspreq/regmail/new`, 'POST', data)
      .then((response) => {
        let resp = response.json();

        console.log(resp, 'response');
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <>
      <div className='regFormContainer'>
        <div className='regForm'>
          {/* FIRST PAGE */}
          {currentPage === 1 ? (
            <form>
              {saveSuccess && (
                <div className='regFormOverlay'>
                  <Alert severity='success' className='regFormOverlayMessage'>
                    {i18n.t('providervafform.savesuccess')}
                  </Alert>
                </div>
              )}
              <div className='regFormContentHeader'>
                <div className='regTitle'>
                  {i18n.t("providervafform.regformtitle")}
                </div>
                <div>
                  <img
                    className='regFormHeaderImg'
                    alt='header'
                    src={blueaccountant}
                  />
                </div>
              </div>

              <div className='regFormContent'>
                {/* ÜGYFÉL ADATOK */}
                <div className='regFormMainLabel'>{i18n.t('providervafform.clientdata')}</div>

                <label htmlFor='companyctcname' className='regLabel'>
                  {i18n.t('providervafform.companyctcname')}
                </label>
                <TextField
                  id='companyctcname'
                  type='text'
                  name='companyctcname'
                  value={tempFormData.companyctcname}
                  onChange={handleChange}
                  className='regTextField'
                  variant='outlined'
                  error={errors.companyctcname}
                  disabled={tempFormData.isRegCompleted}
                />
                {errors.companyctcname && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='email' className='regLabel'>
                  {i18n.t('providervafform.email')}
                </label>
                <TextField
                  id='email'
                  type='email'
                  name='email'
                  value={tempFormData.email}
                  onChange={handleChange}
                  className='regTextField'
                  variant='outlined'
                  error={errors.email}
                  disabled={tempFormData.isRegCompleted}
                />
                {errors.email && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfieldemail')}
                  </Alert>
                )}

                <label htmlFor='companyPhone' className='regLabel'>
                  {i18n.t('providervafform.companyphone')}
                </label>
                <TextField
                  type='tel'
                  name='companyPhone'
                  value={tempFormData.companyPhone}
                  onChange={handleChange}
                  className='regTextField'
                  variant='outlined'
                  error={errors.companyPhone}
                  disabled={tempFormData.isRegCompleted}
                  inputProps={{ maxLength: 11 }}
                />
                {errors.companyPhone && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='reqMessage' className='regLabel'>
                  {i18n.t('providervafform.reqmessage')}
                </label>
                <TextField
                  id='reqMessage'
                  name='reqMessage'
                  type='text'
                  className='regTextArea'
                  variant='outlined'
                  value={tempFormData.reqMessage}
                  onChange={handleChange}
                  multiline
                  minRows={1}
                  error={errors.reqMessage}
                  disabled={tempFormData.isRegCompleted}
                />
                {errors.reqMessage && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='taxconform' className='regLabel'>
                  {i18n.t('providervafform.taxconform')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.taxconform}
                  disabled={tempFormData.isRegCompleted}
                >
                  <Select
                    id='taxconform'
                    name='taxconform'
                    className='regSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.taxconform || ''}
                  >
                    <MenuItem value={'phone'}>
                      {i18n.t('providervafform.taxconformphone')}
                    </MenuItem>
                    <MenuItem value={'online'}>
                      {i18n.t('providervafform.taxconformonline')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.taxconform && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <Divider sx={{ marginTop: 2, borderColor: '#c4c4c4' }} />

                {/* EV ADATOK (AMIT MOST ALAPÍT) */}
                <div className='regFormMainLabel'>{i18n.t('providervafform.soleproprietorshipdata')}</div>
                <div style={{ fontSize: '0.85rem' }}>{i18n.t('providervafform.soleproprietorshipdatainfo')}</div>

                <label htmlFor='companytype' className='regLabel'>
                  {i18n.t('providervafform.companytype')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.companytype}
                  disabled={tempFormData.isRegCompleted}
                >
                  <Select
                    id='companytype'
                    type='text'
                    name='companytype'
                    className='regSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.companytype || ''}
                  >
                    <MenuItem value={'singleprimary'}>
                      {i18n.t('providervafform.singleprimary')}
                    </MenuItem>
                    <MenuItem value={'kiva'}>
                      {i18n.t('providervafform.kivaatalany')}
                    </MenuItem>
                    <MenuItem value={'singlesecondary'}>
                      {i18n.t('providervafform.singlesecondary')}
                    </MenuItem>
                    <MenuItem value={'vszja'}>
                      {i18n.t('providervafform.vszja')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.companytype && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='industry' className='regLabel'>
                  {i18n.t('providervafform.industry')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.industry}
                  disabled={tempFormData.isRegCompleted}
                >
                  <Select
                    id='industry'
                    name='industry'
                    className='regSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={tempFormData.industry || ''}
                  >
                    <MenuItem value={'IT'}>
                      {i18n.t('providervafform.it')}
                    </MenuItem>
                    <MenuItem value={'construction'}>
                      {i18n.t('providervafform.construction')}
                    </MenuItem>
                    <MenuItem value={'etrade'}>
                      {i18n.t('providervafform.etrade')}
                    </MenuItem>
                    <MenuItem value={'trade'}>
                      {i18n.t('providervafform.trade')}
                    </MenuItem>
                    <MenuItem value={'art'}>
                      {i18n.t('providervafform.art')}
                    </MenuItem>
                    <MenuItem value={'education'}>
                      {i18n.t('providervafform.education')}
                    </MenuItem>
                    <MenuItem value={'medicine'}>
                      {i18n.t('providervafform.medicine')}
                    </MenuItem>
                    <MenuItem value={'vehicle'}>
                      {i18n.t('providervafform.vehicle')}
                    </MenuItem>
                    <MenuItem value={'manufacturing'}>
                      {i18n.t('providervafform.manufacturing')}
                    </MenuItem>
                    <MenuItem value={'realestate'}>
                      {i18n.t('providervafform.realestate')}
                    </MenuItem>
                    <MenuItem value={'agriculture'}>
                      {i18n.t('providervafform.agriculture')}
                    </MenuItem>
                    <MenuItem value={'finance'}>
                      {i18n.t('providervafform.finance')}
                    </MenuItem>
                    <MenuItem value={'travel'}>
                      {i18n.t('providervafform.travel')}
                    </MenuItem>
                    <MenuItem value={'startup'}>
                      {i18n.t('providervafform.startup')}
                    </MenuItem>
                    <MenuItem value={'logistics'}>
                      {i18n.t('providervafform.logistics')}
                    </MenuItem>
                    <MenuItem value={'courier'}>
                      {i18n.t('providervafform.courier')}
                    </MenuItem>
                    <MenuItem value={'beauty'}>
                      {i18n.t('providervafform.beauty')}
                    </MenuItem>
                    <MenuItem value={'otherservices'}>
                      {i18n.t('providervafform.otherservices')}
                    </MenuItem>
                    <MenuItem value={'lawyer'}>
                      {i18n.t('providervafform.lawyer')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.industry && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.industry === 'otherservices' && (
                  <>
                    <label htmlFor='industryinput' className='regLabel'>
                      {i18n.t('providervafform.industryinput')}
                    </label>
                    <TextField
                      id='industryinput'
                      name='industryinput'
                      type='text'
                      value={industryinput}
                      onChange={handleOtherIndustryChange}
                      multiline
                      minRows={1}
                      className='regTextArea'
                      variant='outlined'
                      error={errors.industryinput}
                      disabled={tempFormData.isRegCompleted}
                    />
                    {errors.industryinput && (
                      <Alert severity='warning'>
                        {i18n.t('providervafform.warningfield')}
                      </Alert>
                    )}
                  </>
                )}

                <label htmlFor='foreign_trade' className='regLabel'>
                  {i18n.t('providervafform.foreigntrade')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.foreign_trade}
                  disabled={tempFormData.isRegCompleted}
                >
                  <Select
                    type='text'
                    id='foreign_trade'
                    name='foreign_trade'
                    className='regSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    value={
                      tempFormData.foreign_trade !== undefined
                        ? tempFormData.foreign_trade.toString()
                        : ''
                    }
                  >
                    <MenuItem value={true}>
                      {i18n.t('providervafform.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {i18n.t('providervafform.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.foreign_trade && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.foreign_trade !== null &&
                  tempFormData.foreign_trade !== undefined &&
                  tempFormData.foreign_trade === true ? (
                  <>
                    <label htmlFor='foreigntradeEU' className='regLabel'>
                      {i18n.t('providervafform.foreigntradeeu')}
                    </label>
                    <FormControl
                      variant='outlined'
                      error={errors.foreigntradeEU}
                      disabled={tempFormData.isRegCompleted}
                    >
                      <Select
                        type='text'
                        id='foreigntradeEU'
                        name='foreigntradeEU'
                        className='regSelectMenu'
                        variant='outlined'
                        onChange={handleChange}
                        value={tempFormData.foreigntradeEU || ''}
                      >
                        <MenuItem value={'inside'}>
                          {i18n.t('providervafform.inside')}
                        </MenuItem>
                        <MenuItem value={'outside'}>
                          {i18n.t('providervafform.outside')}
                        </MenuItem>
                        <MenuItem value={'both'}>
                          {i18n.t('providervafform.both')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {errors.foreigntradeEU && (
                      <Alert severity='warning'>
                        {i18n.t('providervafform.warningoptions')}
                      </Alert>
                    )}
                  </>
                ) : null}

                <label htmlFor='noofemployees' className='regLabel'>
                  {i18n.t('providervafform.noofemployees')}
                </label>
                <TextField
                  id='noofemployees'
                  name='noofemployees'
                  className='regTextField'
                  variant='outlined'
                  type='number'
                  onChange={handleChange}
                  value={tempFormData.noofemployees}
                  error={errors.noofemployees}
                  InputProps={{
                    inputProps: { min: 0 },
                    onWheel: (e) => e.target.blur(),
                  }}
                  disabled={tempFormData.isVafCompleted}
                />
                {errors.noofemployees && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='yearlyrevenue' className='regLabel'>
                  {i18n.t('providervafform.yearlyrevenue')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.yearlyrevenue}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='yearlyrevenue'
                    type='text'
                    name='yearlyrevenue'
                    className='regSelectMenu'
                    value={tempFormData.yearlyrevenue || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={5}>
                      {i18n.t('providervafform.0-5million')}
                    </MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.5-12million')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.12-50million')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.50-100million')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.100-200million')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.200-500million')}
                    </MenuItem>
                    <MenuItem value={1999}>
                      {i18n.t('providervafform.500-2000million')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.2000+million')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.yearlyrevenue && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='incominginvoices' className='regLabel'>
                  {i18n.t('providervafform.incominginvoices')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.incominginvoices}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='incominginvoices'
                    type='text'
                    name='incominginvoices'
                    className='regSelectMenu'
                    variant='outlined'
                    value={tempFormData.incominginvoices || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{i18n.t('providervafform.0')}</MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.between1-10')}
                    </MenuItem>
                    <MenuItem value={20}>
                      {i18n.t('providervafform.between11-20')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.between21-50')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.between51-100')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.between101-200')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.between201-500')}
                    </MenuItem>
                    <MenuItem value={1000}>
                      {i18n.t('providervafform.between501-1000')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.above1000')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.incominginvoices && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='outgoinginvoices' className='regLabel'>
                  {i18n.t('providervafform.outgoinginvoices')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.outgoinginvoices}
                  disabled={tempFormData.isVafCompleted}
                >
                  <Select
                    id='outgoinginvoices'
                    type='text'
                    name='outgoinginvoices'
                    className='regSelectMenu'
                    variant='outlined'
                    value={tempFormData.outgoinginvoices || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{i18n.t('providervafform.0')}</MenuItem>
                    <MenuItem value={10}>
                      {i18n.t('providervafform.between1-10')}
                    </MenuItem>
                    <MenuItem value={20}>
                      {i18n.t('providervafform.between11-20')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {i18n.t('providervafform.between21-50')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {i18n.t('providervafform.between51-100')}
                    </MenuItem>
                    <MenuItem value={200}>
                      {i18n.t('providervafform.between101-200')}
                    </MenuItem>
                    <MenuItem value={500}>
                      {i18n.t('providervafform.between201-500')}
                    </MenuItem>
                    <MenuItem value={1000}>
                      {i18n.t('providervafform.between501-1000')}
                    </MenuItem>
                    <MenuItem value={2000}>
                      {i18n.t('providervafform.above1000')}
                    </MenuItem>
                    {/* <MenuItem value={0}>
                      {i18n.t('providervafform.hasnoidea')}
                    </MenuItem> */}
                  </Select>
                </FormControl>
                {errors.outgoinginvoices && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                <label htmlFor='vatFilter' className='regLabel'>
                  {i18n.t('providervafform.vatfilter')}
                </label>
                <FormControl
                  variant='outlined'
                  error={errors.regFilter}
                  disabled={tempFormData.isRegCompleted}
                >
                  <Select
                    type='text'
                    id='vatFilter'
                    name='vatFilter'
                    className='regSelectMenu'
                    variant='outlined'
                    onChange={handleChange}
                    // value={(() => {
                    //   if (tempFormData.vatFilter === true) {
                    //     return 'true';
                    //   } else if (tempFormData.vatFilter === false) {
                    //     return 'false';
                    //   } else {
                    //     return '';
                    //   }
                    // })()}
                    value={
                      tempFormData.vatFilter !== undefined
                        ? tempFormData.vatFilter.toString()
                        : ''
                    }
                  >
                    <MenuItem value={true}>
                      {i18n.t('providervafform.yes')}
                    </MenuItem>
                    <MenuItem value={false}>
                      {i18n.t('providervafform.no')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {errors.vatFilter && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningoptions')}
                  </Alert>
                )}

                {tempFormData.vatFilter &&
                  tempFormData.vatFilter !== 'false' && (
                    <>
                      <label htmlFor='vatPayment' className='regLabel'>
                        {i18n.t('providervafform.vatpayment')}
                      </label>
                      <FormControl
                        variant='outlined'
                        error={errors.vatPayment}
                        disabled={tempFormData.isVafCompleted}
                      >
                        <Select
                          type='text'
                          id='vatPayment'
                          name='vatPayment'
                          className='regSelectMenu'
                          variant='outlined'
                          onChange={handleChange}
                          value={tempFormData.vatPayment || ''}
                          error={errors.vatPayment}
                        >
                          <MenuItem value={'monthly'}>
                            {i18n.t('providervafform.monthly')}
                          </MenuItem>
                          <MenuItem value={'quarterly'}>
                            {i18n.t('providervafform.quarterly')}
                          </MenuItem>
                          <MenuItem value={'yearly'}>
                            {i18n.t('providervafform.yearly')}
                          </MenuItem>
                          <MenuItem value={'idk'}>
                            {i18n.t('providervafform.idk')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {errors.vatPayment && (
                        <Alert severity='warning'>
                          {i18n.t('providervafform.warningoptions')}
                        </Alert>
                      )}
                    </>
                  )}

                <Divider sx={{ marginTop: 2, borderColor: '#c4c4c4' }} />

                {/* KONZULTÁCIÓ SORÁN SZÜKSÉGES KITÖLTENI KÖNYVELŐ ÁLTAL */}
                <div className='regFormMainLabel'>{i18n.t('providervafform.providermustfill')}</div>
                <div style={{ fontSize: '0.85rem' }}>{i18n.t('providervafform.providermustfillinfo')}</div>

                <label htmlFor='companytaxnumber' className='regLabel'>
                  {i18n.t('general.taxnumber')}
                </label>
                <TextField
                  value={tempFormData.companytaxnumber}
                  id='companytaxnumber'
                  name='companytaxnumber'
                  placeholder='12345678-9-10'
                  onChange={(event) => {
                    setTempFormData({
                      ...tempFormData,
                      companytaxnumber: event.target.value
                    });
                  }}
                  onBlur={(event) => {
                    dispatch(
                      ACTIONS.addCompanyInfo({
                        id: event.target.id,
                        data: event.target.value,
                        stateObject: 'company_store',
                      })
                    );
                  }}
                  InputProps={{ inputComponent: CleaveInput }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {errors.companytaxnumber && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}

                <label htmlFor='companyfoundingdate' className='regLabel'>
                  {i18n.t('providervafform.companyfoundingdate')}
                </label>
                <TextField
                  id='companyfoundingdate'
                  name='companyfoundingdate'
                  type='date'
                  value={tempFormData.companyfoundingdate}
                  onChange={(event) => {
                    setTempFormData({
                      ...tempFormData,
                      companyfoundingdate: event.target.value
                    });
                  }}
                  className='regTextField'
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={tempFormData.isRegCompleted}
                />
                {errors.companyfoundingdate && (
                  <Alert severity='warning'>
                    {i18n.t('providervafform.warningfield')}
                  </Alert>
                )}
              </div>

              <div className='regNoteContainer'>
                <label htmlFor='providerNotes' className='regLabel' style={{ marginBottom: 8 }}>
                  {i18n.t('providervafform.notesarea')}
                </label>
                <TextField
                  id='providerNotes'
                  name='providerNotes'
                  type='text'
                  placeholder={i18n.t('providervafform.providernotes')}
                  className='regTextArea'
                  variant='outlined'
                  value={tempFormData.providerNotes}
                  onChange={handleChange}
                  multiline
                  minRows={3}
                  disabled={tempFormData.isRegCompleted}
                />
              </div>

              <div className='regButtonContainer'>
                {tempFormData.isRegCompleted ? (
                  <div className='regFormClosed'>
                    {i18n.t('providervafform.vafformclosed')}
                  </div>
                ) : (
                  <>
                    <StyledButton
                      color='NewNavBack'
                      className='regBtnMaxWidth'
                      onClick={(e) => handleSave(e)}
                      isDisabled={tempFormData.isRegCompleted}
                    >
                      {i18n.t('providervafform.save')}
                    </StyledButton>

                    <StyledButton
                      color='NewReqBtn'
                      className='regFormBtnMaxWidth'
                      onClick={handleNextPage}
                      isDisabled={tempFormData.isRegCompleted || !formValid}
                    >
                      {i18n.t('general.forwardbutton')}
                    </StyledButton>
                  </>
                )}
              </div>
            </form>
          ) : (
            // SECOND PAGE
            <>
              <form>
                <div className='regFormContentHeader'>
                  <div className='regTitle'>
                    {i18n.t("providervafform.regformtitle")}
                  </div>
                  <div>
                    <img
                      className='regFormHeaderImg'
                      alt='header'
                      src={blueaccountant}
                    />
                  </div>
                </div>

                <div className='regFormContent'>
                  <label className='regLabel'>
                    {i18n.t('providervafform.regformservices')}
                  </label>
                  <FormControl
                    component='fieldset'
                    className='regCheckboxContainer'
                    variant='outlined'
                    error={errors.services}
                    disabled={tempFormData.isRegCompleted}
                  >
                    <FormGroup>
                      {services.map((service) => (
                        <FormControlLabel
                          key={service.value}
                          label={service.label}
                          labelPlacement='start'
                          control={
                            <Checkbox
                              checked={servicesPicked.indexOf(service.value) > -1}
                              onChange={handleServiceChange}
                              value={service.value}
                              classes={{ root: 'vafCheckbox' }}
                            />
                          }
                          classes={{
                            root:
                              servicesPicked.indexOf(service.value) > -1
                                ? 'regFormControlLabelChecked'
                                : 'regFormControlLabel',
                          }}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>

                  <label htmlFor='providerHowWasIt' className='regLabel'>
                    {i18n.t('providervafform.providerhowwasit')}
                  </label>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box style={{ width: '250px' }}>
                      <Slider
                        aria-label='Temperature'
                        defaultValue={parseInt(tempFormData.providerHowWasIt)}
                        getAriaValueText={(value) => `${value}`}
                        valueLabelDisplay='auto'
                        step={1}
                        marks={sliderMarks}
                        onChange={(e) => {
                          setTempFormData((prevFormData) => ({
                            ...prevFormData,
                            providerHowWasIt: e.target.value,
                          }));
                        }}
                        min={1}
                        max={5}
                        id='providerHowWasIt'
                        name='providerHowWasIt'
                        disabled={tempFormData.isRegCompleted}
                      />
                    </Box>
                  </div>

                  <label htmlFor='wouldProviderTakeit' className='regLabel'>
                    {i18n.t('providervafform.wouldprovidertakeit')}
                  </label>
                  <FormControl
                    variant='outlined'
                    error={errors.wouldProviderTakeit}
                    disabled={tempFormData.isRegCompleted}
                  >
                    <Select
                      id='wouldProviderTakeit'
                      name='wouldProviderTakeit'
                      className='vafSelectMenu'
                      variant='outlined'
                      onChange={handleChange}
                      value={
                        tempFormData.wouldProviderTakeit !== undefined
                          ? tempFormData.wouldProviderTakeit.toString()
                          : ''
                      }
                    >
                      <MenuItem value={true}>{i18n.t('general.yes')}</MenuItem>
                      <MenuItem value={false}>{i18n.t('general.no')}</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.wouldProviderTakeit && (
                    <Alert severity='warning'>
                      {i18n.t('providervafform.warningoptions')}
                    </Alert>
                  )}
                  <label htmlFor='providerWhatToImprove' className='regLabel'>
                    {i18n.t('providervafform.providerwhattoimprove')}
                  </label>
                  <TextField
                    id='providerWhatToImprove'
                    name='providerWhatToImprove'
                    type='text'
                    placeholder='Írd le pár szóban'
                    className='regTextArea'
                    variant='outlined'
                    value={tempFormData.providerWhatToImprove}
                    onChange={handleChange}
                    multiline
                    minRows={3}
                    disabled={tempFormData.isRegCompleted}
                  />
                </div>

                <div className='regButtonContainer'>
                  <StyledButton
                    color='NewNavBack'
                    className='regBtnMaxWidth'
                    onClick={handlePrevPage}
                  >
                    {i18n.t('general.backbutton')}
                  </StyledButton>

                  <StyledButton
                    color='NewReqBtn'
                    className='regFormBtnMaxWidth'
                    onClick={async (e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                    isDisabled={tempFormData.isRegCompleted || !formValid}
                  >
                    {i18n.t('general.close')}
                  </StyledButton>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );

};

export default RequestFormUpdate;
