import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import ENG from './translations/ENG.json';
import hu from './translations/hu.json';
import ro from './translations/ro.json';
import HUN from './translations/HUN.json';
import german from './translations/german.json';
i18n.use(initReactI18next).init({
  lng: 'hun', // 'en' | 'es'
  resources: {
    en: {
      translation: en,
    },
    ro: {
      translation: ro,
    },
    eng: {
      translation: ENG,
    },
    hu: {
      translation: hu,
    },
    german: {
      translation: german,
    },
    hun: {
      translation: HUN,
    },
  },
  debug: true,
  react: {
    wait: true,
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
