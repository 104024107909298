import React from 'react';
import Restrictions from './components/restrictions';
import Services from './components/services';
import ExtraInfo from './components/extraInfo';
import Preferences from './components/preferences';
//REDUX
import './filters.scss';

const Filters = ({ submitToDB, providerData }) => {
  console.log(providerData, 'providerData');
  return (
    <div className='DashboardFiltersContainer'>
      <div className='FiltersContent'>
        <Services submitToDB={submitToDB} />
      </div>
      {providerData.provider_package.packageType === 'advanced' && (
        <div className='FiltersContent'>
          <ExtraInfo submitToDB={submitToDB} />
        </div>
      )}
      <div className='FiltersContent'>
        <Restrictions submitToDB={submitToDB} />
      </div>
      <div className='FiltersContent'>
        <Preferences submitToDB={submitToDB} />
      </div>
    </div>
  );
};

export default Filters;
