import React, { useEffect, useState } from 'react';
import './dlpProviderProfile.scss';
import axios from '../../utils/axiosCompanyProxy';
import { Button, Rating, Pagination } from '@mui/material';
// import HeaderLogo from './assets/DlpProviderHeaderLogo.svg';
// import HeaderAccountant from './assets/DlpProviderHeaderAccountant.svg';
import LongArrowLeft from '../assets/DlpProviderLongArrowLeft.svg';
import HandWave from '../assets/DlpProviderHandWave.svg';
import Shield from '../assets/DlpProviderShield.svg';
import Approved from '../assets/DlpApproved.svg';
import StarBlue from '../assets/DlpStarBlue.svg';
import LangHU from '../../../images/icons/circle-flags/hu.svg';
import LangGB from '../../../images/icons/circle-flags/gb.svg';
import LangDE from '../../../images/icons/circle-flags/de.svg';
import LangFR from '../../../images/icons/circle-flags/fr.svg';
import LangCN from '../../../images/icons/circle-flags/cn.svg';
import LangRU from '../../../images/icons/circle-flags/ru.svg';
import LangRO from '../../../images/icons/circle-flags/ro.svg';
import LangES from '../../../images/icons/circle-flags/es.svg';
import DlpAttFlexibility from '../components/dlpAttFlexibility';
import DlpAttPrecision from '../components/dlpAttPrecision';
import DlpAttPricevalue from '../components/dlpAttPriceValue';
import DlpAttIndustryExperience from '../components/dlpAttIndustryExperience';
import DlpAttIndustryProfessionalism from '../components/dlpAttProfessionalism';
import DigBadge from '../assets/DlpInfos/DlpDigBadge.svg';
import Industry from '../assets/DlpInfos/DlpIndustry.svg';
import NoOfCustomers from '../assets/DlpInfos/DlpNoOfCustomers.svg';
import PackageSize from '../assets/DlpInfos/DlpPackageSize.svg';
import StarGrey from '../assets/DlpInfos/DlpStarGrey.svg';
import WoComplaint from '../assets/DlpInfos/DlpWoComplaint.svg';
import NotVerifiedYet from '../assets/DlpNotVerifiedYet.svg';
import DlpMUIProfileImageDefault from '../assets/DlpMUIProfileImageDefault.svg';
import i18n from '../../../utils/i18n';
import { useTranslation } from 'react-i18next';

const FRONT_END_URL =
	process.env.REACT_APP_FRONT_END_URL || 'https://dashboard-hu.bookkeepie.com';

const DlpProviderProfile = ({ data, setOpen, setOpenForward }) => {
	const id = data.providerID;
	const [providerCompanyName, setProviderCompanyName] = useState('');
	const [ProfileImage, setProfileImage] = useState('');
	const [description, setDescription] = useState('');
	const [liabilityInsurance, setLiabilityInsurance] = useState('');
	const [reviews, setReviews] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [packageType, setPackageType] = useState('');
	const [averages, setAverages] = useState({});
	const [packageSize, setPackageSize] = useState('');
	const [digitalBadge, setDigitalBadge] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [industryExperience, setIndustryExperience] = useState({});
	const [showMore, setShowMore] = useState(false);
	const [city, setCity] = useState('');
	const reviewsPerPage = 9;
	const indexOfLastReview = currentPage * reviewsPerPage;
	const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
	const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
	const totalPages = Math.ceil(reviews.length / reviewsPerPage);

	const { t } = useTranslation();

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handleShowMore = (e) => {
		e.stopPropagation();
		setShowMore(true);
	};

	const handleShowLess = (e) => {
		e.stopPropagation();
		setShowMore(false);
	};

	const dataLayerUpdate = (provider) => {
		window.dataLayer.push({
			event: 'sf_step_listing_offer_pressed',
			sourceprovider: provider,
			event_category: 'listingpage',
		});
	};

	useEffect(() => {
		const fetchProviderDetails = async () => {
			try {
				const response = await axios.get(`/api/provider/${data.providerID}`);
				//console.log(response, 'RESPONSE');
				setCity(response.data.provider.provider_info.city);
				setProviderCompanyName(
					response.data.provider.provider_info.companyname
				);
				setProfileImage(
					response.data.provider.provider_media.profileImage ||
						DlpMUIProfileImageDefault
				);
				setDescription(response.data.provider.provider_info.description);
				setLiabilityInsurance(
					response.data.provider.provider_liability_insurance.accounting
				);
				setLanguages(response.data.provider.provider_languages || {});
				setPackageType(response.data.provider.provider_package.packageType);
				setPackageSize(response.data.provider.provider_package.packageSize);
				setDigitalBadge(response.data.provider.digital_badge);
				setIndustryExperience(
					response.data.provider.provider_industry_experience
				);
			} catch (error) {
				console.error('Error fetching provider details:', error);
			}
		};
		fetchProviderDetails();
	}, [id]);

	let urlVariable = '';

	useEffect(() => {
		urlVariable = encodeURIComponent(providerCompanyName);
	}, [providerCompanyName]);

	useEffect(() => {
		const fetchProviderReviews = (id) => {
			try {
				let data = JSON.stringify({ providerID: id });
				axios
					.post('/api/review/id', data, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((response) => {
						//console.log('PROVIDER REVIEWS', response);
						setReviews(response.data.review);
						setAverages(pickAttributes(response.data.review));
					})
					.catch((error) => {
						console.log('AXIOS ERROR: ', error.response.data);
					});
			} catch (error) {
				console.error('Error fetching provider reviews:', error);
			}
		};
		fetchProviderReviews(id);
	}, [id]);

	const renderLanguages = (languages) => {
		const languageIcons = {
			hungarian: LangHU,
			english: LangGB,
			german: LangDE,
			french: LangFR,
			chinese: LangCN,
			russian: LangRU,
			romanian: LangRO,
			spanish: LangES,
		};

		const countryCodes = {
			hungarian: 'HU',
			english: 'GB',
			german: 'DE',
			french: 'FR',
			chinese: 'CN',
			russian: 'RU',
			romanian: 'RO',
			spanish: 'ES',
		};

		return Object.keys(languages)
			.filter((language) => languages[language] && language !== 'hungarian')
			.map((language, index) => (
				<img
					key={index}
					src={languageIcons[language]}
					alt={`${language} flag`}
					className={`dlpLang${countryCodes[language]}`}
				/>
			));
	};

	const calculateAverageRating = (review) => {
		const {
			flexibility,
			industryexperience,
			precision,
			pricevalue,
			professionalism,
		} = review;
		const total =
			flexibility +
			industryexperience +
			precision +
			pricevalue +
			professionalism;
		return Number((total / 5).toFixed(1));
	};

	const pickAttributes = (reviews) => {
		if (reviews.length === 0) return 0;

		const totalFlexibility = reviews.reduce(
			(total, review) => total + review.flexibility,
			0
		);
		const averageFlexibility = (totalFlexibility / reviews.length).toFixed(1);

		const totalIndustryexperience = reviews.reduce(
			(total, review) => total + review.industryexperience,
			0
		);
		const averageIndustryexperience = (
			totalIndustryexperience / reviews.length
		).toFixed(1);

		const totalPrecision = reviews.reduce(
			(total, review) => total + review.precision,
			0
		);
		const averagePrecision = (totalPrecision / reviews.length).toFixed(1);

		const totalPricevalue = reviews.reduce(
			(total, review) => total + review.pricevalue,
			0
		);
		const averagePricevalue = (totalPricevalue / reviews.length).toFixed(1);

		const totalProfessionalism = reviews.reduce(
			(total, review) => total + review.professionalism,
			0
		);
		const averageProfessionalism = (
			totalProfessionalism / reviews.length
		).toFixed(1);

		const numberOfAttributes = 5;

		const totalAverage = (
			(parseFloat(averageFlexibility) +
				parseFloat(averageIndustryexperience) +
				parseFloat(averagePrecision) +
				parseFloat(averagePricevalue) +
				parseFloat(averageProfessionalism)) /
			numberOfAttributes
		).toFixed(1);

		return {
			averageFlexibility: parseFloat(averageFlexibility),
			averageIndustryexperience: parseFloat(averageIndustryexperience),
			averagePrecision: parseFloat(averagePrecision),
			averagePricevalue: parseFloat(averagePricevalue),
			averageProfessionalism: parseFloat(averageProfessionalism),
			totalAverage: parseFloat(totalAverage),
		};
	};

	const renderIndustryExperience = (industryExperience) => {
		return Object.keys(industryExperience)
			.filter((industry) => industryExperience[industry] >= 4)
			.map((industry, index) => (
				<div key={index}>{t(`industrylabels.${industry}`)}</div>
			));
	};

	// console.log('AVERAGES', averages);

	return (
		<div
			className="dlpProviderProfile"
			onClick={() => {
				setOpen(false);
			}}>
			<div className="dlpProviderBackButton">
				<Button className="dlpProviderBackButtonCustom">
					<img
						src={LongArrowLeft}
						alt="Long Arrow Left"
						className="dlpLongArrowLeft"
					/>
					{i18n.t('dlpproviderprofile.backbutton')}
				</Button>
			</div>

			<div className="dlpProviderHeaderSleek">
				<div className="dlpProviderHeaderTextSleek">
					<div className="dlpProviderHeaderTextUpSleek">
						{i18n.t('dlpproviderprofile.accountants')} / {city} /{' '}
						{providerCompanyName}
					</div>
					<div className="dlpProviderHeaderTextDownSleek">
						<span>{i18n.t('dlpproviderprofile.gettoknowbetter')}</span>
						<span>{providerCompanyName}-t</span>
					</div>
				</div>
			</div>
			<div className="dlpDividerHeaderSleek"></div>
			<div className="dlpProviderContent">
				<div className={`dlpProviderContentLeft ${showMore ? 'show' : ''}`}>
					<div className="dlpProviderMainContainer">
						<div className="dlpProviderMainContainerLeft">
							<div className="dlpProfileImageContainer">
								{ProfileImage ? (
									<img
										src={ProfileImage}
										alt="Profile"
										className="dlpProfileImage"
									/>
								) : (
									<img
										src={DlpMUIProfileImageDefault}
										alt="Profile Default"
										className="dlpProfileImageDefault"
									/>
								)}
								{packageType === 'premium' && (
									<img
										src={Approved}
										alt="Profile"
										className="dlpApproved"></img>
								)}
							</div>
						</div>
						<div className="dlpProviderMainContainerRight">
							<div className="dlpProviderProfileName">
								{providerCompanyName}
							</div>
							{reviews.length > 0 ? (
								<div className="dlpProviderProfileAvgContainer">
									<div className="dlpProviderProfileAvgStars">
										<Rating
											value={averages.totalAverage || 0}
											precision={0.1}
											readOnly
											sx={{ fontSize: '1rem' }}
										/>
										<div className="dlpProviderProfileAvgNumber">
											{averages.totalAverage}
										</div>
										<div className="dlpProviderProfileReviewsNumber">
											({reviews.length})
										</div>
									</div>
								</div>
							) : (
								<div className="dlpNotVerifiedYetConatiner">
									<img
										src={NotVerifiedYet}
										alt="Not Verified Yet"
										className="dlpNotVerifiedYet"
									/>
									<div className="dlpNotVerifiedYetText">
										{i18n.t('dlpproviderprofile.notverifiedyet')}
									</div>
								</div>
							)}
							<div className="dlpRFPButton">
								<Button
									className="dlpRFPButtonCustom"
									onClick={(e) => {
										setOpenForward(true);
										// e.stopPropagation();
										// dataLayerUpdate(providerCompanyName);
										// localStorage.setItem('selectedSP', providerCompanyName);
										// window.open(`${FRONT_END_URL}/company/request/1`);
									}}>
									{i18n.t('providerdashboard.requestcapital')}
								</Button>
							</div>
							{renderLanguages(languages).length > 0 && (
								<div className="dlpLanguagesContainer">
									{renderLanguages(languages)}
								</div>
							)}
						</div>
					</div>
					{reviews.length > 0 && (
						<>
							<div className="DlpDividerContentLeft"></div>
							<div className="dlpAttributesContainer">
								<DlpAttFlexibility inputValue={averages.averageFlexibility} />
								<DlpAttPrecision inputValue={averages.averagePrecision} />
								<DlpAttPricevalue inputValue={averages.averagePricevalue} />
								<DlpAttIndustryExperience
									inputValue={averages.averageIndustryexperience}
								/>
								<DlpAttIndustryProfessionalism
									inputValue={averages.averageProfessionalism}
								/>
							</div>
						</>
					)}
					{!showMore && (
						<div className="dlpShowMoreButton">
							<Button onClick={handleShowMore}>
								{i18n.t('dlpproviderprofile.moreinfo')}
							</Button>
						</div>
					)}
					<div className={`dlpHiddenContent ${showMore ? 'show' : ''}`}>
						{renderIndustryExperience(industryExperience).length > 0 && (
							<>
								<div className="DlpDividerContentLeft"></div>
								<div className="dlpIndustryContainer">
									<img src={Industry} alt="Industry" className="dlpIndustry" />
									<div className="dlpIndustryText">
										{i18n.t('dlpproviderprofile.expertinindustries')}
									</div>
								</div>
								<div className="dlpIndustryContainerCards">
									{renderIndustryExperience(industryExperience).map(
										(industry, index) => (
											<div key={index} className="dlpIndustryCard">
												{industry}
											</div>
										)
									)}
								</div>
							</>
						)}
						{reviews.length > 0 && (
							<>
								<div className="DlpDividerContentLeft"></div>
								<div className="dlpRatingSumContainer">
									<img src={StarGrey} alt="Star Grey" className="dlpStarGrey" />
									<div className="dlpRatingSumAvgNumber">
										{averages.totalAverage}
									</div>
									<div className="dlpRatingSumText">
										{i18n.t('general.reviewsmall')}
									</div>
								</div>
							</>
						)}
						<div className="dlpPackageSizeContainer">
							<img
								src={PackageSize}
								alt="PackageSize"
								className="dlppackageSize"
							/>
							{packageSize === 'entrepreneur' && (
								<div className="dlpPackageSizeText1">
									{i18n.t('general.entrepreneur')}
								</div>
							)}
							{packageSize === 'small_office' && (
								<div className="dlpPackageSizeText1">
									{i18n.t('provider.small_acc_office')}
								</div>
							)}
							{(packageSize === 'office' || packageSize === 'enterprise') && (
								<div className="dlpPackageSizeText1">
									{i18n.t('provider.large_acc_office')}
								</div>
							)}
						</div>
						<div className="dlpDigBadgeContainer">
							<img src={DigBadge} alt="DigBadge" className="dlpDigBadge" />
							<div className="dlpDigBadgeTextContainer">
								<div className="dlpDigBadgeText1">
									{i18n.t('dlpproviderprofile.digital')}
								</div>
								<div className="dlpDigBadgeText1">
									{i18n.t('dlpproviderprofile.withcertifiaction')}
								</div>
								<div className="dlpDigBadgeText2">
									{i18n.t('dlpproviderprofile.has')}
									{/* {digitalBadge} */}
								</div>
							</div>
						</div>
						<div className="DlpDividerContentLeft"></div>
						<div className="dlpWoComplaintContainer">
							<img
								src={WoComplaint}
								alt="WoComplaint"
								className="dlpWoComplaint"
							/>
							<div className="dlpWoComplaintTextContainer">
								<div className="dlpWoComplaintNumber">365</div>
								<div className="dlpWoComplaintText">
									{i18n.t('dlpproviderprofile.workday')}
								</div>
								<div className="dlpWoComplaintText">
									{i18n.t('dlpproviderprofile.complaint')}
								</div>
								<div className="dlpWoComplaintText">
									{i18n.t('dlpproviderprofile.without')}
								</div>
							</div>
						</div>
						<div className="NoOfCustomersContainer">
							<img
								src={NoOfCustomers}
								alt="NoOfCustomers"
								className="dlpNoOfCustomers"
							/>
							<div className="dlpNoOfCustomersTextContainer">
								<div className="dlpNoOfCustomersNumber">15</div>
								<div className="dlpNoOfCustomersText1">
									{i18n.t('general.bookkeepie')}
								</div>
								<div className="dlpNoOfCustomersText2">
									{i18n.t('general.client')}
								</div>
							</div>
						</div>
						<div className="dlpShowLessButton">
							<Button onClick={handleShowLess}>
								{i18n.t('dlpproviderprofile.lessinfo')}
							</Button>
						</div>
					</div>
				</div>
				<div className="dlpProviderContentRight">
					{description && (
						<div className="dlpProviderCard">
							<div className="dlpProviderIntroduction">
								<img src={HandWave} alt="Hand Wave" className="dlpHandWave" />
								{i18n.t('general.description')}
							</div>
							<div className="dlpProviderIntroductionText">{description}</div>
						</div>
					)}
					{liabilityInsurance === true && (
						<div className="dlpProviderCard">
							<div className="dlpProviderLiabilityInsurance">
								<img src={Shield} alt="Shield" className="dlpShield" />
								{i18n.t('dlpproviderprofile.hasliabilityinsurance')}
							</div>
							<div className="dlpProviderLiabilityInsuranceText">
								{i18n.t('dlpproviderprofile.liabilityinsurancetext')}
							</div>
						</div>
					)}
					<div className="dlpProviderCardLast">
						{reviews.length === 0 ? (
							<div className="dlpProviderReviewsNull">
								<img src={StarBlue} alt="Star Blue" className="dlpStarBlue" />
								<div className="dlpSumOfReviews">{reviews.length}</div>
								{i18n.t('general.review')}
							</div>
						) : (
							<div className="dlpProviderReviews">
								<img src={StarBlue} alt="Star Blue" className="dlpStarBlue" />
								<div className="dlpSumOfReviews">{reviews.length}</div>
								{i18n.t('general.review')}
							</div>
						)}
						<div className="dlpProviderReviewsContainer">
							{currentReviews.map((review, index) => (
								<div key={index} className="dlpProviderReviewCard">
									<div className="dlpProviderReviewHeader">
										<div className="dlpProviderReviewReviewer">
											{review.reviewer}
										</div>
										<div className="dlpProviderReviewDate">{review.date}</div>
									</div>
									<div className="dlpProviderReviewAvgContainer">
										<div className="dlpProviderReviewAvgStars">
											<Rating
												value={calculateAverageRating(review)}
												precision={0.1}
												readOnly
												sx={{ fontSize: '1rem' }}
											/>
										</div>
										<div className="dlpProviderReviewAvgNumber">
											{calculateAverageRating(review)}
										</div>
									</div>
									<div className="dlpProviderReviewText">{review.review}</div>
								</div>
							))}
						</div>
						{totalPages > 0 && (
							<Pagination
								count={totalPages}
								page={currentPage}
								onChange={handlePageChange}
								color="primary"
								className="dlpReviewsPaginator"
								shape="rounded"
								sx={{
									'& .MuiPaginationItem-root': {
										color: '#4D5BEC',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
									'& .MuiPaginationItem-root.Mui-selected': {
										backgroundColor: '#4D5BEC',
										color: '#ffffff',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
									'& .MuiPaginationItem-root.Mui-selected:hover': {
										backgroundColor: '#4D5BEC',
										color: '#ffffff',
										border: '1px solid #E3E5E9',
										borderRadius: '6px',
									},
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DlpProviderProfile;
