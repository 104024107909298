import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Pricevalue from '../assets/DlpAttributes/DlpAttCommunication.svg';

const LineIcon = styled('span', {
    shouldForwardProp: (prop) => prop !== 'filled',
})(({ filled }) => ({
    width: '3.5vw',
    minWidth: '30px',
    maxWidth: '55px',
    height: '4px',
    backgroundColor: filled ? '#FB306D' : '#FECBDA',
    borderRadius: '3px',
    '@media (max-width: 600px)': {
        width: '15vw',
        minWidth: '30px',
        maxWidth: '90px',
    },
}));

const RatingBarContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const RatingStyled = styled(Rating)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
});

const RatingBar = () => {
    return (
        <RatingBarContainer>
            <img src={Pricevalue} alt="Pricevalue Icon" style={{ width: '30px' }}></img>
            <Typography sx={{ fontSize: '0.9rem', fontWeight: 600, marginLeft: '8px' }}>
                Iparági tapasztalat
            </Typography>
        </RatingBarContainer>
    );
};

const DlpAttPricevalue = ({ inputValue }) => {
    const [value, setValue] = useState(Math.round(inputValue));

    useEffect(() => {
        setValue(Math.round(inputValue));
    }, [inputValue]);

    return (
        <Box sx={{ padding: "8px 0px", width: '100%' }}>
            <RatingBar />
            <RatingStyled
                name="customized-color"
                value={value}
                getLabelText={(value) => `${value} Line${value !== 1 ? 's' : ''}`}
                precision={1}
                icon={<LineIcon filled={true} />}
                emptyIcon={<LineIcon filled={false} />}
                readOnly
            />
        </Box>
    );
};

export default DlpAttPricevalue;
