import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Alert, Snackbar } from '@mui/material';
import PricingInputs from '../inputs/pricingInputs';
import AccountingInputs from '../inputs/accountingInputs';
//import FoundingInputs from '../inputs/foundingInputs';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import { dbTextPairs } from '../../../../../shared/data/sharedText';
import {
  providerServicesText,
  accountingTaxPricing,
  //auditPricing,
  liquidationPricing,
  payrollPricing,
} from '../../../../data/providerText';
import i18n from '../../../../../utils/i18n';

//REDUX
import ACTIONS from '../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const Services = ({ submitToDB }) => {
  const [edit, setEdit] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [reqFields, setReqFields] = React.useState({});
  const [providerServices, store] = useSelector((state) => [
    state.provider_services,
    state,
  ]);
  const [accPricesCopy] = React.useState(store.provider_acc_monthly_price);
  const [payrollPricesCopy] = React.useState(store.provider_payroll_price);
  const [taxPricesCopy] = React.useState(store.provider_tax_consulting_price);
  //const [auditPricesCopy] = React.useState(store.provider_audit_price);
  //const [foundingPricesCopy] = React.useState(store.provider_founding_price);
  const [liquidationPricesCopy] = React.useState(
    store.provider_liquidation_price
  );
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      for (let i in reqFields) {
        if (reqFields[i]) {
          setError(true);
          return;
        } else setError(false);
      }
    }
  }, [reqFields, error]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleCheck = (event) => {
    setModified(true);
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: event.target.checked,
        stateObject: 'provider_services',
      })
    );
  };

  const getServices = () => {
    let services = [];
    for (let i in providerServices) {
      if (providerServices[i] && i !== 'legalentityfounding' && i !== 'audit') {
        services.push(dbTextPairs()[i]);
      }
    }
    return services;
  };
  const checkError = () => {
    for (let i in reqFields) {
      if (reqFields[i] === true) {
        setError(true);
        return true;
      } else {
        setError(false);
        return false;
      }
    }
  };
  const setRequiredFields = (item, value) => {
    if (!reqFields[item] && !Number.isInteger(value)) {
      setReqFields({ ...reqFields, [item]: true });
    }
  };
  const setRequiredFieldsTrue = (item) => {
    if (reqFields[item]) {
      setReqFields({ ...reqFields, [item]: false });
    }
  };
  const checkReqFields = (item, value) => {
    if (reqFields[item] === true && Number.isInteger(value)) {
      setReqFields({ ...reqFields, [item]: false });
    }
  };
  return (
    <div className='FiltersContentContainer'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <h2>{i18n.t('providerdashboard.myservices')}</h2>
      <div className='ServicesContainer'>
        {edit ? (
          <>
            {providerServicesText().map((item, i) => {
              return (
                <div className='ServicesCheckBox' key={i}>
                  <FormGroup>
                    <FormControlLabel
                      // style={{ margin: '6px 0px 6px -11px' }}
                      control={
                        <Checkbox
                          id={item.id}
                          checked={providerServices[item.id]}
                          onChange={(event) => {
                            handleCheck(event);
                          }}
                        />
                      }
                      label={
                        <span className='SFCheckboxLabel'>{item.label}</span>
                      }
                    />
                  </FormGroup>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {getServices().map((item, i) => {
              return (
                <div className='ServiceLabelFilters' key={i}>
                  {item}
                </div>
              );
            })}
          </>
        )}
        {edit ? (
          <AccountingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={accPricesCopy}
            setModified={setModified}
          />
        ) : null}
        {edit ? (
          <PricingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            title=''
            service='payroll'
            endAdornment={i18n.t('general.currency/person')}
            dataText={payrollPricing()}
            storeObj='provider_payroll_price'
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={payrollPricesCopy}
            setModified={setModified}
          />
        ) : null}
        {edit ? (
          <PricingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            title={i18n.t('general.taxconsulting')}
            service='taxconsulting'
            endAdornment={i18n.t('general.currency')}
            dataText={accountingTaxPricing()}
            storeObj='provider_tax_consulting_price'
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={taxPricesCopy}
            setModified={setModified}
          />
        ) : null}
        {/* {edit ? (
          <PricingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            title={i18n.t('general.audit')}
            service='audit'
            endAdornment={i18n.t('general.currency/year')}
            dataText={auditPricing()}
            storeObj='provider_audit_price'
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={auditPricesCopy}
            setModified={setModified}
          />
        ) : null} */}
        {/* {edit ? (
          <FoundingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={foundingPricesCopy}
            setModified={setModified}
          />
        ) : null} */}
        {edit ? (
          <PricingInputs
            setRequiredFieldsTrue={setRequiredFieldsTrue}
            title={i18n.t('general.liquidation')}
            service='liquidation'
            endAdornment={i18n.t('general.currency')}
            dataText={liquidationPricing()}
            storeObj='provider_liquidation_price'
            setRequiredFields={setRequiredFields}
            checkReqFields={checkReqFields}
            pricesCopy={liquidationPricesCopy}
            setModified={setModified}
          />
        ) : null}
        {error ? (
          <span className='ErrorText'>
            {i18n.t('providerdashboard.servicepricemissingerrortext')}
          </span>
        ) : null}
      </div>
      <StyledButton
        onClick={() => {
          if (!edit) {
            setEdit(true);
          }
          if (edit && !modified) {
            setEdit(false);
          }
          if (edit && modified && !checkError()) {
            setEdit(!edit);
            submitToDB();
            setModified(false);
            setOpen(true);
          }
        }}
        color={'Primary'}
      >
        {edit
          ? modified
            ? i18n.t('general.save')
            : i18n.t('general.backbutton')
          : i18n.t('general.modify')}
      </StyledButton>
    </div>
  );
};

export default Services;
