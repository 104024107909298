import React, { useEffect, useCallback } from 'react';
import { Paper } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { addMatchScore, sortProviderList } from '../../data/helperFunctions';
import ProviderCard from '../components/providerCard/providerCard';
import ProviderProfile from '../providerProfile/providerProfile';
import { sortingText } from '../data/searchFlowText';
import Navigation from '../components/navigation/navigation';
import ProviderTable from '../components/providerTable/providerTable';

import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import StyledButton from '../../../shared/components/styledButton/styledButton';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';

import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CherryProviders = ({ ...props }) => {
  const [providerList, setProviderList] = React.useState([]);
  const [profileData, setProfileData] = React.useState({});
  const [profileIndex, setProfileIndex] = React.useState(0);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [providerCount, setProviderCount] = React.useState(0);
  const [sortBy, setSortBy] = React.useState(i18n.t('searchflow.basedonmatch'));

  const dispatch = useDispatch();
  const [companyStore, matchScoreWeights, potentialProviders, loading] =
    useSelector((state) => [
      state.company_store,
      state.match_score_weights,
      state.company_store.potential_providers,
      state.loading,
    ]);

  const handleBack = useCallback(
    (decr) => {
      if (companyStore.taxconsultingcomplex) {
        props.handleBack(3);
      } else {
        props.handleBack(2);
      }
    },
    [props]
  );
  const showPrices = useCallback(() => {
    if (
      companyStore.incominginvoices > 0 ||
      companyStore.outgoinginvoices > 0 ||
      companyStore.yearlyrevenue > 0
    ) {
      return true;
    } else return false;
  }, [
    companyStore.incominginvoices,
    companyStore.outgoinginvoices,
    companyStore.yearlyrevenue,
  ]);

  const sortProviders = useCallback(() => {
    //console.log(potentialProviders, 'potentialProviders');
    let pp = addMatchScore(potentialProviders, matchScoreWeights);
    let ppCount = 0;
    let ppList = [];
    for (let i in pp) {
      if (pp[i].providerPrice === 0 || null) continue;
      if (showPrices()) {
        pp[i].providerPrice = numberWithCommas(pp[i].providerPrice);
      } else {
        pp[i].providerPrice = '0';
      }
      ppCount += 1;
      ppList.push(pp[i]);
    }
    sortingText().forEach((item) => {
      if (sortBy === item.label) {
        ppList = sortProviderList(ppList, item.id, item.ascending);
      }
    });
    if (providerCount !== ppCount) {
      setProviderCount(ppCount);
    }

    return ppList;
  }, [
    showPrices,
    sortBy,
    matchScoreWeights,
    potentialProviders,
    providerCount,
  ]);

  useEffect(() => {
    if (companyStore.potProvCount !== providerCount)
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'potProvCount',
          data: providerCount,
          stateObject: 'company_store',
        })
      );
  }, [providerCount, companyStore.potProvCount, dispatch]);

  useEffect(() => {
    setProviderList(sortProviders());
  }, [sortProviders]);

  const handleOpen = () => {
    setProfileOpen(true);
  };
  const handleClose = () => {
    setProfileOpen(false);
  };
  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  };
  console.log(companyStore, 'companyStore');
  return (
    <>
      <div className='SFPPContent'>
        <Dialog
          fullScreen
          open={profileOpen}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <ProviderProfile
            setProfileData={setProfileData}
            profileIndex={profileIndex}
            setProfileIndex={setProfileIndex}
            providerList={providerList}
            data={profileData}
            closeProfile={handleClose}
            createRequest={props.createRequest}
            updateRequest={props.updateRequest}
            reqID={props.reqID}
            updateViewedProviders={props.updateViewedProviders}
          />
        </Dialog>
        <div style={{ marginBottom: '4em' }}>
          <p className='SFTitle' style={{ marginTop: '0' }}>
            {i18n.t('searchflow.potprovtext1')}
          </p>
          <p className='SFQuestion'>{i18n.t('searchflow.potprovtext2')}</p>

          <div
            style={{
              margin: '24px 0px',
              display: 'flex',
              justifyContent:
                window.innerWidth < 600 ? 'flex-end' : 'space-between',
            }}
          >
            {window.innerWidth < 600 ? null : (
              <StyledButton
                onClick={() => {
                  dispatch(
                    ACTIONS.addCompanyInfo({
                      id: 'tableMode',
                      data: !companyStore.tableMode,
                      stateObject: 'company_store',
                    })
                  );
                  props.handleSearchUpdate('tableUpdate');
                }}
                color='Transparent'
                className='SquareBtn'
              >
                <span>{companyStore.tableMode ? '☰⠸' : '▤'}</span>
                {companyStore.tableMode
                  ? i18n.t('searchflow.backtolistview')
                  : i18n.t('searchflow.comparison')}
              </StyledButton>
            )}
            <StyledButton
              onClick={async () => {
                await props.fetchAspPotentialProviders(
                  companyStore,
                  matchScoreWeights
                );
                props.handleBack(1);
              }}
              color='TransparentBordered'
            >
              {i18n.t('searchflow.backtoasp')}
            </StyledButton>
          </div>
          {/*  TABLEMODE */}

          {companyStore.tableMode ? (
            <ProviderTable
              data={providerList}
              showPrices={showPrices()}
              setProfileIndex={setProfileIndex}
              setProfileData={setProfileData}
              openProfile={handleOpen}
              closeProfile={handleClose}
            />
          ) : (
            <>
              {' '}
              {window.innerWidth < 600 ? null : (
                <Paper className='SFSortingHeader'>
                  <div>
                    <p
                      style={{
                        fontWeight: '400',
                        fontSize: '18px',
                        lineHeight: '24px',
                      }}
                    >
                      <span
                        style={{
                          color: '#1a00bd',
                          fontWeight: '600',
                          fontSize: '600',
                          lineHeight: '24px',
                        }}
                      >{`${providerCount} `}</span>
                      találat
                    </p>
                  </div>
                  {loading ? (
                    <div style={{ width: '50%' }}>
                      <LoadingIndicator />
                    </div>
                  ) : potentialProviders &&
                    Object.keys(potentialProviders).length === 0 ? (
                    <p className='FlexCenter Bold'>
                      {i18n.t('searchflow.nomatchfound')}
                    </p>
                  ) : null}
                  <div className='FlexCenter'>
                    <span>{i18n.t('searchflow.sortby')}</span>
                    <FormControl
                      variant='standard'
                      sx={{ m: 1, minWidth: 150 }}
                    >
                      <Select
                        labelId='demo-simple-select-standard-label'
                        id='demo-simple-select-standard'
                        value={sortBy}
                        onChange={(e) => {
                          setSortBy(e.target.value);
                          dispatch(
                            ACTIONS.addCompanyInfo({
                              id: 'sortBy',
                              data: e.target.value,
                              stateObject: 'company_store',
                            })
                          );
                        }}
                      >
                        {sortingText().map((item, i) => {
                          return (
                            <MenuItem value={item.label} key={i}>
                              {
                                <span
                                  style={{
                                    color:
                                      item.label === sortBy ? '#1a00bd' : null,
                                    fontWeight:
                                      item.label === sortBy ? 'bold' : null,
                                  }}
                                >
                                  {item.label}
                                </span>
                              }
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </Paper>
              )}
              <div>
                {sortProviders().map((item, i) => {
                  return (
                    <div
                      style={{ margin: '16px 0px' }}
                      className={
                        window.innerWidth < 600
                          ? 'FlexFullCenter'
                          : 'ProviderCardContainer'
                      }
                      key={i}
                    >
                      <ProviderCard
                        data={item}
                        index={i}
                        openProfile={handleOpen}
                        setProfileIndex={setProfileIndex}
                        setProfileData={setProfileData}
                        createRequest={props.createRequest}
                        updateRequest={props.updateRequest}
                        reqID={props.reqID}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <Navigation
          step={25}
          handleBack={() => handleBack()}
          openHelpForm={() => props.openHelpForm()}
        />
      </div>
    </>
  );
};

export default withTranslation()(CherryProviders);
