import React from 'react';
import { TailSpin } from 'react-loader-spinner';

import i18n from '../../utils/i18n';
const LoadingIndicator = ({ text = i18n.t('general.loading') }) => {
  return (
    <div
      style={{
        maxWidth: '100vw',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <p className='SFTitleBlue' style={{ margin: '28px auto', color: 'white' }}>
          {text}
        </p>
      </div>
      <TailSpin color='#FFFFFF' height='50' width='50' />
    </div>
  );
};
export default LoadingIndicator;
