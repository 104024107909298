import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ACTIONS from '../../../redux/action';
import Navigation from '../components/navigation/navigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import CheckboxInput from '../components/searchInputs/newCheckboxInput';
import { zipInput, preferencesText } from '../data/searchFlowText';
import TextInput from '../components/searchInputs/textInput';
import LoadingIndicator from '../../../shared/LoadingIndicator/loadingIndicator';

const Preferences = ({ ...props }) => {
	const companyStore = useSelector((state) => state.company_store);
	const matchScoreWeights = useSelector((state) => state.match_score_weights);
	const [loading, setLoading] = useState(false);
	const [error, setError] = React.useState(false);
	const dispatch = useDispatch();

	const handleNext = useCallback(
		async (inc) => {
			if (
				companyStore.zip_code.toString().length !== 4
				//companyStore.zip_code.toString() !== '0000'
			) {
				setError(true);
			} else {
				if (companyStore.spSelectedFromList !== '') {
					setLoading(true);
					await props.fetchCherryPotentialProviders(
						companyStore,
						matchScoreWeights
					);
					props.handleNext(3);
				} else {
					if (
						!companyStore.legalentityfounding &&
						!companyStore.taxconsultingsimple &&
						!companyStore.taxconsultingcomplex
					) {
						props.handleNext(1);
					} else if (
						companyStore.legalentityfounding ||
						companyStore.taxconsultingsimple
					) {
						setLoading(true);
						await props.fetchAspPotentialProviders(
							companyStore,
							matchScoreWeights
						);
						props.handleNext(2);
					} else if (companyStore.taxconsultingcomplex) {
						setLoading(true);
						await props.fetchCherryPotentialProviders(
							companyStore,
							matchScoreWeights
						);
						props.handleNext(3);
					}
				}
			}
			//await props.fetchAspPotentialProviders(companyStore, matchScoreWeights);
			//props.handleNext(1);
		},
		[props, companyStore.zip_code]
	);

	const handleBack = useCallback(
		(decr) => {
			props.handleBack(decr);
		},
		[props]
	);

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 13) {
				handleNext();
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [props, handleNext]);

	const handleChange = async (event) => {
		dispatch(
			ACTIONS.addCompanyInfo({
				id: event.target.id,
				data: event.target.checked ? 5 : 0,
				stateObject: 'match_score_weights',
			})
		);
		//console.log(matchScoreWeights, 'matchScoreWeights');
	};

	return (
		<div className="stepContainer">
			<div className="SFInput">
				{loading ? (
					<LoadingIndicator />
				) : (
					<>
						<p className="SFSubTitle">
							<span>{i18n.t('searchflow.providerskillstext2')}</span>{' '}
							<span className="questionTracker">2/3</span>
						</p>
						<CheckboxInput
							handleCheck={handleChange}
							dataText={preferencesText()}
							stateObject={'match_score_weights'}
						/>
						{matchScoreWeights.distanceWeight === 5 && (
							<>
								<p className="SFSubTitle">
									{i18n.t('searchflow.sfservicestext4')}
								</p>
								<div
									//className='SFRadioInput'
									onWheel={(e) => {
										e.target.blur();
									}}>
									<TextInput
										storeID="zip_code"
										dataText={zipInput()}
										characterLimit={4}
										type={'number'}
										error={error}
									/>
								</div>
								{error ? (
									<span
										style={{
											color: 'red',
											fontSize: '12px',
											fontWeight: 'bold',
										}}>
										{i18n.t('searchflow.sfservicestext5')}
									</span>
								) : null}
							</>
						)}
					</>
				)}
			</div>
			<Navigation
				step={13}
				handleBack={() => handleBack()}
				handleNext={async () => {
					handleNext();
				}}
				openHelpForm={() => props.openHelpForm()}
			/>
		</div>
	);
};

export default withTranslation()(Preferences);
