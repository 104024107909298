import React from 'react';
/* import placeholder from "../../../../../images/icons/placeholder-new.png"; */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../dashboard.scss';
import i18n from '../../../../../utils/i18n';
//REDUX
import { useSelector } from 'react-redux';
/* import StyledButton from "../../../../../shared/components/styledButton/styledButton"; */

const RequestInfo = ({ ...props }) => {
  const reqInfo = useSelector((state) => state.reqInfo);
  return (
    <div className='InfoContent' style={{ minHeight: '230px' }}>
      <h2>{i18n.t('providerdashboard.newrequests')}</h2>
      {/*     {reqInfo.open > 0 ? (
        <p>{`Van ${reqInfo.open} megválaszolatlan ajánlatkérésed`}</p>
      ) : null} */}
      {reqInfo.open > 0 ? (
        <>
          {reqInfo.reqSorted.open.map((item, i) => {
            if (i <= 1) {
              return (
                <div className='ReqInfoPreview' key={i}>
                  <p>{item.email}</p>
                  <span style={{ color: 'white' }}>
                    {item.timePassed <= 24
                      ? `${item.timePassed} ${i18n.t(
                          'providerdashboard.hours'
                        )}`
                      : `${Math.floor(item.timePassed / 24)} ${i18n.t(
                          'providerdashboard.days'
                        )}`}
                    {/*   <img src={placeholder} alt="" className="Icon16" /> */}
                  </span>
                  {/*  <StyledButton color="WhiteSecondaryBlue"  >
                    Válaszolok
                  </StyledButton> */}
                </div>
              );
            } else return null;
          })}
          <div
            className='ToRequests'
            onClick={() => {
              props.handleStepChange('requests');
            }}
          >
            <p>{`${reqInfo.open} ${i18n.t('providerdashboard.newrequest')}`}</p>
            <ArrowForwardIcon sx={{ color: 'white' }} />
          </div>
        </>
      ) : (
        <p>{i18n.t('providerdashboard.nonewrequests')}</p>
      )}
    </div>
  );
};

export default RequestInfo;
