export const feedback = [
  {
    question:
      "Mennyire vagy megelégedve a könyvelőddel (aki a vállalkozásod 2023 évi beszámolóját készítette) - ÁLTALÁNOSAN?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "A vállalkozásom nem adott be beszámolót",
      "Nem rendelkezem könyvelővel",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    question:
      "Mennyire voltál megelégedve a könyvelőd ELÉRHETŐSÉGÉVEL az elmúlt évben?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    question:
      "Mennyire voltál megelégedve a könyvelőd SZAKMAI TUDÁSÁVAL az elmúlt évben?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    question:
      "Mennyire voltál megelégedve a könyvelőd EMBERI TULAJDONSÁGAIVAL az elmúlt évben?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    question:
      "Mennyire voltál megelégedve a könyvelőd által felszámított KÖNYVELÉSI DÍJJAL az elmúlt évben?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    question:
      "Mit tartasz a legfontosabb szempontnak, amikor könyvelőt választasz?",
    options: [
      "Ár",
      "Szakmai tudás",
      "Profi weboldal",
      "Személyes benyomás",
      "Online szolgáltatás nyújtás",
      "Kommunikáció",
      "Ismerősök ajánlása",
      "Más ügyfelek értékelése az interneten",
      "Ismerje az iparágat, amiben dolgozom",
      "Legyen közel az irodámhoz",
      "Legyen közel az otthonomhoz",
      "Lehessen könnyen parkolni",
      "Egyéb",
    ],
  },
  {
    question:
      "Mennyire tartod valószínűnek, hogy fogsz könyvelőt váltani a következő 1 évben?",
    options: [
      "Egyáltalán nem",
      "Inkább nem",
      "Inkább igen",
      "Teljesen",
      "Nem szeretnék válaszolni",
    ],
  },
  {
    // question: "Kérlek add meg a neved, email címed és a telefonszámodat.",
    question: "",
    options: [],
  },
];
