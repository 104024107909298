import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import { providerSocialText } from '../../../data/providerText';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      padding: '8px 0 0 0',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const SocialInputs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerInfo = useSelector((state) => state.provider_info);

  const handleChange = (event, storeObj) => {
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };
  return (
    <div>
      {providerSocialText().map((item, i) => {
        return (
          <div className='AdornmentInputContainer' key={i}>
            <TextField
              className={classes.root}
              id={item.id}
              variant='outlined'
              type='text'
              placeholder={item.label}
              value={providerInfo[item.id]}
              onChange={(event) => {
                handleChange(event, 'provider_info');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={item.img} alt='' />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(SocialInputs);
