import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import {
  foreignTradeExtra,
  invoicesExtra,
  providerCompanyTypeList,
  revenueExtra,
  vatExtra,
  severalLocationsExtra,
  etradeExtra,
  tourismExtra,
} from '../../../data/providerText';
import wand from '../../../../images/icons/wand-new.svg';
import { NumberFormatCustom } from '../../../../shared/components/formattedInput/formattedInputs';
import prices from '../prices.json';

import i18n from '../../../../utils/i18n';
//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import ExtraFeeInputs from './extraFeeInputs';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      opacity: '0.7',
      marginTop: '-6px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#424559 !important',
      background: 'white !important',
    },
  },
});

const AccountingInputs = ({ ...props }) => {
  const [inputError, setInputError] = React.useState({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    accountingPrice,
    companyTypeFilter,
    specialRestr,
    liabilityIns,
    restrictions,
    priceCategory,
  ] = useSelector((state) => [
    state.provider_acc_monthly_price,
    state.provider_business_filter,
    state.provider_special_restrictions,
    state.provider_liability_insurance,
    state.provider_restrictions,
    state.priceCategory,
  ]);

  const handleChange = (event, storeObj) => {
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: value,
        stateObject: storeObj,
      })
    );
  };
  const autoFill = (id, storeObj, price) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: id,
        data: price,
        stateObject: storeObj,
      })
    );
  };

  const fillPrices = () => {
    let category = priceCategory.category;
    for (let item in companyTypeFilter) {
      if (companyTypeFilter[item]) {
        let id = `${[item]}price`;

        autoFill(
          id,
          'provider_acc_monthly_price',
          prices[category]['provider_acc_monthly_price'][id]
        );
      }
    }
    invoicesExtra().forEach((item) => {
      autoFill(
        item.id,
        'provider_acc_monthly_price',
        prices[category]['provider_acc_monthly_price'][item.id]
      );
    });
    if (specialRestr.insideEU || specialRestr.outsideEU) {
      foreignTradeExtra().forEach((item) => {
        autoFill(
          item.id,
          'provider_acc_monthly_price',
          prices[category]['provider_acc_monthly_price'][item.id]
        );
      });
    }
    revenueExtra().forEach((item) => {
      if (
        restrictions.yearlyrevenue[0] <= item.range[1] &&
        restrictions.yearlyrevenue[1] >= item.range[0]
      ) {
        autoFill(
          item.id,
          'provider_acc_monthly_price',
          prices[category]['provider_acc_monthly_price'][item.id]
        );
      }
    });
    vatExtra().forEach((item) => {
      autoFill(
        item.id,
        'provider_acc_monthly_price',
        prices[category]['provider_acc_monthly_price'][item.id]
      );
    });
  };

  return (
    <div className='PriceInputContainer'>
      <p className='FullWidth'>
        {i18n.t('general.accounting')}
        <span style={{ float: 'right', cursor: 'pointer' }}>
          <img className='Icon32' src={wand} alt='' onClick={fillPrices} />
        </span>
      </p>
      <div className='FullWidth'>
        <FormGroup>
          <FormControlLabel
            style={{
              // margin: '6px 0px 6px -11px',
              width: 'fit-content',
            }}
            control={
              <Checkbox
                id='accounting'
                checked={liabilityIns.accounting}
                onChange={(event) => {
                  dispatch(
                    ACTIONS.addProviderInfo({
                      id: event.target.id,
                      data: event.target.checked,
                      stateObject: 'provider_liability_insurance',
                    })
                  );
                }}
              />
            }
            label={
              <span className='SFCheckboxLabel'>
                {i18n.t('provider.accountingins')}
              </span>
            }
          />
        </FormGroup>
      </div>
      {providerCompanyTypeList().map((item, i) => {
        if (companyTypeFilter[item.id]) {
          return (
            <div className='AdornmentInputContainer Price' key={i}>
              <TextField
                className={classes.root}
                id={`${item.id}price`}
                variant='outlined'
                type='text'
                /*   wand={item.label} */
                value={accountingPrice[`${item.id}price`]}
                label={item.label}
                onChange={(event) => {
                  handleChange(event, 'provider_acc_monthly_price');
                  if (event.target.value && inputError[`${item.id}price`]) {
                    setInputError({
                      ...inputError,
                      [`${item.id}price`]: false,
                    });
                  }
                }}
                error={inputError[`${item.id}price`]}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setInputError({ ...inputError, [`${item.id}price`]: true });
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span
                        style={{
                          color: inputError[`${item.id}price`]
                            ? 'red'
                            : '#2D46B9',
                        }}
                      >
                        {i18n.t('general.currency/month')}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        } else return null;
      })}
      <p className='FullWidth'>{i18n.t('provider.extrafees')}</p>
      <ExtraFeeInputs
        dataText={invoicesExtra()}
        title={i18n.t('provider.invoiceextrafees')}
        endAdornment={i18n.t('general.currency/piece')}
      />
      {specialRestr.insideEU || specialRestr.outsideEU ? (
        <ExtraFeeInputs
          dataText={foreignTradeExtra()}
          title={i18n.t('provider.foreigntradeextrafees')}
          endAdornment='%'
        />
      ) : null}
      <ExtraFeeInputs
        revenue
        dataText={revenueExtra()}
        title={i18n.t('provider.revenueextrafees')}
        endAdornment='%'
      />
      <ExtraFeeInputs
        dataText={vatExtra()}
        title={i18n.t('provider.vatextrafees')}
        endAdornment='%'
      />
      <ExtraFeeInputs
        dataText={severalLocationsExtra()}
        title={'Egyéb felár'}
        endAdornment='Ft / darab / hó'
      />
      <ExtraFeeInputs
        dataText={etradeExtra()}
        title={'Webshop felárak'}
        endAdornment='Ft '
      />
      <ExtraFeeInputs
        dataText={tourismExtra()}
        title={'Szállás, vendéglátás felárak'}
        endAdornment='Ft' />
    </div>
  );
};

export default withTranslation()(AccountingInputs);
