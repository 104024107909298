import React from 'react';
import { Paper } from '@mui/material';
import { providerServicesText2 } from '../../data/providerText';
import i18n from '../../../utils/i18n';

const Services = ({ data }) => {
  return (
    <>
      <Paper className='ProvProfileContentPaper ProvDarkGreyText'>
        <h2>{i18n.t('general.services')}</h2>
        <div className='ProvProfileServices'>
          {providerServicesText2().map((item) => {
            if (item.id !== 'idk' && data[item.id]) {
              return <p>{item.label}</p>;
            } else return null;
          })}
        </div>
      </Paper>
    </>
  );
};

export default Services;
