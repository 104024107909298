import React from "react";
import ReviewAverage from "./reviewAverage";
import ReviewPaper from "./reviewPaper";

const ProviderReviews = ({ reviews, reviewScores }) => {
  const displayReviews = () => {
    let reviewsToDisplay = [];
    reviews.forEach((item) => {
      if (item.review.length !== 0) {
        reviewsToDisplay.push(
          <div style={{ margin: "16px 0px" }}>
            <ReviewPaper review={item} />
          </div>
        );
      }
    });
    return reviewsToDisplay;
  };
  return (
    <div className="ReviewsReviewContainer">
      {reviews && reviewScores ? (
        <>
          <div>
            <ReviewAverage reviewScores={reviewScores} />
          </div>
          <div>{displayReviews()}</div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProviderReviews;
