/* import React from "react"; */
import decode from 'jwt-decode';
//REDUX
/* import { useDispatch } from "react-redux";
import ACTIONS from "../redux/action"; */

/* export const UpdateUserLoggedInState = () => {
  const dispatch = useDispatch();
  return (
    <>
      {dispatch(
        ACTIONS.addProviderInfo({
          id: "status",
          data: true,
          stateObject: "loggedIn",
        })
      )}
    </>
  );
}; */

class Auth {
  constructor() {
    this.authenticated = false;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    const JWT = localStorage.getItem('JWT');
    /* const refreshJWT = localStorage.getItem("refreshJWT"); */

    if (!JWT /* || !refreshJWT */) {
      return false;
    }
    try {
      const { exp } = decode(JWT);
      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }
}

export default new Auth();
