import React, { useCallback } from 'react';
import CompanySizeInput from './input/companySizeInput';
import InputAdornment from '@mui/material/InputAdornment';
import ServicesInput from './input/servicesInput';
//import ExtraInfoInput from './input/extraInfoInput';
import TextField from '@mui/material/TextField';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { couponCodes } from '../../data/providerText';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

import { Paper } from '@mui/material';
//REDUX
import ACTIONS from '../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';

const ProviderServices = ({ activeStep, handleNext }) => {
  const dispatch = useDispatch();
  const [providerServices, providerSubscription] = useSelector((state) => [
    state.provider_services,
    state.provider_subscription,
  ]);
  const [error, setError] = React.useState(false);

  const checkServices = useCallback(() => {
    for (let i in providerServices) {
      if (providerServices[i] === true) {
        setError(false);
        return true;
      }
    }
    setError(true);
    return false;
  }, [providerServices]);

  React.useEffect(() => {
    if (error) {
      checkServices();
    }
  }, [checkServices, error]);

  const handleChange = (event) => {
    dispatch(
      ACTIONS.addProviderInfo({
        id: 'partnerCode',
        data: event.target.value,
        stateObject: 'provider_subscription',
      })
    );
  };

  return (
    <div className='PaddingBot100'>
      <div className='ProviderDataStepContent'>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>{i18n.t('provider.companysize')}</p>
          <CompanySizeInput />
        </Paper>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>{i18n.t('provider.servicesudo')}</p>
          <ServicesInput checkServices={checkServices} />
          {error ? (
            <span className='ErrorText'>
              {i18n.t('provider.selectservices')}
            </span>
          ) : null}
        </Paper>
        {/* <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>
            {i18n.t('general.directcontactlabel')}
          </p>
          <ExtraInfoInput />
        </Paper> */}
        <Paper className='providerPaper'>
          <div className='PaymentMethod'>
            <div>
              <p className='ProviderInputTitle'>
                {i18n.t('provider.partnercodetext')}
              </p>
              <div className='PartnerCodeInput'>
                <TextField
                  className='AdornmentInputContainer'
                  id='partnerCode'
                  label={i18n.t('provider.partnercode')}
                  variant='outlined'
                  type='text'
                  value={providerSubscription.partnerCode}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {providerSubscription.partnerCode ? (
                          providerSubscription.partnerCode.toLowerCase() ===
                            'mkoestart' ||
                          couponCodes[
                            providerSubscription.partnerCode.toLowerCase()
                          ] ? (
                            <CheckCircleIcon sx={{ color: '#009b60' }} />
                          ) : (
                            <CancelIcon sx={{ color: 'red' }} />
                          )
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <ProviderNavigation
        handleNext={() => {
          if (checkServices()) {
            handleNext();
          }
        }}
        step={activeStep}
      />{' '}
    </div>
  );
};

export default withTranslation()(ProviderServices);
