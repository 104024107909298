import { createStore, compose, applyMiddleware } from 'redux';
// PERSIST
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import ReduxThunk from 'redux-thunk';
/* import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"; */
import reducer from './reducer';
import initialState from './storeInitialState';

// PERSIST CONFIG
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['company_store', 'match_score_weights', 'request_data'],
  /* stateReconciler: autoMergeLevel2, */
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancer(applyMiddleware(ReduxThunk))
);
export const persistor = persistStore(store);
