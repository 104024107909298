import React from 'react';
import UploadImage from '../../../../images/icons/upload-image-new.svg';
import ProfileImagePlaceholder from '../../../../images/icons/profile-image-placeholder-new.svg';
import axios from '../../../../shared/axios/axiosProxy';
import i18n from '../../../../utils/i18n';
import { withTranslation } from 'react-i18next';

//REDUX
import ACTIONS from '../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
const ProfileImageInput = () => {
  const dispatch = useDispatch();
  const providerMedia = useSelector((state) => state.provider_media);
  const providerID = useSelector((state) => state.providerID);
  const [profileImg, setProfileImg] = React.useState(
    providerMedia.profileImage
  );

  const uploadInput = React.useRef(null);
  const randomNr = Math.floor(Math.random() * 1000000000);

  const handleClick = () => {
    uploadInput.current.click();
  };

  const handleUpload = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    /* eslint-disable no-unused-vars */
    var url = reader.readAsDataURL(file);
    /* eslint-disable no-unused-vars */
    reader.onloadend = function (e) {
      dispatch(
        ACTIONS.addProviderInfo({
          id: 'profileImage',
          data: `https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_${providerID}?${randomNr}`,
          stateObject: 'provider_media',
        })
      );
      setProfileImg(reader.result);
      const JWT = localStorage.getItem('JWT');
      axios
        .patch(
          '/api/provider/picture',
          {
            provider_media: {
              profileImage: `https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_${providerID}?${randomNr}`,
            },
            image: reader.result,
          },
          {
            headers: {
              Authorization: `Bearer ${JWT}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((error) => {
          console.log('AXIOS ERROR: ', error.response.data);
        })
        .catch((error) => console.log('submitToDB error', error));
    };
  };
  return (
    <div className='Column FlexCenter'>
      <div className='ProfileImage'>
        <img
          style={{ width: '200px', height: '200px', margin: '10px' }}
          src={profileImg.length > 0 ? profileImg : ProfileImagePlaceholder}
          alt=''
        />
        <img
          style={{
            width: '40px',
            height: '40px',
          }}
          onClick={handleClick}
          className='UploadIcon'
          src={UploadImage}
          alt=''
        />
      </div>
      <input
        accept='image/*'
        id='contained-button-file'
        multiple
        type='file'
        onChange={(event) => handleUpload(event)}
        ref={uploadInput}
        style={{ display: 'none' }}
      />
      <p className='StandardText SecondaryBlue Bold Margin0'>
        {i18n.t('general.uploadpicture')}
      </p>
    </div>
  );
};

export default withTranslation()(ProfileImageInput);
