import React, { useCallback } from 'react';
import Radio from '@mui/material/Radio';
//REDUX
import ACTIONS from '../../../../../../redux/action';
import { useSelector, useDispatch } from 'react-redux';
import { providerCompanyIndustryList } from '../../../../../data/providerText';

const experienceRatings = [1, 2, 3, 4, 5];

export default function ExperienceInput({ checkModified }) {
  const dispatch = useDispatch();
  const providerExperience = useSelector(
    (state) => state.provider_industry_experience
  );
  const providerIndustryRestrictions = useSelector(
    (state) => state.provider_industry_restrictions
  );
  const handleChange = (event) => {
    checkModified(true);
    let value = event.target.value;
    dispatch(
      ACTIONS.addProviderInfo({
        id: event.target.id,
        data: parseInt(value),
        stateObject: 'provider_industry_experience',
      })
    );
  };
  const industryCheck = useCallback(() => {
    let industryShown = [];
    providerCompanyIndustryList().forEach((item, i) => {
      let filteredArr = providerIndustryRestrictions.filter((ind) => ind.on);
      filteredArr.forEach((filtereditem) => {
        if (filtereditem.id === item.id) {
          industryShown.push(
            <div className='CenteredInput' key={i + item}>
              <p>{item.label}</p>
              {experienceRatings.map((exp) => {
                return (
                  <Radio
                    key={item + i}
                    id={item.id}
                    checked={providerExperience[item.id] === exp ? true : false}
                    onChange={handleChange}
                    value={exp}
                    inputProps={{ 'aria-label': 'A' }}
                    sx={{
                      color: '#1A00BD',
                      '&.Mui-checked': {
                        color: '#1A00BD',
                      },
                    }}
                  />
                );
              })}
            </div>
          );
        }
      });
    });
    return industryShown;
  }, [providerIndustryRestrictions, providerExperience]);
  return (
    <div className='ProviderInputContainerCentered'>
      <div className='ExperienceInputRatings FullWidth'>
        {experienceRatings.map((exp, i) => {
          return <p key={i + exp}>{exp}</p>;
        })}
      </div>
      <div
        className='ProviderInputContainer FullWidth'
        style={{ padding: '12px 24px' }}
      >
        {industryCheck()}
      </div>
    </div>
  );
}
