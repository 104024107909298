import React, { useEffect, useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ACTIONS from '../../../../redux/action';
import { useDispatch } from 'react-redux';
import RadioInput from '../searchInputs/radioInput';
import { deadlineInput, deadlineInput2 } from '../../data/searchFlowText';

export default function DeadlinePicker({ companyStore }) {
  const [startingdate, setStartingdate] = React.useState(new Date());
  const dispatch = useDispatch();
  //const companyStore = useSelector((state) => state.company_store);

  const setAutoStartingdate = useCallback(() => {
    const startingdate = new Date().setDate(new Date().getDate());
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'startingdate',
        data: startingdate,
        stateObject: 'company_store',
      })
    );
    setStartingdate(startingdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAutoStartingdate();
  }, [setAutoStartingdate]);

  const handleDateChange = (event) => {
    console.log(event.target.value, 'VALUE', typeof event.target.value);
    dispatch(
      ACTIONS.addCompanyInfo({
        id: 'startingdate',
        data: new Date().setDate(new Date().getDate()),
        stateObject: 'company_store',
      })
    );
    if (event.target.value === '0') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'chosendate',
          data: 'asap',
          stateObject: 'company_store',
        })
      );
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '0',
          stateObject: 'company_store',
        })
      );
    } else if (event.target.value === '14') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'chosendate',
          data: '2weeks',
          stateObject: 'company_store',
        })
      );
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '14',
          stateObject: 'company_store',
        })
      );
    } else if (event.target.value === '60') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'chosendate',
          data: '2months',
          stateObject: 'company_store',
        })
      );
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '60',
          stateObject: 'company_store',
        })
      );
    } else if (event.target.value === '180') {
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'chosendate',
          data: '6months',
          stateObject: 'company_store',
        })
      );
      dispatch(
        ACTIONS.addCompanyInfo({
          id: 'deadline',
          data: '180',
          stateObject: 'company_store',
        })
      );
    }

    setStartingdate(
      new Date().setDate(new Date().getDate() + event.target.value)
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RadioInput
          storeID='deadline'
          dataText={deadlineInput()}
          //handleChange={handleDateChange}
        />
        {/* <RadioInput
          storeID='onlyBrowsing'
          dataText={deadlineInput2()}
          //handleChange={handleCheck}
        /> */}
      </LocalizationProvider>
    </>
  );
}
