import React from 'react';
import {
  CompanyType,
  TaxconForm,
  Industry,
  RegContactForm,
} from './steps/index.js';
import axios from '../utils/axiosCompanyProxy.js';
import './regFlow.scss';
import i18n from '../../utils/i18n.js';
import { withTranslation } from 'react-i18next';

import peaking1 from '../../images/peaking.png';
import peaking2 from '../../images/peaking2.png';
import peaking3 from '../../images/peaking3.png';
import peaking4 from '../../images/peaking4.png';
import rocketman from '../../images/Rocketman.png';
import blueaccountant from '../../images/BlueAccountant.png';

//REDUX
import ACTIONS, {
  fetchAspPotentialProviders,
  fetchCherryPotentialProviders,
} from '../../redux/action.js';
import { connect } from 'react-redux';
import { checkStateChange } from './data/regFlowText.js';

const mapStateToProps = (state) => ({
  store: state,
  loading: state.loading,
  company_store: state.company_store,
  match_score_weights: state.match_score_weights,
  potential_providers: state.company_store.potential_providers,
});
const mapDispatchToProps = (dispatch) => ({
  addCompanyInfo: (item) => dispatch(ACTIONS.addCompanyInfo(item)),
  fetchAspPotentialProviders: (companyData, msWeights) =>
    dispatch(fetchAspPotentialProviders(companyData, msWeights)),
  fetchCherryPotentialProviders: (companyData, msWeights) =>
    dispatch(fetchCherryPotentialProviders(companyData, msWeights)),
});
class RegFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      reqID: '',
      cherryID: '',
      searchID: '',
      completedSteps: 0,
      kNetPlusSent: false,
      helpForm: false,
      stepData: `1 - ${new Date().toLocaleTimeString(
        i18n.t('general.countryformat'),
        {
          hour12: false,
        }
      )}`,
      viewedProviders: [],

      vacatiOn: false,
    };
  }

  dataLayerUpdate(stepData) {
    window.dataLayer.push({
      event: 'sf_step_changed',
      step: stepData,
      event_category: 'regflow',
    });
  }

  componentDidMount() {
    this.dataLayerUpdate(this.state.activeStep);
    console.log(this.props.company_store, 'KOMPANI SZTOR');
    //console.log(this.requestCallBack, 'this.requestCallBack');
    let cookie = document.cookie;
    if (!cookie.includes('reqsent')) {
      document.cookie = 'reqsent=false';
    }

    if (this.props.company_store.activeStep !== 0) {
      this.setState({ activeStep: 1 });
    }
  }
  componentDidUpdate(_prevProps, prevState) {
    //console.log(this.state.reqID, 'this.state.reqID IN COMPDIDUPDATE');
    this.handleSearchUpdate(prevState.activeStep);
    if (
      this.state.completedSteps >= 14 &&
      checkStateChange(_prevProps.company_store, this.props.company_store) ===
        true
      // && this.checkServices() === true
    ) {
      this.props.fetchAspPotentialProviders(
        this.props.company_store,
        this.props.match_score_weights
      );
    }
    if (
      this.state.completedSteps >= 15 &&
      this.state.reqID.length > 0 &&
      checkStateChange(_prevProps.company_store, this.props.company_store)
      // && this.checkServices() === true
    ) {
      //this.setState({ reqID: '' });
      this.props.addCompanyInfo({
        id: 'selected_providers',
        data: [],
        stateObject: 'company_store',
      });
    }
  }

  handleNext = (increment = 1) => {
    //console.log(increment, typeof increment, 'INCREMENT');
    if (this.state.activeStep <= 15) {
      if (this.state.searchID === '') {
        this.submitSearchData();
      }
      this.setState({
        activeStep: this.state.activeStep + increment,
      });
      this.dataLayerUpdate(this.state.activeStep + increment);
      if (this.state.completedSteps < this.state.activeStep) {
        this.setState({
          completedSteps: this.state.completedSteps + increment,
        });
      }
      this.props.addCompanyInfo({
        id: 'isCommission',
        data: true,
        stateObject: 'company_store',
      });
    }
    //console.log(this.props.company_store, 'KOMPANI');
    window.scrollTo(0, 0);
  };
  handleBack = (decr = 1) => {
    // window.dataLayer.push({
    //   event: 'sf_step_changed',
    //   step: this.state.activeStep - decr,

    //   event_category: 'regflow',
    // });
    //console.log(decr, typeof decr, 'decrement');
    if (this.state.activeStep !== 0) {
      this.setState({ activeStep: this.state.activeStep - decr });
    }
    window.scrollTo(0, 0);
  };
  handleTracker = (step) => {
    this.setState({ activeStep: step });
  };
  handleKnetPlusContact = () => {
    this.setState({ kNetPlusSent: true });
  };
  openHelpForm = () => {
    this.setState({ helpForm: true });
  };
  closeHelpForm = () => {
    this.setState({ helpForm: false });
  };
  createAspRequest = (companyData) => {
    let timeOfConfirm = this.state.stepData.concat(
      ` Req sent - ${new Date().toLocaleTimeString(
        i18n.t('general.countryformat'),
        {
          hour12: false,
        }
      )}`
    );
    let data = Object.assign({}, companyData);
    // let ppArray = [[], [], []];
    // for (var i in data.potential_providers) {
    //   for (let j in i) {
    //     ppArray[i].push(data.potential_providers[i][j]);
    //   }
    // }
    // data.potential_providers = ppArray;
    data.searchID = this.state.searchID;
    if (data.noofemployees === '') {
      data.noofemployees = 0;
    }
    if (data.incominginvoices === '') {
      data.incominginvoices = 0;
    }
    if (data.outgoinginvoices === '') {
      data.outgoinginvoices = 0;
    }

    //console.log(data, 'ASPO REQ DATA');
    //data.lang = this.props.company_store.chosenLanguage;
    axios
      .post(`/api/aspreq`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // console.log('AXIOS RESPONSE: ', response);
        sessionStorage.setItem('reqEmail', data.email);
        //itt majd nézd már meg kérlek hogy lehet elérni az _id-t a response-ban és az 124 helyett írd be azt
        /* sessionStorage.setItem('reqID', '124'); */
        this.setState({ reqID: response.data.result._id });
        this.props.addCompanyInfo({
          id: 'reqID',
          data: response.data.result._id,
          stateObject: 'company_store',
        });
        this.updateSearchData(timeOfConfirm);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };
  requestCallBack = (companyData) => {
    let data = Object.assign({}, companyData);
    //console.log(data, 'ASPO REQ DATA');

    axios
      .post(`/api/aspreq/requestcallback/new`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
        this.setState({ reqID: response.data.result._id });
        this.props.addCompanyInfo({
          id: 'reqID',
          data: response.data.result._id,
          stateObject: 'company_store',
        });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  createCherryRequest = (companyData) => {
    let timeOfConfirm = this.state.stepData.concat(
      ` Req sent - ${new Date().toLocaleTimeString(
        i18n.t('general.countryformat'),
        {
          hour12: false,
        }
      )}`
    );
    let data = Object.assign({}, companyData);
    let ppArray = [];
    for (var i in data.potential_providers) {
      ppArray.push(data.potential_providers[i]);
    }
    data.potential_providers = ppArray;
    data.searchID = this.state.searchID;
    if (data.noofemployees === '') {
      data.noofemployees = 0;
    }
    if (data.incominginvoices === '') {
      data.incominginvoices = 0;
    }
    if (data.outgoinginvoices === '') {
      data.outgoinginvoices = 0;
    }

    //console.log(data, 'CHERRY REQ DATA');
    //data.lang = this.props.company_store.chosenLanguage;
    axios
      .post(`/api/request`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //sessionStorage.setItem('reqEmail', data.email);
        //itt majd nézd már meg kérlek hogy lehet elérni az _id-t a response-ban és az 124 helyett írd be azt
        //sessionStorage.setItem('reqID', response.data.result._id);
        //console.log('AXIOS RESPONSE: ', response);
        this.setState({ cherryID: response.data.result._id });
        this.props.addCompanyInfo({
          id: 'cherryID',
          data: response.data.result._id,
          stateObject: 'company_store',
        });
        if (this.state.reqID === '') {
          this.setState({ reqID: response.data.result._id });
          this.props.addCompanyInfo({
            id: 'reqID',
            data: response.data.result._id,
            stateObject: 'company_store',
          });
        }
        this.updateSearchData(timeOfConfirm);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error);
      });
  };
  updateRequest = (companyData, reqID = this.state.reqID) => {
    let data = Object.assign({}, companyData);
    if (data.potential_providers) {
      delete data.potential_providers;
    }
    if (data.noofemployees === '') {
      data.noofemployees = 0;
    }
    //console.log(data, this.state.reqID, 'DATA AND REQID IN UPDATE');
    let dataToSend = { reqData: data, reqID: reqID };
    axios
      .patch(`/api/request/addprov`, dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };
  updateFakeCherryRequest = (companyData) => {
    let data = Object.assign({}, companyData);
    // if (data.potential_providers) {
    //   delete data.potential_providers;
    // }
    if (data.noofemployees === '') {
      data.noofemployees = 0;
    }
    //console.log(this.state.reqID, 'REQID');
    let dataToSend = { reqData: data, reqID: this.state.reqID };
    //console.log(dataToSend, 'DATA AND REQID IN UPDATE');
    axios
      .patch(`/api/aspreq/addprov/addfakecherry`, dataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };


  submitSearchData = () => {
    let data = this.props.company_store;
    data.step = this.state.activeStep + 1;
    data.time = this.state.stepData;
    data.returnedProviders = 0;
    data.screenWidth = `${window.innerWidth}`;
    data.lang = this.props.company_store.chosenLanguage;
    axios
      .post(`/api/search`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.statusText);
        this.setState({ searchID: response.data.result._id });
        response.data.result._id &&
          this.props.addCompanyInfo({
            id: 'searchID',
            data: response.data.result._id,
            stateObject: 'company_store',
          });
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };
  updateSearchData = (time) => {
    let data = this.props.company_store;
    data.lang = this.props.company_store.chosenLanguage;
    if (!this.state.requestSent) {
      data.step = this.state.activeStep + 1;
    }
    data.time = time;
    data.reqID = this.state.reqID;
    data.distanceWeight = this.props.store.match_score_weights.distanceWeight;
    data.priceWeight = this.props.store.match_score_weights.priceWeight;
    data.reviewWeight = this.props.store.match_score_weights.reviewWeight;
    data.phoneWeight = this.props.store.match_score_weights.phoneWeight;
    data.availabilityWeight =
      this.props.store.match_score_weights.availabilityWeight;
    data.experienceWeight =
      this.props.store.match_score_weights.experienceWeight;
    data.liabilityInsWeight =
      this.props.store.match_score_weights.liabilityInsWeight;
    data.badgeWeight = this.props.store.match_score_weights.badgeWeight;
    console.log(this.props.potential_providers, 'EEEEE');
    if (
      this.props.potential_providers !== undefined &&
      this.props.potential_providers.constructor === Object &&
      this.props.potential_providers.some((e) => e.length !== 0)
    ) {
      let rPCounter = 0;
      this.props.potential_providers.forEach((e) => {
        console.log(e, 'EEEEE');
        rPCounter += e.length;
      });

      data.returnedProviders = rPCounter;
      let tempArray = [[], [], []];
      for (let i in this.props.potential_providers) {
        for (let j in i) {
          tempArray[i].push(this.props.potential_providers[i][j].companyname);
        }
      }
      data.providers = tempArray;
    }
    axios
      .patch(`/api/search/${this.state.searchID}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response.statusText);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };
  handleSearchUpdate = (prevStep) => {
    if (this.state.activeStep !== prevStep) {
      let timeString = this.state.stepData.concat(
        `, ${this.state.activeStep + 1} - ${new Date().toLocaleTimeString(
          'hu-HU',
          {
            hour12: false,
          }
        )}`
      );
      this.setState({
        stepData: timeString,
      });
      if (this.state.activeStep !== 1) {
        this.updateSearchData(timeString);
      }
    }
    if (prevStep === 'tableUpdate') {
      this.updateSearchData(this.state.stepData);
    }
  };
  updateViewedProviders = (provider) => {
    let data = {};
    let viewedProviders = this.state.viewedProviders;
    if (viewedProviders.length === 0) {
      viewedProviders.push(provider);
    }
    if (
      viewedProviders.length > 0 &&
      !viewedProviders.some((item) => item.name === provider.name)
    ) {
      viewedProviders.push(provider);
    }
    data.viewedProviders = viewedProviders;
    this.setState({
      viewedProviders: viewedProviders,
    });
    axios
      .patch(`/api/search/${this.state.searchID}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('AXIOS RESPONSE: ', response);
      })
      .catch((error) => {
        console.log('AXIOS ERROR: ', error.response.data);
      });
  };

  stepperCounter = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
  };

  headerText = {
    0: (
      <div>
        {/* <p className='headerSubText'>{i18n.t('searchflow.headertext1')}</p>
        <p className='headerText'>{i18n.t('searchflow.headertext1a')}</p> */}
        <p className='regFlowHeaderText'>{i18n.t('searchflow.headertext5')}</p>
      </div>
    ),
    1: (
      <div>
        {/* <p className='headerSubText'></p>
        <p className='headerText'>{i18n.t('searchflow.vaflabel')}</p> */}
        <p className='regFlowHeaderText'>{i18n.t('searchflow.headertext5')}</p>
      </div>
    ),
    2: (
      <div>
        <p className='headerSubText'>{i18n.t('searchflow.headertext3b')}</p>
        <p className='regFlowHeaderText'>{i18n.t('searchflow.headertext3c')}</p>
      </div>
    ),
    3: (
      <div>
        <p className='headerSubText'>{i18n.t('searchflow.sfresulttext2')}</p>
        <p className='regFlowHeaderText'>{i18n.t('searchflow.whatnowtext2')}</p>
      </div>
    ),
  };
  imageSource = {
    0: peaking1,
    1: peaking2,
    2: peaking3,
    3: peaking4,
  };

  getStepContent = (step) => {
    switch (step) {
      case 0: {
        return (
          <CompanyType
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            openHelpForm={this.openHelpForm}
            addCompanyInfo={this.addCompanyInfo}
          />
        );
      }
      case 1: {
        return (
          <Industry
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            openHelpForm={this.openHelpForm}
            addCompanyInfo={this.addCompanyInfo}
          />
        );
      }
      case 2: {
        return (
          <>
            <TaxconForm
              handleNext={this.handleNext}
              handleBack={this.handleBack}
              openHelpForm={this.openHelpForm}
              addCompanyInfo={this.addCompanyInfo}
            />
          </>
        );
      }
      case 3: {
        return (
          <RegContactForm
            addCompanyInfo={this.addCompanyInfo}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            createAspRequest={this.createAspRequest}
            updateRequest={this.updateRequest}
            reqID={this.state.reqID}
            openHelpForm={this.openHelpForm}
            updateViewedProviders={this.updateViewedProviders}
            handleSearchUpdate={this.handleSearchUpdate}
            discountCode={this.state.discountCode}
            requestCallBack={this.requestCallBack}
          />
        );
      }
      default:
        return i18n.t('general.notfound');
    }
  };

  render() {
    return (
      <div className={'RFContainer'}>
        {/*<Dialog
          open={this.state.vacatiOn}
          onClose={() => this.setState({ vacatiOn: false })}
        >
          <div className='announcement'>
            <span
              className='Xbtn'
              onClick={() => this.setState({ vacatiOn: false })}
            >
              X
            </span>
            Kedves Könyvelőt Keresők! <br />
            <br /> A Bookkeepie Csapata és Könyvelő Partnereink nagy része is
            téli szabadságát tölti (2023.12.21-től 2024.01.02-ig), ebben az
            időben kérlek kicsit lassabb ügyintézésre számítsatok. Amint
            visszatértünk, mindannyiótoknak segítünk!
          </div>
    </Dialog>*/}
        {this.state.activeStep !== 15 && this.state.activeStep !== 16 && (
          <div className='RFLeftInfoArea'>
            <img src={rocketman} alt='RocketMan' className='rocketman' />
          </div>
        )}
        <div
          className={`${
            this.state.activeStep !== 15 && this.state.activeStep !== 16
              ? 'RFContentArea'
              : 'RFOfferArea'
          }`}
        >
          {this.state.activeStep !== 15 && this.state.activeStep !== 16 && (
            <div className='RFContentHeader'>
              <div>{this.headerText[this.state.activeStep]}</div>

              <div>
                <img
                  className='headerImg'
                  alt='header'
                  src={this.imageSource[this.state.activeStep]}
                />
              </div>
            </div>
          )}

          {this.getStepContent(this.state.activeStep)}
        </div>
        {this.state.activeStep !== 15 && this.state.activeStep !== 16 && (
          <div className='RFRightInfoArea'>
            <img
              src={blueaccountant}
              alt='Accountant lady'
              className='blueaccountant'
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegFlow)
);
