import React from 'react';
import DescriptionInput from './input/descriptionInput';
import ProviderNavigation from '../components/providerNavigation/providerNavigation';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import { Paper } from '@mui/material';
// import { descriptionGuide } from './descriptionGuide';

const ProviderDescription = ({
  handleNext,
  handleBack,
  activeStep,
  setGuide,
  submitToDB,
}) => {
  React.useEffect(() => {
    let guide = (
      <div className='ProviderDataHelpContent'>
        <h2>{i18n.t('provider.descriptionguidelabel')}</h2>
        <ul>
          {/* {descriptionGuide.map((e, idx)=> {
            return <li key={idx}>{e}</li>
          })} */}
          <li>{i18n.t('provider.descriptionguidetext1')}</li>
          <li>{i18n.t('provider.descriptionguidetext2')}</li>
          <li>{i18n.t('provider.descriptionguidetext3')}</li>
          <li>{i18n.t('provider.descriptionguidetext4')}</li>
          <li>{i18n.t('provider.descriptionguidetext5')}</li>
          <li>{i18n.t('provider.descriptionguidetext6')}</li>
          <li>{i18n.t('provider.descriptionguidetext7')}</li>
          <li>{i18n.t('provider.descriptionguidetext8')}</li>
          <li>{i18n.t('provider.descriptionguidetext9')}</li>
          <li>{i18n.t('provider.descriptionguidetext10')}</li>
          <li>{i18n.t('provider.descriptionguidetext11')}</li>
          <li>{i18n.t('provider.descriptionguidetext12')}</li>
          <li>{i18n.t('provider.descriptionguidetext13')}</li>
        </ul>
      </div>
    );
    setGuide(guide);
  }, [setGuide]);

  return (
    <div className='PaddingBot100'>
      <div className='ProviderDataStepContent'>
        <Paper className='providerPaper'>
          <p className='ProviderInputTitle'>
            {i18n.t('provider.descriptionlabel')}
          </p>
          <div className='ProviderInputContainer Description'>
            <DescriptionInput submitToDB={submitToDB} />
          </div>
        </Paper>
      </div>
      <ProviderNavigation
        handleBack={() => {
          handleBack();
        }}
        handleNext={() => handleNext()}
        step={activeStep}
      />
    </div>
  );
};

export default withTranslation()(ProviderDescription);
