import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import ACTIONS from '../../../redux/action';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';
import DeadlinePicker from '../components/deadlinePicker/deadlinePicker';

import { TextField } from '@mui/material';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles({
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto #1c32f3',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#1c32f3',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#1c31f38b',
		},
	},
});

const Needs = ({ ...props }) => {
	const companyStore = useSelector((state) => state.company_store);
	const [answer, setAnswer] = React.useState(companyStore.answer);
	const [question, setQuestion] = React.useState(companyStore.question);

	const [companyctcname, setCompanyCtcName] = React.useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const classes = useStyles();
	const handleNext = useCallback(
		(inc) => {
			props.handleNext(inc);
		},
		[props]
	);

	useEffect(() => {
		setCompanyCtcName(companyStore.companyctcname);
	}, []);

	useEffect(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 13) {
				handleNext();
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleNext]);

	const clickHandler2 = (event) => {
		dispatch(
			ACTIONS.addCompanyInfo({
				id: 'multiplecompany',
				data: event.target.value,
				stateObject: 'company_store',
			})
		);
	};

	const clickHandler = (event) => {
		// console.log(event.target.value);
		if (event.target.value === 'existing') {
			setAnswer(event.target.value);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'answer',
					data: 'existing',
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'accounting',
					data: true,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'newlyfounded',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'legalentityfounding',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingcomplex',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingsimple',
					data: false,
					stateObject: 'company_store',
				})
			);
		}
		if (event.target.value === 'founding') {
			setAnswer(event.target.value);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'answer',
					data: 'founding',
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'accounting',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'newlyfounded',
					data: true,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'legalentityfounding',
					data: true,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingcomplex',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingsimple',
					data: false,
					stateObject: 'company_store',
				})
			);
		}

		if (event.target.value === 'questions') {
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'answer',
					data: 'questions',
					stateObject: 'company_store',
				})
			);
			setAnswer(event.target.value);
		}
		if (event.target.value === 'noncorp') {
			setQuestion(event.target.value);
			window.open('https://t40tz85n2uw.typeform.com/to/IegeybN3', '_blank');
		}
		if (event.target.value === 'blog') {
			setQuestion(event.target.value);
			window.open('https://www.bookkeepie.com/hu/blog', '_blank');
		}
		if (event.target.value === 'taxsimple') {
			setQuestion(event.target.value);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'question',
					data: 'taxsimple',
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingsimple',
					data: true,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'accounting',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingcomplex',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'legalentityfounding',
					data: false,
					stateObject: 'company_store',
				})
			);
			navigate('/company/request/vaf');
		}
		if (event.target.value === 'taxcomplex') {
			setQuestion(event.target.value);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'question',
					data: 'taxcomplex',
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingcomplex',
					data: true,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'accounting',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'legalentityfounding',
					data: false,
					stateObject: 'company_store',
				})
			);
			dispatch(
				ACTIONS.addCompanyInfo({
					id: 'taxconsultingsimple',
					data: false,
					stateObject: 'company_store',
				})
			);
		}
	};

	//console.log(companyStore, 'CS', answer);

	return (
		<div className="stepContainer">
			<div>
				<div className="SFInput">
					<div>
						<p className="SFSubTitle">
							<span className="SFSubTitle">
								{i18n.t('searchflow.whatcanwecallyou')}
							</span>{' '}
							<span className="questionTracker">1/4</span>
						</p>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								fontSize: '16px',
							}}>
							<div className="SFNameInput">
								<TextField
									className="SFNameInput"
									variant="outlined"
									id="companyctcname"
									type="text"
									name="companyctcname"
									value={companyctcname}
									onChange={(event) => {
										setCompanyCtcName(event.target.value);
									}}
									onBlur={(event) => {
										dispatch(
											ACTIONS.addCompanyInfo({
												id: 'companyctcname',
												data: companyctcname,
												stateObject: 'company_store',
											})
										);
									}}
									label={i18n.t('searchflow.yourname')}
								/>
							</div>
						</div>
					</div>
					<hr />
					<div>
						<FormControl component="fieldset" style={{ width: '100%' }}>
							<FormLabel component="legend" className="SFSubTitle">
								<span className="SFSubTitle">
									{i18n.t('searchflow.sfservicestext1')}
								</span>{' '}
								<span className="questionTracker">2/4</span>
							</FormLabel>
							<RadioGroup
								aria-label="gender"
								name="gender1"
								value={answer}
								onChange={(event) => {
									clickHandler(event);
									//console.log(event.target.value);
								}}>
								<FormControlLabel
									className="SFRadioInput"
									value="existing"
									labelPlacement="start"
									control={
										<Radio
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											{...props}
										/>
									}
									label={i18n.t('searchflow.step1text1')}
								/>
								<FormControlLabel
									className="SFRadioInput"
									value="founding"
									labelPlacement="start"
									control={
										<Radio
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											{...props}
										/>
									}
									label={i18n.t('searchflow.step1text2')}
								/>
								<FormControlLabel
									className="SFRadioInput"
									value="questions"
									labelPlacement="start"
									control={
										<Radio
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											{...props}
										/>
									}
									label={i18n.t('searchflow.step1text3')}
								/>
							</RadioGroup>
						</FormControl>

						{answer === 'questions' && (
							<FormControl
								component="fieldset"
								style={{ marginTop: '36px', width: '100%' }}>
								<FormLabel component="legend" className="SFSubTitle">
									{i18n.t('searchflow.step1questiontitletext')}
								</FormLabel>
								<RadioGroup
									aria-label="question"
									name="question"
									value={question}
									onChange={(event) => {
										clickHandler(event);
										console.log(event.target.value);
									}}>
									<FormControlLabel
										className="SFRadioInput"
										value="taxsimple"
										labelPlacement="start"
										control={
											<Radio
												className={classes.root}
												disableRipple
												color="default"
												checkedIcon={
													<span
														className={clsx(classes.icon, classes.checkedIcon)}
													/>
												}
												icon={<span className={classes.icon} />}
												{...props}
											/>
										}
										label={i18n.t('searchflow.step1text4')}
									/>
									<FormControlLabel
										className="SFRadioInput"
										value="blog"
										labelPlacement="start"
										control={
											<Radio
												className={classes.root}
												disableRipple
												color="default"
												checkedIcon={
													<span
														className={clsx(classes.icon, classes.checkedIcon)}
													/>
												}
												icon={<span className={classes.icon} />}
												{...props}
											/>
										}
										label={i18n.t('searchflow.step1text6')}
									/>
									<FormControlLabel
										className="SFRadioInput"
										value="noncorp"
										labelPlacement="start"
										control={
											<Radio
												className={classes.root}
												disableRipple
												color="default"
												checkedIcon={
													<span
														className={clsx(classes.icon, classes.checkedIcon)}
													/>
												}
												icon={<span className={classes.icon} />}
												{...props}
											/>
										}
										label={i18n.t('searchflow.step1text7')}
									/>
								</RadioGroup>
							</FormControl>
						)}
					</div>
					<hr />
					<div>
						<FormControl component="fieldset" style={{ width: '100%' }}>
							<FormLabel component="legend" className="SFSubTitle">
								<span className="SFSubTitle">
									{i18n.t('searchflow.generaltext2')}
								</span>{' '}
								<span className="questionTracker">3/4</span>
							</FormLabel>
							<RadioGroup
								aria-label="multiplecompany"
								name="multiplecompany"
								value={companyStore.multiplecompany}
								onChange={(event) => {
									clickHandler2(event);
									// console.log(event.target.value);
								}}>
								<FormControlLabel
									className="SFRadioInput"
									value="singlecompany"
									labelPlacement="start"
									control={
										<Radio
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											{...props}
										/>
									}
									label={i18n.t('searchflow.generaltext4')}
								/>
								<FormControlLabel
									className="SFRadioInput"
									value="multiplecompany"
									labelPlacement="start"
									control={
										<Radio
											className={classes.root}
											disableRipple
											color="default"
											checkedIcon={
												<span
													className={clsx(classes.icon, classes.checkedIcon)}
												/>
											}
											icon={<span className={classes.icon} />}
											{...props}
										/>
									}
									label={i18n.t('searchflow.generaltext3')}
								/>
							</RadioGroup>
						</FormControl>
					</div>
					<hr />
					<div>
						<p className="SFSubTitle">
							<span className="SFSubTitle">
								{i18n.t('searchflow.sfservicestext8')}
							</span>{' '}
							<span className="questionTracker">4/4</span>
						</p>
						<DeadlinePicker companyStore={companyStore} />
					</div>
					<Navigation
						step={0}
						handleNext={() =>
							companyStore.multiplecompany === 'multiplecompany'
								? window.open(
										'https://t40tz85n2uw.typeform.com/tobbvallalkozas',
										'_blank'
								  )
								: handleNext()
						}
						openHelpForm={() => props.openHelpForm()}
						disabledProp={
							answer !== '' &&
							companyStore.multiplecompany !== '' &&
							companyStore.chosendate !== ''
								? false
								: true
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(Needs);
