import React from 'react';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import InfoInputs from './inputs/infoInputs';
import i18n from '../../../../../utils/i18n';
import { Alert, Snackbar } from '@mui/material';
//REDUX
import { useSelector } from 'react-redux';
import { profileCompanyInfoText } from '../../../../data/providerText';

const CompanyInfo = ({ submitToDB }) => {
  const [edit, setEdit] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const [providerInfo, providerDiffContact, providerContact] = useSelector(
    (state) => [
      state.provider_info,
      state.provider_diff_contact,
      state.provider_contact,
    ]
  );
  const [open, setOpen] = React.useState(false);

  const checkError = React.useCallback((error) => {
    setError(error);
  }, []);

  const checkModified = (modified) => {
    setModified(modified);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <div className='CompanyInfo'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        style={{ width: '50%' }}
      >
        <Alert onClose={handleClose} severity='success' className='AlertWidth'>
          {i18n.t('general.savedmessage')}
        </Alert>
      </Snackbar>
      <h2>{i18n.t('providerdashboard.companydetails')}</h2>
      {edit ? (
        <>
          <InfoInputs checkError={checkError} checkModified={checkModified} />
          {error ? (
            <p className='ErrorText FullWidth'>
              {i18n.t('providerdashboard.dontleavempty')}
            </p>
          ) : null}
        </>
      ) : (
        <>
          {profileCompanyInfoText().map((item, i) => {
            let storeObject = providerInfo;
            if (item.storeObject === 'provider_contact') {
              storeObject = providerContact;
            }
            if (!providerDiffContact.diffc && item.id === 'contact')
              return null;
            else
              return (
                <div key={i}>
                  <p>{item.label}</p>
                  <p>
                    {!item.address
                      ? storeObject[item.id]
                      : `${storeObject[`${item.id}city`]} ${
                          storeObject[`${item.id}zip`]
                        }, ${storeObject[`${item.id}address`]}`}
                  </p>
                </div>
              );
          })}
        </>
      )}
      <StyledButton
        onClick={() => {
          if (!error) {
            setEdit(!edit);
          }
          if (edit && modified && !error) {
            submitToDB({
              provider_info: providerInfo,
              provider_contact: providerContact,
            });
            setModified(false);
            setOpen(true);
          }
        }}
        color={'Primary'}
      >
        {edit
          ? modified
            ? i18n.t('general.save')
            : i18n.t('general.backbutton')
          : i18n.t('general.modify')}
      </StyledButton>
    </div>
  );
};

export default CompanyInfo;
